import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const documentservice = {
    uploadDocument,
    getDocuments,
    removeDocument
}; 

async function uploadDocument(data:any,familyMemberId:number = 0) {
    const apiPath =  API_ENDPOINTS.UPLOAD_DOCUMENT + "?familyMemberId=" + familyMemberId;
    const options = {
        path: apiPath,
        method: "POST",
        formdata: data,
        customHeaders: {
                'Content-Type': 'multipart/form-data',
        },
    };
    return await makeRequest(options)
}

async function getDocuments(familyMemberId:number = 0) {
    const apiPath =  API_ENDPOINTS.GET_DOCUMENTS + "?familyMemberId=" + familyMemberId;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
                'Content-Type': 'application/json',
        },
    };
    return await makeRequest(options)
}

async function removeDocument(documentType:string, documentSide:string, familyMemberId:number = 0) {
    const apiPath =  API_ENDPOINTS.REMOVE_DOCUMENT + "?documentType=" + documentType + "&documentSide=" + documentSide + "&familyMemberId=" + familyMemberId;
    const options = {
        path: apiPath,
        method: "DELETE",
        customHeaders: {
                'Content-Type': 'application/json',
        },
    };
    return await makeRequest(options)
}