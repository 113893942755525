import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const notificationService = {
  getPatientActiveNoti,
  getLabReports,
  markNotificationRead,
  markAllNotificationRead,
  BroadcastNotification,
  saveNotificationImg,
  getnotificationHistory,
  getAdminList,
  uploadUserExcelData,
  getActiveNotificationCount,
};

async function getPatientActiveNoti() {
  const apiPath = API_ENDPOINTS.GET_PATIENT_ACTIVE_NOTI;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getActiveNotificationCount() {
  const apiPath = API_ENDPOINTS.GET_NOTIFICATION_COUNT;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function saveNotificationImg(data: any) {
  const apiPath = API_ENDPOINTS.SAVE_NOTIFICATION_IMG;
  const options = {
    path: apiPath,
    method: "POST",
    formdata: data,
    customHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await makeRequest(options);
}

async function getnotificationHistory(
  skip: number,
  take: number,
  filtertext: string,
  AdminFilterId: string = "0"
) {
  //,startDate : string ,endDate : string
  const apiPath =
    API_ENDPOINTS.GET_BROADCASTHISTORY +
    "?skip=" +
    skip +
    "&take=" +
    take +
    "&filtertext=" +
    filtertext +
    "&Id=" +
    AdminFilterId; //+"&StartDate="+startDate+"&EndDate="+endDate
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getAdminList() {
  //,startDate : string ,endDate : string
  const apiPath = API_ENDPOINTS.GET_ADMINLIST;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getLabReports() {
  const apiPath = API_ENDPOINTS.GET_ACTIVE_LABREPORTS;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function markNotificationRead(patientId: any) {
  const apiPath = API_ENDPOINTS.MARK_NORIFICATION_READ + patientId;
  const options = {
    path: apiPath,
    method: "PUT",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function markAllNotificationRead() {
  const apiPath = API_ENDPOINTS.MARK_ALL_NOTIFICATION_READ;
  const options = {
    path: apiPath,
    method: "PUT",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function BroadcastNotification(data: any) {
  const apiPath = API_ENDPOINTS.SAVE_PROFILEBROADCASTNOTIFICATION;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      FacilityId: data.FacilityId,
      NotificationType: data.NotificationType,
      InURLPath: data.InURLPath,
      PhysicianNo: data.PhysicianNo,
      ImageUrl: data.ImageURL,
      NotificationText: data.NotificationText,
      NotificationTitle: data.NotificationTitle,
      FromDate: data.FromDate,
      ToDate: data.ToDate,
      brandname: data.BrandName,
      doctorname: data.DoctorName,
      Type: data.Type,
      MobileList: data.Mobilelist,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function uploadUserExcelData(data: any) {
  const apiPath = API_ENDPOINTS.UPLOAD_NOTIFY_USER_EXCEL;
  const options = {
    path: apiPath,
    method: "POST",
    formdata: data,
    customHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await makeRequest(options);
}
