import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const loginservice = {
  checkUser,
  checkUserForLogin,
  verifyUser,
  loginUser,
  forgotPassOtp,
  forgotPassVerifyOtp,
  resetPass,
  changePass,
  getPatientHisInfo,
  getPatientSerach,
  getWpBaseUrl,
  getUAEPassResponse,
  CompleteUserProfile,
  getParseMobileNumber,
  getRefreshToken,
  registerUaePassUser,
};

async function getUAEPassResponse(accessCode: string) {
  const apiPath = API_ENDPOINTS.GET_UAE_PASS_RESPONSE;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      accessCode: accessCode,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function CompleteUserProfile(data: any) {
  const apiPath = API_ENDPOINTS.COMPLETE_USER_PROFILE;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      dateOfBirth: data.dateOfBirth,
      genderId: data.genderId,
      countryId: data.countryId,
      documentTypeId: data.documentTypeId,
      documentId: data.documentId,
      uuid: data.uuid,
      uaePassAccessToken: data.uaePassAccessToken,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function checkUser(
  callingCode: string,
  mobile: string,
  isInvokedForLogin: boolean
) {
  const apiPath = API_ENDPOINTS.CHECK_USER_EXIST;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      isInvokedForLogin: isInvokedForLogin,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function checkUserForLogin(
  callingCode: string,
  mobile: string,
  isInvokedForLogin: boolean,
  password: string
) {
  const apiPath = API_ENDPOINTS.CHECK_USER_EXIST;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      isInvokedForLogin: isInvokedForLogin,
      password: password,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function verifyUser(callingCode: string, mobile: string, otp: string) {
  const apiPath = API_ENDPOINTS.VERIFY_USER;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      otp: otp,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function loginUser(
  callingCode: string,
  mobile: string,
  password: string,
  otp: string,
  referenceIdForLinking: string
) {
  const apiPath = API_ENDPOINTS.LOGIN;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      password: password,
      otp: otp,
      referenceIdForLinking: referenceIdForLinking,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function forgotPassOtp(
  callingCode: string,
  mobile: string,
  dateOfBirth: string
) {
  const apiPath = API_ENDPOINTS.FORGOT_PASS_OTP;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      dateOfBirth: dateOfBirth,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function forgotPassVerifyOtp(
  callingCode: string,
  mobile: string,
  otp: string
) {
  const apiPath = API_ENDPOINTS.FORGOT_PASS_VERIFY_OTP;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      otp: otp,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function resetPass(
  callingCode: string,
  mobile: string,
  otp: string,
  password: string
) {
  const apiPath = API_ENDPOINTS.RESET_PASS;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
      otp: otp,
      newPassword: password,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function changePass(oldPass: string, newPassword: string) {
  const apiPath = API_ENDPOINTS.CHANGE_PASS;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      oldPassword: oldPass,
      newPassword: newPassword,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getPatientHisInfo(data: any) {
  const apiPath = API_ENDPOINTS.GET_PATIENT_HIS_INFO;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      identityNumber: data.identityNumber,
      mobileNo: data.mobileNo,
      identitydocTypeId: data.identitydocTypeId,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getPatientSerach(data: any) {
  const apiPath = API_ENDPOINTS.GET_PATIENT_SERACH;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      patientDetails: {
        HIS: data.his,
        patientId: data.patientId,
        mobileNo: "",
        identitydocTypeId: data.identitydocTypeId,
        identityNumber: "",
      },
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getWpBaseUrl(brandName: any) {
  const apiPath = API_ENDPOINTS.GET_WP_BASE + "?brandName=" + brandName;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getParseMobileNumber(phoneNumber: any) {
  const apiPath =
    API_ENDPOINTS.GET_PARSE_MOBILE_NO + "?phoneNumber=" + phoneNumber;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getRefreshToken(token: any, refreshtoken: any) {
  const apiPath = API_ENDPOINTS.GET_REFRESH_TOKEN;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      token: token,
      refreshToken: refreshtoken,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function registerUaePassUser(data: any) {
  const apiPath = API_ENDPOINTS.REGISTER_UAEPASS_USER;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      referenceId: data.referenceId,
      dateOfBirth: data.dateOfBirth,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
