import React from "react";

import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import Bmi from "./Bmi";
import StrokeRisk from "./StrokeRisk";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function HealthTool(props: any) {
  const [tabValue, setTabValue] = React.useState(0);

  const { t } = useTranslation(["healthTool"]);
  const [showDocTab, setShowDocTab] = React.useState<boolean>(false);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (props.mId !== "0") {
      setShowDocTab(true);
    }
  }, [props.mId]);

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <div className="main-content animate py-4 px-3 px-sm-4">
      <div className="d-flex align-items-center mb-3">
        <a
          href="#"
          className="d-inline-block d-sm-none text-dark"
          onClick={(e) => openMobileMenu(e)}
        >
          <i className="material-icons-outlined me-2">menu</i>
        </a>
        <h3 className="fs-18-24">Health Tools</h3>
        <div className="ms-auto">
          <Notification />
          <UserMenu />
        </div>
      </div>
      <div className="card border-0 shadow-sm rounded-1rem">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label={t("bmi")} {...a11yProps(0)} />
          <Tab disabled={!showDocTab} label={t("stroke")} {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <div className="mt-4 p-4">
            <Bmi />
          </div>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <div className="mt-4 p-4">
            <StrokeRisk />
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default HealthTool;
