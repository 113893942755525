import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// import '../node_modules/bootstrap/dist/css/bootstrap.css';
// import './App.css';
import Home from "./components/Home";
import AuthContext from "./store/auth-context";
import {
  createTheme,
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import ErrorBoundary from "./components/ErrorBoundary";
import ProtectedRoute from "./components/ProtectedRoute";
import AuthLayout from "./components/AuthLayout";
import Loader from "./components/common/Loader";
import Register from "./components/login/Register";
import { loginservice } from "./services/login.service";
import ValetLogin from "./components/valetParking/ValetLogin";
import ValetLayout from "./components/valetParking/ValetLayout";
import RegisterUAEPass from "./components/login/RegisterUAEPass";
import OtpForLogin from "./components/login/OtpForLogin";

const theme = createTheme({
  direction: "rtl",
});

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function App() {
  const authCtx = useContext(AuthContext);
  const [logo, setLogo] = useState<any>("");
  const [brand, setBrandText] = useState<any>("");
  const [brandNameForWPBaseUrl, setBrandNameForWpBaseUrl] = useState<any>("");

  const burjeelBrand = "burjeel";
  const medeorBrand = "medeor";
  const vpsBrand = "vps";
  const lifecareBrand = "lifecare";
  const colabBrand = "colab";
  const llhBrand = "llh";

  useEffect(() => {
    let theme: any = document.getElementById("theme");
    let favicon: any = document.getElementById("favicon");
    const params = new URLSearchParams(window.location.search);
    const brandName: any = params.get("brandName");
    let storedBrandName = localStorage.getItem("brandName");
    if (storedBrandName && storedBrandName !== "null" && !brandName) {
      if (storedBrandName === burjeelBrand) {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/burjeel.png");
        setBrandText("Burjeel");
        theme.href = "/css/theme-burjeel.css";
        document.title = "Burjeel | Patient Portal";
        favicon.href = "/assets/images/burjeel-favicon.png";
        setBrandNameForWpBaseUrl(burjeelBrand);
      } else if (storedBrandName === medeorBrand) {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/medeor-logo.png");
        setBrandText("Medeor");
        theme.href = "/css/theme-medeor.css";
        document.title = "Medeor | Patient Portal";
        favicon.href = "/assets/images/medeor-favicon.png";
        setBrandNameForWpBaseUrl(medeorBrand);
      } else if (storedBrandName === lifecareBrand) {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/lifecare_icon.png");
        setBrandText("Lifecare");
        theme.href = "/css/theme-lifecare.css";
        document.title = "Lifecare | Patient Portal";
        favicon.href = "/assets/images/lifecare-favicon.png";
        setBrandNameForWpBaseUrl(lifecareBrand);
      } else if (storedBrandName === colabBrand) {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/colab-logo.png");
        setBrandText("coLAB");
        theme.href = "/css/theme-colab.css";
        document.title = "coLAB | Patient Portal";
        favicon.href = "/assets/images/colab-favicon.png";
        setBrandNameForWpBaseUrl(colabBrand);
      } else if (storedBrandName === llhBrand) {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/llh-logo.png");
        setBrandText("LLH");
        theme.href = "/css/theme-llh.css";
        document.title = "LLH | Patient Portal";
        favicon.href = "/assets/images/llh-favicon.png";
        setBrandNameForWpBaseUrl(llhBrand);
      } else {
        localStorage.setItem("brandName", storedBrandName);
        setLogo("/assets/images/burjeel.png");
        setBrandText("Burjeel");
        theme.href = "/css/theme-burjeel.css";
        document.title = "Burjeel | Patient Portal";
        favicon.href = "/assets/images/burjeel-favicon.png";
        setBrandNameForWpBaseUrl(burjeelBrand);
      }
      // else {
      //   localStorage.setItem("brandName", storedBrandName);
      //   setLogo("/assets/images/logo.png");
      //   setBrandText("VPS Health");
      //   theme.href = "/css/theme.css";
      //   document.title = "VPS | Patient Portal";
      //   favicon.href = "/assets/images/vps-favicon.png";
      //   setBrandNameForWpBaseUrl(burjeelBrand);
      // }
    } else {
      if (brandName && brandName.toLowerCase().trim() === burjeelBrand) {
        localStorage.setItem("brandName", brandName.toLowerCase());
        setLogo("/assets/images/burjeel.png");
        setBrandText("Burjeel");
        theme.href = "/css/theme-burjeel.css";
        document.title = "Burjeel | Patient Portal";
        favicon.href = "/assets/images/burjeel-favicon.png";
        setBrandNameForWpBaseUrl(burjeelBrand);
      } else if (brandName && brandName.toLowerCase().trim() === medeorBrand) {
        localStorage.setItem("brandName", brandName);
        setLogo("/assets/images/medeor-logo.png");
        setBrandText("Medeor");
        theme.href = "/css/theme-medeor.css";
        document.title = "Medeor | Patient Portal";
        favicon.href = "/assets/images/medeor-favicon.png";
        setBrandNameForWpBaseUrl(medeorBrand);
      } else if (
        brandName &&
        brandName.toLowerCase().trim() === lifecareBrand
      ) {
        localStorage.setItem("brandName", brandName);
        setLogo("/assets/images/lifecare_icon.png");
        setBrandText("Lifecare");
        theme.href = "/css/theme-lifecare.css";
        document.title = "Lifecare | Patient Portal";
        favicon.href = "/assets/images/lifecare-favicon.png";
        setBrandNameForWpBaseUrl(lifecareBrand);
      } else if (brandName && brandName.toLowerCase().trim() === colabBrand) {
        localStorage.setItem("brandName", colabBrand);
        setLogo("/assets/images/colab-logo.png");
        setBrandText("coLAB");
        theme.href = "/css/theme-colab.css";
        document.title = "coLAB | Patient Portal";
        favicon.href = "/assets/images/colab-favicon.png";
        setBrandNameForWpBaseUrl(colabBrand);
      } else if (brandName && brandName.toLowerCase().trim() === llhBrand) {
        localStorage.setItem("brandName", llhBrand);
        setLogo("/assets/images/llh-logo.png");
        setBrandText("LLH");
        theme.href = "/css/theme-llh.css";
        document.title = "LLH | Patient Portal";
        favicon.href = "/assets/images/llh-favicon.png";
        setBrandNameForWpBaseUrl(llhBrand);
      } else {
        localStorage.setItem("brandName", burjeelBrand);
        setLogo("/assets/images/burjeel.png");
        setBrandText("Burjeel");
        theme.href = "/css/theme-burjeel.css";
        document.title = "Burjeel | Patient Portal";
        favicon.href = "/assets/images/burjeel-favicon.png";
        setBrandNameForWpBaseUrl(burjeelBrand);
      }
      // else {
      //   localStorage.setItem("brandName", vpsBrand);
      //   setLogo("/assets/images/logo.png");
      //   setBrandText("VPS Health");
      //   theme.href = "/css/theme.css";
      //   document.title = "VPS | Patient Portal";
      //   favicon.href = "/assets/images/vps-favicon.png";
      //   setBrandNameForWpBaseUrl(burjeelBrand);

      // }
    }
  }, []);

  useEffect(() => {
    if (brandNameForWPBaseUrl !== "") {
      getWordpressBaseUrl();
    }
  }, [brandNameForWPBaseUrl]);

  async function getWordpressBaseUrl() {
    let res = await loginservice.getWpBaseUrl(brandNameForWPBaseUrl);
    if (res && res.data) {
      localStorage.setItem("wpBaseUrl", res.data);
      authCtx.setWpBaseUrl(res.data);
    }
  }

  return (
    <>
      <ErrorBoundary>
        {authCtx.selectedLanguage === "ar" ? (
          <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
              <div dir="rtl">
                <Router>
                  <Switch>
                    <Route exact path="/">
                      <Redirect to="/home/login" />
                    </Route>
                    <Route path="/home">
                      <Home logo={logo} brand={brand} />
                    </Route>
                    <Route path="/home/register">
                      <Register />
                    </Route>
                    <Route path="/home/UAEregister">
                      <RegisterUAEPass />
                    </Route>
                    <Route path="/home/otpforlogin">
                      <OtpForLogin />
                    </Route>
                    <ProtectedRoute
                      path="/auth"
                      component={AuthLayout}
                    ></ProtectedRoute>
                  </Switch>
                </Router>
                {authCtx.isLoader && <Loader />}
              </div>
            </ThemeProvider>
          </StylesProvider>
        ) : (
          <div>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/home/login" />
                </Route>
                <Route path="/home">
                  <Home logo={logo} brand={brand} />
                </Route>
                <Route path="/home/register">
                  <Register />
                </Route>
                <Route path="/home/UAEregister">
                  <RegisterUAEPass />
                </Route>
                <Route path="/home/otpforlogin">
                  <OtpForLogin />
                </Route>
                <ProtectedRoute
                  path="/auth"
                  component={AuthLayout}
                ></ProtectedRoute>
              </Switch>
            </Router>
            {
              // authCtx.isLoader && <div className="loader">
              //   <img className='loader-img' src={'/assets/images/loader.gif'} alt="loader" />
              // </div>
              authCtx.isLoader && <Loader />
            }
          </div>
        )}
      </ErrorBoundary>
      <ErrorBoundary>
        <Router>
          <Switch>
            <Route exact path="/valet">
              <Redirect to="/valet/login" />
            </Route>
            <Route path="/valet/login">
              <ValetLogin />
            </Route>
            <Route path="/valetauth">
              <ValetLayout />
            </Route>
          </Switch>
        </Router>
      </ErrorBoundary>
    </>
  );
}

export default App;
