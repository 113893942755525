import { Snackbar } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { finddoctorservice } from "../services/findDoctor.services";
import AuthContext from "../store/auth-context";
import Notification from "./common/Notification";
import UserMenu from "./common/UserMenu";

function Locations() {
  const authCtx = useContext(AuthContext);
  const [locationList, setLocationList] = useState<any>([]);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modelType, setModelType] = useState<any>("");
  const [modelText, setModelText] = useState<any>("");

  useEffect(() => {
    getAllLocation();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => setShowModel(false);

  const getAllLocation = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getLocations();
    if (res && res.data && res.data.status && res.data.status === "success") {
      const doctorData = res.data.data;
      setLocationList(doctorData);
      setShowModel(false);
      setModelText(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setModelType("error");
      setLocationList(res.data);
      setShowModel(true);
      setModelText(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Locations</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          {locationList &&
            locationList.map((item: any, index: any) => (
              <div className="col-md-12 col-lg-6" key={"LOC" + index}>
                <div className="card border-0 shadow-sm rounded-1rem mt-2">
                  <div className="card-body p-4">
                    <div className="d-flex align-item-center">
                      <div className="flex-shrink-0">
                        {" "}
                        <img
                          src={item.locationImg}
                          alt="img"
                          className="img-95 rounded"
                        />{" "}
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <label className="fw-bold">{item.name}</label>
                        <div className="mt-2 d-flex align-items-center">
                          <Link
                            className="d-flex align-items-center text-decoration-none me-3"
                            to={{
                              pathname: `https://www.google.com/maps/dir//${item.name}`,
                            }}
                            target="_blank"
                          >
                            {" "}
                            <i className="material-icons-outlined"> near_me </i>
                            <span className="ms-2 d-lg-inline-block">
                              Direction
                            </span>
                          </Link>
                          <Link
                            className="d-flex align-items-center  text-decoration-none"
                            to={{
                              pathname: "/auth/findDoctor",
                              state: { item: item, search: "location" },
                            }}
                          >
                            {" "}
                            <i className="material-icons-outlined">
                              {" "}
                              person_search{" "}
                            </i>
                            <span className="ms-2 d-lg-inline-block">
                              Doctor
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {modelType === "error" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modelText}
          </Alert>
        </Snackbar>
      )}
      {modelType === "success" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modelText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Locations;
