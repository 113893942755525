import { API_ENDPOINTS } from "../constants/apiConfig";
import { wpAPI_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";
export const appservice = {
  getLocation,
  getGender,
  getDocumentType,
  getRelation,
  SetNotificationByGender,
  getCountryList,
  SetNotificationType,
  SetNotificationByAge,
  getInAppPath,
  getAppCofigurations,
  deleteAccount,
};
async function getLocation() {
  const apiPath = wpAPI_ENDPOINTS.GET_LOCATIONS;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function getCountryList() {
  const apiPath = API_ENDPOINTS.GET_COUNTRY;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getAppCofigurations() {
  const apiPath = API_ENDPOINTS.GET_APP_CONFIGURARION;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getGender() {
  const apiPath = API_ENDPOINTS.GET_GENDER;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function getInAppPath() {
  const apiPath = API_ENDPOINTS.SET_NOTIFICATION_INAPPPATH;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function SetNotificationType() {
  const apiPath = API_ENDPOINTS.SET_NOTIFICATION_TYPE;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function SetNotificationByAge() {
  const apiPath = API_ENDPOINTS.SET_NOTIFICATION_BY_AGE;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function SetNotificationByGender() {
  const apiPath = API_ENDPOINTS.SET_NOTIFICATION_BY_GENDER;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function getDocumentType() {
  const apiPath = API_ENDPOINTS.GET_DOCUMENT_TYPE;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getRelation() {
  const apiPath = API_ENDPOINTS.GET_RELATION;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function deleteAccount() {
  const apiPath = API_ENDPOINTS.DELETE_ACCOUNT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
