import { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Login from "./login/Login";
import Signup from "./SignUp";
import ForgetPass from "./ForgetPass";
import LoginBanner from "./login/LoginBanner";
import Register from "./login/Register";
import ValetLogin from "./valetParking/ValetLogin";
import RegisterUAEPass from "./login/RegisterUAEPass";
import OtpForLogin from "./login/OtpForLogin";
import Language from "./common/Language";

function Home(props: any) {
  const location = useLocation();
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    if (
      location.pathname &&
      location.pathname.toLowerCase().indexOf("sign-up") > -1
    ) {
      setShowLogo(false);
    } else {
      setShowLogo(true);
    }
  }, [location]);

  return (
    <>
      <div className="vh-100 container-fluid overflow-hidden">
        <div className="row align-items-center">
          <LoginBanner />
          <div className="login-content bg-white shadow vh-100 position-relative overflow-auto primary-scroll p-0 py-4">
            {/* <div className="col-xl-4 col-lg-5 col-md-6 bg-white shadow shadow-md-none vh-100 p-4 p-sm-5 position-relative overflow-auto primary-scroll"> */}
            <div className="login-content-body">
              <img
                src={props.logo}
                alt="logo"
                className={showLogo ? "h-80p" : "d-none"}
              />

              <Switch>
                <Route path="/home/sign-up" component={Signup}>
                  <Signup />
                </Route>
                <Route path="/home/login" component={Login}>
                  <Login />
                </Route>
                <Route path="/home/register" component={Register}>
                  <Register />
                </Route>
                <Route path="/home/UAEregister" component={RegisterUAEPass}>
                  <RegisterUAEPass />
                </Route>
                <Route path="/home/forgotpassword" component={ForgetPass}>
                  <ForgetPass />
                </Route>
                <Route path="/home/otpforlogin" component={OtpForLogin}>
                  <OtpForLogin />
                </Route>
              </Switch>

              <div className="fs-12 d-flex w-100 left-0 mt-5">
                {" "}
                © All Rights Reserved | {props.brand}
                {/* <div className="ms-auto"><Language /></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
