import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const profileService = {
    getProfileDocument
}

async function getProfileDocument(url:any,fileName:any) {
    const apiPath =  API_ENDPOINTS.GET_PROFILE_DOCUMENT + url + "&fileName=" + fileName;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequest(options)
}
