import { wpAPI_ENDPOINTS } from "../constants/apiConfig"; 
import { makeRequestForWP } from "../middleware/axiosForWordpress";

export const finddoctorservice = {
    searchDoctor,
    getLocations,
    getAllDoctors,
    getDoctorByCondition,
    getDoctorByLocation,
    getallspecialties,
    getallspecialtiesByLocation,
    getDoctorProfile
};

async function searchDoctor(searchString: string,) {
    const apiPath =  wpAPI_ENDPOINTS.FIND_DOC + searchString;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getAllDoctors(data:any) {
    const apiPath =  wpAPI_ENDPOINTS.GET_ALL_DOCTOR + data.id + "&cat_type=" + data.cat_type + "&vblog=" + data.vblog;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getLocations() {
    const apiPath =  wpAPI_ENDPOINTS.GET_LOCATIONS;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getDoctorByCondition(data:any) {
    const apiPath =  wpAPI_ENDPOINTS.GET_DOC_BY_CONDITION + data.vblog + "&id=" +data.id;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getDoctorByLocation(data:any) {
    const apiPath =  wpAPI_ENDPOINTS.GET_DOC_BY_LOCATION + data.vblog + "&id=" + data.id;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getallspecialties() {
    const apiPath =  wpAPI_ENDPOINTS.GET_ALL_SPECIALITIES;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}

async function getallspecialtiesByLocation(vblog:any) {
    const apiPath =  wpAPI_ENDPOINTS.GET_ALL_SPECIALITIES_BY_LOCATION + vblog;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}


async function getDoctorProfile(data:any) {
    const apiPath =  wpAPI_ENDPOINTS.GET_DOCTOR_PROFILE + data.vblog + "&id=" + data.id;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            'Content-Type': 'application/json',
    },
        };
    return await makeRequestForWP(options)
}
