import React, { useContext, useEffect, useState } from "react";
import { loginservice } from "../../services/login.service";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import cList from "../../constants/countryList.json";
import Otp from "./Otp";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Link, useHistory } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { appservice } from "../../services/app.service";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";
import PrivacyPolicy from "./PrivacyPolicy";
import TermAndCon from "./TermAndCon";
import FingerprintIcon from "@mui/icons-material/Fingerprint";

function Register() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["login", "profile"]);
  const history = useHistory();

  const [loginMobile, setLoginMobile] = useState<string>("");
  const [loginMobileError, setLoginMobileError] = useState<boolean>(false);
  const [loginMobileErrorText, setLoginMobileErrorText] = useState<string>("");

  const [docId, setDocId] = useState<string>("");
  const [docIdError, setDocIdError] = useState<boolean>(false);
  const [docIdErrorText, setDocIdErrorText] = useState<string>("");
  const [docTypeList, setDocTypeList] = useState<any>([]);

  const [docType, setDocType] = useState(0);

  const [tnc, setTnc] = useState<boolean>(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean>(true);
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [userData, setuserData] = useState<any>(false);

  const [callingCode, setCallingCode] = useState("");

  const [userRegisterModel, setRegisterUserModal] = useState<boolean>(false);
  const [userRegisterModelMessage, setRegisterUserMessage] = useState<any>("");
  const [tncModel, setTncModel] = useState<boolean>(false);
  const [privacyModel, setPrivacyModel] = useState<boolean>(false);
  const [confirmationModel, setConfirmationModel] = useState<boolean>(false);

  const brandName = localStorage.getItem("brandName");
  const uaePassLogin: any = process.env.REACT_APP_UAEPASS_LOGIN;
  // const redirectUri: any = "http://localhost:3000/home/login";
  //  const uaePassLogin: any = `https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=burjeel_web_portal_stage&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&redirect_uri=${redirectUri}&acr_values=urn:safelayer:tws:policies:authentication:level:low`;

  const handleClose = () => setRegisterUserModal(false);
  //const handleShow = () => setRegisterUserModal(true);

  const toggleTnC = () => setTnc((value) => !value);

  const togglePassworBlock = () => {
    setCallingCode(authCtx.cCode);
    setLoginMobile(authCtx.userMobile);
    setIsRegisteredUser(true);

    toggleTnC();
  };

  useEffect(() => {
    if (authCtx.cCode === "" && authCtx.userMobile === "") {
      setCallingCode("971");
    } else {
      setCallingCode(authCtx.cCode);
      setLoginMobile(authCtx.userMobile);
    }
    fetchDocTypeList();
    // eslint-disable-next-line
  }, []);
  const handleComfirmationClose = () => setConfirmationModel(false);

  const fetchDocTypeList = async () => {
    let res = await appservice.getDocumentType();
    setDocTypeList(res);
  };

  const handleMobileChange = (e: any) => {
    setLoginMobile(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setLoginMobileError(true);
      setLoginMobileErrorText("");
    } else {
      if (e.target.value.length < 8) {
        setLoginMobileError(true);
        setLoginMobileErrorText(t("loginInvalidMobileError"));
      } else {
        setLoginMobileError(false);
        setLoginMobileErrorText("");
      }
    }
  };

  const handleDocIdChange = (e: any) => {
    let pattern = /^784[0-9]{12}$/;
    if (docType === 1) {
      let text = e.target.value;
      let emirateId = text.replace(/-/g, "").trim();
      setDocId(emirateId);
      if (pattern.test(emirateId)) {
        if (emirateId.length < 8) {
          setDocIdError(true);
          setDocIdErrorText("Please enter a valid Emirates ID");
        } else {
          setDocIdError(false);
          setDocIdErrorText("");
        }
      } else {
        setDocIdError(true);
        setDocIdErrorText("Please enter a valid Emirates ID");
      }
    } else {
      setDocId(e.target.value);
      if (e.target.value === "" || e.target.value.trim() === "") {
        setDocIdError(true);
        setDocIdErrorText("");
      } else if (e.target.value.length < 6) {
        setDocIdError(true);
        setDocIdErrorText(t("profile:docIdInvalidError"));
      } else {
        setDocIdError(false);
        setDocIdErrorText("");
      }
    }
  };

  let checkUser = async () => {
    authCtx.setDocumetsDetails(docType, docId);
    setDisableInput(true);
    setConfirmationModel(false);
    let his;
    let patientId = null;
    let data = {
      identityNumber: docId,
      identitydocTypeId: docType,
      mobileNo: loginMobile,
    };

    authCtx.toggleLoader(true);

    if (loginMobile.length < 8) {
      setLoginMobileError(true);
      setLoginMobileErrorText(t("loginInvalidMobileError"));
      authCtx.toggleLoader(false);
    } else {
      let res = await loginservice.checkUser(callingCode, loginMobile, false);
      authCtx.setMobile(callingCode, loginMobile);
      if (res.isExistingUser === true) {
        setIsRegisteredUser(true);
        setuserData(res);
        setRegisterUserModal(true);
        setRegisterUserMessage(t("userAlreadyRegister"));
        authCtx.toggleLoader(false);
        setTimeout(() => {
          history.push("/home/login");
        }, 2000);
      } else {
        setuserData(null);
        setIsRegisteredUser(false);
        authCtx.toggleLoader(false);

        // let response = await loginservice.getPatientHisInfo(data);
        // const patientIdList = response.data;
        // for (let i = 0; i < patientIdList.length - 1; i++) {
        //   if (patientIdList[i].patientId !== null) {
        //     patientId = patientIdList[i].patientId;
        //     his = patientIdList[i].his;
        //     break;
        //   }
        // }

        // if (patientId === null) {
        //   setuserData(null);
        //   setIsRegisteredUser(false);
        //   authCtx.toggleLoader(false);
        // } else {
        //   getPatientSearch(his, patientId);
        // }
      }
    }
  };

  // const getPatientSearch = async (his: any, patientId: any) => {
  //   let data = {
  //     his: his,
  //     patientId: patientId,
  //     identitydocTypeId: docType,
  //   };
  //   let res = await loginservice.getPatientSerach(data);
  //   if (res.data && res.data.length > 0) {
  //     setuserData(res.data[0]);
  //   } else {
  //     setuserData(null);
  //   }

  //   setIsRegisteredUser(false);
  //   authCtx.toggleLoader(false);
  // };

  const Login = (e: any) => {
    e.preventDefault();
    history.push("/home/login");
  };

  const handleTncClose = () => setTncModel(false);
  const handlePrivacyClose = () => setPrivacyModel(false);

  const navigateToUAEPass = (e: any) => {
    // localStorage.setItem("uaeUser", "1");
    e.preventDefault();
    window.location.replace(uaePassLogin);
  };

  return (
    <div id="login">
      {isRegisteredUser && (
        <div className="">
          <h3 className="mt-5 fw-500">{t("registerTitle")}</h3>
          {/* <small className="text-muted"> {t("loginSubTitle")}</small> */}
          <div className="mb-3 mt-3">
            <div
              className={"input-group " + (disableInput ? "avoid-clicks" : "")}
            >
              {cList && callingCode && (
                <Autocomplete
                  id="country-select-demo"
                  options={cList}
                  disableClearable
                  autoHighlight
                  size="small"
                  defaultValue={cList.find((x) => x.phoneCode === callingCode)}
                  onChange={(event, value) => {
                    setCallingCode(value.phoneCode);
                  }}
                  filterOptions={createFilterOptions({
                    stringify: (option) => "+" + option.phoneCode + option.name,
                  })}
                  getOptionLabel={(option) => "+" + option.phoneCode}
                  PaperComponent={({ children }) => (
                    <Paper style={{ minWidth: "327px" }}>{children}</Paper>
                  )}
                  style={{ minWidth: "100px" }}
                  renderOption={(option) => (
                    <>
                      {option.name} (+{option.phoneCode})
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
              )}
              <TextField
                required
                autoComplete="off"
                id="loginMobile"
                label={t("mobile")}
                name="loginMobile"
                className="form-control"
                variant="outlined"
                size="medium"
                inputProps={{ maxLength: 10 }}
                value={loginMobile}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e: any) => {
                  handleMobileChange(e);
                }}
                error={loginMobileError}
                helperText={loginMobileErrorText}
              />
            </div>
          </div>
          <div className="mb-3 mt-3">
            <TextField
              required
              select
              id="docType"
              label={t("profile:docType")}
              className="w-100  "
              variant="outlined"
              size="medium"
              value={docType === 0 ? "" : docType}
              onChange={(e: any) => {
                setDocType(e.target.value);
                setDocId("");
              }}
            >
              {docTypeList &&
                docTypeList.map((item: any) => (
                  <MenuItem value={item.id}>{item.text}</MenuItem>
                ))}
            </TextField>
          </div>
          <div className="mb-3 mt-3">
            {docType === 1 ? (
              <NumberFormat
                required
                customInput={TextField}
                id="docId1"
                className="w-100"
                size="medium"
                variant="outlined"
                format="###-####-#######-#"
                label={t("profile:docId")}
                error={docIdError}
                helperText={docIdErrorText}
                value={docId}
                onChange={(e: any) => {
                  handleDocIdChange(e);
                }}
              />
            ) : (
              <TextField
                required
                id="docId"
                label={t("profile:docId")}
                className="w-100"
                variant="outlined"
                size="medium"
                type="tel"
                inputProps={{ maxLength: 15, minLength: 6 }}
                error={docIdError}
                helperText={docIdErrorText}
                value={docId}
                onChange={(e: any) => {
                  handleDocIdChange(e);
                }}
              />
            )}
          </div>
          <div className="mb-2 form-check my-3">
            <input
              type="checkbox"
              className="form-check-input"
              id="agree"
              onChange={toggleTnC}
            />
            <label className="form-check-label fw-normal fs-12" htmlFor="agree">
              I agree with &nbsp;
              <Link
                to="#"
                className="btn-link"
                onClick={(e) => {
                  e.preventDefault();
                  setTncModel(true);
                }}
              >
                Terms &amp; Conditions
              </Link>{" "}
              &amp;{" "}
              <Link
                to="#"
                className="btn-link "
                onClick={(e) => {
                  e.preventDefault();
                  setPrivacyModel(true);
                }}
              >
                Privacy Policy
              </Link>
            </label>
          </div>
          <button
            type="submit"
            id="btnContinue"
            className="btn btn-primary w-100 mt-2 fs-18"
            disabled={!loginMobile || !tnc || docIdError || !docType}
            onClick={() => setConfirmationModel(true)}
          >
            {" "}
            {t("Continue")}
          </button>

          <div
            className="m-2 text-center position-relative mt-3 mb-3"
            id="login_or"
          >
            <strong>or</strong>
          </div>

          <div className="text-center">
            <a href="#" className="" onClick={(e) => navigateToUAEPass(e)}>
              <img
                src="../assets/images/UAEPASS_Sign_up_large.png"
                className="img-fluid"
                alt=""
                height="54px"
              />
            </a>
          </div>

          <div className="d-flex align-items-center mt-3 mb-5 ">
            <h6 className="m-0"> Already have an account?</h6>
            <Link to="#" className="fs-16 ms-2" onClick={(e) => Login(e)}>
              {t("loginButtonTitle")}{" "}
            </Link>
          </div>
        </div>
      )}

      {!isRegisteredUser && (
        <div className="">
          <Otp
            ccode={callingCode}
            loginMobile={loginMobile}
            isInvokedForLogin={false}
            onClick={() => togglePassworBlock()}
            userData={userData}
          />
        </div>
      )}

      <Dialog
        open={tncModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleTncClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Terms And Condition
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleTncClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <TermAndCon />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={privacyModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handlePrivacyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Privacy Policy
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handlePrivacyClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={confirmationModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary">Confirmation</DialogTitle>
        <DialogContent>
          {" "}
          <p className="fs-16 fw-600">
            Please check your document type and document id, you will not be
            able to update it later.
          </p>
        </DialogContent>
        <DialogActions>
          {" "}
          <button onClick={handleComfirmationClose} className="btn btn-primary">
            Close
          </button>
          <button onClick={checkUser} className="btn btn-primary">
            Continue
          </button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={userRegisterModel}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {userRegisterModelMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Register;
