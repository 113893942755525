import { Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AskDoctor from "../doctor/AskDoctor";

function AskDoctorCard() {
  const [askDoctorModel, setAskDoctorModel] = useState<boolean>(false);

 

  const handleAskDoctorClose = () => setAskDoctorModel(false);

  return (
    <>
      <div className="card scale-hover animate border-0 shadow-sm rounded-1rem overflow-hidden cursor-pointer">
        <Link
          to="#"
          onClick={() => setAskDoctorModel(true)}
          className="text-decoration-none"
        >
          <div className="d-flex justify-content-center align-item-center btn-primary py-3"><i className="fa-solid fa-circle-question fs-95 p-2"></i></div>
          <div className="card-body p-3 position-relative">
          <div className="position-absolute left-0 w-100 gradient-bw h-64p t-n64"></div>
      <div className="card-hover position-absolute w-100 left-0 top-0 bg-primary animate"></div>
            <h6 className="fw-bold mb-1 text-truncate">Ask a Doctor</h6>
            <p className="m-0 text-muted fs-12 text-truncate">Submit a query</p>
          </div>
        </Link>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={askDoctorModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Ask Doctor
        <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={()=>handleAskDoctorClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <AskDoctor handleAskDoctorClose={handleAskDoctorClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default AskDoctorCard;
