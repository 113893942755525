import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { dashboardservice } from "../../services/dashboard.service";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";

function HealthPackages() {
  const [healthPackageList, setHealthPackageList] = useState<any>([]);
  const [healthPackagesError, setHealthPackageError] = useState<boolean>(false);
  const [healthPackageErrorText, setHealthPackageErrorText] = useState<any>("");
  const defaultImage = "https://vpswebdev-burjeel.azurewebsites.net/wp-content/uploads/2022/01/cardiac.png";

  useEffect(() => {
    const fetchPackages = async () => {
      await fetchPackgesList();
    };
    fetchPackages();
  }, []);

  const fetchPackgesList = async () => {
      let res = await dashboardservice.getAllHealthPackages();

          if (res && res.data && res.data.status && res.data.status === "success") {
            setHealthPackageList(res.data.data);
            setHealthPackageError(false);
            setHealthPackageErrorText(res.data.message);
          } else {
            setHealthPackageList(res.data.data);
            setHealthPackageError(true);
            setHealthPackageErrorText(res.data.message);
          }
  
    } 
 const openMobileMenu = (e: any) => {
   e.preventDefault();

   document.getElementById("leftMenu")?.classList.remove("d-none");
 };

 return (
   <>
     <div className="main-content animate py-4 px-3 px-sm-4">
       <div className="d-flex align-items-center mb-3">
         <a
           href="#"
           className="d-inline-block d-sm-none text-dark"
           onClick={(e) => openMobileMenu(e)}
         >
           <i className="material-icons-outlined me-2">menu</i>
         </a>
         <h3 className="fs-18-24">Health Packages</h3>
         <div className="ms-auto mw-90px">
           <Notification />
           <UserMenu />
         </div>
       </div>
       <div className="row">
         <div className="col-md-12">
           <div className="row mt-4">
             {healthPackageList &&
               healthPackageList.map((item: any, index: any) => (
                 <div className="col-sm-6 col-xl-4 mb-4" key={index}>
                   <Link
                     to={{
                       pathname: "/auth/healthPackageDetails",
                       state: item,
                     }}
                   >
                     <div className="card border-0 shadow-sm rounded-1rem overflow-hidden cursor-pointer h-100">
                       <img
                         src={item.image === false ? defaultImage : item.image}
                         className="img-fluid"
                         alt={item.packagename}
                       />
                       <div className="card-body p-4">
                         <h6 className="fw-bold mb-1">{item.name}</h6>
                       </div>
                     </div>
                   </Link>
                 </div>
               ))}
           </div>
         </div>
       </div>
     </div>
     <Snackbar
       open={healthPackagesError}
       autoHideDuration={3000}
       onClose={() => setHealthPackageError(false)}
     >
       <Alert onClose={() => setHealthPackageError(false)} severity="error">
         {healthPackageErrorText}
       </Alert>
     </Snackbar>
   </>
 );
}

export default HealthPackages;
