import { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { signupservice } from "../services/signup.service";
import { appservice } from "../services/app.service";
import AuthContext from "../store/auth-context";
import { IconButton, InputAdornment, Paper, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import countryList from "../constants/countryList.json";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import NumberFormat from "react-number-format";
import { count } from "console";

function Signup() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["profile", "changePassword"]);

  const [nationalityList, setNationality] = useState<any>([]);
  const [genderList, setGenderList] = useState<any>([]);
  const [docTypeList, setDocTypeList] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("error");
  const [modalText, setModalText] = useState<string>("");

  const [fName, setfName] = useState<string>("");
  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [mobile, setLoginMobile] = useState<string>("");
  const [mobileError, setLoginMobileError] = useState<boolean>(false);
  const [mobileErrorText, setLoginMobileErrorText] = useState<string>("");
  const [callingCode, setCallingCode] = useState("");

  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");

  const [docId, setDocId] = useState<string>("");
  const [docIdError, setDocIdError] = useState<boolean>(false);
  const [docIdErrorText, setDocIdErrorText] = useState<string>("");

  const [docType, setDocType] = useState(0);
  const [docTypeError, setDocTypeError] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<boolean>(false);

  const [pass, setPass] = useState<string>("");
  const [passError, setPassError] = useState<boolean>(false);
  const [passErrorText, setPassErrorText] = useState<string>("");

  const [cPass, setCpass] = useState<string>("");
  const [cpassError, setCpassError] = useState<boolean>(false);
  const [cPassErrorText, setCpassErrorText] = useState<string>("");

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showComparePassword, setShowComparePassword] = useState(false);
  const handleClickShowComparePassword = () =>
    setShowComparePassword(!showComparePassword);
  const handleMouseDownComparePassword = () =>
    setShowComparePassword(!showComparePassword);

  const location = useLocation();

  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const otp = location.state;

  useEffect(() => {
    if (userData && userData !== null && Object.keys(userData).length !== 0) {
      setfName(userData.firstName);
      setlName(userData.lastName);
      setEmail(userData.email);
      setGender(userData.genderId);
      setLoginMobile(userData.mobile);
      setSelectedDOB(userData.dateOfBirth);
      setCountry(userData.countryId);
      setDocType(userData.documentTypeId);
      setDocId(userData.documentId);
    }
    const getCountryCode = async () => {
      const listFromApi = await fetchCountryCode();
      setNationality(listFromApi);
    };
    const getDocumentTypeList = async () => {
      const listFromApi = await fetchDocTypeList();
      setDocTypeList(listFromApi);
    };
    const getGenderList = async () => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
    };

    getGenderList();
    getCountryCode();
    getDocumentTypeList();
    setMobileData();
    setDocumentDetails();

    // eslint-disable-next-line
  }, []);

  const setMobileData = () => {
    if (authCtx.cCode === "") {
      setCallingCode("971");
    } else {
      setLoginMobile(authCtx.userMobile);
      setCallingCode(authCtx.cCode);
    }
  };

  const setDocumentDetails = () => {
    if (authCtx.docType !== 0 && authCtx.documentId !== "") {
      setDocType(authCtx.docType);
      setDocId(authCtx.documentId);
    } else {
      setDocType(0);
      setDocId("");
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const fetchCountryCode = async () => {
    let res = await appservice.getCountryList();
    return res;
  };

  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };
  const fetchDocTypeList = async () => {
    let res = await appservice.getDocumentType();
    return res;
  };

  function validateForm() {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }
    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (gender === "" || gender === "0" || gender === null) {
      setGenderError(true);
      isValid = false;
    }
    if (selectedDOB === null || selectedDOBError) {
      setSelectedDOBError(true);
      isValid = false;
    }

    if (email.trim() === "" || emailError) {
      setEmailError(true);
      isValid = false;
    }
    if (docId.trim() === "" || docIdError) {
      setDocIdError(true);
      isValid = false;
    }

    if (country === null || country === "" || country === "0" || countryError) {
      setCountryError(true);
      isValid = false;
    }
    if (docType === null || docType === 0 || docTypeError) {
      setDocTypeError(true);
      isValid = false;
    }
    if (pass.trim() === "" || passError) {
      setPassError(true);
      isValid = false;
    }
    if (cPass.trim() === "" || cpassError) {
      setCpassError(true);
      isValid = false;
    }
    return isValid;
  }

  function checkEmirateId() {
    let isValid: boolean = true;
    if (docType.toString() === "1") {
      const emirateId = docId;
      const dobYear = moment(selectedDOB, "YYYY-MM-DD").format("YYYY");
      const compereCodeWith =
        "784" + dobYear + emirateId.toString().slice(7, emirateId.length);
      if (emirateId !== compereCodeWith) {
        isValid = false;
      }
    }
    return isValid;
  }

  let handleSubmit = async () => {
    let data;
    checkEmirateId();
    if (validateForm()) {
      if (checkEmirateId()) {
        authCtx.toggleLoader(true);
        data = {
          firstName: fName.trim(),
          lastName: lName.trim(),
          email: email.trim(),
          mobile: mobile.trim(),
          callingCode: callingCode.trim(),
          dateOfBirth: moment(selectedDOB).toISOString(),
          genderId: gender,
          countryId: country,
          documentTypeId: docType,
          documentId: docId,
          password: pass,
          otp: otp,
        };
        let res = await signupservice.signupUser(data);
        if (res.message.status === "Success") {
          authCtx.setToken(res.data.token);
          let temp = {
            userId: res.data.userId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            gender: res.data.gender,
            email: res.data.email,
            mobile: mobile.trim(),
            callingCode: callingCode.trim(),
            profileImgUrl: res.data.profileImgUrl,
            profileImgFileName: res.data.profileImgFileName,
            refreshToken: res.data.refreshToken,
            roleId: res.data.roleId,
            roleName: res.data.roleName,
            refreshExpiresAt: res.data.refreshExpiresAt,
            tokenExpiresAt: res.data.tokenExpiresAt,
            isDOBAvailable: res.data.isDOBAvailable,
            facilityId: res.data.facilityId,
          };
          // localStorage.setItem("refreshToken", res.data.refreshToken);
          // localStorage.setItem("tokenExpiresAt", res.data.tokenExpiresAt);
          // localStorage.setItem("refreshExpiresAt", res.data.refreshExpiresAt);
          authCtx.setUserInfo(temp);
          history.push("/auth/dashboard");
          // authCtx.toggleLoader(false);
        } else {
          authCtx.toggleLoader(false);
          handleShow();
          setModalType("error");
          setModalText(t("profile:saveRegError"));
          setTimeout(() => {
            setModalText("");
            handleClose();
            history.push("/home/login");
          }, 3000);
        }
      } else {
        authCtx.toggleLoader(false);
        setShowModal(true);
        setModalType("error");
        setModalText("Emirates id or date of birth is invalid, please check");
      }
    } else {
      setShowModal(true);
      setModalType("error");
      setModalText("All fields are required");
    }
    authCtx.toggleLoader(false);
  };
  const handleDOBChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("profile:dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("profile:dobFormatError"));
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);

    if (e.target.id === "pass") {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
        setPassErrorText(t(""));
      } else {
        if (e.target.value.length > 50 || e.target.value.length < 8) {
          errorSetterName(true);
          setPassErrorText(t("changePassword:invalidPassword"));
        } else {
          errorSetterName(false);
          setPassErrorText(t(""));
        }
      }
    } else {
      if (errorSetterName) {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
        } else {
          errorSetterName(false);
        }
      }
    }
  };

  const handleDocIdChange = (e: any) => {
    let pattern = /^784[0-9]{12}$/;
    if (docType === 1) {
      let text = e.target.value;
      let emirateId = text.replace(/-/g, "").trim();
      setDocId(emirateId);
      if (pattern.test(emirateId)) {
        if (emirateId.length < 8) {
          setDocIdError(true);
          setDocIdErrorText("Please enter a valid Emirates ID");
        } else {
          setDocIdError(false);
          setDocIdErrorText("");
        }
      } else {
        setDocIdError(true);
        setDocIdErrorText("Please enter a valid Emirates ID");
      }
    } else {
      setDocId(e.target.value);
      if (e.target.value === "" || e.target.value.trim() === "") {
        setDocIdError(true);
        setDocIdErrorText("");
      } else if (e.target.value.length < 5) {
        setDocIdError(true);
        setDocIdErrorText(t("profile:docIdInvalidError"));
      } else {
        setDocIdError(false);
        setDocIdErrorText("");
      }
    }
  };

  const handleConfirmPasswordChange = (e: any) => {
    setCpass(e.target.value);
    let confirmPass = e.target.value;
    if (e.target.value === "" || e.target.value.trim() === "") {
      setCpassError(true);
      setCpassErrorText("");
    } else {
      if (e.target.value.length > 50 || e.target.value.length < 8) {
        setCpassError(true);
        setCpassErrorText(t("changePassword:invalidPassword"));
      } else {
        if (pass !== confirmPass) {
          setCpassError(true);
          setCpassErrorText(t("changePassword:passNotMatch"));
        } else {
          setCpassError(false);
          setCpassErrorText("");
        }
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText(t("profile:emailValidError"));
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleMobileChange = (e: any) => {
    setLoginMobile(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setLoginMobileError(true);
      setLoginMobileErrorText("");
    } else {
      if (e.target.value.length < 8) {
        setLoginMobileError(true);
        setLoginMobileErrorText("Enter valid mobile no.");
      } else {
        setLoginMobileError(false);
        setLoginMobileErrorText("");
      }
    }
  };

  return (
    <>
      <div id="reg">
        <h3 className="mt-0 fw-500"> {t("profile:signUpTitle")}</h3>
        <small className="text-muted"> {t("profile:signUpSubTitle")}</small>
        <div className="row g-sm-3 mt-0">
          <div className="col-sm">
            <div className="form-floating mb-3">
              <TextField
                required
                id="fName"
                label={t("profile:firstName")}
                className="w-100  "
                variant="outlined"
                size="medium"
                error={fNameError}
                value={fName}
                onChange={(e: any) => {
                  handleInputChange(e, setfName, setFnameError);
                }}
              />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-floating mb-3">
              <TextField
                required
                id="lName"
                label={t("profile:lastName")}
                className="w-100 "
                variant="outlined"
                size="medium"
                error={lNameError}
                value={lName}
                onChange={(e: any) => {
                  handleInputChange(e, setlName, setLnameError);
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-floating mb-3">
          <div className="input-group">
            {countryList && callingCode && (
              <Autocomplete
                disabled
                id="country-select-demo"
                options={countryList}
                disableClearable
                autoHighlight
                size="medium"
                defaultValue={countryList.find(
                  (x) => x.phoneCode === callingCode
                )}
                onChange={(event, value) => {
                  setCallingCode(value.phoneCode);
                }}
                filterOptions={createFilterOptions({
                  stringify: (option) => "+" + option.phoneCode + option.name,
                })}
                getOptionLabel={(option) => "+" + option.phoneCode}
                PaperComponent={({ children }) => (
                  <Paper style={{ minWidth: "327px" }}>{children}</Paper>
                )}
                style={{ minWidth: "100px" }}
                renderOption={(option) => (
                  <>
                    {option.name} (+{option.phoneCode})
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="medium"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
            <TextField
              required
              disabled
              autoComplete="off"
              id="mobile"
              label="Mobile No."
              name="mobile"
              className="form-control"
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 10, minLength: 8 }}
              error={mobileError}
              helperText={mobileErrorText}
              value={mobile}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e: any) => {
                handleMobileChange(e);
              }}
            />
          </div>
        </div>

        <div className="form-floating mb-3">
          <TextField
            required
            id="email"
            label={t("profile:email")}
            className="w-100  "
            variant="outlined"
            size="medium"
            error={emailError}
            helperText={emailErrorText}
            value={email}
            onChange={(e: any) => {
              handleEmailChange(e);
            }}
          />
        </div>

        <div className="form-floating mb-3">
          <KeyboardDatePicker
            required
            autoOk={true}
            disableFuture
            size="medium"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            id="dob"
            views={["year", "month", "date"]}
            label={t("profile:dob")}
            error={selectedDOBError}
            helperText={selectedDOBErrorText}
            value={selectedDOB}
            onChange={handleDOBChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className="m-0 w-100"
          />
        </div>

        <div className="row g-sm-3">
          <div className="col-sm">
            <div className="form-floating mb-3">
              <TextField
                required
                select
                id="profile:gender"
                label={t("gender")}
                className="w-100  "
                variant="outlined"
                size="medium"
                error={genderError}
                value={gender}
                onChange={(e: any) => {
                  setGender(e.target.value);
                  setGenderError(false);
                }}
              >
                {genderList &&
                  genderList.length > 0 &&
                  genderList.map((item: any) => (
                    <MenuItem value={item.id}>{item.text}</MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-floating mb-3">
              <TextField
                required
                select
                id="profile:country"
                label={t("nationality")}
                className="w-100  "
                variant="outlined"
                size="medium"
                error={countryError}
                value={country}
                onChange={(e: any) => {
                  setCountry(e.target.value);
                  setCountryError(false);
                }}
              >
                {nationalityList &&
                  nationalityList.length > 0 &&
                  nationalityList.map((country: any) => (
                    <MenuItem value={country.id}>{country.text}</MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
        </div>
        <div className="row g-sm-3">
          <div className="col-sm">
            <div className="form-floating mb-3">
              <TextField
                required
                select
                id="gender"
                label={t("profile:docType")}
                className="w-100  "
                variant="outlined"
                size="medium"
                disabled
                error={docTypeError}
                value={docType === 0 ? "" : docType}
                onChange={(e: any) => {
                  setDocType(e.target.value);
                  setDocId("");
                }}
              >
                {docTypeList &&
                  docTypeList.length > 0 &&
                  docTypeList.map((item: any) => (
                    <MenuItem value={item.id}>{item.text}</MenuItem>
                  ))}
              </TextField>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-floating mb-3">
              {docType === 1 ? (
                <NumberFormat
                  required
                  customInput={TextField}
                  id="docId1"
                  className="w-100"
                  size="medium"
                  variant="outlined"
                  format="###-####-#######-#"
                  label={t("profile:docId")}
                  disabled
                  error={docIdError}
                  helperText={docIdErrorText}
                  value={docId}
                  onChange={(e: any) => {
                    handleDocIdChange(e);
                  }}
                />
              ) : (
                <TextField
                  required
                  id="docId"
                  label={t("profile:docId")}
                  className="w-100"
                  variant="outlined"
                  size="medium"
                  type="tel"
                  inputProps={{ maxLength: 15 }}
                  disabled
                  error={docIdError}
                  helperText={docIdErrorText}
                  value={docId}
                  onChange={(e: any) => {
                    handleDocIdChange(e);
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="form-floating mb-3">
          <TextField
            required
            id="pass"
            name="password"
            label={t("changePassword:newPass")}
            className="w-100  "
            variant="outlined"
            size="medium"
            type={showNewPassword ? "text" : "password"}
            error={passError}
            helperText={passErrorText}
            value={pass}
            onChange={(e: any) => {
              handleInputChange(e, setPass, setPassError);
            }}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownNewPassword}
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="form-floating mb-3">
          <TextField
            required
            id="cPass"
            label={t("changePassword:confirmPass")}
            className="w-100  "
            variant="outlined"
            size="medium"
            type={showComparePassword ? "text" : "password"}
            error={cpassError}
            helperText={cPassErrorText}
            value={cPass}
            onChange={(e: any) => {
              handleConfirmPasswordChange(e);
            }}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowComparePassword}
                    onMouseDown={handleMouseDownComparePassword}
                  >
                    {showComparePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <button
          className="btn btn-primary w-100 mt-3 fs-18"
          onClick={() => handleSubmit()}
        >
          {t("profile:complete")}{" "}
        </button>
      </div>
      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Signup;
