import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { appservice } from "../services/app.service";
import { dashboardservice } from "../services/dashboard.service";
import AuthContext from "../store/auth-context";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { DialogActions, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { profileService } from "../services/profile.service";
import NumberFormat from "react-number-format";

const PersonalDetails = (props: any) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["profile"]);

  const [cList, setcList] = useState<any>([]);
  const [genderList, setGenderList] = useState<any>([]);
  const [docTypeList, setDocTypeList] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [fName, setfName] = useState<string>("");

  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [mobile, setMobile] = useState<string>("");
  const [mobileCode, setMobileCode] = useState<string>("");

  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [patientDOB, setPatientDOB] = useState<any>();
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");

  const [docId, setDocId] = useState<string>("");
  const [docIdError, setDocIdError] = useState<boolean>(false);
  const [docIdErrorText, setDocIdErrorText] = useState<string>("");

  const [docType, setDocType] = useState(0);
  const [docTypeError, setDocTypeError] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<boolean>(false);
  const [baseImage, setBase64Image] = useState<any>("");

  let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
  let uaeUserInfo = JSON.parse(
    localStorage.getItem("uaeUser") || "0"
  ).toString();

  useEffect(() => {
    const getCountryCode = async () => {
      const listFromApi = await fetchCountryCode();
      setcList(listFromApi);
    };
    getCountryCode();
    setMobileData();

    const getDocumentTypeList = async () => {
      const listFromApi = await fetchDocTypeList();
      setDocTypeList(listFromApi);
    };
    getDocumentTypeList();

    const getGenderList = async () => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
      // setGender(listFromApi[0].id);
    };
    getGenderList();

    const getProfileImage = async () => {
      if (userInfo.profileImgUrl !== "") {
        getImageBase64(userInfo.profileImgUrl, userInfo.profileImgFileName);
      }
    };
    getProfileImage();

    fetchUserProfile();

    // eslint-disable-next-line
  }, []);

  const setMobileData = () => {
    let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    setMobile(userInfo.mobile);
    setMobileCode(userInfo.callingCode);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const fetchCountryCode = async () => {
    let res = await appservice.getCountryList();
    return res;
  };

  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };
  const fetchDocTypeList = async () => {
    let res = await appservice.getDocumentType();
    return res;
  };

  const fetchUserProfile = async () => {
    let res = await dashboardservice.getProfile();
    if (
      res &&
      res.data &&
      res.message &&
      res.message.status &&
      res.message.status === "Success"
    ) {
      setfName(res.data.firstName);
      setlName(res.data.lastName);
      setGender(res.data.genderId);
      setSelectedDOB(res.data.dateOfBirth);
      setPatientDOB(res.data.dateOfBirth.slice(0, 10));
      setEmail(res.data.email);
      setDocId(res.data.documentId);
      setDocType(res.data.documentTypeId);
      setCountry(res.data.countryId);
    }
  };

  function validateForm() {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }
    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (gender === null) {
      setGenderError(true);
      isValid = false;
    }
    if (selectedDOB === null || selectedDOBError) {
      setSelectedDOBError(true);
      isValid = false;
    }

    if (email.trim() === "" || emailError) {
      setEmailError(true);
      isValid = false;
    }
    if (docId.trim() === "" || docIdError) {
      setDocIdError(true);
      isValid = false;
    }
    if (country === null || countryError) {
      setCountryError(true);
      isValid = false;
    }
    if (docType === null || docType === 0 || docTypeError) {
      setDocTypeError(true);
      isValid = false;
    }

    return isValid;
  }

  let handleSubmit = async () => {
    let data;
    if (validateForm()) {
      authCtx.toggleLoader(true);
      data = {
        firstName: fName.trim(),
        lastName: lName.trim(),
        email: email.trim(),
        dateOfBirth: patientDOB,
        genderId: gender,
        countryId: country,
        documentTypeId: docType,
        documentId: docId,
      };
      let res = await dashboardservice.saveProfile(data);
      if (res.message.status === "Success") {
        authCtx.toggleLoader(false);
        authCtx.setUserProfileDetails(res.data);
        let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
        userInfo.firstName = res.data.firstName;
        userInfo.lastName = res.data.lastName;
        localStorage.setItem("user", JSON.stringify(userInfo));
        localStorage.removeItem("getPatientRecord");
        localStorage.setItem("isPatientRecordCall", "false");
        props.reloadPage();
        setModalText(t("saveProfileSuccess"));
        handleShow();
        setModalType("success");
        setTimeout(() => {
          setModalText("");
          handleClose();
          fetchUserProfile();
        }, 3000);
      } else {
        authCtx.toggleLoader(false);
        setModalText(t("saveProfileError"));
        handleShow();
        setModalType("error");
        setTimeout(() => {
          setModalText("");
          handleClose();
          fetchUserProfile();
        }, 3000);
      }
    }
  };
  const handleDOBChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
      setPatientDOB(date.toISOString().slice(0, 10));
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("dobFormatError"));
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };

  const handleDocIdChange = (e: any) => {
    let pattern = /^784[0-9]{12}$/;
    if (docType === 1) {
      let text = e.target.value;
      let emirateId = text.replace(/-/g, "").trim();
      setDocId(emirateId);
      if (pattern.test(emirateId)) {
        if (emirateId.length < 8) {
          setDocIdError(true);
          setDocIdErrorText("Please enter a valid Emirates ID");
        } else {
          setDocIdError(false);
          setDocIdErrorText("");
        }
      } else {
        setDocIdError(true);
        setDocIdErrorText("Please enter a valid Emirates ID");
      }
    } else {
      setDocId(e.target.value);
      if (e.target.value === "" || e.target.value.trim() === "") {
        setDocIdError(true);
        setDocIdErrorText("");
      } else if (e.target.value.length < 6) {
        setDocIdError(true);
        setDocIdErrorText(t("profile:docIdInvalidError"));
      } else {
        setDocIdError(false);
        setDocIdErrorText("");
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText(t("emailValidError"));
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleFileChange = async (e: any) => {
    const file = e.target.files[0]; // accesing file
    if (!file) return;
    var ext = file.name.split(".").pop().toLowerCase();
    if (ext !== "jpg" && ext !== "jpeg" && ext !== "png") {
      setModalText(t("imgFormatError"));
      handleShow();
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
      return;
    }
    if (file.size > 2000 * 1024) {
      setModalText(t("imgSizeError"));
      handleShow();
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
      return;
    }

    let data = new FormData();
    data.append("image", file);
    const result = await dashboardservice.saveProfileImg(data);
    authCtx.toggleLoader(false);
    if (result && result.message && result.message.status === "Success") {
      let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
      userInfo.profileImgUrl = result.data.imgUrl;
      userInfo.profileImgFileName = result.data.fileName;
      localStorage.setItem("user", JSON.stringify(userInfo));
      getImageBase64(userInfo.profileImgUrl, userInfo.profileImgFileName);
      props.reloadPage();
      setModalText(t("saveProfileImgSuccess"));
      handleShow();
      setModalType("success");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    } else {
      setModalText(t("saveProfileImgError"));
      handleShow();
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }
  };

  const confirmDelete = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const removeProfileImg = async () => {
    authCtx.toggleLoader(true);
    setOpenDialog(false);
    let res = await dashboardservice.removeProfileImg();
    if (res.message.status === "Success") {
      let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
      userInfo.profileImgUrl = "";
      localStorage.setItem("user", JSON.stringify(userInfo));
      props.reloadPage();
      authCtx.toggleLoader(false);
      setModalText(t("removeProfileImgSuccess"));
      handleShow();
      setModalType("success");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
      authCtx.toggleLoader(false);
    } else {
      setModalText(t("removeProfileImgError"));
      handleShow();
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
      authCtx.toggleLoader(false);
    }
  };

  const getImageBase64 = async (imageUrl: any, imageFileName: any) => {
    // let res = await profileService.getProfileDocument(imageUrl, imageFileName);
    if (imageFileName) {
      let res = await profileService.getProfileDocument(null, imageFileName);
      setBase64Image(res);
    }
  };
  return (
    <>
      <div className="d-block d-sm-flex align-items-center my-4">
        <div className="flex-shrink-0">
          {" "}
          <img
            src={
              userInfo.profileImgUrl
                ? `data:image/jpeg;base64,${baseImage}`
                : "/assets/images/user.png"
            }
            className="img-75"
            alt=""
          />{" "}
        </div>
        <div className="flex-grow-1 ms-sm-3 mt-3 mt-sm-0">
          <div className="d-flex  align-items-center mb-1">
            <button className="d-flex btn btn-link position-relative align-items-center text-decoration-none  me-3 p-0">
              <i className="material-icons-outlined me-2">image</i>
              <input
                type="file"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                accept=".jpg,.jpeg,.png"
                onChange={(e) => handleFileChange(e)}
              />
              {t("upload")}
            </button>
            {userInfo.profileImgUrl && (
              <Link
                to="#"
                className="d-flex align-items-center text-decoration-none"
                onClick={(e) => {
                  e.preventDefault();
                  confirmDelete();
                }}
              >
                <i className="material-icons-outlined me-2">clear</i>
                {t("remove")}
              </Link>
            )}
          </div>
          <small className="text-muted"> {t("fileSize")}</small>{" "}
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              id="fName"
              label={t("firstName")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled={uaeUserInfo === "1" ? true : false}
              error={fNameError}
              value={fName}
              onChange={(e: any) => {
                handleInputChange(e, setfName, setFnameError);
              }}
            />
          </div>
        </div>
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              id="lName"
              label={t("lastName")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled={uaeUserInfo === "1" ? true : false}
              error={lNameError}
              value={lName}
              onChange={(e: any) => {
                handleInputChange(e, setlName, setLnameError);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <div className="input-group">
          <TextField
            required
            id="mobile"
            label={t("mobile")}
            disabled
            className="w-100 avoid-clicks"
            variant="outlined"
            size="small"
            type="tel"
            inputProps={{ maxLength: 10 }}
            value={"+" + mobileCode + " " + mobile}
          />
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              id="email"
              label={t("email")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled={uaeUserInfo === "1" ? true : false}
              error={emailError}
              helperText={emailErrorText}
              value={email}
              onChange={(e: any) => {
                handleEmailChange(e);
              }}
            />
          </div>
        </div>
        <div className="col-sm">
          <div className="form-floating mb-3">
            <KeyboardDatePicker
              required
              autoOk={true}
              disableFuture
              size="small"
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              margin="dense"
              id="dob"
              views={["year", "month", "date"]}
              label={t("dob")}
              error={selectedDOBError}
              helperText={selectedDOBErrorText}
              value={selectedDOB}
              onChange={handleDOBChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="m-0  w-100"
            />
          </div>
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              select
              id="gender"
              label={t("gender")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled={uaeUserInfo === "1" ? true : false}
              error={genderError}
              value={gender}
              onChange={(e: any) => {
                setGender(e.target.value);
                setGenderError(false);
              }}
            >
              {genderList &&
                genderList.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.text}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              select
              id="country"
              label={t("nationality")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled={uaeUserInfo === "1" ? true : false}
              error={countryError}
              value={country}
              onChange={(e: any) => {
                setCountry(e.target.value);
                setCountryError(false);
              }}
            >
              {cList &&
                cList.map((country: any) => (
                  <MenuItem value={country.id} key={country.id}>
                    {country.text}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm">
          <div className="form-floating mb-3">
            <TextField
              required
              select
              id="docType"
              label={t("docType")}
              className="w-100  "
              variant="outlined"
              size="small"
              disabled
              error={docTypeError}
              value={docType === 0 ? "" : docType}
              onChange={(e: any) => {
                setDocType(e.target.value);
                setDocId("");
              }}
            >
              {docTypeList &&
                docTypeList.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.text}
                  </MenuItem>
                ))}
            </TextField>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-floating mb-3">
            {docType === 1 ? (
              <NumberFormat
                required
                customInput={TextField}
                id="docId1"
                className="w-100"
                size="small"
                variant="outlined"
                format="###-####-#######-#"
                label={t("profile:docId")}
                disabled
                error={docIdError}
                helperText={docIdErrorText}
                value={docId}
                onChange={(e: any) => {
                  handleDocIdChange(e);
                }}
              />
            ) : (
              <TextField
                required
                id="docId"
                label={t("profile:docId")}
                className="w-100"
                variant="outlined"
                size="small"
                type="tel"
                inputProps={{ maxLength: 15 }}
                disabled
                error={docIdError}
                helperText={docIdErrorText}
                value={docId}
                onChange={(e: any) => {
                  handleDocIdChange(e);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => handleSubmit()}>
          {t("save")}
        </button>
      </div>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("removeImg")} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose} className="btn btn-primary">
            {t("cancel")}
          </button>
          <button onClick={removeProfileImg} className="btn btn-primary">
            {t("yes")}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PersonalDetails;
