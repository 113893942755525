import React, { useContext, useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import AuthContext from "../store/auth-context";
import { userService } from "../services/UserService";
import { MenuItem, Paper, Snackbar } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";

function UserDetails(props: any) {
  const authCtx = useContext(AuthContext);
  const [fName, setfName] = useState<string>("");
  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [Password, setPassword] = useState<string>("");
  const [PasswordError, setPasswordError] = useState<boolean>(false);

  const [role, setRole] = useState<any>("");

  const [isActive, setIsActive] = useState<boolean>(true);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [mobile, setLoginMobile] = useState<string>("");
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [mobileErrorText, setMobileErrorText] = useState<string>("");
  const [callingCode, setCallingCode] = useState("");
  const [Email, setLoginEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");

  const [validationError, setValidationError] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<any>("");

  useEffect(() => {
    if (props.id === "0") {
      setCallingCode("971");
    } else {
      setfName(props.firstName);
      setlName(props.lastName);
      setIsActive(props.isActive);
      setRole(props.role);
      setLoginMobile(props.mobile);
      setCallingCode(props.callingCode);
    }

    // eslint-disable-next-line
  }, []);

  function validateForm() {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }
    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (Email === null || Email === "") {
      setEmailError(true);
      isValid = false;
    }
    if (mobile === null || mobile === "") {
      setMobileError(true);
      isValid = false;
    } else if (mobile.length !== 10 && mobile.length < 8) {
      setMobileError(true);
      //setMobileErrorText("Enter valid mobile no.");
    } else {
      setMobileError(false);
      setMobileErrorText("");
    }

    return isValid;
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName: any) => {
    setterName(e.target.value);

    if (e.target.value === "" || e.target.value.trim() === "") {
      errorSetterName(true);
    } else {
      if (e.target.value.length > 50) {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  };

  const handleMobileChange = (e: any) => {
    setLoginMobile(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setMobileError(true);
      setMobileErrorText("");
    } else {
      if (e.target.value.length !== 10 && e.target.value.length < 8) {
        setMobileError(true);
        setMobileErrorText("Enter valid mobile no.");
      } else {
        setMobileError(false);
        setMobileErrorText("");
      }
    }
  };
  const handleEmailChange = (e: any) => {
    setLoginEmail(e.target.value);
    var reg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(Email)) {
        setEmailError(true);
        setEmailErrorText("Enter valid email .");
      } else {
        setEmailError(false);
        setEmailErrorText("");
      }
    }
  };

  const handleSubmit = async () => {
    let data;
    if (validateForm()) {
      authCtx.toggleLoader(true);
      if (props.id === "0") {
        let active = true;
        data = {
          id: 0,
          firstName: fName,
          lastName: lName,
          mobile: mobile,
          email: Email,
          // CallingCode:callingCode,
        };
      } else {
        data = {
          id: props.id,
          firstName: fName,
          lastName: lName,
          mobile: mobile,
          email: Email,
          // CallingCode:callingCode,
          //
        };
      }

      const res = await userService.addUser(data);
      authCtx.toggleLoader(false);
      if (res && res.message.status === "Success") {
        handleShow();
        setModalText(res.message.message);
        setModalType("success");
        setTimeout(() => {
          setModalText("");
          handleClose();
        }, 3000);
        window.location.reload();
      } else if (res && res.message.status === "Duplicate") {
        handleShow();
        setModalText(res.message.message);
        setModalType("error");
        setTimeout(() => {
          setModalText("");
          handleClose();
        }, 3000);
      } else {
        handleShow();
        setModalText("Error while adding user");
        setModalType("error");
        setTimeout(() => {
          setModalText("");
          handleClose();
        }, 3000);
      }
    } else {
      setValidationError(true);
      setValidationText("All fields are required");
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  // const roleList = [
  //   {
  //     label: "Administrator",
  //     value: 1,
  //   },
  //   {
  //     label: "User",
  //     value: 2,
  //   },
  // ];

  return (
    <>
      <TextField
        key={0}
        required
        autoComplete="off"
        id="fName"
        label="First Name"
        className="w-100 my-2"
        variant="outlined"
        size="small"
        inputProps={{ maxLength: 50 }}
        error={fNameError}
        value={fName}
        onChange={(e: any) => {
          handleInputChange(e, setfName, setFnameError);
        }}
      />

      <TextField
        key={1}
        required
        autoComplete="off"
        id="lName"
        label="Last Name"
        className="w-100 my-2"
        variant="outlined"
        size="small"
        inputProps={{ maxLength: 50 }}
        error={lNameError}
        value={lName}
        onChange={(e: any) => {
          handleInputChange(e, setlName, setLnameError);
        }}
      />

      <TextField
        key={2}
        required
        autoComplete="off"
        id="mobile"
        label="Mobile"
        name="mobile"
        className="form-control"
        variant="outlined"
        size="small"
        inputProps={{ maxLength: 10 }}
        error={mobileError}
        helperText={mobileErrorText}
        value={mobile}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        onChange={(e: any) => {
          handleMobileChange(e);
        }}
      />
      <TextField
        key={3}
        required
        autoComplete="off"
        id="email"
        label="Email"
        name="email"
        className="w-100 my-2"
        variant="outlined"
        size="small"
        error={emailError}
        helperText={emailErrorText}
        value={Email}
        onChange={(e: any) => {
          handleEmailChange(e);
        }}
      />

      {/* <TextField
        key={4}
        required
        //disabled={props.id > 0}
        autoComplete="off"
        id="Password"
        label="Password"
        className="w-100 my-2"
        variant="outlined"
        size="small"
        error={PasswordError}
        value={Password}
        onChange={(e: any) => {
          handlePasswordChange(e);
        }}
      /> */}
      {/* <div className="input-group">
          {cList && callingCode && (
            <Autocomplete
              key={4}
              id="country-select-demo"
              options={cList}
              disableClearable
              autoHighlight
              size="small"
              defaultValue={cList.filter((x) => x.phoneCode === callingCode)[0]}
              onChange={(event, value) => {
                setCallingCode(value.phoneCode);
              }}
              filterOptions={createFilterOptions({
                stringify: (option) => "+" + option.phoneCode + option.name,
              })}
              getOptionLabel={(option) => "+" + option.phoneCode}
              PaperComponent={({ children }) => (
                <Paper style={{ minWidth: "400px" }}>{children}</Paper>
              )}
              style={{ minWidth: "100px" }}
              renderOption={(option) => (
                <>
                  {option.name} (+{option.phoneCode})
                </>
              )}
              renderInput={(params) => (
                <TextField
                 key={5}
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}

          
        </div> */}
      <button
        type="submit"
        className="btn btn-primary float-end my-3"
        onClick={(e) => {
          handleSubmit();
        }}
      >
        Save
      </button>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={validationError}
        autoHideDuration={3000}
        onClose={() => setValidationError(false)}
      >
        <Alert onClose={() => setValidationError(false)} severity="error">
          {validationText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default UserDetails;
//test push
