import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
const brandName = localStorage.getItem("brandName");

function FeedbackSubmission(props: any) {
  const history = useHistory();

  const getBackFBHome = () => {
    props.getBackToFBHome(true);
    history.push("/auth/dashboard");
  };

  return (
    <>
      <div>
        <div className="col-md-8 mx-auto mb-5">
          {/* <div className="col-12 stroke-main-div">              */}
          <div className="card border-0 shadow-sm rounded-1rem mt-2 bg-white">
            <div className="card-body p-4 text-center">
              <div>
                <img
                  src="../assets/images/feedbackcheck.png"
                  className="checkedIcon col-md-11"
                  alt="check Icon"
                  style={{ height: 32, width: 32 }}
                />
              </div>
              <div className="col-sm-12 col-md-12 mb-3">
                <span className="vbottom feedbackSubmissionBold_fontSize align-bottom">
                  Thank you for choosing
                </span>
              </div>
              <div className="col-sm-12 col-md-12 mb-3 fw-14 text-primary">
                <span className="vbottom feedbackSubmissionBold_fontSize align-bottom">
                  {props.facilityName.name}
                </span>
              </div>
              <div className="vbottom mt-auto mb-0 fs-10">
                <span className="align-bottom">
                  We value your feedback, It helps us improve
                </span>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        {brandName === "burjeel" && props.facilityName.vblog === "2" ? (
          <div className="col-md-5 mx-auto text-center mb-3">
            <a
              href="https://feedback.burjeel.com/op/departments"
              target="_blank"
              className="link-primary mx-auto"
            >
              Would you like to leave a detailed feedback?
            </a>
          </div>
        ) : null}
        <div className="col-md-5 mx-auto text-center">
          <button
            type="button"
            className="btn btn-outline-primary mb-10 mt-10"
            onClick={getBackFBHome}
          >
            Go to Home
          </button>
        </div>
      </div>
    </>
  );
}

export default FeedbackSubmission;
