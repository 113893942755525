import React from "react";

function ParkingDetails() {
  return (
    <>
    <span className="fw-bold fs-12">Today</span>
    <div className="d-flex mt-4 align-items-center">
      <div className="flex-shrink-0">
        <img
          src="/assets/images/dr-1.png"
          className="img-h-60 rounded-circle"
          alt="dr-img"
        />
      </div>
      <div className="flex-grow-1 ms-2">
        <label className="fw-bold">Zahir Khan</label>
        <p className="text-muted fs-12 m-0">Dr. John Smith</p>
      </div>
      <div className="me-1 rounded-10 bg-lightgray p-1">
       <span className="fw-bold px-2">8:30 - 9:00 AM</span>
      </div>
    </div>

<div className="d-flex mt-4 align-items-center">
<div className="flex-shrink-0">
  <img
    src="/assets/images/dr-4.png"
    className="img-h-60 rounded-circle"
    alt="dr-img"
  />
</div>
<div className="flex-grow-1 ms-2">
  <label className="fw-bold">Usman Ali</label>
  <p className="text-muted fs-12 m-0">Dr. Alex Joe</p>
</div>
<div className="me-1 rounded-10 bg-lightgray p-1">
 <span className="fw-bold px-2">8:30 - 9:00 AM</span>
</div>
</div>
</>
  );
}

export default ParkingDetails;
