import { Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import { dashboardservice } from "../../services/dashboard.service";
import Parser from "html-react-parser";
import UserMenu from "../common/UserMenu";
import Notification from "../common/Notification";

function AllNews() {
  const [newsList, setNewsList] = useState<any>([]);
  const [modelType, setModelType] = useState<any>("");
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modelText, setModelText] = useState<any>("");

  const history = useHistory();
  useEffect(() => {
    const getAllNews = () => {
      fetchAllNewsList();
    };
    getAllNews();
  }, []);

  const handleClose = () => setShowModel(false);

  const fetchAllNewsList = async () => {
    let res = await dashboardservice.getAllNews();
    if (res && res.data && res.data.status === "success") {
      setNewsList(res.data.data);
      setModelType("success");
      setShowModel(false);
      setModelText(res.data.message);
    } else {
      setModelType("error");
      setShowModel(true);
      setModelText(res.data.message);
    }
  };

  const handleNewsChnage = (index: any) => {
    history.push({
      pathname: "/auth/news",
      state: { index: index, newslist: newsList },
    });
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">News</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>

        <div className="row">
          {newsList &&
            newsList.map((item: any, index: any) => (
              <div className=" col-12 col-md-6">
                <Link
                  onClick={() => handleNewsChnage(index)}
                  className="text-black cursor-pointer"
                  key={index}
                >
                  <div className="card border-0 rounded-1rem p-2 mb-4">
                    <div className="card-body px-0">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <img
                            src={item.imagepath}
                            alt="img"
                            className="img-100 rounded"
                          />
                        </div>
                        <div className="flex-grow ms-3">
                          <label className="fw-600 curser-pointer text-truncate-two-line">
                            {Parser(item.title)}
                          </label>
                          <p className="text-muted fs-12 m-0">
                            {item.publishDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>

      {modelType === "error" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modelText}
          </Alert>
        </Snackbar>
      )}
      {modelType === "success" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modelText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default AllNews;
