import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Tab,
  Tabs,
  Box,
  TextField,
  MenuItem,
  Menu,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { patientService } from "../../services/patient.service";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import { bookAppointmentService } from "../../services/bookAppointment.service";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { familyMemberservice } from "../../services/familyMember.services";
import { finddoctorservice } from "../../services/findDoctor.services";
import axios from "axios";
import { teleconsulationService } from "../../services/teleconsult.service";
import { dashboardservice } from "../../services/dashboard.service";

const BookAppointment = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [cancelModel, setCancelModel] = useState<boolean>(false);
  const [cancelAppointmentType, setCancelAppointmentType] =
    useState<string>("");
  const [cancelItem, setCancelItem] = useState<any>({});
  //const [reason, setReason] = useState<any>("");
  let reason: string = "";
  const [cancelShow, setCancelShow] = useState<boolean>(false);
  const [cancelShowmessage, setCancelShowMessage] = useState<any>("");

  const [patientIdHISList, setPatientIdHISList] = useState<any>([]);
  //const [patientIdList, setPatientIdList] = useState<any>([]);
  const [userName, setUserName] = useState<any>("");

  const [upcomingAppt, setUpcomingAppts] = useState<any>([]);
  const [pastAppts, setpastAppts] = useState<any>([]);

  const [familyMemberList, setFamilyMemberList] = useState<any>([]);
  const [familyMemberId, setFamilyMemberId] = useState<any>(0);
  const [isLoadPastAppt, setIsLoadPastAppt] = useState<boolean>(true);
  const [isLoadUpcomingAppt, setIsLoadUpcomingAppt] = useState<boolean>(true);
  const [tabListValue, setTabListValue] = useState("1");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilityId, setFacilityId] = useState<string>("");
  const [hospitallocationList, setHospitalLocationList] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<any>("");
  const [showMoreBtnClick, setShowMoreBtnClick] = useState<boolean>(false);
  const [insuranceErrorDialog, setInsuranceErrorDialog] =
    useState<boolean>(false);
  // const open = Boolean(anchorEl);

  let upcomingAppointmentsArr: any = [];
  let pastAppointmentsArr: any = [];
  const pastThresholdTime: any = process.env.REACT_APP_AFTER_THRESHOLD_MINUTE;
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const appConfiguration = JSON.parse(
    localStorage.getItem("appConfiguration") || "{}"
  );

  const handleClose = () => setCancelModel(false);
  const handleOpen = () => setCancelModel(true);
  const handleModelClose = () => setShowModal(false);
  const handleInsuranceDialogClose = () => setInsuranceErrorDialog(false);

  useEffect(() => {
    if (user.facilityId) {
      setFacilityId(user.facilityId);
    } else {
      setFacilityId("");
    }

    const getLocation = () => {
      getAllHospitalLocations();
    };
    getLocation();

    const getFamilyList = () => {
      fetchFamilyMember();
    };
    getFamilyList();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hospitallocationList.length > 0) {
      const getpatient = () => {
        getPatientRecord();
      };

      getpatient();
    }

    // eslint-disable-next-line
  }, [familyMemberId, hospitallocationList, facilityId]);

  const fetchFamilyMember = async () => {
    const listFromApi = await familyMemberservice.getFamilyList();
    if (
      listFromApi &&
      listFromApi.message &&
      listFromApi.message.status === "Success"
    ) {
      setFamilyMemberList(listFromApi.data);
    } else {
      setFamilyMemberList([]);
    }
  };

  const getAllHospitalLocations = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getLocations();
    if (res.data && res.data.status === "success") {
      setHospitalLocationList(res.data.data);
    } else {
      setModalText("Error in getting hospital locations");
      setModalType("error");
      setShowModal(true);
    }
    authCtx.toggleLoader(false);
  };

  const getPatientRecord = async () => {
    authCtx.toggleLoader(true);
    let idList;
    setPatientIdHISList([]);
    setUpcomingAppts([]);
    setpastAppts([]);
    setIsLoading(true);
    const currentHis: any = hospitallocationList.find(
      (item: any) => item.locationId === facilityId
    );
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const isPatientRecordCall = localStorage.getItem("isPatientRecordCall");

    if (familyMemberId === 0) {
      setUserName(user.firstName + " " + user.lastName);
    } else {
      familyMemberList &&
        familyMemberList.forEach((item: any) => {
          if (item.familyMemberId === familyMemberId) {
            setUserName(item.firstName + " " + item.lastName);
          }
        });
    }

    if (
      Object.keys(patientRecord).length !== 0 &&
      isPatientRecordCall === "true" &&
      familyMemberId === 0 &&
      facilityId
    ) {
      await getApptHistory(patientRecord);
    } else {
      let res = await patientService.getPatientRec(
        user.userId,
        familyMemberId,
        facilityId
      );
      if (res && res.data === undefined) {
        idList = [];
        console.log("Somthing went wrong in response");
      } else {
        idList = res.data;
        //setPatientIdList(idList);
      }

      idList &&
        idList.forEach((item: any) => {
          if (
            item &&
            (item.patientId !== null || (item.mobileNo && item.mobileNo !== ""))
          ) {
            if (patientIdHISList.length > 0) {
              if (patientIdHISList.some((obj: any) => obj.his === item.his)) {
                patientIdHISList.forEach((item2: any) => {
                  if (
                    item2.his === item.his &&
                    item2.patientId !== item.patientId
                  ) {
                    item2.patientId = [...item2.patientId, item.patientId];
                  }
                });
              } else {
                let data = {
                  his: item.his,
                  patientId: [item.patientId],
                  mobileNo: item.mobileNo,
                };
                patientIdHISList.push(data);
              }
            } else {
              let data = {
                his: item.his,
                patientId: [item.patientId],
                mobileNo: item.mobileNo,
              };
              patientIdHISList.push(data);
            }
          }
        });

      if (patientIdHISList.length > 0) {
        // if (familyMemberId === 0) {
        //   localStorage.setItem(
        //     "getPatientRecord",
        //     JSON.stringify(patientIdHISList)
        //   );
        //   localStorage.setItem("isPatientRecordCall", "true");
        // }
        await getApptHistory(patientIdHISList);
      } else {
        setIsLoading(false);
      }
    }
    authCtx.toggleLoader(false);
  };

  const getApptHistory = async (idList: any) => {
    authCtx.toggleLoader(true);
    setIsLoading(true);
    await Promise.all(
      idList.map(async (item: any) => {
        const response = await patientService.apptHistory(item);
        if (
          response &&
          response.message &&
          response.message.status &&
          (response.message.status[0] === "S" ||
            response.message.status[0] === "Success")
        ) {
          const upcommingAppt = response.upcomingAppts.map((prop: any) => {
            if (prop.patientId === null || prop.patientId === "") {
              return {
                ...prop,
                patientId: item.patientId[0],
                mobileNo: item.mobileNo,
              };
            } else {
              return {
                ...prop,
                mobileNo: item.mobileNo,
              };
            }
          });
          const pastAppt = response.pastAppts.map((prop: any) => {
            const timediff = diff_minutes(
              new Date(),
              new Date(prop.apptDate + " " + prop.apptTime)
            );
            const todaysDate = new Date().toISOString().slice(0, 10);
            if (prop.patientId === null || prop.patientId === "") {
              return {
                ...prop,
                patientId: item.patientId[0],
                mobileNo: item.mobileNo,
                timeDifference: timediff,
                currentDate: todaysDate,
              };
            } else {
              return {
                ...prop,
                mobileNo: item.mobileNo,
                timeDifference: timediff,
                currentDate: todaysDate,
              };
            }
          });
          upcomingAppointmentsArr =
            upcomingAppointmentsArr.concat(upcommingAppt);
          pastAppointmentsArr = pastAppointmentsArr.concat(pastAppt);
        }
      })
    );
    if (upcomingAppointmentsArr && upcomingAppointmentsArr.length !== 0) {
      if (facilityId !== "" && appConfiguration.filterToBeApplied) {
        const filterdAppointment = upcomingAppointmentsArr.filter(
          (item: any) => {
            return item.facilityId === facilityId;
          }
        );
        setUpcomingAppts(filterdAppointment);
      } else {
        setUpcomingAppts(upcomingAppointmentsArr);
      }
    } else {
      setUpcomingAppts([]);
      setIsLoadUpcomingAppt(false);
    }

    if (pastAppointmentsArr && pastAppointmentsArr.length !== 0) {
      if (facilityId !== "" && appConfiguration.filterToBeApplied) {
        const filterdPastAppointment = pastAppointmentsArr.filter(
          (item: any) => {
            return item.facilityId === facilityId;
          }
        );
        setpastAppts(filterdPastAppointment);
      } else {
        setpastAppts(pastAppointmentsArr);
      }
    } else {
      setpastAppts([]);
      setIsLoadPastAppt(false);
    }
    setIsLoading(false);
    authCtx.toggleLoader(false);
  };

  const cancelAppt = async () => {
    authCtx.toggleLoader(true);
    let response: any;
    let data = {
      facilityId: cancelItem.facilityId,
      appointmentId: cancelItem.appointmentRefNo,
      reason: reason,
    };
    if (cancelAppointmentType === "OPD") {
      response = await bookAppointmentService.deleteAppointment(data);
    } else {
      response = await teleconsulationService.deleteTeleAppointment(data);
    }

    if (response && response.data !== null) {
      setCancelShow(true);
      setCancelShowMessage("Appointment cancelled successfully!");
      let updatedAppts = upcomingAppt.filter(
        (item: any) => item.appointmentRefNo !== cancelItem.appointmentRefNo
      );
      setUpcomingAppts(updatedAppts);
      setTimeout(() => {
        setCancelShow(false);
      }, 3000);
    }
    authCtx.toggleLoader(false);
    setIsLoadUpcomingAppt(false);
  };

  const handleFamilyChange = (e: any) => {
    setIsLoadUpcomingAppt(true);
    setIsLoadPastAppt(true);
    if (e.target.value === user.firstName + " " + user.lastName) {
      setFamilyMemberId(0);
    } else {
      setFamilyMemberId(Number(e.target.value));
    }
  };

  const handleTabListChange = (event: any, newValue: any) => {
    setTabListValue(newValue);
  };

  const handleLocationChange = (e: any) => {
    setFacilityId(e.target.value);
  };

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(item);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
    setOpen("");
  };

  const handleRescheduleTeleAppt = (e: any, item: any) => {
    e.stopPropagation();
    const data = {
      item: item,
      userName: userName,
      fmId: familyMemberId,
      familyMemberList: familyMemberList,
    };

    history.push({
      pathname: "/auth/rescheduleTeleAppointment",
      state: data,
    });
  };

  const handleRebookAndReschedule = (item: any, type: string) => {
    if (type === "reschedule") {
      history.push({
        pathname: "/auth/rescheduleAppointment",
        state: {
          item: item,
          userName: userName,
          fmId: familyMemberId,
          familyMemberList: familyMemberList,
        },
      });
    } else if (type === "rebook") {
      history.push({
        pathname: "/auth/bookPastAppointment",
        state: {
          item: item,
          fmId: familyMemberId,
        },
      });
    }
  };

  const checkInsuranceValidation = async (doctorValue: any, type: string) => {
    authCtx.toggleLoader(true);
    const facility_insurance_validation =
      doctorValue.facility_insurance_validation
        ? doctorValue.facility_insurance_validation.toLowerCase()
        : "";

    const specialty_insurance_validation =
      doctorValue.specialty_insurance_validation
        ? doctorValue.specialty_insurance_validation.toLowerCase()
        : "";

    const doctor_insurance_validation = doctorValue.doctor_insurance_validation
      ? doctorValue.doctor_insurance_validation.toLowerCase()
      : "";

    if (
      facility_insurance_validation === "yes" ||
      specialty_insurance_validation === "yes" ||
      doctor_insurance_validation === "yes"
    ) {
      await getPatientId(doctorValue, type);
    } else {
      handleRebookAndReschedule(doctorValue, type);
    }
  };

  const getPatientId = async (doctorValue: any, type: string) => {
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const location = hospitallocationList.find((item: any) => {
      return doctorValue.facilityId === item.locationId;
    });

    if (Object.keys(patientRecord).length !== 0) {
      const data = {
        his: patientRecord[0].his,
        patientId: patientRecord[0].patientId[0],
        institution: doctorValue.facilityId,
      };
      getPatientDetails(data, doctorValue, location.id, type);
    } else {
      let familMembersId = 0;
      let res = await patientService.getPatientRec(
        user.userId,
        familMembersId,
        doctorValue.facilityId
      );
      if (res && res.data && res.data !== undefined) {
        let patientIdData = res.data.find((item: any) => {
          return item.his === location.his_name;
        });
        const data = {
          his: patientIdData.his,
          patientId: patientIdData.patientId,
          institution: doctorValue.facilityId,
        };
        getPatientDetails(data, doctorValue, location.id, type);
      } else {
        setShowModal(true);
        setModalType("error");
        setModalText("Error in get patient records");
        authCtx.toggleLoader(false);
      }
    }
  };

  const getPatientDetails = async (
    data: any,
    doctorValue: any,
    id: any,
    type: string
  ) => {
    const res = await dashboardservice.getPatientDetails(data);
    if (res && res.data) {
      const patientDetails: any = res.data[0];
      if (
        patientDetails &&
        patientDetails.insuranceName &&
        patientDetails.planName
      ) {
        handleInsuranceValidation(patientDetails, doctorValue, id, type);
      } else {
        handleRebookAndReschedule(doctorValue, type);
      }
    } else {
      setShowModal(true);
      setModalType("error");
      setModalText("Patient details not found");
      authCtx.toggleLoader(false);
    }
  };

  const handleInsuranceValidation = async (
    patientDetails: any,
    doctorValue: any,
    id: any,
    type: string
  ) => {
    const res = await dashboardservice.getRestrictedInsurance(id);
    if (res && res.data && res.data.data && res.status === 200) {
      const restrictedInsuranceList = res.data.data;
      const patientInsuranceName = patientDetails.insuranceName
        ? patientDetails.insuranceName
        : "";
      const patientInsurancePlanName = patientDetails.planName
        ? patientDetails.planName
        : "";
      let insuranceMatching = restrictedInsuranceList.find((item: any) => {
        return (
          item.name.trim().toLowerCase() ===
            patientInsuranceName.trim().toLowerCase() &&
          item.insurance_plan.trim().toLowerCase() ===
            patientInsurancePlanName.trim().toLowerCase()
        );
      });
      if (insuranceMatching) {
        setInsuranceErrorDialog(true);
        authCtx.toggleLoader(false);
      } else {
        handleRebookAndReschedule(doctorValue, type);
      }
    } else {
      handleRebookAndReschedule(doctorValue, type);
    }
  };

  const handleTeleConsultation = async (item: any) => {
    authCtx.toggleLoader(true);
    const currentDate: any = new Date();
    const appointmentDate: any = new Date(item.apptDate + " " + item.apptTime);
    const timeDiff = diff_minutes(appointmentDate, currentDate);
    const threshold: any = process.env.REACT_APP_BEFORE_THRESHOLD_MINUTE;
    if (
      currentDate.toISOString().slice(0, 10) ===
      appointmentDate.toISOString().slice(0, 10)
    ) {
      if (timeDiff <= Number(threshold)) {
        const baseUrlForTele = process.env.REACT_APP_TELECONSULT_BASEURL;
        const loginURL: any = baseUrlForTele + "/web/api/default/sso-login";
        const APPID: any = process.env.REACT_APP_TELECONSULT_APPID_BURJEEL;
        let patientData: any = {};
        if (familyMemberId === 0) {
          const user: any = authCtx.userProfileDetails;
          patientData = {
            name: user.firstName + user.lastName,
            gender: user.genderId === 1 ? "male" : "female",
            phone: user.mobile,
            emirates: user.documentId,
          };
        } else {
          const familyMember: any = familyMemberList.find(
            (item: any) => item.familyMemberId === familyMemberId
          );
          patientData = {
            name: familyMember.firstName + familyMember.lastName,
            gender: familyMember.genderId === 1 ? "male" : "female",
            phone: familyMember.mobile,
            emirates: familyMember.documentId,
          };
        }
        try {
          const res = await axios.post(loginURL, patientData, {
            headers: {
              "Content-Type": "application/json",
              APPID: APPID,
            },
          });
          if (res.status === 200 && res.data.success === true) {
            const authorization: any = res.data.data.access_token;
            const user_id = res.data.data.PatientProfile.user_id;
            const patient_profile_id = res.data.data.PatientProfile.id;
            const myGlobals = {
              baseURL: baseUrlForTele,
              APPID: APPID,
              Authorization: "Bearer" + " " + authorization,
              user_id: user_id,
              patient_profile_id: patient_profile_id,
              accessToken: authorization,
              apptDate: item.apptDate,
              apptTime: item.apptTime,
            };

            history.push({
              pathname: "/auth/Teleconsultation",
              state: myGlobals,
            });
          } else {
            setModalType("error");
            setModalText("Something went wrong");
            setShowModal(true);
          }
        } catch (error: any) {
          setModalType("error");
          setModalText("Something went wrong");
          setShowModal(true);
          authCtx.toggleLoader(false);
        }
      } else {
        setModalText(
          "You can start teleconsultation before 5 minute of your appointment time"
        );
        setModalType("error");
        setShowModal(true);
      }
    } else {
      setModalText(
        `Your teleconsultation appointment is scheduled on ${appointmentDate
          .toISOString()
          .slice(0, 10)}`
      );
      setModalType("error");
      setShowModal(true);
    }

    authCtx.toggleLoader(false);
  };

  const handlePastTeleConsultation = async (item: any) => {
    authCtx.toggleLoader(true);
    const currentDate: any = new Date();
    const appointmentDate: any = new Date(item.apptDate + " " + item.apptTime);
    const timeDiff = diff_minutes(appointmentDate, currentDate);
    const baseUrlForTele = process.env.REACT_APP_TELECONSULT_BASEURL;
    const loginURL: any = baseUrlForTele + "/web/api/default/sso-login";
    const APPID: any = process.env.REACT_APP_TELECONSULT_APPID_BURJEEL;
    let patientData: any = {};
    if (familyMemberId === 0) {
      const user: any = authCtx.userProfileDetails;
      patientData = {
        name: user.firstName + user.lastName,
        gender: user.genderId === 1 ? "male" : "female",
        phone: user.mobile,
        emirates: user.documentId,
      };
    } else {
      const familyMember: any = familyMemberList.find(
        (item: any) => item.familyMemberId === familyMemberId
      );
      patientData = {
        name: familyMember.firstName + familyMember.lastName,
        gender: familyMember.genderId === 1 ? "male" : "female",
        phone: familyMember.mobile,
        emirates: familyMember.documentId,
      };
    }
    try {
      const res = await axios.post(loginURL, patientData, {
        headers: {
          "Content-Type": "application/json",
          APPID: APPID,
        },
      });
      if (res.status === 200 && res.data.success === true) {
        const authorization: any = res.data.data.access_token;
        const user_id = res.data.data.PatientProfile.user_id;
        const patient_profile_id = res.data.data.PatientProfile.id;
        const myGlobals = {
          baseURL: baseUrlForTele,
          APPID: APPID,
          Authorization: "Bearer" + " " + authorization,
          user_id: user_id,
          patient_profile_id: patient_profile_id,
          accessToken: authorization,
          apptDate: item.apptDate,
          apptTime: item.apptTime,
        };

        history.push({ pathname: "/auth/Teleconsultation", state: myGlobals });
      } else {
        setModalType("error");
        setModalText("Something went wrong");
        setShowModal(true);
      }
    } catch (error: any) {
      setModalType("error");
      setModalText("Something went wrong");
      setShowModal(true);
      authCtx.toggleLoader(false);
    }
    authCtx.toggleLoader(false);
  };

  function diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  const handleShowMoreClick = () => {
    setShowMoreBtnClick(true);
    setFacilityId("");
  };
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Appointments</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="card-body p-1 mt-1">
          <div className="card border-0 shadow-sm rounded-1rem">
            <div className="card-body py-2 px-0 position-relative">
              <TabContext value={tabListValue}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={tabListValue}
                    onChange={handleTabListChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab value="1" label="Upcoming Appointments" />
                    <Tab value="2" label="Past Appointments" />
                  </Tabs>
                </Box>
                <div className="gx-3 light-bg py-3 row m-0 px-2">
                  {/* <div className="col-lg-4 col-md-12">
                    {tabListValue === "1" ? (
                      <p className="mt-4">
                        All upcomming appointments for selected patient &
                        hospital
                      </p>
                    ) : (
                      <p className="mt-4">
                        All past appointments for selected patient & hospital
                      </p>
                    )}
                  </div> */}
                  {appConfiguration.filterToBeApplied && (
                    <div className="col-xl-3 col-md-6 ms-auto">
                      <div className="form-floating">
                        <select
                          className="form-select fs-14 no-focus w-100"
                          id="selectHospital"
                          onChange={(e) => handleLocationChange(e)}
                          value={facilityId}
                        >
                          {hospitallocationList &&
                            hospitallocationList.map(
                              (item: any, index: any) => (
                                <option key={index} value={item.locationId}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                        <label htmlFor="selectHospital">Select Hospital</label>
                      </div>
                    </div>
                  )}

                  <div
                    className={`col-xl-3 col-md-6 mt-md-0  ${
                      !appConfiguration.filterToBeApplied ? "ms-auto" : "mt-3"
                    } `}
                  >
                    <div className="form-floating">
                      <select
                        className="form-select fs-14 no-focus w-100"
                        id="selectPatient"
                        onChange={(e) => handleFamilyChange(e)}
                      >
                        <option value={user.firstName + " " + user.lastName}>
                          {user.firstName} {user.lastName}
                        </option>
                        {familyMemberList &&
                          familyMemberList.map((item: any, index: any) => (
                            <option key={index} value={item.familyMemberId}>
                              {item.firstName} {item.lastName} ({item.relation})
                            </option>
                          ))}
                      </select>
                      <label htmlFor="selectPatient">Select Patient</label>
                    </div>
                  </div>
                </div>

                <TabPanel value="1">
                  {upcomingAppt && upcomingAppt.length > 0 ? (
                    <div className="container-fluid">
                      <div className="row align-items-center d-none d-lg-flex">
                        <div className="col-lg-4 p-3 fw-600">Doctor </div>
                        <div className="col-lg-3 p-3 fw-600">Patient</div>
                        <div className="col-lg-2 p-3 fw-600">Status</div>
                        <div className="col-lg-3 p-3 fw-600">Action</div>
                      </div>

                      {upcomingAppt &&
                        upcomingAppt.map((item: any, index: any) => (
                          <>
                            <div className="row border-top align-items-center">
                              <div className="col-lg-4 col-sm-6 p-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={
                                        item && item.image
                                          ? item.image
                                          : "/assets/images/doc.png"
                                      }
                                      alt="img"
                                      className="img-h-50 rounded-circle dr-photo"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3 mt-2 text-truncate ln-normal">
                                    <label className="fw-bold text-truncate w-100">
                                      {item.physicianName}
                                    </label>

                                    <p className="text-muted fs-12 m-0 text-truncate w-100 mt-1">
                                      {item.scheduleType === "TELEMED"
                                        ? "Teleconsultation"
                                        : item.location}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 px-3 pt-sm-3 pb-3">
                                {" "}
                                <label className="fw-bold">{userName}</label>
                                <p className="text-muted fs-12 mb-0 mt-1">
                                  {new Date(
                                    "1970-01-01T" + item.apptTime + "Z"
                                  ).toLocaleTimeString("en-US", {
                                    timeZone: "UTC",
                                    hour12: true,
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}{" "}
                                  | {new Date(item.apptDate).toDateString()}
                                </p>
                              </div>
                              <div className="col-lg-2 col-sm-6 px-3 py-sm-3">
                                {item.appointmentStatus === "0" ? (
                                  <span className="px-3 py-2 rounded-3 light-bg text-primary">
                                    Booked
                                  </span>
                                ) : (
                                  <span className="px-3 py-2 rounded-3 light-bg text-primary">
                                    {item.appointmentStatus}
                                  </span>
                                )}
                              </div>
                              <div className="col-lg-3 col-sm-6 p-3">
                                {item.scheduleType === "TELEMED" ? (
                                  <div className="d-flex">
                                    <button
                                      className="btn btn-primary no-focus"
                                      onClick={(e) =>
                                        handleTeleConsultation(item)
                                      }
                                    >
                                      Start Teleconsultation
                                    </button>
                                    {/* <div className="dropdown no-caret d-inline-block ms-3">
                                      <div
                                        id="basic-button"
                                        aria-controls={
                                          open ? "basic-menu" : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                          open ? "true" : undefined
                                        }
                                        onClick={(e) =>
                                          handleDropdownClick(e, item)
                                        }
                                      >
                                        <div className="d-flex align-items-center fs-14 cursor-pointer bg-ef1 p-1 rounded">
                                          <i className="material-icons-outlined ">
                                            more_vert
                                          </i>
                                        </div>
                                      </div>
                                      <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={open === item}
                                        onClose={handleDropdownClose}
                                        MenuListProps={{
                                          "aria-labelledby": "basic-button",
                                        }}
                                      >
                                        <MenuItem
                                          onClick={(e) =>
                                            handleRescheduleTeleAppt(e, item)
                                          }
                                        >
                                          Reschedule
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setCancelItem(item);
                                            setCancelAppointmentType(
                                              "TELEMED"
                                            );
                                            handleDropdownClose();
                                            handleOpen();
                                          }}
                                        >
                                          Cancel Booking
                                        </MenuItem>
                                      </Menu>
                                    </div> */}
                                  </div>
                                ) : (
                                  <div className="d-flex">
                                    {item.appointment_message === null ||
                                    item.appointment_message.trim() === "" ? (
                                      <button
                                        onClick={() =>
                                          checkInsuranceValidation(
                                            item,
                                            "reschedule"
                                          )
                                        }
                                        className="btn btn-primary no-focus"
                                      >
                                        Reschedule
                                      </button>
                                    ) : (
                                      <div>
                                        <a
                                          className="btn btn-primary position-relative bottom-0 no-focus"
                                          href={`tel: ${item.appointment_message}`}
                                        >
                                          <img
                                            className="imageForCall pb-1"
                                            src={
                                              "/assets/images/phone_white.png"
                                            }
                                          />
                                          {item.appointment_message}
                                        </a>
                                      </div>
                                    )}
                                    <button
                                      className="btn btn-outline-primary no-focus ms-2"
                                      onClick={() => {
                                        setCancelItem(item);
                                        setCancelAppointmentType("OPD");
                                        handleOpen();
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </div>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Appointments
                    </p>
                  ) : (
                    <>
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        No appointments to display
                      </p>
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {pastAppts && pastAppts.length > 0 ? (
                    <div className="container-fluid">
                      <div className="row align-items-center d-none d-lg-flex">
                        <div className="col-lg-4 p-3 fw-600">Doctor </div>
                        <div className="col-lg-3 p-3 fw-600">Patient</div>
                        <div className="col-lg-3 p-3 fw-600">Status</div>
                        <div className="col-lg-2 p-3 fw-600">Action</div>
                      </div>
                      {pastAppts &&
                        pastAppts.map((item: any, index: any) => (
                          <>
                            <div className="row border-top align-items-center">
                              <div className="col-lg-4 col-sm-6 p-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    <img
                                      src={
                                        item && item.image
                                          ? item.image
                                          : "/assets/images/doc.png"
                                      }
                                      alt="img"
                                      className="img-h-50 rounded-circle dr-photo"
                                    />
                                  </div>
                                  <div className="flex-grow-1 ms-3 mt-2 text-truncate ln-normal">
                                    <label className="fw-bold text-truncate w-100">
                                      {item.physicianName}
                                    </label>

                                    <p className="text-muted fs-12 m-0 text-truncate w-100 mt-1">
                                      {item.scheduleType === "TELEMED"
                                        ? "Teleconsultation"
                                        : item.location}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 px-3 pt-sm-3 pb-3">
                                {" "}
                                <label className="fw-bold">{userName}</label>
                                <p className="text-muted fs-12 mb-0 mt-1">
                                  {new Date(
                                    "1970-01-01T" + item.apptTime + "Z"
                                  ).toLocaleTimeString("en-US", {
                                    timeZone: "UTC",
                                    hour12: true,
                                    hour: "numeric",
                                    minute: "numeric",
                                  })}{" "}
                                  | {new Date(item.apptDate).toDateString()}
                                </p>
                              </div>
                              <div className="col-lg-3 col-sm-6 px-3 py-sm-3">
                                {item.appointmentStatus === "Not Visited" ? (
                                  <span className="px-3 py-2 rounded-3 bg-danger text-danger bg-opacity-10">
                                    {item.appointmentStatus}
                                  </span>
                                ) : (
                                  <span className="px-3 py-2 rounded-3 bg-success text-success bg-opacity-10">
                                    {item.appointmentStatus}
                                  </span>
                                )}
                              </div>
                              <div className="col-lg-2 col-sm-6 p-3">
                                {item.scheduleType === "TELEMED" &&
                                item.apptDate === item.currentDate &&
                                item.timeDifference <
                                  Number(pastThresholdTime) ? (
                                  <button
                                    className="btn btn-primary no-focus w-75"
                                    onClick={(e) =>
                                      handlePastTeleConsultation(item)
                                    }
                                  >
                                    Start
                                  </button>
                                ) : item.appointment_message === null ||
                                  item.appointment_message.trim() === "" ? (
                                  <>
                                    {item.scheduleType === "TELEMED" ? (
                                      <Link
                                        to={{
                                          pathname:
                                            "/auth/bookTelePastAppointment",
                                          state: {
                                            item: item,
                                            fmId: familyMemberId,
                                          },
                                        }}
                                        className="btn btn-primary w-75 no-focus"
                                      >
                                        Book
                                      </Link>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          checkInsuranceValidation(
                                            item,
                                            "rebook"
                                          )
                                        }
                                        className="btn btn-primary w-75 no-focus"
                                      >
                                        Book
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <a
                                    className="btn btn-primary mt-3 position-relative bottom-0"
                                    href={`tel: ${item.appointment_message}`}
                                  >
                                    <img
                                      className="pb-1"
                                      src={"/assets/images/phone_white.png"}
                                    />
                                    {item && item.appointment_message
                                      ? item.appointment_message
                                      : null}
                                  </a>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </div>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Appointments
                    </p>
                  ) : (
                    <>
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        No appointments to display
                      </p>
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={insuranceErrorDialog}>
        <DialogTitle className="text-primary">Error</DialogTitle>
        <DialogContent>
          <h6 className="fw-600 py-2">
            Please contact Experience Center 80055 for appointment booking.
          </h6>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary mx-2 my-1 no-focus"
            onClick={handleInsuranceDialogClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={cancelModel} onClose={handleClose}>
        <DialogTitle>
          {cancelAppointmentType === "OPD"
            ? "Cancel Appointment"
            : "Cancel Teleconsultation"}
        </DialogTitle>
        <DialogContent>
          <div className="card-body p-4  ln-24">
            <div className="d-flex ">
              <div className="flex-shrink-0">
                {" "}
                <img
                  src={cancelItem.image}
                  alt="img"
                  className="img-75 rounded-circle"
                />{" "}
              </div>
              <div className="flex-grow-1 ms-3">
                <label className="fw-bold">{cancelItem.physicianName}</label>
                <p className="text-muted fs-12 m-0">
                  {new Date(
                    "1970-01-01T" + cancelItem.apptTime + "Z"
                  ).toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                    hour12: true,
                    hour: "numeric",
                    minute: "numeric",
                  })}{" "}
                  {new Date(cancelItem.apptDate).toDateString()}
                </p>
                <p className="text-muted fs-12 m-0">{cancelItem.location}</p>
                <p className="text-muted fs-12 m-0">
                  For <span className="fw-bold">{userName}</span>
                </p>
              </div>
            </div>
          </div>
          <DialogContentText>
            {cancelAppointmentType === "OPD"
              ? "Are you sure, you want to cancel this appointment?"
              : "Are you sure, you want to cancel this teleconsultation?"}
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Enter Reason"
            type="text"
            fullWidth
            variant="standard"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          /> */}
        </DialogContent>
        <DialogActions>
          <button className="btn btn-secondary" onClick={handleClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              cancelAppt();
              handleClose();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={cancelShow}
        onClose={() => {
          setCancelShow(false);
          setCancelShowMessage("");
        }}
      >
        <Alert onClose={() => setCancelShow(false)} severity="success">
          {cancelShowmessage}
        </Alert>
      </Snackbar>

      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default BookAppointment;
