import Notification from "./common/Notification";
import UserMenu from "./common/UserMenu";
import BrandsCard from "./dashboard/BrandsCard";
import ConsultedDoctorsCard from "./dashboard/ConsultedDoctorsCard";
import DashboardBanner from "./dashboard/DashboardBanner";
import FindDoctorCard from "./dashboard/FindDoctorCard";
import HealthToolsCard from "./dashboard/HealthToolsCard";
import UpcomingAppointmentsCard from "./dashboard/UpcomingAppointmentsCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import AskDoctorCard from "./dashboard/AskDoctorCard";
import LocationCard from "./dashboard/LocationCard";
import InsuranceCard from "./dashboard/InsuranceCard";
import HealthPackagesCard from "./dashboard/HealthPackagesCard";
import NewsAndMediaCard from "./news&media/NewsAndMediaCard";
import AppointmentCard from "./dashboard/AppointmentCard";
import LabReportCard from "./dashboard/LabReportCard";
import Media from "./news&media/Media";
import MyMedicationCard from "./dashboard/MyMedicationCard";
import TelecosultationCard from "./dashboard/TelecosultationCard";
import Location from "./common/Location";

function Dashboard() {
  const brandName = localStorage.getItem("brandName");
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Dashboard</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <Location />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <DashboardBanner />
            <div className="row mt-4">
              <div className="col-sm-6 col-xl-4 mb-4">
                <AppointmentCard />
              </div>
              <div className="col-sm-6 col-xl-4 mb-4">
                <FindDoctorCard />
              </div>
              {brandName === "burjeel" && user.facilityId === "BU10" && (
                <div className="col-sm-6 col-xl-4 mb-4">
                  <TelecosultationCard />
                </div>
              )}

              <div className="col-sm-6 col-xl-4 mb-4">
                <LabReportCard />
              </div>
              <div className="col-sm-6 col-xl-4 mb-4">
                <MyMedicationCard />
              </div>

              {brandName !== "lifecare" ? (
                <div className="col-sm-6 col-xl-4 mb-4">
                  <HealthPackagesCard />
                </div>
              ) : null}
              <div className="col-sm-6 col-xl-4 mb-4">
                <LocationCard />
              </div>

              <div className="col-sm-6 col-xl-4 mb-4">
                <InsuranceCard />
              </div>
              <div className="col-sm-6 col-xl-4 mb-4">
                <AskDoctorCard />
              </div>
              <div className="col-sm-6 col-xl-4 mb-4">
                <HealthToolsCard />
              </div>
            </div>
            <NewsAndMediaCard topMargin={false} />
          </div>
          <div className="col-md-4 mt-4 mt-md-0">
            <UpcomingAppointmentsCard />

            <ConsultedDoctorsCard topMargin={false} />
            {!brandName || brandName === "null" || brandName === "vps" ? (
              <BrandsCard />
            ) : (
              ""
            )}
            <Media />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
