import React, { useEffect } from 'react'

function ValetLogin() {
  return (   
<div className="login-bg"> 
<div className="container-fluid overflow-hidden">
  <div className="row justify-content-center align-items-center vh-100">
    <div className="col-sm-9 col-lg-5">
      <div className="card border-0 shadow-sm my-4 rounded-3">
        <div className="card-body p-4 p-sm-5"> <img src="/assets/images/burjeel.png" className='h-64p' alt="logo" />
          <div id="login">
            <h3 className="mt-5 fw-bold">Login</h3>
            <small className="text-muted mb-4 d-block">Please enter your login details to access your account.</small>
            <div className="form-floating mb-3">
              <input type="text" className="form-control" placeholder="Email/Username" />
              <label>Email/Username</label>
            </div>
            <div className="form-floating mb-3">
              <input type="password" className="form-control" placeholder="Password" />
              <label>Password</label>
            </div>
            <a href="javascript:void(0);">Forgot Password</a> <a href="/valetauth/dashboard" className="btn btn-primary w-100 mt-5 btn-lg" >Continue</a> </div>
        </div>
      </div>
      <div className="my-4 text-center fs-6"> <a href="javascript:void(0);" className="text-white">Go to Home</a></div>
    </div>
  </div>
</div>
</div>
  )
}

export default ValetLogin