import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const LabReportsServices = {
  getLabReports,
  getSelectedPDFLabReport,
};

async function getLabReports(
  patientId: [string],
  his: string,
  startDate: string,
  endDate: string,
  mobileNo: string,
  dateOfBirth: string
) {
  const apiPath = API_ENDPOINTS.GET_LAB_REPORTS;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      patientId: patientId,
      his: his,
      startDate: startDate,
      endDate: endDate,
      mobileNo: mobileNo,
      dateOfBirth: dateOfBirth,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

//async function getSelectedPDFLabReport(documentNum:string, documentPart:string, documentType:string, documentVersion:string, facilityId:[string], patientId:string){

async function getSelectedPDFLabReport(data: any) {
  const apiPath = API_ENDPOINTS.GET_SELECTED_PDF_REPORT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: data,
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
