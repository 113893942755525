import React from "react";
import FamilyMembersDetails from "./FamilyMembersDetails";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UploadDocs from "./UploadDocs";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const FamilyMembers = (props: any) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [familyMemberId, setFamilyMemberId] = React.useState<string>("");
  const { t } = useTranslation(["familyMember"]);
  const [showDocTab, setShowDocTab] = React.useState<boolean>(false);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const showDoc = (id: string) => {
    setFamilyMemberId(id);
    setShowDocTab(true);
    setTabValue(1);
  };
  useEffect(() => {
    if (props.mId !== "0") {
      setShowDocTab(true);
      setFamilyMemberId(props.mId);
    }
  }, [props.mId]);

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className="mx-n4"
        aria-label="Profile Details"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label={t("personalDetails")} {...a11yProps(0)} />
        {/* <Tab disabled={!showDocTab} label={t("documents")} {...a11yProps(1)} /> */}
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <div className="mt-4">
          <FamilyMembersDetails
            mId={props.mId}
            mData={props.mData}
            showDoc={showDoc}
            handleEditClose={props.handleEditClose}
            handlePopupClose={props.handlePopupClose}
          />
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <div className="mt-4">
          <UploadDocs mId={familyMemberId} />
        </div>
      </TabPanel>
    </div>
  );
};

export default FamilyMembers;
