import { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { loginservice } from "../../services/login.service";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import cList from "../../constants/countryList.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { burjeelAndroidUrl, burjeelIosUrl } from "../../constants/apiConfig";
import { IconButton, InputAdornment, Snackbar } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import clearWebStorage from "../clearWebStorage";

function Login() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["login", "uaePass"]);
  const history = useHistory();
  const location = useLocation();

  const [loginMobile, setLoginMobile] = useState<string>("");
  const [loginMobileError, setLoginMobileError] = useState<boolean>(false);
  const [loginMobileErrorText, setLoginMobileErrorText] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean>(false);
  const [isRegisteredUserMessage, setIsRegisteredUserMessage] =
    useState<any>("");
  const [disableInput, setDisableInput] = useState<boolean>(false);
  //const [userData, setuserData] = useState<any>(false);

  const [callingCode, setCallingCode] = useState("");

  const handleRegisterClose = () => setIsRegisteredUser(false);
  const brandName = localStorage.getItem("brandName");

  const [uaePassError, setUaePassError] = useState<string>("");
  const [showUAEError, setShowUAEError] = useState<boolean>(false);

  const [usePassConfirmation, setUaePassConfirmation] =
    useState<boolean>(false);
  const [linkUaePassAccount, setLinkUaePassAccount] = useState<boolean>(false);

  const [loginPass, setLoginPass] = useState<string>("");
  const [loginPassError, setLoginPassError] = useState<boolean>(false);
  const [loginPassErrorText, setLoginPassErrorText] = useState<string>("");

  const [linkingPass, setLinkingPass] = useState<string>("");
  const [linkingPassError, setLinkingPassError] = useState<boolean>(false);
  const [linkingPassErrorText, setLinkingPassErrorText] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowPassword(!showPassword);
  const handleMouseDownNewPassword = () => setShowPassword(!showPassword);
  const [uaePassUserInfo, setUaePassUserInfo] = useState<any>("");

  const uaePassLogin: any = process.env.REACT_APP_UAEPASS_LOGIN;
  const uaePassLogout: any = process.env.REACT_APP_UAEPASS_LOGOUT;

  //const uaePassLogout: any ="https://stg-id.uaepass.ae/idshub/logout?redirect_uri=http://localhost:3000/home/login";
  //const uaePassLogin: any = `https://stg-id.uaepass.ae/idshub/authorize?response_type=code&client_id=sandbox_stage&scope=urn:uae:digitalid:profile:general&state=HnlHOJTkTb66Y5H&redirect_uri=http://localhost:3000/home/login&acr_values=urn:safelayer:tws:policies:authentication:level:low`;

  useEffect(() => {
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const myParam: any = urlParams.get("channel");
    // const myParamUri: any = urlParams.get("redirect");
    // const brand: any = urlParams.get("brandName");
    // if (brand === "colab" && myParam && myParamUri) {
    //   localStorage.setItem("channel", myParam);
    //   localStorage.setItem("colabRedirectUrl", myParamUri);
    // }
    removeUserDataFromStorage();
    const uaeCode = window.location.href;
    if (uaeCode.includes("home/login?code=")) {
      let accessCode = uaeCode.substring(
        uaeCode.indexOf("code=") + 5,
        uaeCode.lastIndexOf("&")
      );
      processUserInfo(accessCode);
    } else if (
      uaeCode.includes("error=access_denied") ||
      uaeCode.includes("error=invalid_request") ||
      uaeCode.includes("error=login_required") ||
      uaeCode.includes("error=cancelledOnApp")
    ) {
      setUaePassError("User cancelled the login");
      setShowUAEError(true);
      history.push("login");
    } else if (uaeCode.includes("isSOP1User=true")) {
      window.history.pushState("", document.title, "login");
      setUaePassError(
        "You are not eligible to access this service. Your account is either not upgraded or you have a visitor account. Please contact 'Burjeel Holdings' to access the services."
      );
      setShowUAEError(true);
    }

    if (authCtx.cCode === "" && authCtx.userMobile === "") {
      setCallingCode("971");
    } else {
      setCallingCode(authCtx.cCode);
      setLoginMobile(authCtx.userMobile);
    }
    // eslint-disable-next-line
  }, []);

  const removeUserDataFromStorage = () => {
    try {
      localStorage.removeItem("userData");
      clearWebStorage();
    } catch (err) {}
  };

  const handleClose = () => setShowModal(false);

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    errorSetterName(false);
    setLoginMobileErrorText("");
    setShowUAEError(false);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
        if (e.target.name === "loginMobile") {
          // setLoginMobileErrorText(t("loginMobileError"));
        }
      } else {
        const re = /^[0-9\b]+$/;
        if (!re.test(e.target.value)) {
          errorSetterName(true);
          if (e.target.name === "loginMobile") {
            setLoginMobileErrorText(t("numberError"));
          } else {
            errorSetterName(false);
            setLoginMobileErrorText("");
          }
        } else if (e.target.value.length < 8) {
          errorSetterName(true);
          setLoginMobileErrorText(t("loginInvalidMobileError"));
        }
      }
    }
  };

  let checkUserForLogin = async () => {
    setDisableInput(true);

    authCtx.toggleLoader(true);

    if (validateLogin()) {
      let res = await loginservice.checkUserForLogin(
        callingCode,
        loginMobile,
        true,
        loginPass
      );
      authCtx.setMobile(callingCode, loginMobile);
      if (res && res.isExistingUser && res.otpSent) {
        setIsRegisteredUser(false);
        history.push({
          pathname: "/home/otpforlogin",
          state: {
            callingCode: callingCode,
            loginMobile: loginMobile,
            password: loginPass,
          },
        });
      } else if (res && res.isExistingUser === false) {
        setIsRegisteredUser(true);
        setIsRegisteredUserMessage(t("userNotRegister"));
      } else if (res && res.message && res.message === "Network Error") {
        setIsRegisteredUser(true);
        setIsRegisteredUserMessage(res.errorResponse.data);
      } else if (res && res.status === 429) {
        setIsRegisteredUser(true);
        setIsRegisteredUserMessage(res.errorResponse.data);
      } else if (res && res.status === 400) {
        setIsRegisteredUser(true);
        setIsRegisteredUserMessage(res.errorResponse.data);
      }
    }
    authCtx.toggleLoader(false);
  };

  let handleUAEValidUser = async (existingUserInfo: any) => {
    authCtx.setToken(existingUserInfo.data.token);
    let temp = {
      userId: existingUserInfo.data.userId,
      firstName: existingUserInfo.data.firstName,
      lastName: existingUserInfo.data.lastName,
      gender: existingUserInfo.data.gender,
      email: existingUserInfo.data.email,
      mobile: existingUserInfo.data.mobile,
      callingCode: existingUserInfo.data.callingCode,
      profileImgUrl: existingUserInfo.data.profileImgUrl,
      profileImgFileName: existingUserInfo.data.profileImgFileName,
      refreshToken: existingUserInfo.data.refreshToken,
      isDOBAvailable: existingUserInfo.data.isDOBAvailable,
      roleId: existingUserInfo.data.roleId,
      roleName: existingUserInfo.data.roleName,
      refreshExpiresAt: existingUserInfo.data.refreshExpiresAt,
      tokenExpiresAt: existingUserInfo.data.tokenExpiresAt,
      facilityId: existingUserInfo.data.facilityId,
    };
    authCtx.setMobile(temp.callingCode, temp.mobile);
    // localStorage.setItem("refreshToken", existingUserInfo.data.refreshToken);
    // localStorage.setItem(
    //   "tokenExpiresAt",
    //   existingUserInfo.data.tokenExpiresAt
    // );
    // localStorage.setItem(
    //   "refreshExpiresAt",
    //   existingUserInfo.data.refreshExpiresAt
    // );
    authCtx.setUserInfo(temp);
    history.push("/auth/dashboard");
  };

  let registerFirst = async (e: any) => {
    e.preventDefault();
    history.push("/home/register");
  };

  let uaePassregisterFirst = async (e: any) => {
    e.preventDefault();
    if (uaePassUserInfo.isExistingMobile) {
      setIsRegisteredUser(true);
      setIsRegisteredUserMessage(
        "Your mobile number already registered. Please link with your account."
      );
    } else {
      history.push({ pathname: "/home/UAEregister", state: uaePassUserInfo });
    }
  };

  const navigateToUAEPass = (e: any) => {
    // localStorage.setItem("uaeUser", "1");
    e.preventDefault();
    window.location.replace(uaePassLogin);
  };

  let processUserInfo = async (accesscode: string) => {
    let userInfo = await loginservice.getUAEPassResponse(accesscode);
    if (userInfo && userInfo.message.status) {
      if (
        userInfo.data === null &&
        userInfo.message.status === "Invalid" &&
        userInfo.message.message === "SOP1 user"
      ) {
        // setUaePassError(t("sop1UserLoginError"));
        // setShowUAEError(true);
        // // setTimeout(() => {
        // //   window.location.replace(uaePassLogout);
        // // }, 2000);
        window.location.replace(uaePassLogout + "?isSOP1User=true");
      } else if (
        userInfo.data === null &&
        userInfo.message.status === "Error" &&
        userInfo.message.message ===
          "Unable to find the user information from UAE Pass, please try again."
      ) {
        setUaePassError(userInfo.message.message);
        setShowUAEError(true);
      } else if (
        userInfo.data === null &&
        userInfo.message.status === "Invalid" &&
        userInfo.message.message ===
          "You are not eligible to access this service. Your account is either not upgraded or you have a visitor account. Please contact Burjeel Holdings to access the services."
      ) {
        setUaePassError(userInfo.message.message);
        setShowUAEError(true);
        setTimeout(() => {
          history.push("/home/register");
        }, 3000);
      } else if (userInfo.data && userInfo.message.status === "Success") {
        //Navigate to dashboard
        setUaePassUserInfo(userInfo.data);
        if (userInfo.data.token) {
          localStorage.setItem("uaeUser", "1");
          handleUAEValidUser(userInfo);
        } else {
          setUaePassConfirmation(true);
        }
      } else if (
        userInfo.message.status &&
        userInfo.message.status === "BadRequest"
      ) {
        setIsRegisteredUser(true);
        setIsRegisteredUserMessage(
          "Inactive authorization code received from token request"
        );
      }
    } else if (
      userInfo &&
      userInfo.message &&
      userInfo.message === "Network Error"
    ) {
      setIsRegisteredUser(true);
      setIsRegisteredUserMessage(userInfo.errorResponse.data);
    } else if (userInfo && userInfo.status === 429) {
      setIsRegisteredUser(true);
      setIsRegisteredUserMessage(userInfo.errorResponse.data);
    }
  };

  function validateLinkingLogin() {
    let isValid: boolean = true;

    if (linkingPass.trim() === "" || linkingPassError) {
      setLinkingPassError(true);
      isValid = false;
    }

    return isValid;
  }

  const validateLogin = () => {
    let isValid: boolean = true;
    if (loginPass.trim() === "" || loginPassError) {
      setLoginPassError(true);
      isValid = false;
    }
    if (loginMobile.length < 8 || loginMobileError) {
      setLoginMobileError(true);
      isValid = false;
    }
    return isValid;
  };

  const linkAccountWithUaePAss = async () => {
    authCtx.toggleLoader(true);
    const otp = "";
    const referenceIdForLinking = uaePassUserInfo.referenceId;
    if (validateLinkingLogin()) {
      let res = await loginservice.loginUser(
        callingCode,
        loginMobile,
        linkingPass,
        otp,
        referenceIdForLinking
      );
      authCtx.toggleLoader(false);
      if (
        res &&
        res.data &&
        res.message.status &&
        res.message.status === "Success"
      ) {
        if (brandName === "colab") {
          const redirectColabUrl = process.env.REACT_APP_COLAB_REDIRECT_URL;
          if (redirectColabUrl) {
            authCtx.toggleLoader(true);
            localStorage.setItem("channel", "");
            localStorage.setItem("colabRedirectUrl", "");
            window.location.href = `${redirectColabUrl}?token=${res.data.token}&refreshToken=${res.data.refreshToken}`;
            authCtx.toggleLoader(false);
          } else {
            setIsRegisteredUserMessage(
              "Something went wrong, please try again!"
            );
            setIsRegisteredUser(true);
          }
        } else {
          authCtx.setToken(res.data.token);
          let temp = {
            userId: res.data.userId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            gender: res.data.gender,
            email: res.data.email,
            mobile: loginMobile,
            callingCode: callingCode,
            profileImgUrl: res.data.profileImgUrl,
            profileImgFileName: res.data.profileImgFileName,
            refreshToken: res.data.refreshToken,
            roleId: res.data.roleId,
            roleName: res.data.roleName,
            refreshExpiresAt: res.data.refreshExpiresAt,
            tokenExpiresAt: res.data.tokenExpiresAt,
            isDOBAvailable: res.data.isDOBAvailable,
            facilityId: res.data.facilityId,
          };
          // localStorage.setItem("refreshToken", res.data.refreshToken);
          // localStorage.setItem("tokenExpiresAt", res.data.tokenExpiresAt);
          // localStorage.setItem("refreshExpiresAt", res.data.refreshExpiresAt);
          authCtx.setUserInfo(temp);
          history.push("/auth/dashboard");
        }
      }
      if (res.message.status === "Invalid") {
        setIsRegisteredUserMessage(res.message.message);
        setIsRegisteredUser(true);
      }
    }
    authCtx.toggleLoader(false);
  };

  const handlePasswordChange = (
    e: any,
    setterName: any,
    errorSetterName?: any,
    errorSetterText?: any
  ) => {
    setterName(e.target.value);
    errorSetterName(false);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
        errorSetterText(t(""));
      } else {
        if (e.target.value.length > 50 || e.target.value.length < 8) {
          errorSetterName(true);
          errorSetterText(t("invalidPassword"));
        } else {
          errorSetterName(false);
          errorSetterText(t(""));
        }
      }
    }
  };

  const forgotPass = async (e: any) => {
    e.preventDefault();
    history.push("/home/forgotpassword");
  };

  const handleUaePassYes = () => {
    setUaePassConfirmation(false);
    setLinkUaePassAccount(true);
  };

  const handleUaePassNo = () => {
    if (uaePassUserInfo.isExistingMobile) {
      setIsRegisteredUser(true);
      setIsRegisteredUserMessage(
        "Your mobile number already registered. Please link with your account."
      );
    } else {
      history.push({ pathname: "/home/UAEregister", state: uaePassUserInfo });
    }
  };

  return (
    <>
      <div id="login">
        {!usePassConfirmation && !linkUaePassAccount && (
          <div id="loginPage">
            <h3 className="mt-5 fw-500">{t("loginTitle")}</h3>
            {/* <small className="text-muted mb-5"> {t("loginSubTitle")}</small> */}

            <div className="mb-39 mt-3">
              {/* <label className="form-label">Please Enter Your Phone Number</label> */}

              <div
                className={
                  "input-group " + (disableInput ? "avoid-clicks" : "")
                }
              >
                {cList && callingCode && (
                  <Autocomplete
                    id="country-select-demo"
                    options={cList}
                    disableClearable
                    autoHighlight
                    size="small"
                    defaultValue={cList.find(
                      (x) => x.phoneCode === callingCode
                    )}
                    onChange={(event, value) => {
                      setCallingCode(value.phoneCode);
                      setShowUAEError(false);
                    }}
                    filterOptions={createFilterOptions({
                      stringify: (option) =>
                        "+" + option.phoneCode + option.name,
                    })}
                    getOptionLabel={(option) => "+" + option.phoneCode}
                    PaperComponent={({ children }) => (
                      <Paper style={{ minWidth: "327px" }}>{children}</Paper>
                    )}
                    style={{ minWidth: "100px" }}
                    renderOption={(option) => (
                      <>
                        {option.name} (+{option.phoneCode})
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="medium"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
                <TextField
                  autoComplete="off"
                  id="loginMobile"
                  label={t("mobile")}
                  name="loginMobile"
                  className="form-control"
                  variant="outlined"
                  size="medium"
                  inputProps={{ maxLength: 10 }}
                  value={loginMobile}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                      if (event.key === "Enter") {
                        checkUserForLogin();
                      }
                    }
                  }}
                  onChange={(e: any) => {
                    handleInputChange(e, setLoginMobile, setLoginMobileError);
                  }}
                  error={loginMobileError}
                  helperText={loginMobileErrorText}
                />
                <TextField
                  autoComplete="off"
                  id="loginPass"
                  label={t("Password")}
                  className="w-100 mt-4"
                  variant="outlined"
                  size="medium"
                  type={showPassword ? "text" : "password"}
                  error={loginPassError}
                  helperText={loginPassErrorText}
                  value={loginPass}
                  onChange={(e: any) => {
                    handlePasswordChange(
                      e,
                      setLoginPass,
                      setLoginPassError,
                      setLoginPassErrorText
                    );
                  }}
                  onKeyPress={(e: any) =>
                    e.key === "Enter" && checkUserForLogin()
                  }
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="d-flex justify-content-between mt-3">
                  <Link to="#" className="" onClick={(e) => forgotPass(e)}>
                    {t("forgotPassLink")}
                  </Link>
                </div>
              </div>

              <div className="mb-2 mt-2">
                <button
                  type="submit"
                  id="btnLogin"
                  className="btn btn-primary w-100 mt-2 fs-18"
                  disabled={loginMobile === "" || loginPass === ""}
                  onClick={() => checkUserForLogin()}
                >
                  {" "}
                  {t("loginButtonTitle")}
                </button>
              </div>

              <div
                className="m-2 text-center position-relative mt-3 mb-3"
                id="login_or"
              >
                <strong>or</strong>
              </div>

              {showUAEError ? (
                <div className="text-danger fs-12">
                  <p className="mb-1 fw-600 fs-14">{uaePassError}</p>
                </div>
              ) : null}

              <div className="text-center">
                <a href="#" className="" onClick={(e) => navigateToUAEPass(e)}>
                  <img
                    src="../assets/images/loginWithUAEPASS.png"
                    className="img-fluid"
                    alt=""
                    height="54px"
                  />
                </a>
              </div>

              {/* <div className="text-center">
                <a href="#" className="" onClick={(e) => navigateToUAEPass(e)}>
                  <img
                    src="../assets/images/loginWithUAEPASS.png"
                    className="img-fluid"
                    alt=""
                    height="54px"
                  />
                </a>
              </div> */}

              <div className="d-flex align-items-center mt-3 mb-5 ">
                <h6 className="m-0"> New User?</h6>
                <Link
                  to="#"
                  className="fs-16 ms-2"
                  onClick={(e) => registerFirst(e)}
                >
                  {t("registerTitle")}{" "}
                </Link>
              </div>
              {brandName !== "colab" && (
                <div className="mb-5 row g-2">
                  <div className="col-12">
                    Download Burjeel Holdings App Now!
                  </div>
                  <div className="col-12">
                    <Link to={{ pathname: burjeelIosUrl }} target="_blank">
                      <img
                        src={
                          brandName === "burjeel"
                            ? "/assets/images/app-store.svg"
                            : brandName === "medeor"
                            ? "/assets/images/app-store-medeor.svg"
                            : brandName === "lifecare"
                            ? "/assets/images/app-store-lifecare.svg"
                            : "/assets/images/app-store-llh.svg"
                        }
                        className="me-1 h-40p"
                        alt="apple store"
                      />
                    </Link>
                    <Link to={{ pathname: burjeelAndroidUrl }} target="_blank">
                      <img
                        src={
                          brandName === "burjeel"
                            ? "/assets/images/google-play.svg"
                            : brandName === "medeor"
                            ? "/assets/images/google-play-medeor.svg"
                            : brandName === "lifecare"
                            ? "/assets/images/google-play-lifecare.svg"
                            : "/assets/images/google-play-llh.svg"
                        }
                        className="h-40p"
                        alt="google store"
                      />
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {usePassConfirmation && (
          <div id="uaepass" className="mt-5">
            <h5 className="mt-3 fw-500 mb-2">{t("uaePass:uaepassProvider")}</h5>
            <p className="text-center fs-14 fw-500 mt-4">
              {t("uaePass:providerMessage")}
            </p>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <button
                className="btn btn-primary mx-3"
                onClick={() => handleUaePassYes()}
                style={{ backgroundColor: "#ff8b00", borderColor: "#ff8b00" }}
              >
                Yes *
              </button>
              <button
                className="btn btn-primary mx-3"
                onClick={() => handleUaePassNo()}
                style={{ backgroundColor: "#ff8b00", borderColor: "#ff8b00" }}
              >
                No **
              </button>
            </div>
            <div className="mt-4">
              <p className="fs-12 text-muted">{t("uaePass:line1")}</p>
              <p className="fs-12 text-muted">{t("uaePass:line2")}</p>
              <p className="fs-12">{t("uaePass:note")}</p>
            </div>
          </div>
        )}
        {linkUaePassAccount && (
          <div id="linkUaePassAccount">
            <h5 className="mt-5 fw-500">{t("uaePass:uaepassProvider")}</h5>
            <p className="fs-14 mb-4" style={{ color: "#916eae" }}>
              ({t("uaePass:loginCredentialMessage")})
            </p>

            <div className="mb-39 mt-3">
              {/* <label className="form-label">Please Enter Your Phone Number</label> */}

              <div
                className={
                  "input-group " + (disableInput ? "avoid-clicks" : "")
                }
              >
                {cList && callingCode && (
                  <Autocomplete
                    id="country-select-demo"
                    options={cList}
                    disableClearable
                    autoHighlight
                    size="small"
                    defaultValue={cList.find(
                      (x) => x.phoneCode === callingCode
                    )}
                    onChange={(event, value) => {
                      setCallingCode(value.phoneCode);
                      setShowUAEError(false);
                    }}
                    filterOptions={createFilterOptions({
                      stringify: (option) =>
                        "+" + option.phoneCode + option.name,
                    })}
                    getOptionLabel={(option) => "+" + option.phoneCode}
                    PaperComponent={({ children }) => (
                      <Paper style={{ minWidth: "327px" }}>{children}</Paper>
                    )}
                    style={{ minWidth: "100px" }}
                    renderOption={(option) => (
                      <>
                        {option.name} (+{option.phoneCode})
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="medium"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
                <TextField
                  autoComplete="off"
                  id="loginMobile"
                  label={t("mobile")}
                  name="loginMobile"
                  className="form-control"
                  variant="outlined"
                  size="medium"
                  inputProps={{ maxLength: 10 }}
                  value={loginMobile}
                  onKeyPress={(event: any) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                      if (event.key === "Enter") {
                        linkAccountWithUaePAss();
                      }
                    }
                  }}
                  onChange={(e: any) => {
                    handleInputChange(e, setLoginMobile, setLoginMobileError);
                  }}
                  error={loginMobileError}
                  helperText={loginMobileErrorText}
                />
              </div>
              <div className="mb-3 mt-4">
                <TextField
                  autoComplete="off"
                  id="linkingPass"
                  label={t("Password")}
                  className="w-100 mb-4"
                  variant="outlined"
                  size="medium"
                  type={showPassword ? "text" : "password"}
                  error={linkingPassError}
                  helperText={linkingPassErrorText}
                  value={linkingPass}
                  onChange={(e: any) => {
                    handlePasswordChange(
                      e,
                      setLinkingPass,
                      setLinkingPassError,
                      setLinkingPassErrorText
                    );
                  }}
                  onKeyPress={(e: any) =>
                    e.key === "Enter" && linkAccountWithUaePAss()
                  }
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownNewPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Link
                  to="#"
                  className="fw-600"
                  onClick={(e) => forgotPass(e)}
                  style={{ color: "#ff8b00" }}
                >
                  {t("forgotPassLink")}{" "}
                </Link>
              </div>

              <div className="mb-3 mt-4">
                <button
                  type="submit"
                  id="btnLogin"
                  className="btn w-100 mt-3 fs-18 no-focus"
                  disabled={loginMobileError}
                  onClick={() => linkAccountWithUaePAss()}
                  style={{ backgroundColor: "#e1ecd0", color: "#000" }}
                >
                  {" "}
                  {t("uaePass:submitButtonTitle")}
                </button>
              </div>
              <div className="d-flex align-items-center mt-3 mb-5 ">
                <h6 className="m-0"> Dont have an account?</h6>
                <Link
                  to="#"
                  className="fs-16 ms-2 fw-600"
                  onClick={(e) => uaePassregisterFirst(e)}
                  style={{ color: "#ff8b00" }}
                >
                  {t("uaePass:uaepassregisterTitle")}
                </Link>
              </div>
              <p className="fs-12">{t("uaePass:note")}</p>
            </div>
          </div>
        )}
      </div>

      <Snackbar
        open={isRegisteredUser}
        autoHideDuration={3000}
        onClose={handleRegisterClose}
      >
        <Alert onClose={handleRegisterClose} severity="error">
          {isRegisteredUserMessage}
        </Alert>
      </Snackbar>

      {modalType === "error" ? (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Login;
