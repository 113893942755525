import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const signupservice = {
  signupUser,
};

async function signupUser(data: any) {
  const apiPath = API_ENDPOINTS.SIGNUP;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      dateOfBirth: data.dateOfBirth,
      genderId: data.genderId,
      countryId: data.countryId,
      documentTypeId: data.documentTypeId,
      documentId: data.documentId,
      password: data.password,
      otp: data.otp,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
