import { API_ENDPOINTS, wpAPI_ENDPOINTS } from "../constants/apiConfig";
import { makeRequestForWP } from "../middleware/axiosForWordpress";
import { makeRequestForTele } from "../middleware/axiosMiddleware";

export const teleconsulationService = {
  getTimeSlot,
  bookAppointmentForTele,
  getTelecosnultationDoctor,
  deleteTeleAppointment,
  updateTeleAppointment,
};

async function getTimeSlot(data: any) {
  const apiPath = API_ENDPOINTS.GET_TIMESLOT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      facilityId: [data.facilityId],

      department: [data.department],

      physicianLicense: [data.physicianLicense],

      physicianNo: [data.physicianNo],

      providerLicence: "",

      beginDate: data.beginDate,

      endDate: data.endDate,

      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForTele(options);
}

async function bookAppointmentForTele(data: any) {
  const apiPath = API_ENDPOINTS.BOOK_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      facilityId: [data.facilityId],
      appointmentdetails: {
        apptDate: data.apptDate,
        apptStartTime: data.apptStartTime,
        apptEndTime: data.apptEndTime,
        patientId: data.patientId,
        familyMemberId: data.familyMemberId,
        callpoint: data.callpoint,
        department: data.department,
        physicianNo: data.physicianNo,
        physicianLicense: data.physicianLicense,
        scheduleType: data.scheduleType,
        comment: data.comment,
      },
      patientdetails: {
        comment: data.comment,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dateOfBirth: data.dateOfBirth,
        identityNumber: data.identityNumber,
        mobileNo: data.mobileNo,
        genderId: data.genderId,
        nationality: data.nationality,
        countryId: data.countryId,
        documentTypeId: data.documentTypeId,
        identityDocExpiryDate: data.identityDocExpiryDate,
        patientLang: data.patientLang,
        occupation: data.occupation,
        bloodGroup: data.bloodGroup,
        externalPatientId: data.externalPatientId,
        address: {
          country: data.country,
          city: data.city,
          region: data.region,
          streetNumber: data.streetNumber,
          postOfficeBox: data.postOfficeBox,
        },
        kinFirstName: data.kinFirstName,
        kinLastName: data.kinLastName,
        kinCountry: data.kinCountry,
        kinTelephone: data.kinTelephone,
        kinRelationship: data.kinRelationship,
      },
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForTele(options);
}

async function getTelecosnultationDoctor() {
  const apiPath = wpAPI_ENDPOINTS.GET_TELECONSULT_DOCTOR;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function deleteTeleAppointment(data: any) {
  const apiPath = API_ENDPOINTS.DELETE_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "DELETE",
    bodyObj: {
      facilityId: [data.facilityId],
      appointmentId: data.appointmentId,
      reason: data.reason,
      changeReason: "",
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForTele(options);
}

async function updateTeleAppointment(data: any) {
  const apiPath = API_ENDPOINTS.UPDATE_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "PUT",
    bodyObj: {
      facilityId: [data.facilityId],

      appointmentId: data.appointmentId,

      apptDate: data.apptDate,

      apptTime: data.apptTime,

      duration: "",

      department: "",

      patientNo: data.patientNo,

      physicianNo: data.physicianNo,

      physicianLicense: data.physicianLicense,

      changeReason: "",

      mobileNo: data.mobileNo,

      extensions: {
        additionalProp1: "",

        additionalProp2: "",

        additionalProp3: "",
      },
    },

    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForTele(options);
}
