import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function Teleconsultation() {
  const authCtx = useContext(AuthContext);
  const iframeRef: any = useRef(null);
  const location: any = useLocation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  let myGlobals: any = {};

  useEffect(() => {
    if (location.state) {
      myGlobals = location.state;
      joinConsultation();
    }
  }, []);

  const handleModelClose = () => setShowModal(false);

  function joinConsultation() {
    authCtx.toggleLoader(true);

    const consultation = {
      user_id: myGlobals.user_id,
      patient_profile_id: myGlobals.patient_profile_id,
    };

    const apptTime = new Date("1970-01-01T" + myGlobals.apptTime + "Z")
      .toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
      })
      .toLocaleLowerCase();

    const apptDate = myGlobals.apptDate.split("-").reverse().join("-");

    const globalObject = {
      baseURL: myGlobals.baseURL + "/web/api/",

      APPID: myGlobals.APPID,

      Authorization: myGlobals.Authorization,

      patient_profile_id: myGlobals.patient_profile_id,

      user_id: myGlobals.user_id,

      font_color: " #000000",

      subfont_color: "#00008b",

      font_size: "18px",

      font_family: " Arial, sans-serif",

      primary_color: "#8b0548",

      secondary_color: "#4E525B",
    };

    const postValues = consultation;
    var baseUrl = myGlobals.baseURL;
    var APPID = myGlobals.APPID;
    var Authorization = myGlobals.Authorization;
    var url = baseUrl + "/web/api/video/consultation-list";
    const headers = {
      "Content-Type": "application/json",
      APPID: APPID,
      Authorization: Authorization,
    };

    const postData = {
      patient_id: myGlobals.user_id,
      today: "yes",
    };

    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        let redirectUrl;
        if (
          data &&
          data.success === true &&
          data.consult_count === 1 &&
          data.data.length === 1
        ) {
          const consultData: any = data.data[0].consultData;
          redirectUrl =
            baseUrl +
            "/src/camcheck.php?id=" +
            consultData.id +
            "&meeting_id=" +
            consultData.meeting_id +
            "&doctor_id=" +
            consultData.doctor_id +
            "&patient_id=" +
            consultData.patient_id +
            "&patient_profile_id=" +
            consultData.patient_profile_id +
            "&access_token=" +
            myGlobals.accessToken +
            "&globals=" +
            encodeURIComponent(JSON.stringify(globalObject));

          data["postValues"] = postValues;
        } else if (
          data &&
          data.success === true &&
          data.consult_count > 1 &&
          data.data.length > 1
        ) {
          const consultData: any = data.data.find((item: any) => {
            return (
              item.consultData.date === apptDate &&
              item.consultData.time === apptTime
            );
          });
          redirectUrl =
            baseUrl +
            "/src/camcheck.php?id=" +
            consultData.consultData.id +
            "&meeting_id=" +
            consultData.consultData.meeting_id +
            "&doctor_id=" +
            consultData.consultData.doctor_id +
            "&patient_id=" +
            consultData.consultData.patient_id +
            "patient_profile_id=" +
            consultData.consultData.patient_profile_id +
            "&access_token=" +
            myGlobals.accessToken +
            "&globals=" +
            encodeURIComponent(JSON.stringify(globalObject));

          data["postValues"] = postValues;
        } else {
          redirectUrl = baseUrl + "/src/consultation.php";
        }
        window.location.replace(redirectUrl);
        // var jsonString = JSON.stringify(data["data"]);
        // const global = JSON.stringify(globalObject);
        // console.log("global object", global);
        // //var xhr = new XMLHttpRequest();

        // const iframe = iframeRef.current;
        // const iframeDocument =
        //   iframe.contentDocument || iframe.contentWindow.document;

        // // Prepare the AJAX request
        // var form = iframeDocument.createElement("form");
        // form.setAttribute("method", "post");
        // form.setAttribute("action", redirectUrl);
        // // form.setAttribute("target", "_blank");

        // // Create an input field to hold the array data
        // var input = iframeDocument.createElement("input");
        // input.setAttribute("type", "hidden");
        // input.setAttribute("name", "consultation");
        // input.setAttribute("value", jsonString);

        // const input1 = document.createElement("input");
        // input1.setAttribute("type", "hidden");
        // input1.setAttribute("name", "global");
        // input1.setAttribute("value", global);

        // // Append the input field to the form
        // form.appendChild(input);
        // form.appendChild(input1);

        // // Append the form to the document body
        // iframeDocument.body.appendChild(form);

        // // Submit the form
        // form.submit();
      })
      .catch((error) => {
        console.log("Error:", error);
        setModalType("error");
        setModalText("Something went wrong");
        setShowModal(true);
        authCtx.toggleLoader(false);
      });
    authCtx.toggleLoader(false);
  }

  return (
    <>
      {/* <div className="main-content animate min-vh-100">
        <iframe
          ref={iframeRef}
          id="myIframe"
          src="about:blank"
          width="100%"
          height="100%"
          allow="camera *;microphone *"
          sandbox="allow-same-origin allow-scripts allow-forms"
        ></iframe>
      </div> */}
      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Teleconsultation;
