import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Parser from "html-react-parser";
import { Dialog, DialogContent, IconButton, Snackbar } from "@material-ui/core";
import { Alert, DialogTitle } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import BookHealthPackages from "./BookHealthPackages";
import { dashboardservice } from "../../services/dashboard.service";

function HealthPackageDetails() {
  const authCtx = useContext(AuthContext);
  const [healthPackagesList, setHealthPackagesList] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isErrorText, setErrorMessage] = useState<any>("");
  const [packageName, setPackageName] = useState<any>("");
  //const [packageItemName, setPackageItemName] = useState<any>("");
  const [packageImage, setPackageImageName] = useState<any>("");
  const [subPackageName, setSubPackageName] = useState<any>("");

  const [bookHealthPackageModel, setHealthPackageModel] =
    useState<boolean>(false);
  const history = useHistory();
  let loc = useLocation();
  let healthPackage: any = loc.state;

  const handleBookHealthPackageClose = () => setHealthPackageModel(false);

  useEffect(() => {
    if (!healthPackage) {
      history.push("/auth/healthPackages");
    } else {
      setPackageName(healthPackage.name);
      //setPackageItemName(healthPackage.packagename);
      setPackageImageName(healthPackage.image);

      const getHealthPackages = async () => {
        await fetchHealthPackagesDetails(healthPackage.packagename);
      };
      getHealthPackages();
    }
    // eslint-disable-next-line
  }, []);

  const fetchHealthPackagesDetails = async (packageName: any) => {
    setHealthPackagesList([]);

    let res = await dashboardservice.getSpecificHealthPackage(packageName);
    authCtx.toggleLoader(true);
    if (res && res.data && res.data.status && res.data.status === "success") {
      setHealthPackagesList(res.data.data);
      setIsError(false);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setIsError(true);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  const goBack = (e: any) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Health Package Details</h3>
          <div className="ms-auto mw-90px">
            <a href="#" onClick={(e) => goBack(e)}>
              Back
            </a>
            {/* <Notification />
            <UserMenu /> */}
          </div>
        </div>

        <div
          className="specialities_banner d-flex align-items-end rounded-1rem"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,1) 100%),url(${packageImage})`,
          }}
        >
          <div className="px-4">
            <div className="row">
              <div className="col">
                <div className="mb-5 ml-md-4">
                  <h1 className="text-white m-2">{packageName}</h1>
                  <p className="text-white m-2">Exclusive Health Packages</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="speciality-section  pt-md-4 pt-sm-2">
          {healthPackagesList &&
            healthPackagesList.map((item: any, index: any) => (
              <div className="custom-border-radious mt-md-n5 mt-2" key={index}>
                <div className="card border-0 shadow-sm rounded-1rem packages-list mb-3">
                  <div className="card-body p-4">
                    <h3 className=" text-primary mt-0">{Parser(item.name)}</h3>

                    {Parser(
                      `<div className="ul-columns mt-4">${item.description}</div>`
                    )}

                    <h5
                      className="mb-2 text-primary mt-0"
                      style={{ textAlign: "right" }}
                    >
                      {item.price}
                    </h5>

                    <div className="d-flex align-items-center mt-2">
                      <small>
                        Terms &#38; Conditions
                        <sup>*</sup>
                      </small>
                      <button
                        className="btn btn-primary mt-2 mt-md-0 w-sm-100 text-right px-4"
                        onClick={() => {
                          setHealthPackageModel(true);
                          setSubPackageName(item.name);
                        }}
                      >
                        Book
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={bookHealthPackageModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Book Health Package
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => handleBookHealthPackageClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <BookHealthPackages
            packageName={packageName}
            healthPackagesList={healthPackagesList}
            subPackageName={subPackageName}
            close={handleBookHealthPackageClose}
          />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={isError}
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      >
        <Alert onClose={() => setIsError(false)} severity="error">
          {isErrorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default HealthPackageDetails;
