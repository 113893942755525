import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const MyMedicationServices = {
        getMyMedicationDetails
};

async function getMyMedicationDetails(patientId:[string], his:string, brandName:any){
    const apiPath = API_ENDPOINTS.GET_MEDICATION_DETAILS + brandName;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: {
            "patientId":patientId,
            "HIS":his
        },
        customHeaders:{
            'Content-Type':'application/json'
        },
    };
    return await makeRequest(options)
}
