import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import ConditionsCard from "../dashboard/ConditionsCard";
import ConsultedDoctorsCard from "../dashboard/ConsultedDoctorsCard";
import { finddoctorservice } from "../../services/findDoctor.services";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import DoctorProfile from "../doctor/DoctorProfile";
import { teleconsulationService } from "../../services/teleconsult.service";

function TeleDoctor() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["findDoctor"]);
  const [doctorSearchString, setDoctorSearchString] = useState<string>("");
  const [doctorSearchList, setDoctorSearchList] = useState<any>([]);
  const [doctorResultList, setDoctorResultList] = useState<any>([]);
  const [doctorResultListBakup, setDoctorResultListBakup] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [docSearchError, setDocSerachError] = useState<any>(false);
  const [docSerachMessage, setDocSearchErroeMessage] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");
  const [doctorProfileDialog, setDoctorProfileDialog] =
    useState<boolean>(false);
  const [doctorProfile, setDoctorProfile] = useState<any>();

  let loc: any = useLocation();

  useEffect(() => {
    const getTeleconsultationDoctor = () => {
      fetchTeleconsultationDoctor();
    };
    getTeleconsultationDoctor();
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e: any) => {
    e.preventDefault();
    setDoctorSearchString(e.target.value);
    handleDoctorSearch(e.target.value);
  };

  const handleDoctorSearch = (searchString: any) => {
    if (searchString === "") {
      setDoctorResultList(doctorResultListBakup);
    } else {
      if (searchString.length > 2) {
        const filteredItems: any = doctorResultListBakup.filter((item: any) => {
          return item.name.toLowerCase().includes(searchString.toLowerCase());
        });
        setDoctorResultList(filteredItems);
      }
    }
  };

  const sortDoctorsList = (doctorslist: any) => {
    doctorslist.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
    return doctorslist;
  };

  const fetchTeleconsultationDoctor = async () => {
    authCtx.toggleLoader(true);
    let res = await teleconsulationService.getTelecosnultationDoctor();

    if (res && res.data && res.data.status && res.data.status === "success") {
      const doctorData = sortDoctorsList(res.data.data);
      setDoctorResultList(doctorData);
      setDoctorResultListBakup(doctorData);
      setIsError(false);
      authCtx.toggleLoader(false);
    } else {
      setDoctorResultList([]);
      setDoctorResultListBakup([]);
      setIsError(true);
      setErrorMessage("Something went wrong");
      authCtx.toggleLoader(false);
    }
  };

  const bookAppointment = (doctorDetail: any) => {
    history.push({
      pathname: "/auth/bookTeleAppointment",
      state: doctorDetail,
    });
  };

  const handleDoctorProfile = (item: any) => {
    setDoctorProfile(item);
    setDoctorProfileDialog(true);
  };

  const hanldeDoctorProfileDialogClose = () => {
    setDoctorProfileDialog(false);
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24"> {t("teleconsultation")}</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 position-relative">
            <div className="card border-0 shadow-sm rounded-1rem">
              <div className="card-body d-block d-sm-flex">
                <TextField
                  id="searchString"
                  type="search"
                  autoComplete="off"
                  placeholder={t("searchTeleDoctorPlaceholder")}
                  className="form-control me-sm-3 fs-14"
                  variant="outlined"
                  size="small"
                  value={doctorSearchString}
                  onChange={(e: any) => handleInputChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <p className="text-muted fs-14">Our All General Practitioner </p>
          </div>
          <div className="col-12 z-0">
            <div className="row g-3 mt-1">
              {doctorResultList.length > 0 &&
                doctorResultList.map((item: any, index: any) => (
                  <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
                    <div className="card border-0 shadow-sm rounded-1rem text-center h-100 doc-profile-container">
                      {/* <i className="material-icons-outlined btn btn-link text-decoration-none doc-profile-icon fs-1" onClick={()=> setDoctorProfileDialog(true)}>
                          info
                          </i> */}

                      <div className="card-body pt-4 h-100">
                        <img
                          src={
                            item.image ? item.image : "/assets/images/doc.png"
                          }
                          className="dr-photo rounded-circle"
                          alt=""
                        />
                        <div className="mt-2 w-100">
                          <h6 className="fw-bold mt-3 text-truncate">
                            {" "}
                            {item.name}
                          </h6>
                          <p className="fw-bold text-muted mb-1 text-truncate">
                            {" "}
                            General Practitioner
                          </p>
                        </div>
                        <button
                          className="btn btn-link no-focus"
                          onClick={() => handleDoctorProfile(item)}
                        >
                          View Profile
                        </button>

                        <button
                          onClick={() => bookAppointment(item)}
                          className="btn btn-primary w-100 mt-3 position-relative bottom-0"
                        >
                          {t("bookTeleconsultation")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

              {!doctorSearchList && (
                <div className="card border-0 shadow-sm rounded-1rem text-center">
                  <div className="card-body pt-4">
                    <div className="text-center">
                      <h5>No Doctors to display</h5>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={doctorProfileDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Doctor's Profile
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => hanldeDoctorProfileDialogClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DoctorProfile doctorProfileDetails={doctorProfile} />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={docSearchError}
        autoHideDuration={3000}
        onClose={() => setDocSerachError(false)}
      >
        <Alert onClose={() => setDocSerachError(false)} severity="error">
          {docSerachMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isError}
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      >
        <Alert onClose={() => setIsError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TeleDoctor;
