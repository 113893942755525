import { dashboardservice } from "../../services/dashboard.service";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { finddoctorservice } from "../../services/findDoctor.services";

const ConditionsCard = (props: any) => {
  const authCtx = useContext(AuthContext);
  const [conditionList, setConditionList] = useState<any>([]);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  useEffect(() => {
    const ac = new AbortController();
    const getCondition = () => {
      fetchConditions();
    };
    getCondition();

    return () => ac.abort();
    // eslint-disable-next-line
  }, []);
  const fetchConditions = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getallspecialtiesByLocation(user.vblog);
    if (res && res.data && res.data.data) {
      setConditionList(res.data.data);
      props.setSpecialityList(res.data.data);
    } else {
      setConditionList([]);
    }
    authCtx.toggleLoader(false);
  };
  return (
    <div
      className={
        "card border-0 shadow-sm rounded-1rem " +
        (props.topMargin ? "mt-4" : "")
      }
    >
      <div className="card-body p-4">
        <h6 className="fw-bold text-primary">Find doctor by speciality</h6>
        <ul className="row p-0 mt-4 mb-0 list-hover-effect z-1 position-relative">
          {conditionList &&
            conditionList.length > 0 &&
            conditionList.map((item: any, index: any) => (
              <li
                key={"specility" + index}
                className="col-sm-6 col-md-6 col-xl-4 d-inline tooltip expand"
                data-title={item.name}
              >
                <Link
                  to={{
                    pathname: "/auth/findDoctor",
                    state: { item: item, search: "condition" },
                  }}
                  className="text-dark d-block ps-3 py-2 text-decoration-none text-ellipsis"
                >
                  {item.name}
                </Link>
              </li>
            ))}
        </ul>
        {conditionList && conditionList.length <= 0 && (
          <div className="mh-240p d-flex justify-content-center align-items-center">
            <div className="text-center text-999 p-4">
              {" "}
              <i className="material-icons-outlined fs-1"> info </i>
              <p className="fs-6">No conditions to display</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConditionsCard;
