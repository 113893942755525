import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { documentservice } from "../services/document.service";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { profileService } from "../services/profile.service";
import AuthContext from "../store/auth-context";

enum DocumentType {
  EmiratesID = 1,
  LabourCard = 2,
  DrivingLicense = 3,
  Passport = 4,
  InternationalId = 5,
  Insurance = 6,
}

enum DocumentSide {
  Front = 1,
  Back = 2,
}

const UploadDocs = (props: any) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["document"]);

  const [emiratesIdFrontFileName, setEmiratesIdFrontFileName] = useState("");
  const [emiratesIdFrontFileUrl, setEmiratesIdFrontFileUrl] = useState("");
  const [emiratesIdBackFileName, setEmiratesIdBackFileName] = useState("");
  const [emiratesIdBackFileUrl, setEmiratesIdBackFileUrl] = useState("");

  const [passportFrontFileName, setPassportFrontFileName] = useState("");
  const [passportFrontFileUrl, setPassportFrontFileUrl] = useState("");
  const [passportBackFileName, setPassportBackFileName] = useState("");
  const [passportBackFileUrl, setPassportBackFileUrl] = useState("");

  const [insuranceFrontFileName, setInsuranceFrontFileName] = useState("");
  const [insuranceFrontFileUrl, setInsuranceFrontFileUrl] = useState("");
  const [insuranceBackFileName, setInsuranceBackFileName] = useState("");
  const [insuranceBackFileUrl, setInsuranceBackFileUrl] = useState("");

  const [drivingLicenseFrontFileName, setDrivingLicenseFrontFileName] =
    useState("");
  const [drivingLicenseFrontFileUrl, setDrivingLicenseFrontFileUrl] =
    useState("");
  const [drivingLicenseBackFileName, setDrivingLicenseBackFileName] =
    useState("");
  const [drivingLicenseBackFileUrl, setDrivingLicenseBackFileUrl] =
    useState("");

  const [labourCardFrontFileName, setLabourCardFrontFileName] = useState("");
  const [labourCardFrontFileUrl, setLabourCardFrontFileUrl] = useState("");
  const [labourCardBackFileName, setLabourCardBackFileName] = useState("");
  const [labourCardBackFileUrl, setLabourCardBackFileUrl] = useState("");

  // const [internationalIdFrontFileName, setInternationalIdFrontFileName] = useState("");
  // const [internationalIdFrontFileUrl, setInternationalIdFrontFileUrl] = useState("");
  // const [internationalIdBackFileName, setInternationalIdBackFileName] = useState("");
  // const [internationalIdBackFileUrl, setInternationalIdBackFileUrl] = useState("");

  const [fileErrorText, setFileErrorText] = useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const [selectedDocumentSide, setSelectedDocumentSide] = useState("");

  useEffect(() => {
    getDocuments();
    // eslint-disable-next-line
  }, []);

  const getBase64File = async (fileUrl: any, fileName: any) => {
    authCtx.toggleLoader(true);
    // let res = await profileService.getProfileDocument(fileUrl, fileName);
    if (fileName) {
      let res = await profileService.getProfileDocument(null, fileName);
      downloadFile(res, fileName);
    }
  };

  const downloadFile = (base64: any, fileName: any) => {
    let linkSource: any;
    let ext = fileName.split(".").pop().toLowerCase();
    if (ext === "pdf") {
      linkSource = `data:application/${ext};base64,${base64}`;
    } else if (ext === "jpg" || ext === "png" || ext === "jpeg") {
      linkSource = `data:image/${ext};base64,${base64}`;
    } else if (ext === "docx") {
      linkSource = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document/${ext};base64,${base64}`;
    }

    const downloadLink = document.createElement("a");
    const file = fileName;

    downloadLink.href = linkSource;
    downloadLink.download = file;
    downloadLink.click();
    authCtx.toggleLoader(false);
  };

  const getDocuments = async () => {
    authCtx.toggleLoader(true);
    const res = await documentservice.getDocuments(props.mId);
    if (res && res.data && res.message && res.message.status === "Success") {
      const docResult = res.data;
      setEmiratesIdFrontFileName(docResult.emiratesIdFrontFileName);
      setEmiratesIdFrontFileUrl(docResult.emiratesIdFrontFileUrl);
      setEmiratesIdBackFileName(docResult.emiratesIdBackFileName);
      setEmiratesIdBackFileUrl(docResult.emiratesIdBackFileUrl);

      setPassportFrontFileName(docResult.passportFrontFileName);
      setPassportFrontFileUrl(docResult.passportFrontFileUrl);
      setPassportBackFileName(docResult.passportBackFileName);
      setPassportBackFileUrl(docResult.passportBackFileUrl);

      setInsuranceFrontFileName(docResult.insuranceFrontFileName);
      setInsuranceFrontFileUrl(docResult.insuranceFrontFileUrl);
      setInsuranceBackFileName(docResult.insuranceBackFileName);
      setInsuranceBackFileUrl(docResult.insuranceBackFileUrl);

      setDrivingLicenseFrontFileName(docResult.drivingLicenceFrontFileName);
      setDrivingLicenseFrontFileUrl(docResult.drivingLicenceFrontFileUrl);
      setDrivingLicenseBackFileName(docResult.drivingLicenceBackFileName);
      setDrivingLicenseBackFileUrl(docResult.drivingLicenceBackFileUrl);

      setLabourCardFrontFileName(docResult.labourCardFrontFileName);
      setLabourCardFrontFileUrl(docResult.labourCardFrontFileUrl);
      setLabourCardBackFileName(docResult.labourCardBackFileName);
      setLabourCardBackFileUrl(docResult.labourCardBackFileUrl);

      // setInternationalIdFrontFileName(docResult.iternationalIdFrontFileName);
      // setInternationalIdFrontFileUrl(docResult.iternationalIdFrontFileUrl)
      // setInternationalIdBackFileName(docResult.iternationalIdBackFileUrl);
      // setInternationalIdBackFileUrl(docResult.iternationalIdBackFileName);
    }
    authCtx.toggleLoader(false);
  };

  const handleFileChange = async (
    e: any,
    documentType: DocumentType,
    documentSide: DocumentSide,
    fileUrlSetter: any,
    fileNameSetter: any
  ) => {
    const file = e.target.files[0]; // accesing file

    if (!file) return;

    var ext = file.name.split(".").pop().toLowerCase();
    if (
      ext !== "pdf" &&
      ext !== "docx" &&
      ext !== "doc" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "png"
    ) {
      setFileErrorText(t("docFormatError"));
      setTimeout(() => {
        setFileErrorText("");
      }, 5000);
      return;
    }
    if (file.size > 2000 * 1024) {
      setFileErrorText(t("docSizeError"));
      setTimeout(() => {
        setFileErrorText("");
      }, 5000);
      return;
    }

    fileNameSetter(file.name);

    let data = new FormData();
    data.append("documentType", documentType.toString());
    data.append("documentSide", documentSide.toString());
    data.append("document", file);
    const result = await documentservice.uploadDocument(data, props.mId);
    if (result && result.message && result.message.status === "Success") {
      fileUrlSetter(result.data.fileUrl);
    }
  };

  const confirmDelete = (
    documentType: DocumentType,
    documentSide: DocumentSide
  ) => {
    setSelectedDocumentType(documentType.toString());
    setSelectedDocumentSide(documentSide.toString());
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const deleteFile = async () => {
    const result = await documentservice.removeDocument(
      selectedDocumentType,
      selectedDocumentSide,
      props.mId
    );
    if (result && result.message && result.message.status === "Success") {
      switch (selectedDocumentType) {
        case DocumentType.EmiratesID.toString(): {
          switch (selectedDocumentSide) {
            case DocumentSide.Front.toString(): {
              setEmiratesIdFrontFileName("");
              setEmiratesIdFrontFileUrl("");
              break;
            }
            case DocumentSide.Back.toString(): {
              setEmiratesIdBackFileName("");
              setEmiratesIdBackFileUrl("");
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        case DocumentType.Passport.toString(): {
          switch (selectedDocumentSide) {
            case DocumentSide.Front.toString(): {
              setPassportFrontFileName("");
              setPassportFrontFileUrl("");
              break;
            }
            case DocumentSide.Back.toString(): {
              setPassportBackFileName("");
              setPassportBackFileUrl("");
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        case DocumentType.Insurance.toString(): {
          switch (selectedDocumentSide) {
            case DocumentSide.Front.toString(): {
              setInsuranceFrontFileName("");
              setInsuranceFrontFileUrl("");
              break;
            }
            case DocumentSide.Back.toString(): {
              setInsuranceBackFileName("");
              setInsuranceBackFileUrl("");
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        case DocumentType.DrivingLicense.toString(): {
          switch (selectedDocumentSide) {
            case DocumentSide.Front.toString(): {
              setDrivingLicenseFrontFileName("");
              setDrivingLicenseFrontFileUrl("");
              break;
            }
            case DocumentSide.Back.toString(): {
              setDrivingLicenseBackFileName("");
              setDrivingLicenseBackFileUrl("");
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        case DocumentType.LabourCard.toString(): {
          switch (selectedDocumentSide) {
            case DocumentSide.Front.toString(): {
              setLabourCardFrontFileName("");
              setLabourCardFrontFileUrl("");
              break;
            }
            case DocumentSide.Back.toString(): {
              setLabourCardBackFileName("");
              setLabourCardBackFileUrl("");
              break;
            }
            default: {
              break;
            }
          }
          break;
        }
        // case DocumentType.InternationalId.toString(): {
        //     switch (selectedDocumentSide) {
        //         case DocumentSide.Front.toString(): {
        //             setInternationalIdFrontFileName("");
        //             setInternationalIdFrontFileUrl("");
        //             break;
        //         }
        //         case DocumentSide.Back.toString(): {
        //             setInternationalIdBackFileName("");
        //             setInternationalIdBackFileUrl("");
        //             break;
        //         }
        //         default: {
        //             break;
        //         }
        //     }
        //     break;
        // }
        default: {
          break;
        }
      }
    }
    setOpenDialog(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("confirmation")} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("confirmDelete")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose} className="btn btn-primary">
            {t("cancel")}
          </button>
          <button onClick={deleteFile} className="btn btn-primary">
            {t("yes")}
          </button>
        </DialogActions>
      </Dialog>
      {fileErrorText && (
        <div className="alert alert-danger" role="alert">
          {fileErrorText}
        </div>
      )}

      <div className="row">
        <div className="col-sm-5 mb-2">{t("emiratesID")}</div>
        <div className="col-sm-7">
          {emiratesIdFrontFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("frontCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      {" "}
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            emiratesIdFrontFileUrl,
                            emiratesIdFrontFileName
                          )
                        }
                      >
                        {emiratesIdFrontFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.EmiratesID,
                          DocumentSide.Front
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!emiratesIdFrontFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.EmiratesID,
                    DocumentSide.Front,
                    setEmiratesIdFrontFileUrl,
                    setEmiratesIdFrontFileName
                  )
                }
              />
              {t("uploadFrontCopy")}
            </button>
          )}
          <hr />
          {emiratesIdBackFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      {" "}
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            emiratesIdBackFileUrl,
                            emiratesIdBackFileName
                          )
                        }
                      >
                        {emiratesIdBackFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.EmiratesID,
                          DocumentSide.Back
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!emiratesIdBackFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.EmiratesID,
                    DocumentSide.Back,
                    setEmiratesIdBackFileUrl,
                    setEmiratesIdBackFileName
                  )
                }
              />
              {t("uploadBackCopy")}
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-5 mb-2"> {t("passport")} </div>
        <div className="col-sm-7">
          {passportFrontFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("frontCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            passportFrontFileUrl,
                            passportFrontFileName
                          )
                        }
                      >
                        {passportFrontFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.Passport,
                          DocumentSide.Front
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!passportFrontFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.Passport,
                    DocumentSide.Front,
                    setPassportFrontFileUrl,
                    setPassportFrontFileName
                  )
                }
              />
              {t("uploadFrontCopy")}
            </button>
          )}
          <hr />
          {passportBackFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      {" "}
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            passportBackFileUrl,
                            passportBackFileName
                          )
                        }
                      >
                        {passportBackFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(DocumentType.Passport, DocumentSide.Back);
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!passportBackFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.Passport,
                    DocumentSide.Back,
                    setPassportBackFileUrl,
                    setPassportBackFileName
                  )
                }
              />
              {t("uploadBackCopy")}
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-5 mb-2"> {t("insuranceCard")}</div>
        <div className="col-sm-7">
          {insuranceFrontFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold">{t("frontCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            insuranceFrontFileUrl,
                            insuranceFrontFileName
                          )
                        }
                      >
                        {insuranceFrontFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.Insurance,
                          DocumentSide.Front
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!insuranceFrontFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.Insurance,
                    DocumentSide.Front,
                    setInsuranceFrontFileUrl,
                    setInsuranceFrontFileName
                  )
                }
              />
              {t("uploadFrontCopy")}
            </button>
          )}

          <hr />
          {insuranceBackFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            insuranceBackFileUrl,
                            insuranceBackFileName
                          )
                        }
                      >
                        {insuranceBackFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.Insurance,
                          DocumentSide.Back
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!insuranceBackFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.Insurance,
                    DocumentSide.Back,
                    setInsuranceBackFileUrl,
                    setInsuranceBackFileName
                  )
                }
              />
              {t("uploadBackCopy")}
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-5 mb-2">{t("drivingLicense")}</div>
        <div className="col-sm-7">
          {drivingLicenseFrontFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("frontCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            drivingLicenseFrontFileUrl,
                            drivingLicenseFrontFileName
                          )
                        }
                      >
                        {drivingLicenseFrontFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.DrivingLicense,
                          DocumentSide.Front
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!drivingLicenseFrontFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.DrivingLicense,
                    DocumentSide.Front,
                    setDrivingLicenseFrontFileUrl,
                    setDrivingLicenseFrontFileName
                  )
                }
              />
              {t("uploadFrontCopy")}
            </button>
          )}
          <hr />
          {drivingLicenseBackFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            drivingLicenseBackFileUrl,
                            drivingLicenseBackFileName
                          )
                        }
                      >
                        {drivingLicenseBackFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.DrivingLicense,
                          DocumentSide.Back
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!drivingLicenseBackFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.DrivingLicense,
                    DocumentSide.Back,
                    setDrivingLicenseBackFileUrl,
                    setDrivingLicenseBackFileName
                  )
                }
              />
              {t("uploadBackCopy")}
            </button>
          )}
        </div>
      </div>
      <hr />
      {/* <div className="row">
                <div className="col-sm-5 mb-2">{t("internationalId")}</div>
                <div className="col-sm-7">

                    {internationalIdFrontFileName &&
                        <div className="uploaded">
                            <label className="fs-12 mb-1 fw-bold"> {t("frontCopy")}</label>
                            <div className="d-flex align-items-center">
                                <small className="text-primary me-2">
                                    <div className="d-flex align-items-center">
                                        <small className="text-primary me-2"> <a href={internationalIdFrontFileUrl}>{internationalIdFrontFileName}</a></small>
                                        <Link to="#" className="ln-0" onClick={(e) => {
                                            e.preventDefault()
                                            confirmDelete(DocumentType.InternationalId, DocumentSide.Front)
                                        }}><i className="material-icons-outlined"> highlight_off </i></Link>
                                    </div>
                                </small>
                            </div>
                        </div>

                    }
                    {!internationalIdFrontFileName &&
                        <button className="btn btn-sm btn-primary fs-12 position-relative">
                            <input type="file"  accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                                onChange={(e) => handleFileChange(e, DocumentType.InternationalId, DocumentSide.Front, setInternationalIdFrontFileUrl, setInternationalIdFrontFileName)} />
                            {t("uploadFrontCopy")}
                        </button>
                    }
                    <hr />
                    {internationalIdBackFileName &&
                        <div className="uploaded">
                            <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
                            <div className="d-flex align-items-center">
                                <small className="text-primary me-2">
                                    <div className="d-flex align-items-center">
                                        <small className="text-primary me-2"> <a href={internationalIdBackFileUrl}>{internationalIdBackFileName}</a></small>
                                        <Link to="#" className="ln-0" onClick={(e) => {
                                            e.preventDefault();
                                            confirmDelete(DocumentType.InternationalId, DocumentSide.Back)
                                        }}><i className="material-icons-outlined"> highlight_off </i></Link>
                                    </div>
                                </small>
                            </div>
                        </div>
                    }
                    {!internationalIdBackFileName &&
                            <button className="btn btn-sm btn-primary fs-12 position-relative">
                                <input type="file"   accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                                    onChange={(e) => handleFileChange(e, DocumentType.InternationalId, DocumentSide.Back, setInternationalIdBackFileUrl, setInternationalIdBackFileName)} />
                                {t("uploadBackCopy")}
                            </button>
                    }

                </div>
            </div> */}
      <div className="row">
        <div className="col-sm-5 mb-2">{t("labourCard")}</div>
        <div className="col-sm-7">
          {labourCardFrontFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("frontCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            labourCardFrontFileUrl,
                            labourCardFrontFileName
                          )
                        }
                      >
                        {labourCardFrontFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.LabourCard,
                          DocumentSide.Front
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!labourCardFrontFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.LabourCard,
                    DocumentSide.Front,
                    setLabourCardFrontFileUrl,
                    setLabourCardFrontFileName
                  )
                }
              />
              {t("uploadFrontCopy")}
            </button>
          )}
          <hr />
          {labourCardBackFileName && (
            <div className="uploaded">
              <label className="fs-12 mb-1 fw-bold"> {t("backCopy")}</label>
              <div className="d-flex align-items-center">
                <small className="text-primary me-2">
                  <div className="d-flex align-items-center">
                    <small className="text-primary me-2">
                      <button
                        className="btn btn-link no-focus fw-300 text-ellipsis"
                        onClick={() =>
                          getBase64File(
                            labourCardBackFileUrl,
                            labourCardBackFileName
                          )
                        }
                      >
                        {labourCardBackFileName}
                      </button>
                    </small>
                    <Link
                      to="#"
                      className="ln-0"
                      onClick={(e) => {
                        e.preventDefault();
                        confirmDelete(
                          DocumentType.LabourCard,
                          DocumentSide.Back
                        );
                      }}
                    >
                      <i className="material-icons-outlined"> highlight_off </i>
                    </Link>
                  </div>
                </small>
              </div>
            </div>
          )}
          {!labourCardBackFileName && (
            <button className="btn btn-sm btn-primary fs-12 position-relative">
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) =>
                  handleFileChange(
                    e,
                    DocumentType.LabourCard,
                    DocumentSide.Back,
                    setLabourCardBackFileUrl,
                    setLabourCardBackFileName
                  )
                }
              />
              {t("uploadBackCopy")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default UploadDocs;
