import React from 'react';
class ErrorBoundary extends React.Component {
    state: any;
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
    }

    render() {
        if (this.state.errorInfo) {
            // Error path
            return (
                <div>
                    <h6>Something went wrong, please try again.</h6>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        <summary>{this.state.error && this.state.error.toString()}</summary>
                        <p>
                            {this.state.errorInfo.componentStack}
                        </p>
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

export default ErrorBoundary;