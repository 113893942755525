import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { timeSlotService } from "../../services/timeSlot.service";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import AuthContext from "../../store/auth-context";
import { bookAppointmentService } from "../../services/bookAppointment.service";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import AppointmentBooked from "./AppointmentBooked";

function ReschedulAppt() {
  const [beginDate, setBeginDate] = useState<any>("");
  const [morningSlots, setMorningSlot] = useState<any>([]);
  const [afternoonSlots, setAfternoonSlot] = useState<any>([]);
  const [eveningSlots, setEveningSlot] = useState<any>([]);
  const [futureDates, setFutureDates] = useState<any>([]);
  const [timeSlot, setTime] = useState<any>("");
  const [timeItem, setTimeItem] = useState<any>("");
  const authCtx = useContext(AuthContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [rescheduleShowModal, setrescheduleShowModal] =
    useState<boolean>(false);
  const [appointmentBookedModal, setAppointBookedModal] =
    useState<boolean>(false);
  const [patientName, setPatientName] = useState<any>("");
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [valetParkng, setValetParking] = useState<boolean>(false);
  const [apptRefNumber, setAppointmentRefNo] = useState<string>("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const location: any = useLocation();
  const doctorValue: any = location.state.item;
  const familyMemberId = location.state.fmId;
  const familyMemberList = location.state.familyMemberList;

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    setPatientName(location.state.userName);
    const setDate = () => {
      setBeginDate(doctorValue.apptDate);
    };

    setDate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (beginDate !== "") {
      const fetchTime = () => {
        fetchTimeSlot();
      };
      fetchTime();
    }

    // eslint-disable-next-line
  }, [beginDate]);

  const handleDateChange = (e: any) => {
    setBeginDate(e.target.value);
    setTime("");
  };

  const fetchTimeSlot = async () => {
    authCtx.toggleLoader(true);
    let timeData: any = {};
    timeData = {
      facilityId: doctorValue.facilityId,

      department: doctorValue.department,

      physicianLicense: doctorValue.physicianLicense,

      physicianNo: doctorValue.physicianNo,

      providerLicence: "",

      beginDate: beginDate,

      endDate: "",
    };
    // authCtx.toggleLoader(false);
    let result = await timeSlotService.getTimeSlot(timeData);
    if (result.status === 500) {
      console.log(result.message);
    } else {
      setMorningSlot(result.morningSlots);
      setAfternoonSlot(result.afternoonSlots);
      setEveningSlot(result.eveningSlots);
      setFutureDates(result.futureDates);
    }
    authCtx.toggleLoader(false);
  };

  const hanldeTimeChange = (e: any, timeItem: any) => {
    setTime(e.target.value);
    setTimeItem(timeItem);
  };

  const confirmBooking = async () => {
    let data: any;

    if (familyMemberId !== 0 && familyMemberId !== null) {
      let familMember = familyMemberList.find(
        (item: any) => item.familyMemberId === familyMemberId
      );
      if (familMember.age > 18) {
        if (
          familMember.mobile === user.mobile &&
          familMember.callingCode === user.callingCode
        ) {
          setShowModal(true);
          setModalType("error");
          setModalText(
            "family member mobile number is same as login user mobile number"
          );
          setIsValidUser(false);
        }
      }
    }

    if (isValidUser) {
      authCtx.toggleLoader(true);
      data = {
        facilityId: doctorValue.facilityId,
        appointmentId: doctorValue.appointmentRefNo,
        apptDate: beginDate,
        apptTime: timeItem.startTime,
        duration: "",
        department: "",
        patientNo: doctorValue.patientId,
        mobileNo: doctorValue.mobileNo,
        physicianNo: doctorValue.physicianNo,
        physicianLicense: doctorValue.physicianLicense,
      };

      const response = await bookAppointmentService.updateAppointment(data);
      authCtx.toggleLoader(false);
      if (response && response.data && response.data.appointmentRefNo) {
        setAppointmentRefNo(response.data.appointmentRefNo);
        setAppointBookedModal(true);
      } else {
        handleShow();
        setModalText("Something went wrong");
        setModalType("error");
        setTimeout(() => {
          setModalText("");
          handleClose();
        }, 3000);
      }
    }
  };

  const toggleValetParking = () => {
    setValetParking(!valetParkng);
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Reschedule Appointment</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="card border-0 shadow-sm rounded-1rem">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    {" "}
                    <img
                      src={doctorValue && doctorValue.image}
                      className="img-h-75  rounded-circle dr-photo"
                      alt="dr-img"
                    />{" "}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fw-bold mt-0 mb-1">
                      {doctorValue && doctorValue.physicianName}
                    </h6>
                    <p className="fw-bold text-muted mb-1">
                      {doctorValue && doctorValue.specialty}
                    </p>
                    <p className="text-muted mb-1">
                      {doctorValue && doctorValue.experience}
                    </p>
                    <p className="text-muted mb-1">
                      {doctorValue && doctorValue.location}
                    </p>{" "}
                    <p className="text-muted mb-1">
                      Current Booking:&nbsp;
                      {new Date(
                        "1970-01-01T" + doctorValue.apptTime + "Z"
                      ).toLocaleTimeString("en-US", {
                        timeZone: "UTC",
                        hour12: true,
                        hour: "numeric",
                        minute: "numeric",
                      })}{" "}
                      | {new Date(doctorValue.apptDate).toDateString()}
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    style={{ backgroundColor: "white" }}
                    className=" ms-auto text-dark border-0 pt-1 float-end"
                    onClick={() => {
                      let date = new Date(beginDate);
                      date.setDate(date.getDate() + 1);
                      let newDate = date.toISOString().slice(0, 10);
                      setBeginDate(newDate);
                      setTime("");
                    }}
                  >
                    <i className="material-icons-outlined"> chevron_right </i>
                  </button>
                  <button
                    style={{
                      pointerEvents:
                        beginDate === new Date().toISOString().slice(0, 10)
                          ? "none"
                          : "auto",
                      backgroundColor: "white",
                    }}
                    className="text-dark border-0 pt-1 float-end"
                    onClick={() => {
                      let date = new Date(beginDate);
                      date.setDate(date.getDate() - 1);
                      let newDate = date.toISOString().slice(0, 10);
                      setBeginDate(newDate);
                      setTime("");
                    }}
                  >
                    <i className="material-icons-outlined"> chevron_left </i>
                  </button>
                  <label className="fw-bold mb-1 mt-1">Choose Date</label>
                  <input
                    type="date"
                    className="form-control fs-14 no-focus"
                    id="slotDate"
                    value={beginDate}
                    onChange={(e) => handleDateChange(e)}
                    min={new Date().toISOString().slice(0, 10)}
                  />
                </div>
                <div className="fs-12 mt-4 border-top pt-4">
                  <h6 className="mb-4 fs-14">
                    Showing slots for{" "}
                    <span className="fw-bold">
                      {" "}
                      {new Date(beginDate).toLocaleString("en-uk", {
                        weekday: "long",
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                      })}
                    </span>{" "}
                  </h6>
                  <div className="mb-3">
                    <h6 className="fs-12">Morning</h6>
                    {morningSlots && morningSlots.length > 0 ? (
                      morningSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Morning"
                            id={"morning-" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"morning-" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"morning-" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Afternoon</h6>
                    {afternoonSlots && afternoonSlots.length > 0 ? (
                      afternoonSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Morning"
                            id={"afternoon" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"afternoon" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"afternoon" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Evening</h6>
                    {eveningSlots && eveningSlots.length > 0 ? (
                      eveningSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Morning"
                            id={"evening" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"evening" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"evening" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Next Available Slots</h6>
                    {futureDates && futureDates.length > 0 ? (
                      futureDates.map((futureItem: any, index: any) => (
                        <button
                          key={index}
                          className="btn btn-outline-success btn-xs mb-1 me-1 fs-12k w-185 no-focus"
                          onClick={() => {
                            setBeginDate(futureItem);
                            setTime("");
                          }}
                        >
                          {new Date(futureItem).toLocaleString("en-uk", {
                            weekday: "short",
                            day: "2-digit",
                            year: "numeric",
                            month: "short",
                          })}
                        </button>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slots available for next 30 days
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <div className="card border-0 shadow-sm rounded-1rem">
              <div className="card-body">
                <div className="mt-2">
                  <label className="fw-bold mb-1">Selected Patient</label>
                  <h6>{patientName}</h6>
                </div>
                <div className="mt-4">
                  <label className="fw-bold mb-2">Timing</label>
                  {timeSlot ? (
                    <p className="fs-14 m-0">
                      {timeSlot}, {new Date(beginDate).toDateString()}
                    </p>
                  ) : (
                    <p className="fs-14 m-0">--:--</p>
                  )}
                </div>
                <div className="mt-4">
                  <label className="fw-bold mb-2">
                    Address{" "}
                    <Link
                      className="position-relative ms-1 t-5"
                      to={{
                        pathname: `https://www.google.com/maps/dir//${doctorValue.location}`,
                      }}
                      target="_blank"
                    >
                      {" "}
                      <i className="material-icons-outlined"> near_me </i>{" "}
                    </Link>
                  </label>
                  <p className="fs-14 m-0">{doctorValue.location}</p>
                </div>
                <button
                  className="btn btn-primary mt-3 w-100"
                  onClick={() => setrescheduleShowModal(true)}
                  disabled={timeSlot === ""}
                >
                  Confirm Booking
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          onClose={() => {
            handleClose();
            setModalText("");
          }}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}

      <Dialog
        open={rescheduleShowModal}
        onClose={() => setrescheduleShowModal(false)}
      >
        <DialogTitle>Reschedule Appointment</DialogTitle>
        <DialogContent>
          <div className="card-body p-4  ln-24">
            <div className="d-flex ">
              <div className="flex-shrink-0">
                {" "}
                <img
                  src={doctorValue.image}
                  alt="img"
                  className="img-h-75 rounded-circle dr-photo"
                />{" "}
              </div>
              <div className="flex-grow-1 ms-3">
                <label className="fw-bold">{doctorValue.physicianName}</label>
                <p className="text-muted fs-12 m-0">
                  {new Date(
                    "1970-01-01T" + timeItem.startTime + "Z"
                  ).toLocaleTimeString("en-US", {
                    timeZone: "UTC",
                    hour12: true,
                    hour: "numeric",
                    minute: "numeric",
                  })}{" "}
                  | {new Date(beginDate).toDateString()}
                </p>
                <p className="text-muted fs-12 m-0">{doctorValue.location}</p>
                <p className="text-muted fs-12 m-0">
                  For <span className="fw-bold"> {patientName}</span>
                </p>
              </div>
            </div>
          </div>
          <DialogContentText>
            {/* <div className="mb-2 form-check">
            <input
              type="checkbox"
              className="form-check-input no-focus"
              id="agree"
              onChange={toggleValetParking}
            />
            <label className="form-check-label fw-bold fs-14" htmlFor="agree">
                Do you want valet parking?
            </label>
          </div> */}
            Are you sure, you want to reschedule this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-primary"
            onClick={() => setrescheduleShowModal(false)}
          >
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              confirmBooking();
              setrescheduleShowModal(false);
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={appointmentBookedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="bg-ef1">
          <AppointmentBooked
            appointmentDoctor={[doctorValue]}
            timeSlot={timeSlot}
            timeItem={timeItem}
            beginDate={beginDate}
            patientName={patientName}
            appointmentText={"Rescheduled"}
            apptRefNumber={apptRefNumber}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ReschedulAppt;
