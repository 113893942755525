import React, { useState, useEffect, useContext } from "react";
import UserMenu from "../common/UserMenu";
import Notification from "../common/Notification";
import AuthContext from "../../store/auth-context";
import { patientService } from "../../services/patient.service";
import { familyMemberservice } from "../../services/familyMember.services";
import { MyMedicationServices } from "../../services/myMedication.services";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Alert, TabContext, TabPanel } from "@material-ui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { finddoctorservice } from "../../services/findDoctor.services";

function MyMedication() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["labReports", "login", "findDoctor"]);
  const [userName, setUserName] = useState<any>("");
  const [familyMemberId, setFamilyMemberId] = useState<any>(0);
  const [patientIdHISList, setPatientIdHISList] = useState<any>([]);
  const [familyMemberList, setFamilyMemberList] = useState<any>([]);
  const [activeMedicationsList, setActiveMedicationsList] = useState<any>([]);
  const [pastMedicationsList, setPastMedicationsList] = useState<any>([]);
  const [activeAllMedicationsList, setActiveAllMedicationsList] = useState<any>(
    []
  );
  const [pastAllMedicationsList, setPastAllMedicationsList] = useState<any>([]);
  const [eachDocCardMedList, setEachDocCardMedList] = useState<any>([]);
  const [distinctDocList, setDistinctDocList] = useState<any>([]);
  const [myMedApiResult, setMyMedApiResult] = useState<any>();
  const [doctorChange, setDoctorChange] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabListValue, setTabListValue] = useState("1");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [activeDoctorsCard, setActiveDoctorsCard] = useState<any>([]);
  const [pastDoctorsCard, setPastDoctorsCard] = useState<any>([]);
  const [filteredActiveMedDetailsList, setFilteredActiveMedDetailsList] =
    useState<any>([]);
  const [filteredPastMedDetailsList, setFilteredPastMedDetailsList] =
    useState<any>([]);
  const [openmedicationDialog, setOpenMedicationDialog] =
    useState<boolean>(false);
  const [facilityId, setFacilityId] = useState<string>("");
  const [hospitallocationList, setHospitalLocationList] = useState<any>([]);
  const [showMoreBtnClick, setShowMoreBtnClick] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  let differenceInDays = 0;

  useEffect(() => {
    if (hospitallocationList.length > 0) {
      const getpatient = () => {
        getPatientRecord();
      };

      getpatient();
    }

    // eslint-disable-next-line
  }, [familyMemberId, hospitallocationList, facilityId]);

  useEffect(() => {
    if (user.facilityId) setFacilityId(user.facilityId);
    const getLocation = () => {
      getAllHospitalLocations();
    };
    getLocation();

    const getFamilyList = () => {
      fetchFamilyMember();
    };
    getFamilyList();

    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const getAllHospitalLocations = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getLocations();
    if (res.data && res.data.status === "success") {
      setHospitalLocationList(res.data.data);
    } else {
      setModalText("Error in getting feedback locations");
      setModalType("error");
      setShowModal(true);
    }
    authCtx.toggleLoader(false);
  };

  const getPatientRecord = async () => {
    authCtx.toggleLoader(true);
    const currentHis: any = hospitallocationList.find(
      (item: any) => item.locationId === facilityId
    );
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const isPatientRecordCall = localStorage.getItem("isPatientRecordCall");
    let idList;
    setPatientIdHISList([]);

    if (familyMemberId === 0) {
      setUserName(user.firstName + " " + user.lastName);
    } else {
      familyMemberList &&
        familyMemberList.forEach((item: any) => {
          if (item.familyMemberId === familyMemberId) {
            setUserName(item.firstName + " " + item.lastName);
          }
        });
    }

    if (
      Object.keys(patientRecord).length !== 0 &&
      isPatientRecordCall === "true" &&
      familyMemberId === 0
    ) {
      fetchMedicationDetails(patientRecord);
    } else {
      let res = await patientService.getPatientRec(
        user.userId,
        familyMemberId,
        facilityId
      );
      if (res && res.data === undefined) {
        idList = [];
        console.log("Something went wrong in response");
      } else {
        idList = res.data;
      }

      idList &&
        idList.forEach((item: any) => {
          if (
            item &&
            (item.patientId !== null || (item.mobileNo && item.mobileNo !== ""))
          ) {
            if (patientIdHISList.length > 0) {
              if (patientIdHISList.some((obj: any) => obj.his === item.his)) {
                patientIdHISList.forEach((item2: any) => {
                  if (
                    item2.his === item.his &&
                    item2.patientId !== item.patientId
                  ) {
                    item2.patientId = [...item2.patientId, item.patientId];
                  }
                });
              } else {
                let data = {
                  his: item.his,
                  patientId: [item.patientId],
                  mobileNo: item.mobileNo,
                };
                patientIdHISList.push(data);
              }
            } else {
              let data = {
                his: item.his,
                patientId: [item.patientId],
                mobileNo: item.mobileNo,
              };
              patientIdHISList.push(data);
            }
          }
        });

      if (patientIdHISList.length > 0) {
        // if (familyMemberId === 0) {
        //   localStorage.setItem(
        //     "getPatientRecord",
        //     JSON.stringify(patientIdHISList)
        //   );
        //   localStorage.setItem("isPatientRecordCall", "true");
        // }
        fetchMedicationDetails(patientIdHISList);
        authCtx.toggleLoader(false);
      } else {
        authCtx.toggleLoader(false);
      }
    }
    authCtx.toggleLoader(false);
    // setIsLoading(false);
  };

  const fetchFamilyMember = async () => {
    const listFromApi = await familyMemberservice.getFamilyList();
    if (
      listFromApi &&
      listFromApi.message &&
      listFromApi.message.status === "Success"
    ) {
      setFamilyMemberList(listFromApi.data);
    } else {
      setFamilyMemberList([]);
    }
  };

  const fetchMedicationDetails = async (patientIdHisElement: any) => {
    authCtx.toggleLoader(true);

    let activeMediAllHISList: any = [];
    let pastMediAllHISList: any = [];
    let resultArr: any = [];
    const brandNameStr = localStorage.getItem("brandName");

    await Promise.all(
      patientIdHisElement.map(async (item: any) => {
        const res = await MyMedicationServices.getMyMedicationDetails(
          item.patientId,
          item.his,
          brandNameStr
        );

        if (
          res &&
          res.message &&
          res.message.status &&
          res.message.status[0] === "S"
        ) {
          if (resultArr.length === 0) {
            resultArr = res;
          } else {
            resultArr.activeMedications = resultArr.activeMedications.concat(
              res.activeMedications
            );
            resultArr.pastMedications = resultArr.pastMedications.concat(
              res.pastMedications
            );
          }

          if (res.activeMedications.length !== 0) {
            activeMediAllHISList = activeMediAllHISList.concat(
              res.activeMedications
            );
          }
          if (res.pastMedications.length !== 0) {
            pastMediAllHISList = pastMediAllHISList.concat(res.pastMedications);
          }
        }
      })
    );
    setActiveMedicationsList(
      activeMediAllHISList.sort(
        (objA: any, objB: any) =>
          Number(new Date(objB.creationDate)) -
          Number(new Date(objA.creationDate))
      )
    );
    setPastMedicationsList(
      pastMediAllHISList.sort(
        (objA: any, objB: any) =>
          Number(new Date(objB.creationDate)) -
          Number(new Date(objA.creationDate))
      )
    );
    setMyMedApiResult(resultArr);
    setActiveAllMedicationsList(activeMediAllHISList);
    setPastAllMedicationsList(pastMediAllHISList);
    if (tabListValue === "1") {
      getDoctorsListDropdown(activeMediAllHISList);
    } else if (tabListValue === "2") {
      getDoctorsListDropdown(pastMediAllHISList);
    }
    if (resultArr.length === 0) {
      console.log();
    } else {
      getEachDoctorsDetails(resultArr);
    }

    setIsLoading(false);
    authCtx.toggleLoader(false);
  };

  const handleFamilyChange = (e: any) => {
    setEachDocCardMedList([]);
    setActiveMedicationsList([]);
    setPastMedicationsList([]);
    setIsLoading(true);
    setShowMoreBtnClick(false);
    if (user.facilityId) setFacilityId(user.facilityId);
    if (e.target.value === user.firstName + " " + user.lastName) {
      setFamilyMemberId(0);
    } else {
      setFamilyMemberId(Number(e.target.value));
    }
  };

  const formatDate = (date: any) => {
    if (date !== undefined && date !== "") {
      var myDate = new Date(date);
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ][myDate.getMonth()];
      var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
      return str;
    }
    return "";
  };

  const getbarColor = () => {
    if (localStorage.getItem("brandName") === "burjeel") {
      return "#8b0548";
    } else if (localStorage.getItem("brandName") === "medeor") {
      return "#0D2240";
    } else if (localStorage.getItem("brandName") === "lifecare") {
      return "#11b6ba";
    } else if (localStorage.getItem("brandName") === "llh") {
      return "#0068b3";
    } else {
      return "#8b0548";
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: getbarColor(),
    },
  }));

  const getDoctorsListDropdown = (activeMedArr: any) => {
    const dummyArr = activeMedArr.map(
      (obj: { physicianNo: string; doctorName: string }) => ({
        ...obj,
        key: obj.physicianNo + "|" + obj.doctorName,
      })
    );
    const uniquedocsinActiveMed = Array.from(
      new Set(dummyArr.map((item: { key: any }) => item.key))
    );
    setDistinctDocList(uniquedocsinActiveMed);
  };

  const getMedicineProgressValue = (createdDate: any, duration: any) => {
    let values = createdDate.split("-");
    let creationDate = new Date(values[1] + "/" + values[2] + "/" + values[0]);
    let today = new Date();
    let compDate = new Date(creationDate);
    let completionDate = new Date(compDate.getTime() + duration * 86400000);
    let Difference_In_Time = today.getTime() - creationDate.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (creationDate < today && completionDate < today) {
      differenceInDays = duration;
      return 100;
    } else {
      differenceInDays = Math.round(Difference_In_Days);
      return Math.round((Difference_In_Days / duration) * 100);
    }
  };

  const handleDoctorChange = (e: any) => {
    setEachDocCardMedList([]);
    setDoctorChange(e.target.value);
    let docName = e.target.value;
    if (docName.toLowerCase() === "alldoctors") {
      if (myMedApiResult.activeMedications.length !== 0) {
        setActiveMedicationsList(
          myMedApiResult.activeMedications.sort(
            (objA: any, objB: any) =>
              Number(new Date(objB.creationDate)) -
              Number(new Date(objA.creationDate))
          )
        );
      }
      if (myMedApiResult.pastMedications.length !== 0) {
        setPastMedicationsList(
          myMedApiResult.pastMedications.sort(
            (objA: any, objB: any) =>
              Number(new Date(objB.creationDate)) -
              Number(new Date(objA.creationDate))
          )
        );
      }
    } else {
      const docNum = distinctDocList
        .find((ele: any) => ele.includes(docName))
        .split("|")[0];
      if (myMedApiResult.activeMedications.length !== 0) {
        const newActiveMedList = myMedApiResult.activeMedications.filter(
          (item: any) => item.physicianNo === docNum
        );
        setActiveMedicationsList(
          newActiveMedList.sort(
            (objA: any, objB: any) =>
              Number(new Date(objB.creationDate)) -
              Number(new Date(objA.creationDate))
          )
        );
      }
      if (myMedApiResult.pastMedications.length !== 0) {
        const newPastMedList = myMedApiResult.pastMedications.filter(
          (item: any) => item.physicianNo === docNum
        );
        setPastMedicationsList(
          newPastMedList.sort(
            (objA: any, objB: any) =>
              Number(new Date(objB.creationDate)) -
              Number(new Date(objA.creationDate))
          )
        );
      }
    }
  };

  const getEachDoctorsDetails = (resultArr: any) => {
    if (resultArr.activeMedications.length > 0) {
      let medicationsSetArray: any = [];
      medicationsSetArray = resultArr.activeMedications.map(
        (obj: { physicianNo: string; creationDate: string }) => ({
          ...obj,
          key: obj.physicianNo + "|" + obj.creationDate,
        })
      );

      const uniqueKeys = Array.from(
        new Set(medicationsSetArray.map((item: { key: any }) => item.key))
      );

      const arr: { key: any; medicineArr: any }[] = [];

      const doctorCardList = uniqueKeys.map((item: any) => {
        const filteredArray = medicationsSetArray.filter(
          (ele: any) => ele.key == item
        );
        arr.push({ key: item, medicineArr: filteredArray });
        const docObj = {
          key: item,
          docImage: filteredArray[0].doctorImage,
          docName: filteredArray[0].doctorName,
          creationDate: filteredArray[0].creationDate,
          maxDuration: Math.max(
            ...filteredArray.map((element: { duration: any }) => {
              return element.duration;
            })
          ),
        };
        return docObj;
      });
      setFilteredActiveMedDetailsList(arr);
      setActiveDoctorsCard(doctorCardList);
    }
    if (resultArr.pastMedications.length > 0) {
      let medicationsSetArray: any = [];
      medicationsSetArray = resultArr.pastMedications.map(
        (obj: { physicianNo: string; creationDate: string }) => ({
          ...obj,
          key: obj.physicianNo + "|" + obj.creationDate,
        })
      );
      const uniqueKeys = Array.from(
        new Set(medicationsSetArray.map((item: { key: any }) => item.key))
      );

      const arr: { key: any; medicineArr: any }[] = [];

      const doctorCardList = uniqueKeys.map((item: any) => {
        const filteredArray = medicationsSetArray.filter(
          (ele: any) => ele.key == item
        );
        arr.push({ key: item, medicineArr: filteredArray });
        const docObj = {
          key: item,
          docImage: filteredArray[0].doctorImage,
          docName: filteredArray[0].doctorName,
          creationDate: filteredArray[0].creationDate,
          maxDuration: Math.max(
            ...filteredArray.map((element: { duration: any }) => {
              return element.duration;
            })
          ),
        };
        return docObj;
      });
      setFilteredPastMedDetailsList(arr);
      setPastDoctorsCard(doctorCardList);
    }
  };

  const showMedicationDetails = (e: any, item: any, type: any) => {
    e.stopPropagation();
    if (type === "active") {
      const medicinesList = filteredActiveMedDetailsList.find(
        (obj: any) => obj.key === item.key
      );
      setEachDocCardMedList(medicinesList.medicineArr);
    } else if (type === "past") {
      const medicinesList = filteredPastMedDetailsList.find(
        (obj: any) => obj.key === item.key
      );
      setEachDocCardMedList(medicinesList.medicineArr);
    }
    handleOpenMedication();
  };

  const handleTabListChange = (event: any, newValue: any) => {
    setTabListValue(newValue);
    if (newValue === "1") {
      getDoctorsListDropdown(activeAllMedicationsList);
    } else if (newValue === "2") {
      getDoctorsListDropdown(pastAllMedicationsList);
    }
  };

  const handleOpenMedication = () => {
    setOpenMedicationDialog(true);
  };

  const handleCloseMedication = () => {
    setOpenMedicationDialog(false);
  };

  const handleShowMoreClick = () => {
    setShowMoreBtnClick(true);
    setFacilityId("");
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>

          <h3 className="fs-18-24">{"My Medication"}</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="ms-auto"></div>
        <div className="card-body p-1 mt-1">
          <div className="card border-0 shadow-sm rounded-1rem">
            <div className="card-body  py-2 px-0 position-relative">
              <TabContext value={tabListValue}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={tabListValue}
                    onChange={handleTabListChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab value="1" label="Active Medication" />
                    <Tab value="2" label="Past Medication" />
                  </Tabs>
                </Box>
                <div className="gx-3 light-bg py-3 row m-0 px-2">
                  {/* <div className="col-xl-4">
                    {tabListValue === "1" ? (
                      <p className="mt-xl-3 mb-2">
                        List of all active medication for selected patient
                      </p>
                    ) : (
                      <p className="mt-xl-3 mb-2">
                        List of all past medication for selected patient
                      </p>
                    )}
                  </div> */}
                  <div className="col-xl-3 col-md-6 ms-auto">
                    <div className="form-floating">
                      <select
                        className="form-select fs-14 no-focus w-100"
                        onChange={(e) => handleDoctorChange(e)}
                        id="selectDoctor"
                        disabled={distinctDocList.length === 0 ? true : false}
                      >
                        <option value="allDoctors">{"All Doctors"}</option>
                        {distinctDocList &&
                          distinctDocList.map((item: any, index: any) => (
                            <option key={index} value={item.vblog}>
                              {item.split("|")[1]}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="selectDoctor">Select Doctor</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mt-md-0 mt-3">
                    <div className="form-floating">
                      <select
                        className="form-select fs-14 no-focus w-100"
                        id="selectPatient"
                        onChange={(e) => handleFamilyChange(e)}
                      >
                        <option value={user.firstName + " " + user.lastName}>
                          {user.firstName} {user.lastName}
                        </option>
                        {familyMemberList &&
                          familyMemberList.map((item: any, index: any) => (
                            <option key={index} value={item.familyMemberId}>
                              {item.firstName} {item.lastName} ({item.relation})
                            </option>
                          ))}
                      </select>
                      <label htmlFor="selectPatient">Select Patient</label>
                    </div>
                  </div>
                  {/* <div className="col align-self-center mt-3 mt-md-0">
                    <button
                      onClick={() => handleShowMoreClick()}
                      className="btn btn-primary no-focus w-100"
                      disabled={showMoreBtnClick}
                    >
                      Show more
                    </button>
                  </div> */}
                </div>
                <TabPanel value="1">
                  {activeMedicationsList && activeMedicationsList.length > 0 ? (
                    <div className="container-fluid">
                      <div className="row align-items-center d-none d-lg-flex">
                        <div className="col-lg-4 p-3 fw-600">Doctor </div>
                        <div className="col-lg-3 p-3 fw-600">Date</div>
                        <div className="col-lg-2 p-3 fw-600">Duration</div>
                        <div className="col-lg-3 p-3 fw-600">Action</div>
                      </div>
                      {activeDoctorsCard &&
                        activeDoctorsCard.map((item: any, index: any) => (
                          <>
                            <div
                              className="row border-top align-items-center"
                              key={"activeDoc" + index}
                            >
                              <div className="col-lg-4 col-sm-6 col-12 p-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    {" "}
                                    <img
                                      src={
                                        item && item.docImage
                                          ? item.docImage
                                          : "/assets/images/doc.png"
                                      }
                                      alt="img"
                                      className="img-h-50 rounded-circle dr-photo"
                                    />{" "}
                                  </div>
                                  <div className="flex-grow-1 ms-3 mt-2 text-truncate">
                                    <label className="fw-bold text-truncate w-100">
                                      {item.docName ? item.docName : ""}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 col-7 px-3 py-lg-3">
                                {item.creationDate ? item.creationDate : ""}
                              </div>
                              <div className="col-lg-2 col-sm-6 col-5  px-3 py-lg-3 pb-sm-3">
                                {item.maxDuration
                                  ? item.maxDuration + " " + "Days"
                                  : ""}
                              </div>
                              <div className="col-lg-3 col-sm-6 col-12 px-3 py-lg-3 pb-3">
                                <button
                                  className="btn btn-link no-focus p-0 mt-2 mt-sm-0"
                                  onClick={(e) =>
                                    showMedicationDetails(e, item, "active")
                                  }
                                >
                                  View Medication
                                </button>
                              </div>
                            </div>
                          </>
                        ))}
                      {/* {!showMoreBtnClick && (
                        <div className="w-100 d-flex justify-content-center">
                          <button
                            onClick={() => handleShowMoreClick()}
                            className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                          >
                            <i className="material-icons-outlined me-2">
                              autorenew
                            </i>
                            Load More
                          </button>
                        </div>
                      )} */}
                    </div>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Medications
                    </p>
                  ) : (
                    <>
                      {" "}
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        No Active Medications
                      </p>
                      {/* {!showMoreBtnClick && (
                        <div className="w-100 d-flex justify-content-center">
                          <button
                            onClick={() => handleShowMoreClick()}
                            className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                          >
                            <i className="material-icons-outlined me-2">
                              autorenew
                            </i>
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {pastMedicationsList && pastMedicationsList.length > 0 ? (
                    <div className="container-fluid">
                      <div className="row align-items-center d-none d-lg-flex">
                        <div className="col-lg-4 p-3 fw-600">Doctor </div>
                        <div className="col-lg-3 p-3 fw-600">Date</div>
                        <div className="col-lg-2 p-3 fw-600">Duration</div>
                        <div className="col-lg-3 p-3 fw-600">Action</div>
                      </div>
                      {pastDoctorsCard &&
                        pastDoctorsCard.map((item: any, index: any) => (
                          <>
                            <div
                              className="row border-top align-items-center"
                              key={"pastDoc" + index}
                            >
                              <div className="col-lg-4 col-sm-6 col-12 p-3">
                                <div className="d-flex">
                                  <div className="flex-shrink-0">
                                    {" "}
                                    <img
                                      src={
                                        item && item.docImage
                                          ? item.docImage
                                          : "/assets/images/doc.png"
                                      }
                                      alt="img"
                                      className="img-h-50 rounded-circle dr-photo"
                                    />{" "}
                                  </div>
                                  <div className="flex-grow-1 ms-3 mt-2 text-truncate">
                                    <label className="fw-bold text-truncate w-100">
                                      {item.docName ? item.docName : ""}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-sm-6 col-7 px-3 py-lg-3">
                                {item.creationDate ? item.creationDate : ""}
                              </div>
                              <div className="col-lg-2 col-sm-6 col-5  px-3 py-lg-3 pb-sm-3">
                                {item.maxDuration
                                  ? item.maxDuration + " " + "Days"
                                  : ""}
                              </div>
                              <div className="col-lg-3 col-sm-6 col-12 px-3 py-lg-3 pb-3">
                                <button
                                  className="btn btn-link no-focus p-0 mt-2 mt-sm-0"
                                  onClick={(e) =>
                                    showMedicationDetails(e, item, "past")
                                  }
                                >
                                  View Medication
                                </button>
                              </div>
                            </div>
                          </>
                        ))}
                      {/* {!showMoreBtnClick && (
                        <div className="w-100 d-flex justify-content-center">
                          <button
                            onClick={() => handleShowMoreClick()}
                            className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                          >
                            <i className="material-icons-outlined me-2">
                              autorenew
                            </i>
                            Load More
                          </button>
                        </div>
                      )} */}
                    </div>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Medications
                    </p>
                  ) : (
                    <>
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        No Past Medications
                      </p>
                      {/* {!showMoreBtnClick && (
                        <div className="w-100 d-flex justify-content-center">
                          <button
                            onClick={() => handleShowMoreClick()}
                            className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                          >
                            <i className="material-icons-outlined me-2">
                              autorenew
                            </i>
                            Load More
                          </button>
                        </div>
                      )} */}
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openmedicationDialog}
        //open= {true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary py-4 border-bottom">
          {eachDocCardMedList.length > 0 && eachDocCardMedList[0].doctorName}

          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => setOpenMedicationDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="primary-scroll px-2">
          <div className="d-flex align-item-center mt-2">
            <span className="fw-bold fs-14 text-secondary ms-4">
              Medication List
            </span>
            <span className="fw-bold fs-14 text-secondary ms-auto">
              Patient: {userName}
            </span>
          </div>
          <div className="mt-4">
            <ul>
              {eachDocCardMedList.length > 0 &&
                eachDocCardMedList.map((item: any) => (
                  <li className="list-unstyled">
                    <div className="card border-0 rounded-1rem col-md-12">
                      <div className="d-flex align-items-center gx-1 mb-1 rounded-1rem mt-2">
                        <div className="flex-grow-1">
                          <span className="fw-bold fs-12">
                            {item.brandName} ({item.genericName})
                          </span>
                          <p className="text-muted text-secondary fs-12 m-0">
                            {item.frequency}
                          </p>
                        </div>
                      </div>
                      <Box sx={{ flexGrow: 1 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={getMedicineProgressValue(
                            item.creationDate,
                            item.duration
                          )}
                        />
                      </Box>
                      <div className=" d-flex ms-auto px-2 fs-12">
                        <span className="text-start position-absolute left-0 float-left">
                          {item.duration - differenceInDays} more days remaining
                        </span>
                        <span className="fw-bold float-right">
                          {differenceInDays} days
                        </span>
                        /
                        <span className="text-muted float-right">
                          {item.duration} days
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </DialogContent>
        <DialogActions className="py-4 me-2 border-top">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={() => handleCloseMedication()}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default MyMedication;
