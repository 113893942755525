import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";

import TextField from "@mui/material/TextField";

import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import QuestionnaireList from "../../constants/questionnaireList.json";
import { feedbackService } from "../../services/feedback.service";
import { Alert } from "@material-ui/lab";
import AuthContext from "../../store/auth-context";

function FeedbackQuestionnaire(props: any) {
  const authCtx = useContext(AuthContext);

  const [experienceq1, setExperienceq1] = useState<any>(""); //excellent

  const [experienceq5, setExperienceq5] = useState<any>(""); //yes
  const [experienceValue, setExperienceValue] = useState<any>(1);

  const [feedbackModal, setFeedBackModal] = useState<boolean>(false);
  const [validationModal, setValidationModal] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<any>("");

  const [rawQuestionnaire, setRawQuestionnaire] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [textValue, setTextValue] = useState<string>();
  const [radio5Value, setRadio5Value] = useState<any>();
  const [showNoQuestionnaire, setShowNoQuestionnaire] =
    useState<boolean>(false);
  const [showApiErrorMsg, setShowApiErrorMsg] = useState<boolean>(false);
  const brandName = localStorage.getItem("brandName");

  useEffect(() => {
    setRawQuestionnaire([]);
    setAnswers([]);
    const getQuestionnaire = () => {
      getFacilityBasedQuestionnaire();
    };
    getQuestionnaire();

    // eslint-disable-next-line
  }, [props.feedbackPatientType]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [props, answers]);

  const getFacilityBasedQuestionnaire = async () => {
    if (
      (brandName === "burjeel" ||
        brandName === "medeor" ||
        brandName === "lifecare" ||
        brandName === "llh") &&
      props.feedbackPatientType !== ""
    ) {
      const answersArr: any = [];
      authCtx.toggleLoader(true);
      const res = await feedbackService.getFeedbackQuestionnaire(
        props.facilityVblogValue.facilityVblogValue,
        props.feedbackPatientType
      );
      if (res.data && res.data.status && res.data.status === "success") {
        if (res.data.data.length > 0) {
          res.data.data.map((question: any, index: any) => {
            answersArr.push({
              Questionid: question.questionID,
              Answer: "",
            });
          });
          setRawQuestionnaire(res.data.data);
          setAnswers(answersArr);
        } else {
          setShowNoQuestionnaire(true);
        }
      } else {
        setShowNoQuestionnaire(true);
      }
      authCtx.toggleLoader(false);
    }
  };

  const handleSelectionChange = (e: any, quesNum: any) => {
    const dummyArr = [...answers];
    const matchingQues = dummyArr.findIndex(
      (obj: any) => obj.Questionid === quesNum
    );
    dummyArr[matchingQues].Answer =
      e.target.value !== "Select Option" ? e.target.value : "";
    setAnswers(dummyArr);
    // setTextValue(e.target.value);
  };

  const isSubmitButtondisabled = () => {
    const questionsLength = rawQuestionnaire.length;

    if (questionsLength) {
      const result = rawQuestionnaire.filter((item: any) => {
        if (item.is_mandatory === "Yes") {
          const ansObjet = answers.find(
            (ansItem: any) => ansItem.Questionid === item.questionID
          );
          if (ansObjet?.Answer) {
            return ansObjet.Answer?.trim().length !== 0;
          }

          return false;
        }

        return true;
      });

      return result.length !== questionsLength;
    }

    return true;
  };

  const handleRadioTwoOptions = (e: any, quesNum: any) => {
    const dummyArr = [...answers];
    const matchingQues = dummyArr.findIndex(
      (obj: any) => obj.Questionid === quesNum
    );
    dummyArr[matchingQues].Answer = e.target.value;
    setAnswers(dummyArr);

    if (e.target.value === "yes") {
      //setExperienceq5(e.target.value);
      setExperienceValue(1);
    } else if (e.target.value === "no") {
      //setExperienceq5(e.target.value);
      setExperienceValue(2);
    }
  };

  const handleTextboxChange = (e: any, quesNum: any) => {
    const dummyArr = [...answers];
    const matchingQues = dummyArr.findIndex(
      (obj: any) => obj.Questionid === quesNum
    );
    dummyArr[matchingQues].Answer = e.target.value;
    setAnswers(dummyArr);
    setTextValue(e.target.value);
  };

  const handleInputFieldChange = (e: any, quesNum: any) => {
    const dummyArr = [...answers];
    const matchingQues = dummyArr.findIndex(
      (obj: any) => obj.Questionid === quesNum
    );
    dummyArr[matchingQues].Answer = e.target.value;
    setAnswers(dummyArr);
    setTextValue(e.target.value);
  };

  const handleClose = () => {
    setShowApiErrorMsg(false);
  };

  const buildQuestionnaire = () => {
    return (
      <div>
        {rawQuestionnaire.length > 0 ? (
          rawQuestionnaire.map((question: any, index: any) => {
            const is_mandatory = question.is_mandatory === "Yes";
            if (QuestionnaireList.includes(question.questiontype)) {
              if (question.questiontype === "R") {
                // if(question.options.length === 2)
                // {
                return (
                  <div className="mt-2">
                    <p className="mb-0">
                      {index + 1}. {question.question}{" "}
                      {is_mandatory ? (
                        <span className="fs-16" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <div className="col-sm-12 col-md-12 questionnaire">
                      <RadioGroup
                        id={index + 1}
                        name={index + 1}
                        value={
                          answers && answers.length !== 0
                            ? answers.find(
                                (ans: any) =>
                                  ans.Questionid === question.questionID
                              ).Answer
                            : ""
                        }
                        onChange={(e: any) =>
                          handleRadioTwoOptions(e, question.questionID)
                        }
                      >
                        {question.options.length > 0
                          ? question.options.map((option: any) =>
                              option !== "" ? (
                                <FormControlLabel
                                  id={option + "_" + index + 1}
                                  value={option}
                                  control={<Radio />}
                                  label={option}
                                  labelPlacement="bottom"
                                />
                              ) : null
                            )
                          : null}
                      </RadioGroup>
                    </div>
                  </div>
                );
              } else if (question.questiontype === "T") {
                return (
                  <div className="mt-3">
                    <p>
                      {index + 1}. {question.question}{" "}
                      {is_mandatory ? (
                        <span className="fs-16" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Write your text here"
                        multiline
                        maxRows={8}
                        value={
                          answers && answers.length !== 0
                            ? answers.find(
                                (ans: any) =>
                                  ans.Questionid === question.questionID
                              ).Answer
                            : ""
                        }
                        onChange={(e: any) =>
                          handleTextboxChange(e, question.questionID)
                        }
                        style={{ width: 400 }}
                      />
                    </Box>
                  </div>
                );
              } else if (question.questiontype === "I") {
                return (
                  <div className="mt-3">
                    <p className="mb-0">
                      {index + 1}. {question.question}{" "}
                      {is_mandatory ? (
                        <span className="fs-16" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Input Text"
                        maxRows={8}
                        value={
                          answers && answers.length !== 0
                            ? answers.find(
                                (ans: any) =>
                                  ans.Questionid === question.questionID
                              ).Answer
                            : ""
                        }
                        onChange={(e: any) =>
                          handleInputFieldChange(e, question.questionID)
                        }
                        style={{ width: 400 }}
                      />
                    </Box>
                  </div>
                );
              } else if (question.questiontype === "D") {
                return (
                  <div className="mt-3">
                    <p className="mb-0">
                      {index + 1}. {question.question}{" "}
                      {is_mandatory ? (
                        <span className="fs-16" style={{ color: "red" }}>
                          *
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <select
                      className="mt-2 mb-3 w-80 md-col-10 form-select fs-12 no-focus rounded-0.5rem"
                      id="selectPatient"
                      onChange={(e) =>
                        handleSelectionChange(e, question.questionID)
                      }
                    >
                      <option value={"Select Option"}>{"Select Option"}</option>
                      {question.options.length > 0
                        ? question.options.map((option: any) => (
                            <option value={option}>{option}</option>
                          ))
                        : null}
                    </select>
                  </div>
                );
              }
            } else {
              return null;
            }
          })
        ) : (brandName === "medeor" ||
            brandName === "burjeel" ||
            brandName === "lifecare" ||
            brandName === "llh") &&
          props.feedbackPatientType === "" ? (
          props.feedbackPatientTypeList.length > 0 ? (
            <p className="fw-10 text-secondary text-center">
              Please select the patient type
            </p>
          ) : (
            props.noPatientTypesFound && (
              <p className="fw-10 text-secondary text-center">
                No Patient types found!
              </p>
            )
          )
        ) : (
          showNoQuestionnaire && (
            <p className="fw-10 text-secondary text-center">
              Sorry! No Questions from the selected Facility
            </p>
          )
        )}
      </div>
    );
  };

  const handleBack = async () => {
    props.handleBackButton();
  };

  const handleSubmit = async () => {
    authCtx.toggleLoader(true);

    const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    const feedbackResponseReq = {
      userName: userInfo.firstName + " " + userInfo.lastName,
      userMobile: userInfo.mobile,
      userEmail: userInfo.email,
      vblog: props.facilityVblogValue.facilityVblogValue,
      FPlatform: "Portal",
      UserResponse: answers,
      patientType: props.feedbackPatientType,
    };

    let res = await feedbackService.saveFeedbackAnswers(feedbackResponseReq);
    if (res && res.data && res.data.status === "success") {
      props.fbSubmissionStatus(res); //true
      setFeedBackModal(true);
      setValidationModal(true);
      setValidationText("Thank You for providing your feedback");
      authCtx.toggleLoader(false);
    } else {
      setShowApiErrorMsg(true);
      setFeedBackModal(false);
      setValidationModal(true);
      setValidationText("Something went wrong");
      authCtx.toggleLoader(false);
    }
  };

  return (
    <>
      <div className="animate p-1 col-md-12">
        <div className="col-md-12 stroke-main-div card border-0 shadow-sm rounded-0.5rem mt-2 pt-3 ps-4">
          {rawQuestionnaire && buildQuestionnaire()}
          {rawQuestionnaire.length > 0 ? (
            <div className="text-center pb-2">
              <button
                className="btn btn-primary mt-2 me-2"
                value=""
                onClick={() => {
                  handleBack();
                }}
              >
                Back
              </button>
              <button
                className="btn btn-primary mt-2"
                value=""
                disabled={isSubmitButtondisabled()}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Submit
              </button>
            </div>
          ) : (
            <div className="text-center pb-2">
              <button
                className="btn btn-primary mt-2 me-2"
                value=""
                onClick={() => {
                  handleBack();
                }}
              >
                Back
              </button>
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={showApiErrorMsg}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {"Something went wrong! Try again"}
        </Alert>
      </Snackbar>
    </>
  );
}

export default FeedbackQuestionnaire;
