import { useState, useEffect, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link ,useHistory} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import UserDetails from "./UserDetails";
import { userService } from "../services/UserService";
import AuthContext from "../store/auth-context";
import { DialogActions,Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfiniteScroll from "react-infinite-scroll-component";
import {Switch, makeStyles }from "@material-ui/core";

const useStyles = makeStyles({
  switch: {
    "& .Mui-checked": {
      color: "#225DB3"
      // transform: "translateX(25px) !important"
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#225DB3"
    }
  }
});

function ManageUser() {
  const authCtx = useContext(AuthContext);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [EditData, setEditData] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const history = useHistory();
  const [UserList, setUserList] = useState<any>([]);
  const [skip1, setSkip] = useState<any>(0);
  const [take1, setTake] = useState<any>(15);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<any>("");
  const [resetPassAskModel, setResetPassAskModel] = useState<boolean>(false);
  const [resetPassUserData,setResetPassUserData] = useState<any>({});
  const [changePasswordModal, setChnagePasswordModal] = useState<boolean>(false);
  const [callFetchUserList,setCallFetchUserList] = useState<boolean>(false);
  const classes = useStyles();

  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (userData.isDefaultPassword === true) {
      setChnagePasswordModal(true);
    } 
    if(userData.roleId !== 3)
    history.push("/home/login");
    const getUserList = async () => {
      setSkip(0);
      refreshData();
    };
    getUserList();
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    const callUserList = async () => {
    const res = await fetchUserList(0, take1, searchTerm);
    
    if(res && res.data ){        
      setUserList(res.data);
    }else{        
      setUserList([]);
    }
  }

    callUserList();
  },[callFetchUserList])


  const setpage = async () => {
    const nextUserList = await fetchUserList(skip1, take1, searchTerm);
    if( nextUserList && nextUserList.data ){
    setUserList([...UserList, ...nextUserList.data]);
    
    if (nextUserList.data.length === 0) {
      setHasMore(false);
    }else{
      setHasMore(true);
    }
  }else{
    setUserList([]);
  }
  };

  const refreshData = async (skip: number = skip1, take: number = take1, searchKey:string = "") => {
    authCtx.toggleLoader(true);
    const listFromApi = await fetchUserList(skip, take, searchKey);
    authCtx.toggleLoader(false);
    if (
      listFromApi &&
      listFromApi.data &&      
     ( listFromApi.data.length > 0 ||  listFromApi.data.length < take1)
    ) {      
      setUserList(listFromApi.data);
    }else{
      setUserList([]);
    }
  };

  const fetchUserList = async (skip: number, take: number, searchKey:string = "") => {
  let res = await userService.getUserList(skip, take, searchKey);
    setSkip(skip1 + take1);
    return res;
  };

  const handleAddClose = async () => {
    setShowAddModal(false);
  };

  const handleAddShow = () => {
    setShowAddModal(true);
  };

  const handleEditClose = async () => {
    setShowEditModal(false);
  };

  const handleEditShow = (data: any) => {
    setEditData(data);
    setShowEditModal(true);
  };


  const hnadleChnagePassowrdClose = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      setChnagePasswordModal(false);
    }
  };

  const handleresetClose = () => setResetPassAskModel(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChecked = async (item: any, isActive: any) => {
    authCtx.toggleLoader(false);
    let data = {
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName,
      userName: item.userName,
      isActive: isActive,
      roleId:  item.roleId,
      CallingCode:item.callingCode,
      mobile:item.mobile

    };
    const res = await userService.addUser(data);
    authCtx.toggleLoader(false);
    if (res && res.status === "Success") {
      refreshData(0,skip1);
    } else {
      handleShow();
      setModalText("Error while updating record");
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }
  };

  const resetUserPassword = async() => {
    authCtx.toggleLoader(true);
    let res = await userService.resetPassword(resetPassUserData.id);
    if(res && res.status === "Success"){
      setModalType("success");
      setShowModal(true);
      setModalText(res.message);
      authCtx.toggleLoader(false);
    }else if(res.status === "NotFound"){
      setModalType("error");
      setShowModal(true);
      setModalText("User are inactive");
      authCtx.toggleLoader(false);
    }
    else {
      setModalType("error");
      setShowModal(true);
      setModalText("Error while resetting password");
      authCtx.toggleLoader(false);
    }
  }

  const handleSearchChange = async (e:any) => {
    setSearchTerm(e.target.value);
    // let filterkey = e.target.value;

    if(e.target.value.length >= 3){
      setHasMore(true);
      setSkip(0);
      setCallFetchUserList(!callFetchUserList);
    }else if(e.target.value === ""){
      setHasMore(true);
      setSkip(0);
      setCallFetchUserList(!callFetchUserList);

    }

  }
    const openMobileMenu = (e: any) => {
      e.preventDefault();

      document.getElementById("leftMenu")?.classList.remove("d-none");
    }; 

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-sm-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>

          <h3 className="fs-18-24">Admin Users</h3>

          {userData.roleId === 3 && (
            <div className="ms-auto">
              {" "}
              <button
                className="btn btn-outline-primary ms-sm-auto"
                onClick={handleAddShow}
              >
                Add Admin
              </button>
            </div>
          )}
        </div>
        {userData.roleId === 3 && (
          <>
            <div className="row">
              <div className="col-lg-12 position-relative">
                <div className="card  border-0 shadow-sm rounded-10">
                  <div className="d-sm-flex">
                    <div className="p-2 flex-fill">
                      <div className="input-group ">
                        <span className="input-group-text bg-transparent border-0 p-2">
                          <i className="material-icons-outlined text-999">
                            search
                          </i>
                        </span>
                        <input
                          type="search"
                          className="form-control border-0 fs-14 no-focus"
                          value={searchTerm}
                          placeholder="Search user"
                          onChange={(e) => handleSearchChange(e)}
                        />
                      </div>
                    </div>
                    <div className="p-2">
                      <button
                        type="submit"
                        className="btn btn-primary d-block d-sm-flex"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-0 shadow-sm rounded-3 mt-3">
              <div className="card-body p-4">
                <InfiniteScroll
                  dataLength={UserList.length}
                  next={setpage}
                  hasMore={hasMore}
                  loader={false}
                >
                  <div className="table-responsive-lg">
                    <table className="table align-middle">
                      <thead>
                        <tr>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col" className="d-none d-sm-table-cell">
                            Mobile
                          </th>
                          <th scope="col" className="d-none d-sm-table-cell">
                            Email
                          </th>
                          {/* <th scope="col">Mobile</th> */}
                          {userData.roleId === 3 && <th scope="col">Active</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {UserList &&
                          UserList.length > 0 &&
                          UserList.map((item: any) => (
                            <tr key={item.id}>
                              <td>{item.firstName}</td>
                              <td>{item.lastName}</td>
                              <td className="d-none d-sm-table-cell">
                                {item.mobile}
                              </td>
                              <td className="d-none d-sm-table-cell">
                                {item.email}
                              </td>
                              {/* <td>+({item.callingCode})-{item.mobile}</td> */}

                              <td>
                                <Switch
                                  id={item.id}
                                  value={item.isActive}
                                  checked={item.isActive === true}
                                  onChange={() =>
                                    handleChecked(item, !item.isActive)
                                  }
                                  color="primary"
                                  className={classes.switch}
                                />
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  className="me-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleEditShow(item);
                                  }}
                                >
                                  <i className="material-icons-outlined">
                                    edit
                                  </i>
                                </Link>
                              </td>
                              {/* <td className="w-150">
                            <Link to="#" className="btn btn-link p-0 no-focus"
                                onClick={(e)=>{e.preventDefault();setResetPassUserData(item);setResetPassAskModel(true);}}
                                >
                              Reset password
                            </Link>
                          </td> */}
                            </tr>
                          ))}

                        {!UserList && (
                          <tr>
                            <td>
                              <div className="text-center text-999">
                                {" "}
                                <i className="material-icons-outlined fs-1">
                                  {" "}
                                  info{" "}
                                </i>
                                <p className="fs-6">No record found</p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </>
        )}
      </div>
      <Dialog
        open={showAddModal}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Add Admin
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleAddClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <UserDetails id={"0"} handleAddClose={handleAddClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={showEditModal}
        onClose={handleEditClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Edit User
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleEditClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <UserDetails {...EditData} handleEditClose={handleEditClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={resetPassAskModel} onClose={handleresetClose}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to reset password for{" "}
            <strong>
              {resetPassUserData.firstName} {resetPassUserData.lastName}
            </strong>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" onClick={handleresetClose}>
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              resetUserPassword();
              handleresetClose();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={changePasswordModal}
        onClose={hnadleChnagePassowrdClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change Password
          <small className="d-block fs-12">Please change your password</small>
        </DialogTitle>
        <DialogContent className="pt-0">
          {/* <ChnagePassword
            setModalType={setModalType}
            setModalText={setModalText}
            setShowModal={setShowModal}
            hnadleChnagePassowrdClose={hnadleChnagePassowrdClose}
          /> */}
        </DialogContent>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ManageUser;
