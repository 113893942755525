import React from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";

function ComplaintSubmission(props: any){
    const history = useHistory();
    const brandName = localStorage.getItem("brandName");

    const getBackFBHomeFromComplaints = () =>{
        if(brandName === "burjeel"){
        props.getBackToFBHomeFromCompSub(true);}
        history.push("/auth/dashboard");
    }

    return (
      <>
        <div>
          <div className="col-md-5 mx-auto mb-5">
            {/* <div className="col-12 stroke-main-div">              */}
            <div className="card border-0 shadow-sm rounded-1rem mt-2 bg-white">
              <div className="card-body p-4 text-center">
                <div>
                  <img
                    src="../assets/images/feedbackcheck.png"
                    className="checkedIcon col-md-11"
                    alt="check Icon"
                    style={{ height: 32, width: 32 }}
                  />
                </div>
                <div className="col-sm-12 col-md-12 mb-3 fs-14 fw-b">
                  <span className="vbottom feedbackSubmissionBold_fontSize align-bottom">
                    Dear User, We apologize for the inconvenience caused.
                  </span>
                </div>
                <div className="vbottom mt-auto mb-0 fs-12">
                  <span className="align-bottom">
                    We have directed this complaint to the concerned team for
                    appropriate action
                  </span>
                  <p>Complaint No: {props.complaintNum}</p>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-5 mx-auto text-center">
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={getBackFBHomeFromComplaints}
            >
              Go to Home
            </button>
          </div>
        </div>
      </>
    );
}

export default ComplaintSubmission;