import { debug } from "console";
import React, { useState, useEffect } from "react";

const AuthContext = React.createContext({
  isLoader: false,
  isLoggedIn: false,
  userMobile: "",
  apiToken: "",
  cCode: "",
  userInfo: "",
  docType: 0,
  documentId: "",
  selectedLanguage: "",
  wpBaseUrl: "",
  isDobAvailable: false,
  consultedDoctorList: [],
  locationGlobalList: [],
  facilityIdGlobal: "",
  userProfileDetails: {},
  onLogout: () => {},
  onLogin: (mobile: string) => {},
  setMobile: (cCode: string, mobile: string) => {},
  removeUserMobile: () => {},
  setToken: (token: string) => {},
  removeApiToken: () => {},
  toggleLoader: (flag: boolean) => {},
  setUserInfo: (userInfo: any) => {},
  removeUserInfo: () => {},
  setSelectedLanguage: (language: string) => {},
  setDocumetsDetails: (docType: any, documentId: any) => {},
  setWpBaseUrl: (wpBaseUrl: string) => {},
  setIsDobAvalable: (isDobAvalilabe: boolean) => {},
  setConsultedDoctorList: (consultedDoctor: any) => {},
  setLocationGlobalList: (locationList: any) => {},
  setFacilityIdGlobal: (facilityId: any) => {},
  setUserProfileDetails: (userProfile: any) => {},
});

export const AuthContextProvider = (props: any) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userMobile, setUserMobile] = useState("");
  const [cCode, setCCode] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [docType, setDocType] = useState<any>(0);
  const [documentId, setDocumentId] = useState<any>("");
  const [wpBaseUrl, setWpBaseUrl] = useState<string>("");
  const [isDobAvailable, setIsDobAvalable] = useState<boolean>(false);
  const [consultedDoctorList, setConsultedDoctorList] = useState<any>([]);
  const [locationGlobalList, setLocationGlobalList] = useState<any>([]);
  const [facilityIdGlobal, setFacilityIdGlobal] = useState<string>("");
  const [userProfileDetails, setUserProfileDetails] = useState<any>({});

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");

    if (storedUserLoggedInInformation === "1") {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleLoader = (flag: boolean) => {
    setIsLoader(flag);
  };

  const handleUserProfileDetails = (userProfile: any) => {
    setUserProfileDetails(userProfile);
  };

  const logoutHandler = () => {
    localStorage.removeItem("isLoggedIn");
    setIsLoggedIn(false);
  };

  const loginHandler = () => {
    localStorage.setItem("isLoggedIn", "1");
    setIsLoggedIn(true);
  };
  const removeApiTokenHandler = () => {
    setApiToken("");
    localStorage.removeItem("token");
  };

  const setApiTokenHandler = (token: string) => {
    setApiToken(token);
    localStorage.setItem("token", token);
  };

  const removeUserMobileHandler = () => {
    setUserMobile("");
    setCCode("");
  };

  const setUserMobileHandler = (cCode: any, mobile: string) => {
    setUserMobile(mobile);
    setCCode(cCode);
    localStorage.setItem(
      "userMobile",
      JSON.stringify({ callingCode: cCode, mobileNumber: mobile })
    );
  };

  const handleDocumentDetails = (docType: any, documentId: any) => {
    setDocType(docType);
    setDocumentId(documentId);
  };

  const setUserInfoHandler = (userInfo: any) => {
    localStorage.setItem("user", JSON.stringify(userInfo));
    setUserInfo(userInfo);
  };
  const removeUserInfoHandler = () => {
    localStorage.removeItem("user");
    setUserInfo("");
  };

  const languageHandler = (language: string) => {
    setSelectedLanguage(language);
  };

  const handleWpBaseUrl = (wpBaseUrl: string) => {
    setWpBaseUrl(wpBaseUrl);
  };

  const handleDobCheck = (isDOBAvailable: boolean) => {
    setIsDobAvalable(isDOBAvailable);
  };

  const handleConsultedDoctor = (consultedDoctors: any) => {
    setConsultedDoctorList(consultedDoctors);
  };

  const handleLocationList = (locationList: any) => {
    setLocationGlobalList(locationList);
  };

  const handleFacilityId = (facilityId: any) => {
    setFacilityIdGlobal(facilityId);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        isLoader: isLoader,
        cCode: cCode,
        userMobile: userMobile,
        docType: docType,
        documentId: documentId,
        apiToken: apiToken,
        userInfo: userInfo,
        selectedLanguage: selectedLanguage,
        wpBaseUrl: wpBaseUrl,
        isDobAvailable: isDobAvailable,
        consultedDoctorList: consultedDoctorList,
        locationGlobalList: locationGlobalList,
        facilityIdGlobal: facilityIdGlobal,
        userProfileDetails: userProfileDetails,
        onLogout: logoutHandler,
        onLogin: loginHandler,
        setToken: setApiTokenHandler,
        removeApiToken: removeApiTokenHandler,
        setMobile: setUserMobileHandler,
        removeUserMobile: removeUserMobileHandler,
        toggleLoader: toggleLoader,
        setUserInfo: setUserInfoHandler,
        setSelectedLanguage: languageHandler,
        removeUserInfo: removeUserInfoHandler,
        setDocumetsDetails: handleDocumentDetails,
        setWpBaseUrl: handleWpBaseUrl,
        setIsDobAvalable: handleDobCheck,
        setConsultedDoctorList: handleConsultedDoctor,
        setLocationGlobalList: handleLocationList,
        setFacilityIdGlobal: handleFacilityId,
        setUserProfileDetails: handleUserProfileDetails,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
