import { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import AuthContext from "../store/auth-context";
import { useTranslation } from "react-i18next";
import { dashboardservice } from "../services/dashboard.service";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContactUs from "./ContactUs";
import TermAndCon from "./login/TermAndCon";
import PrivacyPolicy from "./login/PrivacyPolicy";
import clearWebStorage from "./clearWebStorage";

const SideNav = () => {
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(true);
  const { t } = useTranslation(["sidenav"]);
  const [contactUsModel, setContactUsModel] = useState<boolean>(false);
  const [brand, setBrand] = useState<any>("");
  const [logo, setLogo] = useState<string>("");
  const brandName = localStorage.getItem("brandName");
  const [tncModel, setTncModel] = useState<boolean>(false);
  const [privacyModel, setPrivacyModel] = useState<boolean>(false);

  const uaePassLogout: any = process.env.REACT_APP_UAEPASS_LOGOUT;
  // const uaePassLogout = "https://stg-id.uaepass.ae/idshub/logout?redirect_uri=http://localhost:3000/home/login";

  useEffect(() => {
    if (brandName === "burjeel") {
      setLogo("/assets/images/burjeel.png");
      setBrand("Burjeel");
    } else if (brandName === "medeor") {
      setLogo("/assets/images/medeor-logo.png");
      setBrand("Medeor");
    } else if (brandName === "lifecare") {
      setLogo("/assets/images/lifecare.png");
      setBrand("Lifecare");
    } else if (brandName === "llh") {
      setLogo("/assets/images/llh-logo.png");
      setBrand("LLH");
    } else {
      setLogo("/assets/images/burjeel.png");
      setBrand("VPS Health");
    }
    if (userData.roleId === 3 || userData.roleId === 2) {
      setAdmin(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (brandName === "burjeel" && userData.facilityId === "BM10") {
      let theme: any = document.getElementById("theme");
      theme.href = "/css/theme-bmc.css";
      document.title = "BMC | Patient Portal";
      setLogo("/assets/images/bmc-eng.png");
    } else if (brandName === "burjeel" && userData.facilityId !== "BM10") {
      let theme: any = document.getElementById("theme");
      theme.href = "/css/theme-burjeel.css";
      document.title = "Burjeel | Patient Portal";
      setLogo("/assets/images/burjeel.png");
    }
  }, [authCtx.isDobAvailable]);

  useEffect(() => {
    return history.listen((location) => {
      document.getElementById("leftMenu")?.classList.add("d-none");
    });
  }, []);

  let logout = async (e: any) => {
    e.preventDefault();
    authCtx.toggleLoader(true);
    await dashboardservice.logout();
    authCtx.toggleLoader(false);
    authCtx.setMobile("", "");
    authCtx.removeUserInfo();
    authCtx.removeApiToken();
    if (localStorage.getItem("uaeUser") === "1") {
      localStorage.setItem("uaeUser", "0");
      window.location.replace(uaePassLogout);
    } else {
      history.push("/home/login");
    }
    clearWebStorage();
    if (brandName === "burjeel" && userData.facilityId === "BM10") {
      let theme: any = document.getElementById("theme");
      theme.href = "/css/theme-burjeel.css";
      document.title = "Burjeel | Patient Portal";
    }
  };

  const toggleMenu = (e: any) => {
    e.preventDefault();
    let prevState = showMenu;
    if (prevState) {
      document.body.classList.add("collapse-menu");
    } else {
      document.body.classList.remove("collapse-menu");
    }
    setShowMenu(!showMenu);
  };

  const closeMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.add("d-none");
  };

  const handleContactUsClose = () => setContactUsModel(false);
  const handleTncClose = () => setTncModel(false);
  const handlePrivacyClose = () => setPrivacyModel(false);

  return (
    <>
      <div
        id="leftMenu"
        className="left-menu position-fixed animate px-4 pt-4 pb-2 bg-white shadow vh-100 overflow-auto d-none d-sm-block"
      >
        <div
          onClick={(e) => closeMobileMenu(e)}
          className="bg-dark bg-opacity-75 position-fixed h-100 top-0 end-0 close-menu-bg d-sm-none"
        ></div>

        <Link
          to="/"
          id="hide-menu"
          onClick={toggleMenu}
          className="text-dark enable-xl-cursor d-none d-sm-inline-block"
        >
          <i className="material-icons-outlined"> menu </i>
        </Link>
        <img
          src={logo}
          className={`logo animate ${
            brandName === "burjeel" && userData.facilityId === "BM10"
              ? "h-xl-50p"
              : "h-xl-80p"
          } ${!showMenu ? "small" : ""}`}
          alt="logo"
        />

        <button
          className="d-inline-block d-sm-none position-absolute btn btn-link text-decoration-none p-3 end-0 top-0"
          onClick={(e) => closeMobileMenu(e)}
        >
          <i className="material-icons-outlined">close</i>
        </button>

        <ul className="menu p-0 mt-4">
          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("auth/dashboard") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/dashboard"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> dashboard </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("dashboard")}{" "}
              </span>
            </Link>
          </li>
          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("auth/finddoctor") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to={{ pathname: "/auth/finddoctor/", state: { docSearch: "" } }}
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> person_search </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("findDoc")}{" "}
              </span>
            </Link>
          </li>
          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("auth/appointments") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/appointments"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> today </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("appointment")}{" "}
              </span>
            </Link>
          </li>
          {brandName === "burjeel" && (
            <li
              onClick={(e) => closeMobileMenu(e)}
              className={`d-block m-0 position-relative ${
                location.pathname
                  .toLowerCase()
                  .indexOf("auth/teleconsultationappointments") > -1
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/auth/teleconsultationAppointments"
                className="d-flex align-items-center text-decoration-none"
              >
                <i className="material-icons-outlined"> devices </i>
                <span
                  className={`ms-3 d-sm-none ${
                    showMenu ? "d-xl-inline-block" : ""
                  } mh`}
                >
                  {" "}
                  {t("teleAppointment")}{" "}
                </span>
              </Link>
            </li>
          )}

          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname
                .toLowerCase()
                .indexOf("/auth/diagnosticreports") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/diagnosticReports"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> summarize </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("labReports")}{" "}
              </span>
            </Link>
          </li>
          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("/auth/mymedication") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/myMedication"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> local_pharmacy </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("myMedication")}{" "}
              </span>
            </Link>
          </li>
          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("auth/managefamily") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/manageFamily"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> people_alt </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("familyMembers")}{" "}
              </span>
            </Link>
          </li>
          <li
            onClick={(e) => closeMobileMenu(e)}
            className="d-block m-0 position-relative"
          >
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setContactUsModel(true);
              }}
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> contact_phone </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("contactUs")}{" "}
              </span>
            </Link>
          </li>

          <li
            onClick={(e) => closeMobileMenu(e)}
            className={`d-block m-0 position-relative ${
              location.pathname.toLowerCase().indexOf("/auth/feedback") > -1
                ? "active"
                : ""
            }`}
          >
            <Link
              to="/auth/feedback"
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> rate_review </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                Feedback / Complaint{" "}
              </span>
            </Link>
          </li>

          {userData.roleId === 3 && (
            <>
              {/* <li
               onClick={(e) => closeMobileMenu(e)}
              className={`d-block m-0 position-relative ${
                location.pathname.toLowerCase().indexOf("/auth/user") > -1
                  ? "active"
                  : ""
              }`}
            >
              <Link
                to="/auth/user"
                className="d-flex align-items-center text-decoration-none"
              >
                <i className="material-icons-outlined"> people </i>
                <span
                  className={`ms-3 d-sm-none ${
                    showMenu ? "d-xl-inline-block" : ""
                  } mh`}
                >
                  {" "}
                  User Management{" "}
                </span>
              </Link>
            </li> */}

              <li
                onClick={(e) => closeMobileMenu(e)}
                className={`d-block m-0 position-relative ${
                  location.pathname
                    .toLowerCase()
                    .indexOf("/auth/notification/broadcastnotification") > -1
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to="/auth/notification/BroadcastNotification"
                  className="d-flex align-items-center text-decoration-none"
                >
                  <i className="material-icons-outlined"> campaign </i>
                  <span
                    className={`ms-3 d-sm-none ${
                      showMenu ? "d-xl-inline-block" : ""
                    } mh`}
                  >
                    {" "}
                    Broadcast Notification{" "}
                  </span>
                </Link>
              </li>
            </>
          )}
          {userData.roleId === 2 && (
            <>
              <li
                onClick={(e) => closeMobileMenu(e)}
                className={`d-block m-0 position-relative ${
                  location.pathname
                    .toLowerCase()
                    .indexOf("/auth/notification/BroadcastNotification") > -1
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to="/auth/notification/BroadcastNotification"
                  className="d-flex align-items-center text-decoration-none"
                >
                  <i className="material-icons-outlined"> campaign </i>
                  <span
                    className={`ms-3 d-sm-none ${
                      showMenu ? "d-xl-inline-block" : ""
                    } mh`}
                  >
                    {" "}
                    Broadcast Notification{" "}
                  </span>
                </Link>
              </li>
            </>
          )}

          <li
            onClick={(e) => closeMobileMenu(e)}
            className="d-block m-0 position-relative"
          >
            <Link
              to="/"
              onClick={logout}
              className="d-flex align-items-center text-decoration-none"
            >
              <i className="material-icons-outlined"> power_settings_new </i>
              <span
                className={`ms-3 d-sm-none ${
                  showMenu ? "d-xl-inline-block" : ""
                } mh`}
              >
                {" "}
                {t("logout")}{" "}
              </span>
            </Link>
          </li>
        </ul>
        <div className="bottom-copyright bottom-15">
          {/* <Language /> */}
          <div className="mb-2 d-none d-xl-block mh">
            <Link
              to="#"
              className="text-muted fs-10 me-3 pe-3 border-end"
              onClick={(e) => {
                e.preventDefault();
                setTncModel(true);
              }}
            >
              Terms &amp; Conditions
            </Link>
            <Link
              to="#"
              className="text-muted fs-10"
              onClick={(e) => {
                e.preventDefault();
                setPrivacyModel(true);
              }}
            >
              Privacy Policy
            </Link>
          </div>
          <small className="text-muted d-none d-xl-block mh">
            {" "}
            © All Rights Reserved | {brand}
          </small>
          <small className="d-none d-xl-block mh text-muted opacity-50">
            V 3.0.2
          </small>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={contactUsModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Contact Us
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => handleContactUsClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ContactUs handleContactUsClose={handleContactUsClose} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={tncModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleTncClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Terms And Condition
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleTncClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <TermAndCon />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={privacyModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handlePrivacyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Privacy Policy
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handlePrivacyClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SideNav;
