import { Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import { dashboardservice } from "../../services/dashboard.service";
import Parser from "html-react-parser";

function Media() {
  const [mediaList, setMediaList] = useState<any>([]);
  const [modelType, setModelType] = useState<any>("");
  const [customMediaList, setCustomMediaList] = useState<any>([]);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modelText, setModelText] = useState<any>("");
  const [isOpenVideo, setIsOpenVideo] = useState<any>(false);
  const [videoUrl, setVideoUrl] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    const ac = new AbortController();
    const getAllMedia = () => {
      fetchAllMediaList();
    };
    getAllMedia();

    return () => ac.abort();
  }, []);

  const fetchAllMediaList = async () => {
    let res = await dashboardservice.getAllMedia();
    if (res && res.data && res.data.status === "success") {
      setMediaList(res.data.data);
      res.data.data && res.data.data.length > 5
        ? setCustomMediaList(res.data.data.slice(0, 5))
        : setCustomMediaList(res.data.data);
      setModelType("success");
      setShowModel(false);
      setModelText(res.data.message);
    } else {
      setMediaList(res.data.data);
      setModelType("error");
      setShowModel(true);
      setModelText(res.data.message);
    }
  };

  const handleMediaOpen = (item: any) => {
    setVideoUrl(item.videolink);
    setIsOpenVideo(true);
  };

  const handleMediaClose = () => {
    setIsOpenVideo(false);
  };

  const handleClose = () => setShowModel(false);

  return (
    <>
      <div className="card border-0 shadow-sm rounded-1rem">
        <div className="card-body p-4">
          <h6 className="fw-bold text-primary">Media</h6>

          {customMediaList &&
            customMediaList.map((item: any, index: any) => (
              <Link
                onClick={() => handleMediaOpen(item)}
                className="text-black cursor-pointer"
                key={index}
              >
                {" "}
                <div className="card border-0 rounded-1rem p-2">
                  <div className="card-body px-0">
                    <div className="d-flex">
                      <div className="flex-shrink">
                        <img
                          src={item.imagepath}
                          alt="img"
                          className="img-95 rounded"
                        />
                      </div>
                      <div className="flex-grow ms-3">
                        <label className="fw-600 cursor-pointer text-truncate-two-line">
                          {Parser(item.title)}
                        </label>
                        <p className="text-muted fs-12 m-0">
                          {item.publishDate}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          {customMediaList &&
            mediaList &&
            mediaList.length > customMediaList.length && (
              <button
                className="btn btn-link no-focus float-end"
                onClick={() => history.push({ pathname: "/auth/allMedia" })}
              >
                View more
              </button>
            )}
        </div>
      </div>

      <Modal
        open={isOpenVideo}
        onClose={handleMediaClose}
        styles={{
          modal: {
            maxWidth: "unset",
            width: "50vw",
            height: "50vh",
            padding: "unset",
            overflow: "unset",
          },

          closeButton: {
            background: "white",
          },
        }}
        center
      >
        <ReactPlayer url={videoUrl} width="50vw" height="50vh" />
      </Modal>

      {modelType === "error" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modelText}
          </Alert>
        </Snackbar>
      )}
      {modelType === "success" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modelText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Media;
