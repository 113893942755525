import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import ConditionsCard from "../dashboard/ConditionsCard";
import ConsultedDoctorsCard from "../dashboard/ConsultedDoctorsCard";
import { finddoctorservice } from "../../services/findDoctor.services";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import DoctorProfile from "./DoctorProfile";
import { bookAppointmentService } from "../../services/bookAppointment.service";
import Autocomplete from "@mui/material/Autocomplete";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
import { dashboardservice } from "../../services/dashboard.service";
import { patientService } from "../../services/patient.service";

const FindDoctor = () => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["findDoctor"]);
  const [doctorSearched, setDoctorSearched] = useState(false);
  const [doctorSearchString, setDoctorSearchString] = useState<string>("");
  const [doctorSearchList, setDoctorSearchList] = useState<any>([]);
  const [doctorResultList, setDoctorResultList] = useState<any>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [docSearchError, setDocSerachError] = useState<any>(false);
  const [docSerachMessage, setDocSearchErroeMessage] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");
  const [locationList, setLocationList] = useState<any>([]);
  const [location, setLocation] = useState<any>("");
  const [speciality, setSpeciality] = useState<any>("");
  const [specialityList, setSpecialityList] = useState<any>([]);
  const [doctorProfileDialog, setDoctorProfileDialog] =
    useState<boolean>(false);
  const [doctorProfile, setDoctorProfile] = useState<any>();
  const [deptList, setDeptList] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [enterKeyPressed, setEnterKeyPressed] = useState<boolean>(false);
  const [insuranceErrorDialog, setInsuranceErrorDialog] =
    useState<boolean>(false);

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  let loc: any = useLocation();

  useEffect(() => {
    if (loc.state === undefined) {
      console.log("State is undefined");
    } else if (loc.state.search === "doc") {
      setDoctorSearched(true);
      fetchDoctorData(loc.state.item);
    } else if (loc.state.search === "condition") {
      setDoctorSearched(true);
      setSpeciality(loc.state.item.id.toString());
      let item = { vblog: location, id: loc.state.item.id.toString() };
      fetchDoctorByLocation(item);
    } else if (loc.state.search === "location") {
      let item = { vblog: loc.state.item.vblog, id: "" };
      setDoctorSearched(true);
      setLocation(loc.state.item.vblog);
      fetchAllSpecialities(loc.state.item.vblog);
      fetchDoctorByLocation(item);
    }
    fetchAllLocations();
    // eslint-disable-next-line
  }, [loc]);

  const fetchAllLocations = async () => {
    let res = await finddoctorservice.getLocations();
    if (res && res.data && res.data.status && res.data.status === "success") {
      if (user.facilityId) {
        let filtervalue = res.data.data.find(
          (item: any) => item.locationId === user.facilityId
        );
        setLocation(filtervalue.vblog);
      }
      setLocationList(res.data.data);
      setIsError(false);
      setErrorMessage(res.data.message);
    } else {
      setLocationList(res.data.data);
      setIsError(true);
      setErrorMessage(res.data.message);
    }
  };

  const fetchAllSpecialities = async (vblog: any) => {
    let res = await finddoctorservice.getallspecialtiesByLocation(vblog);
    if (res && res.data && res.data.status && res.data.status === "success") {
      setSpecialityList(res.data.data);
      setIsError(false);
      setErrorMessage(res.data.message);
    } else {
      setSpecialityList(res.data.data);
      setIsError(true);
      setErrorMessage(res.data.message);
    }
  };

  const handleLocationChange = (e: any) => {
    setLocation(e.target.value);
    setSpeciality("");
    if (e.target.value === "") {
      fetchAllSpecialities(e.target.value);
      let item = { vblog: "", id: "" };
      fetchDoctorByLocation(item);
    } else {
      fetchAllSpecialities(e.target.value);
      let item = { vblog: e.target.value, id: "" };
      fetchDoctorByLocation(item);
    }
  };

  const handleSpecialityChange = (e: any) => {
    setSpeciality(e.target.value);
    if (e.target.value === "") {
      let item = { vblog: location, id: "" };
      fetchDoctorByLocation(item);
    } else {
      let item = { vblog: location, id: e.target.value };
      fetchDoctorByLocation(item);
    }
  };

  const handleInputChange = (e: any) => {
    e.preventDefault();
    setDoctorSearchString(e.target.value);
    handleDoctorSearch(e.target.value);
  };

  const handleDoctorSearch = async (docSearch: any) => {
    setDoctorSearched(true);
    setDoctorSearchList([]);
    if (docSearch.length > 2) {
      let res = await finddoctorservice.searchDoctor(docSearch);

      if (res && res.data && res.data.status && res.data.status === "success") {
        const doctorSearchList = sortDoctorsList(res.data.data);
        setDoctorSearchList(doctorSearchList);
        setIsError(false);
        setErrorMessage(res.data.message);
      } else {
        setDoctorSearchList(res.data.data);
        setDoctorResultList([]);
        setIsError(true);
        setErrorMessage(res.data.message);
      }
    }
  };

  const sortDoctorsList = (doctorslist: any) => {
    doctorslist.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase()
        ? 1
        : b.name.toLowerCase() > a.name.toLowerCase()
        ? -1
        : 0
    );
    return doctorslist;
  };

  const fetchDoctorData = async (item: any) => {
    authCtx.toggleLoader(true);
    setLocation("");
    setSearchValue(item.name);
    let res = await finddoctorservice.getAllDoctors(item);

    if (res && res.data && res.data.status && res.data.status === "success") {
      const doctorData = sortDoctorsList(res.data.data);
      setDoctorResultList(doctorData);
      setIsError(false);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setDoctorResultList(res.data);
      setIsError(true);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

  const fetchDoctorByCondition = async (item: any) => {
    authCtx.toggleLoader(true);
    setSearchValue(item.name);
    let res = await finddoctorservice.getDoctorByCondition(item);

    if (res && res.data && res.data.status && res.data.status === "success") {
      const doctorData = sortDoctorsList(res.data.data);
      setDoctorResultList(doctorData);
      setIsError(false);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setDoctorResultList(res.data);
      setIsError(true);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

  const fetchDoctorByLocation = async (item: any) => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getDoctorByLocation(item);
    const doctorData = sortDoctorsList(res.data.data);
    if (res && res.data && res.data.status && res.data.status === "success") {
      setDoctorResultList(doctorData);
      setSearchValue(doctorData[0].location);
      setIsError(false);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setDoctorResultList(res.data.data);
      setIsError(true);
      setErrorMessage(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

  const bookAppointment = (doctorDetails: any) => {
    history.push({
      pathname: "/auth/bookappointment",
      state: doctorDetails,
    });
  };

  const search = () => {
    if (doctorSearchString === "") {
      setDocSerachError(true);
      setDocSearchErroeMessage("Search first");
    } else {
      history.push({
        pathname: "/auth/searchDoctorResult/",
        state: { doctorSearchString },
      });
    }
  };

  const handleDoctorProfile = (item: any) => {
    setDoctorProfile(item);
    setDoctorProfileDialog(true);
  };

  const hanldeDoctorProfileDialogClose = () => {
    setDoctorProfileDialog(false);
  };

  const handleEnterPressed = async (e: any) => {
    if (e.key === "Enter" && doctorSearchString.length > 1) {
      //alert('Enter pressed')
      setEnterKeyPressed(true);
      setDoctorSearchString("");
      //console.log("doc value before====",doctorSearchList[0]);
      if (doctorSearchList.length > 0) {
        const docDet = await fetchDoctorData(doctorSearchList[0]);
        if (docDet !== undefined) {
          setDoctorResultList(docDet);
          //console.log("doc value====",docDet);
        }
      }
    }
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  const checkInsuranceValidation = async (doctorValue: any) => {
    if (doctorValue && doctorValue.department_code && doctorValue.locationId) {
      authCtx.toggleLoader(true);
      const facility_insurance_validation =
        doctorValue.facility_insurance_validation
          ? doctorValue.facility_insurance_validation.toLowerCase()
          : "";

      const specialty_insurance_validation =
        doctorValue.specialty_insurance_validation
          ? doctorValue.specialty_insurance_validation.toLowerCase()
          : "";

      const doctor_insurance_validation =
        doctorValue.doctor_insurance_validation
          ? doctorValue.doctor_insurance_validation.toLowerCase()
          : "";

      if (
        facility_insurance_validation === "yes" ||
        specialty_insurance_validation === "yes" ||
        doctor_insurance_validation === "yes"
      ) {
        await getPatientRecord(doctorValue);
      } else {
        bookAppointment(doctorValue);
      }
    } else {
      setIsError(true);
      setErrorMessage(
        "There is an issue with this record, please contact 80055."
      );
    }
  };

  const getPatientRecord = async (doctorValue: any) => {
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const location = locationList.find((item: any) => {
      return doctorValue.locationId === item.locationId;
    });

    if (Object.keys(patientRecord).length !== 0) {
      const data = {
        his: patientRecord[0].his,
        patientId: patientRecord[0].patientId[0],
        institution: doctorValue.locationId,
      };
      getPatientDetails(data, doctorValue, location.id);
    } else {
      let familMembersId = 0;
      let res = await patientService.getPatientRec(
        user.userId,
        familMembersId,
        doctorValue.locationId
      );
      if (res && res.data && res.data !== undefined) {
        let patientIdData = res.data.find((item: any) => {
          return item.his === location.his_name;
        });
        if (patientIdData.patientId) {
          const data = {
            his: patientIdData.his,
            patientId: patientIdData.patientId,
            institution: doctorValue.locationId,
          };
          localStorage.setItem(
            "getPatientRecord",
            JSON.stringify([patientIdData])
          );
          localStorage.setItem("isPatientRecordCall", "true");
          getPatientDetails(data, doctorValue, location.id);
        } else {
          bookAppointment(doctorValue);
        }
      } else {
        setIsError(true);
        setErrorMessage("Error in get patient records");
        authCtx.toggleLoader(false);
      }
    }
  };

  const getPatientDetails = async (data: any, doctorValue: any, id: any) => {
    const res = await dashboardservice.getPatientDetails(data);
    if (res && res.data) {
      const patientDetails: any = res.data[0];
      if (
        patientDetails &&
        patientDetails.insuranceName &&
        patientDetails.planName
      ) {
        handleInsuranceValidation(patientDetails, doctorValue, id);
      } else {
        bookAppointment(doctorValue);
      }
    } else {
      setIsError(true);
      setErrorMessage("Patient details not found");
      authCtx.toggleLoader(false);
    }
  };

  const handleInsuranceValidation = async (
    patientDetails: any,
    doctorValue: any,
    id: any
  ) => {
    const res = await dashboardservice.getRestrictedInsurance(id);
    if (res && res.data && res.data.data && res.status === 200) {
      const restrictedInsuranceList = res.data.data;
      const patientInsuranceName = patientDetails.insuranceName
        ? patientDetails.insuranceName
        : "";
      const patientInsurancePlanName = patientDetails.planName
        ? patientDetails.planName
        : "";
      let insuranceMatching = restrictedInsuranceList.find((item: any) => {
        return (
          item.name.trim().toLowerCase() ===
            patientInsuranceName.trim().toLowerCase() &&
          item.insurance_plan.trim().toLowerCase() ===
            patientInsurancePlanName.trim().toLowerCase()
        );
      });
      if (insuranceMatching) {
        setInsuranceErrorDialog(true);
        authCtx.toggleLoader(false);
      } else {
        bookAppointment(doctorValue);
      }
    } else {
      bookAppointment(doctorValue);
    }
  };

  const handleInsuranceDialogClose = () => setInsuranceErrorDialog(false);

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24"> {t("findDocTitle")}</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 position-relative">
            <div className="card border-0 shadow-sm rounded-1rem">
              <div className="card-body d-block d-sm-flex">
                <TextField
                  id="searchString"
                  type="search"
                  autoComplete="off"
                  placeholder={t("searchPlaceholder")}
                  className="form-control me-sm-3 fs-14"
                  variant="outlined"
                  size="small"
                  value={doctorSearchString}
                  onChange={(e: any) => handleInputChange(e)}
                  onKeyPress={(e: any) => handleEnterPressed(e)}
                />
                <button
                  id="searchBtn"
                  className="btn btn-primary mt-2 mt-sm-0 float-end"
                  onClick={() => search()}
                >
                  {t("search")}
                </button>
              </div>
              <ul
                className={`card border-0 shadow-sm rounded-10 text-start p-0 m-0 w-100 position-absolute overflow-auto mxh-160 z-1 top-70 ${
                  doctorSearchString.length < 2 ? "d-none" : " "
                }`}
              >
                {doctorSearchList &&
                  doctorSearchList.map((item: any, index: any) => (
                    <li
                      className={
                        "d-block " + (index === 0 ? "list-active-bg" : null)
                      }
                      key={item.id}
                    >
                      <Link
                        to={{
                          pathname: "/auth/finddoctor/",
                          state: { item: item, search: "doc" },
                        }}
                        defaultChecked
                        onClick={() => setDoctorSearchString("")}
                        className="p-3 d-block text-dark text-decoration-none"
                      >
                        {item.name}
                        <small className="float-end text-999">
                          {item.cat_type}
                        </small>{" "}
                        <small className="text-999">{item.location}</small>
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            {!doctorSearched && (
              <ConditionsCard
                topMargin={true}
                setSpecialityList={setSpecialityList}
              />
            )}
          </div>
          {!doctorSearched && (
            <div className="col-md-4" id="recentDoc">
              <ConsultedDoctorsCard topMargin={true} />
            </div>
          )}
          {doctorSearched && (
            <div className="col-12 z-0">
              <div className="card border-0 shadow-sm rounded-1rem mt-4">
                <div className="card-body">
                  <div className="gx-3 row m-0 px-2">
                    <div className="col-xl-4 mb-3">
                      <h6 className="fw-normal mb-0">
                        {t("searchResultTitle")}{" "}
                        <span className="fw-bold">{searchValue}</span>
                      </h6>
                    </div>
                    <div className="col-xl-3 col-md-6 ms-auto">
                      <select
                        className="form-select fs-12 me-3 no-focus"
                        aria-label="Default select example"
                        value={location}
                        onChange={(e) => handleLocationChange(e)}
                      >
                        {/* <option value="">{t("location")}</option> */}
                        {locationList &&
                          locationList.map((item: any, index: any) => (
                            <option key={index} value={item.vblog}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-xl-3 col-md-6 mt-md-0 mt-3">
                      {" "}
                      <select
                        className="form-select fs-12 no-focus"
                        aria-label="Default select example"
                        value={speciality}
                        onChange={(e) => handleSpecialityChange(e)}
                        disabled={location === ""}
                      >
                        <option selected value="">
                          {" "}
                          {t("department")}
                        </option>
                        {specialityList &&
                          specialityList.map((item: any, index: any) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-3 mt-1">
                {doctorResultList.length > 0
                  ? doctorResultList.map((item: any, index: any) => (
                      <div className="col-sm-6 col-md-4 col-xl-3" key={index}>
                        <div className="card border-0 shadow-sm rounded-1rem text-center h-100 doc-profile-container">
                          {/* <i className="material-icons-outlined btn btn-link text-decoration-none doc-profile-icon fs-1" onClick={()=> setDoctorProfileDialog(true)}>
                          info
                          </i> */}

                          <div className="card-body pt-4 h-100">
                            <img
                              src={
                                item.image
                                  ? item.image
                                  : "/assets/images/doc.png"
                              }
                              className="dr-photo rounded-circle"
                              alt=""
                            />
                            <div className="mt-2 w-100">
                              <h6 className="fw-bold mt-3 text-truncate">
                                {" "}
                                {item.name}
                              </h6>
                              <p className="fw-bold text-muted mb-1 text-truncate">
                                {" "}
                                {item.specialty}
                              </p>
                              {/* <p className="text-muted mb-1">
                              {t("experience")} - {item.experience}
                            </p> */}
                              <p className="text-muted mb-0 text-truncate">
                                {" "}
                                {item.location}
                              </p>
                            </div>
                            <button
                              className="btn btn-link no-focus"
                              onClick={() => handleDoctorProfile(item)}
                            >
                              View Profile
                            </button>
                            {item.appointment_message === null ||
                            item.appointment_message.trim() === "" ? (
                              <button
                                onClick={() => checkInsuranceValidation(item)}
                                className="btn btn-primary w-100 mt-3 position-relative bottom-0"
                              >
                                {t("book")}
                              </button>
                            ) : (
                              <a
                                className="btn btn-primary w-100 mt-3 position-relative bottom-0"
                                href={`tel: ${item.appointment_message}`}
                              >
                                <img
                                  className="pb-1"
                                  src={"/assets/images/phone_white.png"}
                                />
                                {item.appointment_message}
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
                {!doctorSearchList && (
                  <div className="card border-0 shadow-sm rounded-1rem text-center">
                    <div className="card-body pt-4">
                      <div className="text-center">
                        <h5>No Doctors to display</h5>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog open={insuranceErrorDialog}>
        <DialogTitle className="text-primary">Error</DialogTitle>
        <DialogContent>
          <h6 className="fw-600 py-2">
            Please contact Experience Center 80055 for appointment booking.
          </h6>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary mx-2 my-1 no-focus"
            onClick={handleInsuranceDialogClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={doctorProfileDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          Doctor's Profile
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => hanldeDoctorProfileDialogClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DoctorProfile doctorProfileDetails={doctorProfile} />
        </DialogContent>
      </Dialog>

      <Snackbar
        open={docSearchError}
        autoHideDuration={3000}
        onClose={() => setDocSerachError(false)}
      >
        <Alert onClose={() => setDocSerachError(false)} severity="error">
          {docSerachMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isError}
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      >
        <Alert onClose={() => setIsError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FindDoctor;
