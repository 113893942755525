import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { bookAppointmentService } from "../../services/bookAppointment.service";
import { dashboardservice } from "../../services/dashboard.service";
import AuthContext from "../../store/auth-context";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { patientService } from "../../services/patient.service";

const ConsultedDoctorsCard = (props: any) => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [docLoder, setDocLoder] = useState<any>(false);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [insuranceErrorDialog, setInsuranceErrorDialog] =
    useState<boolean>(false);
  const locationList: any = authCtx.locationGlobalList;

  useEffect(() => {}, [authCtx.isDobAvailable, authCtx.consultedDoctorList]);

  const reBookAppointment = async (item: any) => {
    history.push({ pathname: "/auth/bookappointment/", state: item });
  };

  const checkInsuranceValidation = async (doctorValue: any) => {
    if (doctorValue && doctorValue.department_code && doctorValue.locationId) {
      authCtx.toggleLoader(true);
      const facility_insurance_validation =
        doctorValue.facility_insurance_validation
          ? doctorValue.facility_insurance_validation.toLowerCase()
          : "";

      const specialty_insurance_validation =
        doctorValue.specialty_insurance_validation
          ? doctorValue.specialty_insurance_validation.toLowerCase()
          : "";

      const doctor_insurance_validation =
        doctorValue.doctor_insurance_validation
          ? doctorValue.doctor_insurance_validation.toLowerCase()
          : "";

      if (
        facility_insurance_validation === "yes" ||
        specialty_insurance_validation === "yes" ||
        doctor_insurance_validation === "yes"
      ) {
        await getPatientRecord(doctorValue);
      } else {
        reBookAppointment(doctorValue);
      }
    } else {
      setIsError(true);
      setErrorMessage(
        "There is an issue with this record, please contact 80055."
      );
    }
  };

  const getPatientRecord = async (doctorValue: any) => {
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const location = locationList.find((item: any) => {
      return doctorValue.locationId === item.locationId;
    });

    if (Object.keys(patientRecord).length !== 0) {
      const data = {
        his: patientRecord[0].his,
        patientId: patientRecord[0].patientId[0],
        institution: doctorValue.locationId,
      };
      getPatientDetails(data, doctorValue, location.id);
    } else {
      let familMembersId = 0;
      let res = await patientService.getPatientRec(
        user.userId,
        familMembersId,
        doctorValue.locationId
      );
      if (res && res.data && res.data !== undefined) {
        let patientIdData = res.data.find((item: any) => {
          return item.his === location.his_name;
        });
        const data = {
          his: patientIdData.his,
          patientId: patientIdData.patientId,
          institution: doctorValue.locationId,
        };
        getPatientDetails(data, doctorValue, location.id);
      } else {
        setIsError(true);
        setErrorMessage("Error in get patient records");
        authCtx.toggleLoader(false);
      }
    }
  };

  const getPatientDetails = async (data: any, doctorValue: any, id: any) => {
    const res = await dashboardservice.getPatientDetails(data);
    if (res && res.data) {
      const patientDetails: any = res.data[0];
      if (
        patientDetails &&
        patientDetails.insuranceName &&
        patientDetails.planName
      ) {
        handleInsuranceValidation(patientDetails, doctorValue, id);
      } else {
        reBookAppointment(doctorValue);
      }
    } else {
      setIsError(true);
      setErrorMessage("Patient details not found");
      authCtx.toggleLoader(false);
    }
  };

  const handleInsuranceValidation = async (
    patientDetails: any,
    doctorValue: any,
    id: any
  ) => {
    const res = await dashboardservice.getRestrictedInsurance(id);
    if (res && res.data && res.data.data && res.status === 200) {
      const restrictedInsuranceList = res.data.data;
      const patientInsuranceName = patientDetails.insuranceName
        ? patientDetails.insuranceName
        : "";
      const patientInsurancePlanName = patientDetails.planName
        ? patientDetails.planName
        : "";
      let insuranceMatching = restrictedInsuranceList.find((item: any) => {
        return (
          item.name.trim().toLowerCase() ===
            patientInsuranceName.trim().toLowerCase() &&
          item.insurance_plan.trim().toLowerCase() ===
            patientInsurancePlanName.trim().toLowerCase()
        );
      });
      if (insuranceMatching) {
        setInsuranceErrorDialog(true);
        authCtx.toggleLoader(false);
      } else {
        reBookAppointment(doctorValue);
      }
    } else {
      reBookAppointment(doctorValue);
    }
  };

  const handleInsuranceDialogClose = () => setInsuranceErrorDialog(false);

  return (
    <>
      <div
        className={
          "card border-0 shadow-sm rounded-1rem " +
          (props.topMargin ? "mt-4 mt-md-0" : "mb-4")
        }
      >
        <div className="card-body p-0 ">
          <h6 className="fw-bold text-primary p-4 mb-0">
            Recently Consulted Doctors
          </h6>
          <div className="consultedoverflow primary-scroll p-3 pt-0">
            {authCtx.consultedDoctorList &&
              authCtx.consultedDoctorList.map((item: any, index: any) => (
                <React.Fragment key={index}>
                  {" "}
                  <div
                    className={
                      index === 0
                        ? "d-flex align-items-center"
                        : "d-flex mt-4 align-items-center"
                    }
                  >
                    <div className="flex-shrink-0">
                      <img
                        src={item.image}
                        className="img-h-60 rounded-circle dr-photo"
                        alt="dr-img"
                      />{" "}
                    </div>
                    <div className="flex-grow-1 ms-2">
                      <label className="fw-bold">{item.name}</label>
                      {item.scheduleType === "TELEMED" ? (
                        <p className="text-muted fs-12 m-0">
                          General Practitioner
                        </p>
                      ) : (
                        <>
                          <p className="text-muted fs-12 m-0">
                            {item.specialty}
                          </p>
                          <p className="text-muted fs-12 m-0">
                            {item.location}
                          </p>
                        </>
                      )}
                    </div>
                    {item.appointment_message === null ||
                    item.appointment_message.trim() === "" ? (
                      item.scheduleType === "TELEMED" ? (
                        <Link
                          to={{
                            pathname: "/auth/bookTeleAppointment/",
                            state: item,
                          }}
                          className="btn btn-primary btn-sm w-77 no-focus"
                        >
                          Re-book
                        </Link>
                      ) : (
                        <button
                          onClick={() => checkInsuranceValidation(item)}
                          className="btn btn-primary btn-sm w-77 no-focus"
                        >
                          Re-book
                        </button>
                      )
                    ) : (
                      <a
                        className="btn btn-primary btn-sm w-77 fw-8"
                        href={`tel: ${item.appointment_message}`}
                      >
                        <img
                          className="pb-1"
                          src={"/assets/images/phone_white.png"}
                        />
                        {item.appointment_message}
                      </a>
                    )}
                  </div>
                </React.Fragment>
              ))}
          </div>
          {authCtx.consultedDoctorList &&
            authCtx.consultedDoctorList.length === 0 && (
              <div className="mh-240p d-flex justify-content-center align-items-center">
                <div className="text-center text-999 mb-4">
                  {docLoder ? (
                    <i className="material-icons-outlined fs-1">
                      {" "}
                      <CircularProgress color="inherit" />
                    </i>
                  ) : (
                    <>
                      <i className="material-icons-outlined fs-1"> info </i>
                      <p className="fs-6">No consulted Doctors</p>
                    </>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>

      <Dialog open={insuranceErrorDialog}>
        <DialogTitle className="text-primary">Error</DialogTitle>
        <DialogContent>
          <h6 className="fw-600 py-2">
            Please contact Experience Center 80055 for appointment booking.
          </h6>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary mx-2 my-1 no-focus"
            onClick={handleInsuranceDialogClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={isError}
        autoHideDuration={3000}
        onClose={() => setIsError(false)}
      >
        <Alert onClose={() => setIsError(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ConsultedDoctorsCard;
