import SideNav from "./SideNav";
import { Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import ManageFamily from "./ManageFamily";
import FindDoctor from "./doctor/FindDoctor";
import Appointment from "./appointment/Appointment";
import HealthTool from "./health_tools/HealthTool";
import InternationalPatient from "./international_patient/InternationalPatient";
import LabReports from "./labReports/LabReports";
import BookAppointment from "./appointment/BookAppointment";
import ReschedulAppt from "./appointment/ReschedulAppt";
import SearchDocResult from "./doctor/SearchDocResult";
import Feedback from "./feedback/Feedback";
import HealthPackages from "../components/healthpackages/HealthPackages";
import HealthPackageDetails from "../components/healthpackages/HealthPackageDetails";
import NeedHelp from "./NeedHelp";
import Locations from "./Locations";
import BookApptFromPast from "./appointment/BookApptFromPast";
import Insurance from "./Insurance";
import News from "./news&media/News";
import AllMedia from "./news&media/AllMedia";
import AllNews from "./news&media/AllNews";
import MyMedication from "./MyMedication/MyMedication";
import Complaints from "./feedback/Complaints";
import ManageUser from "./ManageUser";
import BroadcastNotification from "./notification/BroadcastNotification";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AuthContext from "../store/auth-context";
import { Alert, Autocomplete } from "@material-ui/lab";
import { userService } from "../services/UserService";
import TeleDoctor from "./teleconsultation/TeleDoctor";
import BookTeleAppointments from "./teleconsultation/BookTeleAppointments";
import { finddoctorservice } from "../services/findDoctor.services";
import { dashboardservice } from "../services/dashboard.service";
import RescheduleTeleAppointments from "./teleconsultation/RescheduleTeleAppointments";
import BookTeleApptFromPast from "./teleconsultation/BookTeleApptFromPast";
import Teleconsultation from "./teleconsultation/Teleconsultation";
import TeleAppointment from "./appointment/TeleAppointment";
import { appservice } from "../services/app.service";
import ComingSoon from "./teleconsultation/ComingSoon";

const AuthLayout = () => {
  const authCtx = useContext(AuthContext);
  const [completeProfileDialog, setCompleteProfileDialog] =
    useState<boolean>(false);
  const [chooseLocatinDialog, setChooseLocationDialog] =
    useState<boolean>(false);
  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [patientDOB, setPatientDOB] = useState<any>();
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");
  const [facilityId, setFacilityId] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const { t } = useTranslation(["profile"]);
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const brandName = localStorage.getItem("brandName");

  useEffect(() => {
    const getHospitalLocation = () => {
      getAllHospitalLocations();
    };

    getHospitalLocation();

    const getUserProfile = () => {
      fetchUserProfileDetails();
    };

    getUserProfile();

    const getAppConfigValue = () => {
      fetchAppConfigurationValue();
    };
    getAppConfigValue();

    // eslint-disable-next-line
  }, []);

  const checkCompleteProfile = () => {
    if (userData) {
      if (userData.isDOBAvailable && userData.facilityId) {
        setCompleteProfileDialog(false);
        setChooseLocationDialog(false);
        setFacilityId(userData.facilityId);
      } else if (userData.isDOBAvailable && !userData.facilityId) {
        setChooseLocationDialog(true);
      } else if (!userData.isDOBAvailable && userData.facilityId) {
        setCompleteProfileDialog(true);
      } else if (!userData.isDOBAvailable && !userData.facilityId) {
        setCompleteProfileDialog(true);
      }
    }
  };

  const fetchUserProfileDetails = async () => {
    let profileInfo = await dashboardservice.getProfile();
    if (
      profileInfo &&
      profileInfo.message &&
      profileInfo.message.status === "Success"
    ) {
      authCtx.setUserProfileDetails(profileInfo.data);
    }
  };

  const fetchAppConfigurationValue = async () => {
    const res = await appservice.getAppCofigurations();
    if (res) {
      const appConfigurartion = res;
      localStorage.setItem(
        "appConfiguration",
        JSON.stringify(appConfigurartion)
      );
    }
  };

  const getAllHospitalLocations = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getLocations();
    if (res.data && res.data.status === "success") {
      authCtx.setLocationGlobalList(res.data.data);
      if (userData.isDOBAvailable && userData.facilityId) {
        const filterdValue = res.data.data.find((item: any) => {
          return item.locationId === userData.facilityId;
        });
        if (!filterdValue) {
          const exsitingUserData = userData;
          exsitingUserData["facilityId"] = null;
          localStorage.setItem("user", JSON.stringify(exsitingUserData));
          checkCompleteProfile();
        } else {
          const exsitingUserData = userData;
          exsitingUserData["vblog"] = filterdValue.vblog;
          localStorage.setItem("user", JSON.stringify(exsitingUserData));
        }
        authCtx.setIsDobAvalable(!authCtx.isDobAvailable);
      } else {
        checkCompleteProfile();
      }
    } else {
      setModalText("Error in getting hospital locations");
      setModalType("error");
      setShowModal(true);
    }
    authCtx.toggleLoader(false);
  };

  const handleDOBChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
      setPatientDOB(date.toISOString().slice(0, 10));
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("dobFormatError"));
    }
  };

  function validateForm() {
    let isValid = true;
    if (selectedDOB === null || patientDOB === "" || selectedDOBError) {
      setSelectedDOBError(true);
      setSelectedDOBErrorText("Enter valid date of birth");
      isValid = false;
    } else if (facilityId === null || facilityId === "") {
      setModalText("Please select the hospital");
      setModalType("error");
      setShowModal(true);
      isValid = false;
    }
    return isValid;
  }

  const handleProfileSubmit = () => {
    if (validateForm()) {
      updateUserDateOfBirth();
    }
  };

  const handleSaveLocation = () => {
    if (facilityId !== "") {
      updateUserFacilityId();
    } else {
      setModalText("Please select the hospital");
      setModalType("error");
      setShowModal(true);
    }
  };

  const updateUserDateOfBirth = async () => {
    authCtx.toggleLoader(true);
    let res = await userService.saveUserDOB(patientDOB);
    if (res.message.status === "Success") {
      const exsitingUserData = userData;
      exsitingUserData["isDOBAvailable"] = true;
      localStorage.setItem("user", JSON.stringify(exsitingUserData));
      updateUserFacilityId();
    } else {
      setModalType("error");
      setModalText("Error in update date of birth");
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }

    authCtx.toggleLoader(false);
  };

  const updateUserFacilityId = async () => {
    authCtx.toggleLoader(true);
    let res = await userService.saveUserFacilityId(facilityId);
    if (res.message.status === "Success") {
      setModalType("success");
      setModalText("Profile updated successfully");
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
      setCompleteProfileDialog(false);
      setChooseLocationDialog(false);
      const filterValue: any = authCtx.locationGlobalList.find(
        (item: any) => item.locationId === facilityId
      );
      const exsitingUserData = userData;
      exsitingUserData["facilityId"] = facilityId;
      exsitingUserData["vblog"] = filterValue.vblog;
      localStorage.setItem("user", JSON.stringify(exsitingUserData));
      authCtx.setIsDobAvalable(!authCtx.isDobAvailable);
    } else {
      setModalType("error");
      setModalText("Error in update facility id");
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }

    authCtx.toggleLoader(false);
  };

  const handleGlobalLocationChange = (e: any) => {
    setFacilityId(e.target.value);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  return (
    <>
      <div className="d-flex">
        <SideNav />
        <Switch>
          <Route path="/auth" exact>
            <Redirect to="/auth/dashboard" />
          </Route>
          <Route path="/auth/dashboard">
            <Dashboard />
          </Route>
          <Route path="/auth/profile">
            <Profile />
          </Route>
          <Route path="/auth/manageFamily">
            <ManageFamily />
          </Route>
          <Route path="/auth/finddoctor">
            <FindDoctor />
          </Route>
          <Route path="/auth/appointments">
            <Appointment />
          </Route>
          <Route path="/auth/bookAppointment">
            <BookAppointment />
          </Route>
          <Route path="/auth/bookPastAppointment">
            <BookApptFromPast />
          </Route>
          <Route path="/auth/rescheduleAppointment">
            <ReschedulAppt />
          </Route>
          <Route path="/auth/diagnosticReports">
            <LabReports />
          </Route>
          <Route path="/auth/healthtools">
            <HealthTool />
          </Route>
          <Route path="/auth/internationalpatients">
            <InternationalPatient />
          </Route>
          <Route path="/auth/searchDoctorResult">
            <SearchDocResult />
          </Route>
          <Route path="/auth/feedback">
            <Feedback />
          </Route>
          <Route path="/auth/complaint">
            <Complaints />
          </Route>
          <Route path="/auth/healthPackages">
            <HealthPackages />
          </Route>
          <Route path="/auth/healthPackageDetails">
            <HealthPackageDetails />
          </Route>
          <Route path="/auth/needhelp">
            <NeedHelp />
          </Route>
          <Route path="/auth/locations">
            <Locations />
          </Route>
          <Route path="/auth/insurance">
            <Insurance />
          </Route>
          <Route path="/auth/news">
            <News />
          </Route>
          <Route path="/auth/allNews">
            <AllNews />
          </Route>
          <Route path="/auth/allMedia">
            <AllMedia />
          </Route>
          <Route path="/auth/myMedication">
            <MyMedication />
          </Route>
          <Route path="/auth/user">
            <ManageUser />
          </Route>
          <Route path="/auth/notification/BroadcastNotification">
            <BroadcastNotification />
          </Route>
          <Route path="/auth/comingsoon">
            <ComingSoon />
          </Route>
          {brandName === "burjeel" && (
            <>
              <Route path="/auth/teleconsultationDoctor">
                <TeleDoctor />
              </Route>
              <Route path="/auth/bookTeleAppointment">
                <BookTeleAppointments />
              </Route>
              <Route path="/auth/rescheduleTeleAppointment">
                <RescheduleTeleAppointments />
              </Route>
              <Route path="/auth/bookTelePastAppointment">
                <BookTeleApptFromPast />
              </Route>
              <Route path="/auth/Teleconsultation">
                <Teleconsultation />
              </Route>
              <Route path="/auth/teleconsultationAppointments">
                <TeleAppointment />
              </Route>
            </>
          )}
        </Switch>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={completeProfileDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary">Complete Profile</DialogTitle>
        <DialogContent className="px-3 primary-scroll">
          <div className="row light-bg py-3  border-top">
            <div className="col-6">
              {" "}
              <p className="mt-2 my-0">
                Hi {userData.firstName} {userData.lastName},
              </p>
              <p className="fs-10 my-0">
                Provide your DOB to complete sign up process
              </p>
            </div>
            <div className="col-6">
              <div className="form-floating ">
                <KeyboardDatePicker
                  required
                  disableFuture
                  autoOk={true}
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="dense"
                  id="dob"
                  views={["year", "month", "date"]}
                  label={t("dob")}
                  error={selectedDOBError}
                  helperText={selectedDOBErrorText}
                  value={selectedDOB}
                  onChange={handleDOBChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className="w-100 rounded-1 bg-white"
                />
              </div>
            </div>
          </div>

          <div className="">
            <FormControl className="w-100">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={facilityId}
                onChange={(e) => handleGlobalLocationChange(e)}
              >
                {authCtx.locationGlobalList &&
                  authCtx.locationGlobalList.map((item: any) => (
                    <FormControlLabel
                      value={item.locationId}
                      control={<Radio />}
                      label={item.name}
                      className="border-bottom"
                      key={"LOC-" + item.locationId}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="py-3 px-4 border-top">
          <button onClick={handleProfileSubmit} className="btn btn-primary">
            {t("submit")}
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={chooseLocatinDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary border-bottom">
          Choose Hospital
        </DialogTitle>
        <DialogContent className="px-3 primary-scroll">
          <div className="">
            <FormControl className="w-100">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={facilityId}
                onChange={(e) => handleGlobalLocationChange(e)}
              >
                {authCtx.locationGlobalList &&
                  authCtx.locationGlobalList.map((item: any) => (
                    <FormControlLabel
                      value={item.locationId}
                      control={<Radio />}
                      label={item.name}
                      className="border-bottom"
                      key={"LOC-" + item.locationId}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="py-3 px-4 border-top">
          <button onClick={handleSaveLocation} className="btn btn-primary">
            {t("submit")}
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AuthLayout;
