import { useState, useEffect, useContext } from "react";
import { notificationService } from "../../services/notification.service";
import AuthContext from "../../store/auth-context";
import InfiniteScroll from "react-infinite-scroll-component";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { debug } from "console";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const BroadcastHistory = (props: any) => {
  const authCtx = useContext(AuthContext);
  const [skip1, setSkip] = useState<any>(0);
  const [take1, setTake] = useState<any>(10);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [broadcastList, setbroadcastList] = useState<any>([]);
  const [adminList, setAdminList] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [filtertext, setfiltertext] = useState<string>("");
  const [callFetchList, setCallFetchList] = useState<boolean>(false);

  const [AdminFilterId, SetAdminFilterId] = useState<string>("");

  const [selectedStartDate, setStartDate] = useState<any>(
    new Date().toISOString().slice(0, 10)
  );
  const [selectedEndDate, setEndDate] = useState<any>(
    new Date().toISOString().slice(0, 10)
  );

  const [confirmationModel, setConfirmationModel] = useState<boolean>(false);
  const [resendItem, setResendItem] = useState<any>("");

  const brandName = localStorage.getItem("brandName");

  // useEffect(() => {
  //   const getNotificationAdminlist = async () => {
  //     let res = await notificationService.getAdminList();
  //     if(res.data && res.data.length>0) {
  //     setAdminList(res.data);
  //     }
  //     else
  //     setAdminList([]);
  //     };
  //   getNotificationAdminlist();
  // }, []);

  useEffect(() => {
    setbroadcastList([]);
    setSkip(0);
    setHasMore(true);
    refreshData(0, take1, filtertext);
  }, [callFetchList, props.refreshPage]);

  // useEffect(() => {
  //   setSkip(0);

  //   refreshData(0,take1,filtertext);
  // }, [props.refreshPage]);

  // const handleAdminFilterChange = async (e:any) => {
  //     SetAdminFilterId(e.target.value);
  //     setSkip(0);
  //     setHasMore(true);
  //     setCallFetchList(!callFetchList);

  // }

  // const handleSearchChange = async (e: any) => {
  //   setfiltertext(e.target.value);
  //   setTimeout(() => {
  //     if (e.target.value.length >= 3) {
  //       setSkip(0);
  //       setHasMore(true);
  //       setCallFetchList(!callFetchList);
  //     } else if (e.target.value === "") {
  //       setSkip(0);
  //       setHasMore(true);
  //       setCallFetchList(!callFetchList);
  //     }
  //   }, 2000);
  // };

  const handleConfirmationClose = () => {
    console.log("");
    setResendItem("");
    setConfirmationModel(false);
  };

  const handleClose = () => setShowModal(false);

  const setpage = async () => {
    const nextBroadcastList = await getNotificationHistorylist(
      skip1,
      take1,
      filtertext,
      AdminFilterId
    ); // selectedStartDate,selectedEndDate

    if (nextBroadcastList && nextBroadcastList.data) {
      setbroadcastList([...broadcastList, ...nextBroadcastList.data]);
      if (
        nextBroadcastList.data.length === 0 ||
        nextBroadcastList.data.length < take1
      ) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } else {
      setHasMore(false);
    }
  };

  const refreshData = async (
    skip: number = skip1,
    take: number = take1,
    text: string = "",
    Id: string = "0"
  ) => {
    authCtx.toggleLoader(true);
    const listFromApi = await getNotificationHistorylist(skip, take, text, Id); //, selectedStartDate,selectedEndDate
    authCtx.toggleLoader(false);
    if (
      listFromApi &&
      listFromApi.data &&
      (listFromApi.data.length > 0 || listFromApi.data.length < take1)
    ) {
      setbroadcastList(listFromApi.data);
      if (listFromApi.data.length < take1) setHasMore(false);
    } else {
      setTimeout(() => {
        setbroadcastList([]);
        setHasMore(false);
      }, 500);
    }
  };

  const getNotificationHistorylist = async (
    skip: number,
    take: number,
    filtertext: string,
    Id: string = "0"
  ) => {
    if (Id == "") Id = "0";
    let res = await notificationService.getnotificationHistory(
      skip,
      take,
      filtertext,
      Id
    );
    setSkip(skip + take1);
    return res;
  };

  const handleResend = (e: any, item: any) => {
    e.preventDefault();
    if (item.isExcelUploadedNotification) {
      props.handleRePublishNotification(item);
    } else {
      setResendItem(item);
      setConfirmationModel(true);
    }
  };

  const handleResendNotification = async () => {
    authCtx.toggleLoader(true);
    const data = {
      FacilityId: resendItem.facilityId,
      NotificationType: resendItem.notificationType,
      NotificationTitle: resendItem.notificationTitle,
      InURLPath: btoa(resendItem.callbackURL),
      PhysicianNo: "",
      NotificationText: resendItem.message,
      FromDate: "",
      ToDate: "",
      ImageURL: "",
      BrandName: brandName,
      DoctorName: "",
      Type: "toExcel",
      Mobilelist: "",
    };
    let res = await notificationService.BroadcastNotification(data);
    if (res.message.status === "Success") {
      setModalType("success");
      setShowModal(true);
      setModalText(res.message.message);
      props.setRefreshPage(!props.refreshPage);
    } else {
      setModalType("error");
      setShowModal(true);
      setModalText(res.message.message);
    }
    authCtx.toggleLoader(false);
  };

  return (
    <>
      {/* <div className="card  border-0 shadow-sm rounded-10">
        <div className="row">
          <div className="col-12">
            <div className="d-sm-flex">
              <div className="p-2 flex-fill">
                <div className="input-group ">
                  <span className="input-group-text bg-transparent border-0 p-2">
                    <i className="material-icons-outlined text-999">search</i>
                  </span>
                  <input
                    type="search"
                    className="form-control border-0 fs-14 no-focus"
                    value={filtertext}
                    placeholder="Search by Admin"
                    onChange={(e) => handleSearchChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="nothist primary-scroll" id="notfyhistory">
        <InfiniteScroll
          dataLength={broadcastList.length}
          next={setpage}
          hasMore={hasMore}
          loader={false}
          scrollableTarget={"notfyhistory"}
          className="overflow-hidden"
        >
          <div className="table-responsive-lg">
            <table className="table align-middle">
              <thead>
                <tr>
                  <th scope="col" className="msgwrap ps-3">
                    Message
                  </th>
                  <th scope="col">Type</th>
                  <th scope="col">Time</th>
                  <th scope="col">Push Success</th>
                  <th scope="col">Push Failure</th>
                  <th scope="col">Status</th>
                  <th scope="col">Sent By</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {broadcastList &&
                  broadcastList.length > 0 &&
                  broadcastList.map((item: any) => (
                    <tr>
                      <td className="btn-hover w-125 ps-3">
                        <div className="d-flex-py-2">
                          <div className="flex-grow-1 ms-3 mt-2">
                            <label className="fw-bold">
                              {item.notificationTitle}
                            </label>

                            <p className="text-muted fs-12 m-0">
                              <div className="text-truncate msgwrap">
                                {item.message}
                              </div>
                              <span className="hovertext">{item.message}</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      {item.notificationType == 1 && (
                        <td>
                          <a href={item.callbackURL} target="_blank">
                            View App
                          </a>
                        </td>
                      )}
                      {item.notificationType == 2 &&
                      item.callbackURL &&
                      item.callbackURL !== "" ? (
                        <td>
                          <a href={item.callbackURL} target="_blank">
                            View URL
                          </a>
                        </td>
                      ) : (
                        <td>NA</td>
                      )}
                      <td>{item.createdOn}</td>
                      <td className="text-center">{item.pushSuccess} </td>
                      <td className="text-center">{item.pushFail} </td>
                      <td>{item.status} </td>
                      <td>{item.createdBy} </td>
                      <td>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={(e) => handleResend(e, item)}
                        >
                          Re-Send
                        </button>
                      </td>
                    </tr>
                  ))}

                {broadcastList.length === 0 && !hasMore && (
                  <tr>
                    <td colSpan={7}>
                      <div className="text-center">
                        {" "}
                        <i className="material-icons-outlined fs-1"> info </i>
                        <p className="fs-6">No record found</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
        {hasMore && (
          <>
            <div className="text-center">Loading...</div>
          </>
        )}
      </div>

      <Dialog open={confirmationModel} onClose={handleConfirmationClose}>
        <DialogTitle>Resend Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to resend this notification?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-secondary"
            onClick={handleConfirmationClose}
          >
            No
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleResendNotification();
              handleConfirmationClose();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default BroadcastHistory;
