import { wpAPI_ENDPOINTS, API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";
import {
  makeRequestForWP,
  makeRequestForWPPost,
} from "../middleware/axiosForWordpress";

export const feedbackService = {
  // getFeedbackSelect,
  getFeedbackLocations,
  getFeedbackQuestionnaire,
  saveFeedbackAnswers,
  postComplaints,
  getFeedbackpatientType,
};

async function postComplaints(complaintsData: any) {
  const apiPath = API_ENDPOINTS.POST_COMPLAINTS_SUBMISSION;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: complaintsData,
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getFeedbackLocations(position: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_FEEDBACK_LOCATIONS + position;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getFeedbackQuestionnaire(vblog: any, patientType: any) {
  const apiPath =
    wpAPI_ENDPOINTS.GET_FEEDBACK_QUESTIONNAIRE +
    vblog +
    "&patient_type=" +
    patientType;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function saveFeedbackAnswers(feedbackData: any) {
  const apiPath = wpAPI_ENDPOINTS.POST_FEEDBACK_RESPONSE;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      userName: feedbackData.userName,
      userMobile: feedbackData.userMobile,
      userEmail: feedbackData.userEmail,
      vblog: feedbackData.vblog,
      FPlatform: feedbackData.FPlatform,
      UserResponse: feedbackData.UserResponse,
      patientType: feedbackData.patientType,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWPPost(options);
}

async function getFeedbackpatientType(vblog: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_FEEDBACK_PATIENT_TYPE + vblog;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}
