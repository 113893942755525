import React from "react";
import { useHistory } from "react-router-dom";

function ComingSoon() {
  const history = useHistory();

  const gotoDashboard = () => {
    history.push("/auth/dashboard");
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <div className="main-content animate py-4 px-3 px-sm-4 position-relative">
      <div className="d-flex align-items-center mb-3 position-relative z-1">
        <a
          href="#"
          className="d-inline-block d-sm-none text-dark"
          onClick={(e) => openMobileMenu(e)}
        >
          <i className="material-icons-outlined me-2">menu</i>
        </a>
      </div>
      <div className="position-absolute top-0 left-0 vh-100 w-100 bg-white d-flex align-items-center">
        <div className="row text-center m-0 justify-content-center w-100">
          <div className="col-11 col-md-6 ">
            <h4 className="text-primary fw-bold">Teleconsultation</h4>
            <h1 className="text-primary fw-bold fs-48">Coming Soon</h1>

            <div className="progress mt-3 mb-4">
              <div
                style={{ width: "75%" }}
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                //   aria-valuenow="75"
                //   aria-valuemin="0"
                //   aria-valuemax="100"
              >
                75%
              </div>
            </div>

            {/* <p className="mt-3 mb-4">
              Stay tuned for convenient, remote healthcare services.
            </p> */}
            <button className="btn btn-primary mt-4" onClick={gotoDashboard}>
              Go To Dashoboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;
