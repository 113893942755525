import React, { useEffect, useState } from "react";
import { dashboardservice } from "../../services/dashboard.service";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";

const DashboardBanner = () => {
  const [bannerImage, setBannerImage] = useState<any>([]);

  useEffect(() => {
    getBannerImage();
  }, []);

  const getBannerImage = async () => {
    let response = await dashboardservice.getBannerImg("Banner");
    if (response && response.data && response.data.data) {
      setBannerImage(response.data.data);
    }
  };

  return (
    <div className="card border-0 shadow-sm rounded-1rem overflow-hidden">
      <div className="card-body p-0">
        {/* <div className="me-2">
                    <h6 className="fw-bold text-primary">Covid - 19 Vaccination</h6>
                    <p className="fs-12">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos</p>
                    <button className="btn btn-sm btn-primary">Learn More</button>
                </div>
                <div className="ms-auto d-none d-sm-block">
                    <img src={"/assets/images/girl-with-mask.png"} alt="" />
                </div> */}

        <Carousel
          controls={false}
          fade
          interval={3000}
          pause={"hover"}
          slide={true}
          variant={"dark"}
          indicators={true}
        >
          {bannerImage && bannerImage.map((item: any, index: any) => (
            <Carousel.Item key={index}>
              <div className="">
                {/* <button className="btn btn-sm btn-primary">Learn More</button> */}
                <img
                  src={item.imagepath}
                  alt=""
                  className="dashboardbanner-img"
                />
              </div>
              <div className="ms-auto d-none d-sm-block"></div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default DashboardBanner;
