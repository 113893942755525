import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { appservice } from "../services/app.service";
import { familyMemberservice } from "../services/familyMember.services";
import AuthContext from "../store/auth-context";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import countryList from "../constants/countryList.json";
import { loginservice } from "../services/login.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import NumberFormat from "react-number-format";

const FamilyMembersDetails = (props: any) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["familyMember", "profile", "login"]);
  const [callingCode, setCallingCode] = useState("971");
  const [nationalityList, setNationality] = useState<any>([]);
  const [genderList, setGenderList] = useState<any>([]);
  const [docTypeList, setDocTypeList] = useState<any>([]);
  const [relationList, setRelationList] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [showVerifyNumberModal, setVerifyNumberModal] =
    useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("error");

  const [fmId, setfmId] = useState<string>("");
  const [fName, setfName] = useState<string>("");

  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [patientDOB, setPatientDOB] = useState<any>();
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");

  const [docId, setDocId] = useState<string>("");
  const [docIdError, setDocIdError] = useState<boolean>(false);
  const [docIdErrorText, setDocIdErrorText] = useState<string>("");

  const [docType, setDocType] = useState(0);
  const [docTypeError, setDocTypeError] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("");
  const [countryError, setCountryError] = useState<boolean>(false);

  const [relation, setRelation] = useState<string>("");
  const [relationError, setRelationError] = useState<boolean>(false);

  const [loginMobile, setLoginMobile] = useState<string>("");
  const [loginMobileError, setLoginMobileError] = useState<boolean>(false);
  //const [loginMobileErrorText, setLoginMobileErrorText] = useState<string>("");
  const [isNumberVerified, setIsNumberVerified] = useState(false);

  const [age, setAge] = useState<number>(0);
  const [isDateChange, setIsDateChange] = useState<boolean>(false);

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpErrortext, setOtpErrorText] = useState<string>("");
  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);

  const [confirmationModel, setConfirmationModel] = useState<boolean>(false);

  useEffect(() => {
    const getCountryCode = async () => {
      const listFromApi = await fetchCountryCode();
      setNationality(listFromApi);
    };

    const getDocumentTypeList = async () => {
      const listFromApi = await fetchDocTypeList();
      setDocTypeList(listFromApi);
    };

    const getGenderList = async () => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
      if (props.mId !== "0") {
        setGender(props.mData.genderId);
      }
    };

    const getRelationshipList = async () => {
      const listFromApi = await fetchRelationshipList();
      setRelationList(listFromApi);
    };
    setfmId(props.mId);

    getGenderList();
    getCountryCode();
    getDocumentTypeList();
    getRelationshipList();

    const setFamilyData = () => {
      if (props.mId !== "0") {
        setfName(props.mData.firstName);
        setlName(props.mData.lastName);
        setEmail(props.mData.email);
        setSelectedDOB(props.mData.dateOfBirth);
        setPatientDOB(props.mData.dateOfBirth.slice(0, 10));
        setLoginMobile(props.mData.mobile);
        setGender(props.mData.genderId);
        setCountry(props.mData.countryId);
        setDocId(props.mData.documentId);
        setDocType(props.mData.documentTypeId);
        setRelation(props.mData.relationId);
        setCallingCode(props.mData.callingCode);
        setIsNumberVerified(true);
      }
    };

    setFamilyData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.mId !== "0") {
      if (!isDateChange) {
        calculateAge(new Date(props.mData.dateOfBirth));
      }
    }
    // eslint-disable-next-line
  }, [loginMobile]);

  useEffect(() => {
    let intervalId: any;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          const secondCounter = counter % 60;
          const minuteCounter = Math.floor(counter / 60);
          const computedSecond =
            String(secondCounter).length === 1
              ? `0${secondCounter}`
              : secondCounter;
          const computedMinute =
            String(minuteCounter).length === 1
              ? `0${minuteCounter}`
              : minuteCounter;
          setSecond(computedSecond.toString());
          setMinute(computedMinute.toString());
          setCounter((counter) => counter - 1);
        } else {
          setIsActive(false);
          setCounter(60);
          setSecond("00");
          setMinute("00");
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const handleComfirmationClose = () => setConfirmationModel(false);

  const fetchCountryCode = async () => {
    let res = await appservice.getCountryList();
    return res;
  };

  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };
  const fetchDocTypeList = async () => {
    let res = await appservice.getDocumentType();
    return res;
  };
  const fetchRelationshipList = async () => {
    let res = await appservice.getRelation();
    return res;
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleVerifyNumberModalClose = async () => {
    setVerifyNumberModal(false);
    setIsActive(false);
    setCounter(60);
    setSecond("00");
    setMinute("00");
  };

  const handleVerifyNumberModalShow = () => {
    sendOtpToFamily();
  };

  function validateForm() {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }

    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (loginMobile.trim() === "") {
      setLoginMobileError(true);
      isValid = false;
    }
    if (gender === null || gender === "") {
      setGenderError(true);
      isValid = false;
    }
    if (selectedDOB === null || selectedDOBError) {
      setSelectedDOBError(true);
      isValid = false;
    }

    if (email.trim() === "" || emailError) {
      setEmailError(true);
      isValid = false;
    }
    if (docId.trim() === "" || docIdError) {
      setDocIdError(true);
      isValid = false;
    }

    if (docType === null || docType === 0 || docTypeError) {
      setDocTypeError(true);
      isValid = false;
    }
    if (country === null || country === "" || countryError) {
      setCountryError(true);
      isValid = false;
    }
    if (relation === null || relation === "" || relationError) {
      setRelationError(true);
      isValid = false;
    }

    return isValid;
  }

  function checkEmirateId() {
    let isValid: boolean = true;
    if (docType.toString() === "1") {
      const emirateId = docId;
      const dobYear = moment(selectedDOB, "YYYY-MM-DD").format("YYYY");
      const compereCodeWith =
        "784" + dobYear + emirateId.toString().slice(7, emirateId.length);
      if (emirateId !== compereCodeWith) {
        isValid = false;
      }
    }
    return isValid;
  }

  const handleDOBChange = (date: any | null) => {
    setIsDateChange(true);
    setIsNumberVerified(false);
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
      setPatientDOB(date.toISOString().slice(0, 10));
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("profile:dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("profile:dobFormatError"));
    }

    calculateAge(date);
  };

  function calculateAge(date: any) {
    let userMobileDetails = JSON.parse(
      localStorage.getItem("userMobile") || "{}"
    );
    if (date !== null) {
      var diff_ms = Date.now() - date.getTime();
      var age_dt = new Date(diff_ms);

      let age = Math.abs(age_dt.getUTCFullYear() - 1970);
      if (age < 18) {
        if (
          loginMobile === userMobileDetails.mobileNumber &&
          callingCode === userMobileDetails.callingCode
        ) {
          setIsNumberVerified(true);
        } else {
          if (
            props.mId !== "0" &&
            loginMobile === props.mData.mobile &&
            callingCode === props.mData.callingCode
          ) {
            setIsNumberVerified(true);
          } else {
            setIsNumberVerified(false);
          }
        }
      } else {
        if (
          loginMobile === userMobileDetails.mobileNumber &&
          callingCode === userMobileDetails.callingCode
        ) {
          setIsNumberVerified(false);
          setTimeout(() => {
            setLoginMobile("");
          }, 1000);
          setShowModal(true);
          setModalType("error");
          setModalText("Please choose a different mobile number");
        } else {
          if (
            props.mId !== "0" &&
            loginMobile === props.mData.mobile &&
            callingCode === props.mData.callingCode
          ) {
            setIsNumberVerified(true);
          } else {
            setIsNumberVerified(false);
          }
        }
      }
      setAge(age);
    }
  }

  function validateOtp() {
    let isValid: boolean = true;
    if (otp.trim() === "" || otpError) {
      setOtpError(true);
      isValid = false;
    }
    return isValid;
  }

  let sendOtpToFamily = async () => {
    setOtp("");
    authCtx.toggleLoader(true);
    let res = await familyMemberservice.sendOtpToFamily(
      callingCode,
      loginMobile
    );
    authCtx.toggleLoader(false);
    if (res.message.status === "Success") {
      setIsActive(true);
      setVerifyNumberModal(true);
      handleShow();
      setModalText(t("login:otpSendSuccess"));
      setModalType("success");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    } else {
      handleShow();
      setModalText(t("login:otpSendError"));
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }
  };

  let handleOtp = async () => {
    setIsNumberVerified(false);
    authCtx.toggleLoader(true);
    if (validateOtp()) {
      let res = await loginservice.verifyUser(callingCode, loginMobile, otp);
      authCtx.toggleLoader(false);
      if (res.validOtp && res.message === "Success") {
        setVerifyNumberModal(false);
        setIsNumberVerified(true);
        // handleShow()
        // setModalText(t("login:otpVerifySuccess"));
        // setModalType("success")
        // setTimeout(() => {
        //     setModalText("");
        //     handleClose()
        //     setOtp("")
        // }, 3000);
      } else {
        if (!res.validOtp) {
          handleShow();
          setModalText(t("login:otpVerifyError"));
          setModalType("error");
          setTimeout(() => {
            setModalText("");
            handleClose();
          }, 3000);
        }
      }
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    let userMobileDetails = JSON.parse(
      localStorage.getItem("userMobile") || "{}"
    );
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.name === "loginMobile") {
        //Check if its same as the previous value
        if (props.mId !== "0") {
          if (
            e.target.value !== props.mData.mobile &&
            callingCode !== props.mData.callingCode
          ) {
            setIsNumberVerified(false);
          } else {
            if (age < 18) {
              if (
                e.target.value === userMobileDetails.mobileNumber &&
                callingCode === userMobileDetails.callingCode
              ) {
                setIsNumberVerified(true);
              } else {
                setIsNumberVerified(false);
              }
            } else {
              if (
                e.target.value === userMobileDetails.mobileNumber &&
                callingCode === userMobileDetails.callingCode
              ) {
                setIsNumberVerified(false);
                setTimeout(() => {
                  setLoginMobile("");
                }, 1000);
                setShowModal(true);
                setModalType("error");
                setModalText("Please choose a different mobile number");
              } else {
                setIsNumberVerified(false);
              }
            }
          }
        } else {
          if (age < 18) {
            if (
              e.target.value === userMobileDetails.mobileNumber &&
              callingCode === userMobileDetails.callingCode
            ) {
              setIsNumberVerified(true);
            } else {
              setIsNumberVerified(false);
            }
          } else {
            if (
              e.target.value === userMobileDetails.mobileNumber &&
              callingCode === userMobileDetails.callingCode
            ) {
              setIsNumberVerified(false);
              setTimeout(() => {
                setLoginMobile("");
              }, 1000);
              setShowModal(true);
              setModalType("error");
              setModalText("Please choose a different mobile number");
            } else {
              setIsNumberVerified(false);
            }
          }
        }
      }
      if (e.target.name === "loginMobile" || e.target.name === "otp") {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
          if (e.target.name === "otp") {
            setOtpErrorText(t("login:otpError"));
          }
        } else {
          const re = /^[0-9\b]+$/;
          if (!re.test(e.target.value)) {
            errorSetterName(true);
            if (e.target.name === "otp") {
              setOtpErrorText(t("login:numberError"));
            }
          } else {
            errorSetterName(false);
            setOtpErrorText("");
          }
        }
      } else {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
        } else {
          errorSetterName(false);
        }
      }
    }
  };

  const handleCallingCode = (phoneCode: any) => {
    let userMobileDetails = JSON.parse(
      localStorage.getItem("userMobile") || "{}"
    );
    setCallingCode(phoneCode);
    if (props.mId !== "0") {
      if (
        loginMobile !== props.mData.mobile ||
        phoneCode !== props.mData.callingCode
      ) {
        if (
          loginMobile === userMobileDetails.mobileNumber &&
          phoneCode === userMobileDetails.callingCode
        ) {
          setIsNumberVerified(false);
          setTimeout(() => {
            setLoginMobile("");
          }, 1000);
          setShowModal(true);
          setModalType("error");
          setModalText("Please choose a different mobile number");
        } else {
          setIsNumberVerified(false);
        }
      } else {
        if (age < 18) {
          if (
            loginMobile === userMobileDetails.mobileNumber &&
            phoneCode === userMobileDetails.callingCode
          ) {
            setIsNumberVerified(true);
          } else {
            if (
              props.mId !== "0" &&
              loginMobile === props.mData.mobile &&
              callingCode === props.mData.callingCode
            ) {
              setIsNumberVerified(true);
            } else {
              setIsNumberVerified(false);
            }
          }
        } else {
          if (
            loginMobile === userMobileDetails.mobileNumber &&
            phoneCode === userMobileDetails.callingCode
          ) {
            setIsNumberVerified(false);
            setTimeout(() => {
              setLoginMobile("");
            }, 1000);
            setShowModal(true);
            setModalType("error");
            setModalText("Please choose a different mobile number");
          } else {
            if (
              props.mId !== "0" &&
              loginMobile === props.mData.mobile &&
              callingCode === props.mData.callingCode
            ) {
              setIsNumberVerified(true);
            } else {
              setIsNumberVerified(false);
            }
          }
        }
      }
    } else {
      if (age < 18) {
        if (
          loginMobile === userMobileDetails.mobileNumber &&
          phoneCode === userMobileDetails.callingCode
        ) {
          setIsNumberVerified(true);
        } else {
          setIsNumberVerified(false);
        }
      } else {
        if (
          loginMobile === userMobileDetails.mobileNumber &&
          phoneCode === userMobileDetails.callingCode
        ) {
          setIsNumberVerified(false);
          setTimeout(() => {
            setLoginMobile("");
          }, 1000);
          setShowModal(true);
          setModalType("error");
          setModalText("Please choose a different mobile number");
        } else {
          setIsNumberVerified(false);
        }
      }
    }
  };

  const handleDocIdChange = (e: any) => {
    let pattern = /^784[0-9]{12}$/;
    if (docType === 1) {
      let text = e.target.value;
      let emirateId = text.replace(/-/g, "").trim();
      setDocId(emirateId);
      if (pattern.test(emirateId)) {
        if (emirateId.length < 8) {
          setDocIdError(true);
          setDocIdErrorText("Please enter a valid Emirates ID");
        } else {
          setDocIdError(false);
          setDocIdErrorText("");
        }
      } else {
        setDocIdError(true);
        setDocIdErrorText("Please enter a valid Emirates ID");
      }
    } else {
      setDocId(e.target.value);
      if (e.target.value === "" || e.target.value.trim() === "") {
        setDocIdError(true);
        setDocIdErrorText("");
      } else if (e.target.value.length < 6) {
        setDocIdError(true);
        setDocIdErrorText(t("profile:docIdInvalidError"));
      } else {
        setDocIdError(false);
        setDocIdErrorText("");
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText(t("profile:emailValidError"));
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleConfirmationOpen = () => {
    if (validateForm()) {
      if (props.mId !== "0") {
        handleSubmit();
      } else {
        setConfirmationModel(true);
      }
    }
  };

  let handleSubmit = async () => {
    let data;
    setConfirmationModel(false);

    authCtx.toggleLoader(true);
    data = {
      familyMemberId: fmId,
      firstName: fName.trim(),
      lastName: lName.trim(),
      email: email.trim(),
      dateOfBirth: patientDOB,
      mobile: loginMobile,
      callingCode: callingCode,
      genderId: gender,
      countryId: country,
      documentTypeId: docType,
      documentId: docId,
      relationId: relation,
      otp: otp,
      // digitalConsentFileUrl:
      //   "https://vpsstapreprodmuleuaen01.blob.core.windows.net/patientportalstorage/170c846f-f715-4c91-9db6-7b687ca5488d/signature.png",
      // digitalConsentFileName:
      //   "170c846f-f715-4c91-9db6-7b687ca5488d/signature.png",
    };
    let res = await familyMemberservice.saveFamilyMember(data);
    authCtx.toggleLoader(false);
    if (
      res &&
      res.message &&
      res.message.status &&
      res.message.status === "Success"
    ) {
      handleShow();
      setfmId(res.data.familyMemberId);
      setModalText(res.message.message);
      setModalType("success");
      props.handlePopupClose();
      setTimeout(() => {
        setModalText("");
        handleClose();
        if (props.mId !== "0") {
          props.handleEditClose();
        } else {
          //Commenting it to stop redirecting to upload documents screen
          // props.showDoc(res.data.familyMemberId);
        }
      }, 3000);
    } else if (res && res.status === 429) {
      handleShow();
      setModalText(res.errorResponse.data);
      setModalType("error");
    } else {
      handleShow();
      setModalText(t("familyMember:saveFamilyMemberError"));
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }
  };
  return (
    <>
      <div className="row g-sm-3 ">
        {/* sign-up-form */}
        <div className="col-sm mb-3">
          <TextField
            required
            id="fName"
            label={t("profile:firstName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={fNameError}
            value={fName}
            onChange={(e: any) => {
              handleInputChange(e, setfName, setFnameError);
            }}
          />
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            id="lName"
            label={t("profile:lastName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={lNameError}
            value={lName}
            onChange={(e: any) => {
              handleInputChange(e, setlName, setLnameError);
            }}
          />
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm mb-3">
          <TextField
            required
            id="email"
            label={t("profile:email")}
            className="w-100  "
            variant="outlined"
            size="small"
            error={emailError}
            helperText={emailErrorText}
            value={email}
            onChange={(e: any) => {
              handleEmailChange(e);
            }}
          />
        </div>
        <div className="col-sm mb-3">
          <KeyboardDatePicker
            autoOk={true}
            required
            disableFuture
            size="small"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="dense"
            id="dob"
            views={["year", "month", "date"]}
            label={t("profile:dob")}
            error={selectedDOBError}
            helperText={selectedDOBErrorText}
            value={selectedDOB}
            onChange={handleDOBChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className="m-0 w-100"
          />
        </div>
      </div>
      <div className="mb-3 position-relative">
        <div className="input-group">
          {countryList && (
            <Autocomplete
              id="country-select-demo"
              options={countryList}
              disableClearable
              autoHighlight
              size="small"
              defaultValue={countryList.find(
                (x) => x.phoneCode === callingCode
              )}
              value={countryList.find((x) => x.phoneCode === callingCode)}
              onChange={(event, value) => {
                handleCallingCode(value.phoneCode);
              }}
              filterOptions={createFilterOptions({
                stringify: (option) => "+" + option.phoneCode + option.name,
              })}
              getOptionLabel={(option) => "+" + option.phoneCode}
              PaperComponent={({ children }) => (
                <Paper style={{ minWidth: "400px" }}>{children}</Paper>
              )}
              style={{ minWidth: "100px" }}
              renderOption={(option) => (
                <>
                  {option.name} (+{option.phoneCode})
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}

          <TextField
            required
            autoComplete="off"
            id="mobile"
            label={t("profile:mobile")}
            name="loginMobile"
            className="form-control"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 10 }}
            error={loginMobileError}
            //helperText={loginMobileErrorText}
            value={loginMobile}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e: any) => {
              handleInputChange(e, setLoginMobile, setLoginMobileError);
            }}
          />
        </div>
        {!isNumberVerified && (
          <button
            className="btn btn-link p-0 position-absolute r-8 t-8"
            onClick={handleVerifyNumberModalShow}
            disabled={!loginMobile}
          >
            Verify
          </button>
        )}
        {isNumberVerified && (
          <span className="material-icons-outlined text-success position-absolute r-8 t-8">
            check_circle
          </span>
        )}
      </div>

      <div className="row g-sm-3">
        <div className="col-sm mb-3">
          <TextField
            required
            select
            id="gender"
            label="Gender"
            className="w-100  "
            variant="outlined"
            size="small"
            error={genderError}
            value={gender}
            onChange={(e: any) => {
              setGender(e.target.value);
              setGenderError(false);
            }}
          >
            {genderList &&
              genderList.map((item: any) => (
                <MenuItem value={item.id}>{item.text}</MenuItem>
              ))}
          </TextField>
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            select
            id="country"
            label={t("profile:nationality")}
            className="w-100  "
            variant="outlined"
            size="small"
            error={countryError}
            value={country}
            onChange={(e: any) => {
              setCountry(e.target.value);
              setCountryError(false);
            }}
          >
            {nationalityList &&
              nationalityList.map((country: any) => (
                <MenuItem value={country.id}>{country.text}</MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm mb-3">
          <TextField
            required
            select
            id="docType"
            label={t("profile:docType")}
            className="w-100  "
            variant="outlined"
            size="small"
            disabled={props.mId !== "0" ? true : false}
            error={docTypeError}
            value={docType === 0 ? "" : docType}
            onChange={(e: any) => {
              setDocType(e.target.value);
              setDocId("");
              setDocTypeError(false);
            }}
          >
            {docTypeList &&
              docTypeList.map((item: any) => (
                <MenuItem value={item.id}>{item.text}</MenuItem>
              ))}
          </TextField>
        </div>
        <div className="col-sm mb-3">
          {docType === 1 ? (
            <NumberFormat
              required
              customInput={TextField}
              id="docId1"
              className="w-100"
              size="small"
              variant="outlined"
              format="###-####-#######-#"
              label={t("profile:docId")}
              disabled={props.mId !== "0" ? true : false}
              error={docIdError}
              helperText={docIdErrorText}
              value={docId}
              onChange={(e: any) => {
                handleDocIdChange(e);
              }}
            />
          ) : (
            <TextField
              required
              id="docId"
              label={t("profile:docId")}
              className="w-100"
              variant="outlined"
              size="small"
              type="tel"
              disabled={props.mId !== "0" ? true : false}
              inputProps={{ maxLength: 15, minLength: 6 }}
              error={docIdError}
              helperText={docIdErrorText}
              value={docId}
              onChange={(e: any) => {
                handleDocIdChange(e);
              }}
            />
          )}
        </div>
      </div>
      <div className="row g-sm-3">
        <div className="col-sm mb-3">
          <TextField
            required
            select
            id="relation"
            label={t("familyMember:relation")}
            className="w-100  "
            variant="outlined"
            size="small"
            error={relationError}
            value={relation}
            onChange={(e: any) => {
              setRelation(e.target.value);
              setRelationError(false);
            }}
          >
            {relationList &&
              relationList.map((relation: any) => (
                <MenuItem value={relation.id}>{relation.text}</MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className="text-end">
        <button
          type="submit"
          className="btn btn-primary "
          value=""
          disabled={!isNumberVerified}
          onClick={() => handleConfirmationOpen()}
        >
          {t("familyMember:save")}
          {isNumberVerified}
        </button>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showVerifyNumberModal}
        onClose={handleVerifyNumberModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("familyMember:verifyNumber")}
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleVerifyNumberModalClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <small className="text-muted mb-5">
            {t("familyMember:sentOtpTitle")}
            <span className="fw-bolder">
              {" "}
              +{callingCode}-<span id="numberEntered">{loginMobile}</span>
            </span>
          </small>
          <div className="mb-3 mt-4">
            <TextField
              autoComplete="off"
              id="otp"
              label={t("login:enterOTP")}
              name="otp"
              className="otp w-100 mb-4 letter-spacing-4"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 6 }}
              error={otpError}
              value={otp}
              helperText={otpErrortext}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e: any) => {
                handleInputChange(e, setOtp, setOtpError);
              }}
            />
          </div>
          <p className="text-muted">
            {t("login:resendOtp")}{" "}
            <span className="float-end">
              <span className="minute">{minute}</span>
              <span>:</span>
              <span className="second">{second}</span>
            </span>
          </p>

          <Link
            to="#"
            className={
              isActive ? " btn btn-link  p-0 disabled" : " btn btn-link  p-0"
            }
            onClick={(e) => {
              e.preventDefault();
              sendOtpToFamily();
            }}
          >
            {" "}
            {t("login:resendOtpLink")}
          </Link>

          <button
            type="submit"
            className="btn btn-primary w-100 mt-4 mb-3"
            disabled={!otp}
            onClick={() => handleOtp()}
          >
            {" "}
            {t("familyMember:verifyNumber")}
          </button>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={confirmationModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary">Confirmation</DialogTitle>
        <DialogContent>
          {" "}
          <p className="fs-16 fw-600">
            Please check your document type and document id, you will not be
            able to update it later.
          </p>
        </DialogContent>
        <DialogActions>
          {" "}
          <button onClick={handleComfirmationClose} className="btn btn-primary">
            Close
          </button>
          <button onClick={handleSubmit} className="btn btn-primary">
            Continue
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default FamilyMembersDetails;
