import React, { useContext, useEffect, useState } from "react";
import { dashboardservice } from "../../services/dashboard.service";
import Parser from "html-react-parser";
import AuthContext from "../../store/auth-context";

function TermAndCon() {
  const authCtx = useContext(AuthContext);
  const [tncDetails,setTncDetails] = useState<any>("");
  useEffect(()=> {
    fetchTermsAndCondition()
    // eslint-disable-next-line
  },[]);

  const fetchTermsAndCondition = async () =>{ 
    authCtx.toggleLoader(true);
    const res = await dashboardservice.getTermsAndCondition();
    if(res && res.data && res.data.status === "success"){
      setTncDetails(res.data.data);
    }
    authCtx.toggleLoader(false);
  }
  return (
    <div className="container background-white">
      <div className="row">
        <div className="col-12">
          {tncDetails && Parser(tncDetails.page_text)}
        </div>
      </div>
    </div>
  );
}

export default TermAndCon;
