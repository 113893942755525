import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import FamilyMembers from "./FamilyMembers";
import { familyMemberservice } from "../services/familyMember.services";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AuthContext from "../store/auth-context";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "./common/Notification";
import UserMenu from "./common/UserMenu";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
function ManageFamily() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["familyMember"]);

  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [familyData, setFamilyData] = useState<any>();
  const [familyMemberId, setFamilyMemberId] = useState<any>();
  const [familyMemberName, setFamilyMemberName] = useState<string>();

  const [familyList, setFamilyList] = useState<any>([]);

  useEffect(() => {
    const getFamilyList = async () => {
      refreshData();
    };
    getFamilyList();
    // eslint-disable-next-line
  }, []);

  const refreshData = async () => {
    const listFromApi = await fetchFamilyList();
    setFamilyList(listFromApi.data);
  };

  const fetchFamilyList = async () => {
    let res = await familyMemberservice.getFamilyList();
    return res;
  };

  const handleAddClose = async () => {
    setShowAddModal(false);
    refreshData();
  };

  const handlePopupClose = () => {
    setShowAddModal(false);
    refreshData();
  };

  const handleAddShow = () => {
    setShowAddModal(true);
  };

  const handleEditClose = () => {
    setShowEditModal(false);
    refreshData();
  };

  const handleEditShow = (data: any) => {
    setFamilyData(data);
    setShowEditModal(true);
    setFamilyMemberId(data.familyMemberId);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const confirmDelete = (familyMemberId: string, familyMemberName: string) => {
    setFamilyMemberId(familyMemberId);
    setFamilyMemberName(familyMemberName);
    setOpenDialog(true);
  };

  const deleteMember = async () => {
    authCtx.toggleLoader(true);
    setOpenDialog(false);
    let res = await familyMemberservice.deleteFamilyMember(familyMemberId);
    if (res.message.status === "Success") {
      authCtx.toggleLoader(false);
      setModalType("success");
      setModalText(t("removeFamilyMemberSuccess"));
      handleShow();

      setTimeout(() => {
        setModalText(" ");
        handleClose();
        refreshData();
      }, 3000);
    } else {
      authCtx.toggleLoader(false);
      setModalType("error");
      setModalText(t("removeFamilyMemberError"));
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleClose();
        refreshData();
      }, 3000);
    }
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();
    console.log("");
    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">{t("familyTitle")}</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="card border-0 shadow-sm rounded-1rem">
          <div className="card-body p-sm-4 p-3">
            <div className="d-flex justify-content-end mb-2">
              {/* <h6 className="text-primary">{t("listTitle")}</h6> */}
              <button className="btn btn-primary" onClick={handleAddShow}>
                {t("addMember")}
              </button>
            </div>
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-5 col-sm-4 p-3 fw-600">Name </div>
                <div className="col-sm-3 p-3 fw-600 d-none d-sm-inline-block">
                  Relation
                </div>
                <div className="col-3 col-sm-2 p-3 fw-600">Age</div>
                <div className="col-4 col-sm-3 p-3 fw-600">Action</div>
              </div>
              {familyList &&
                familyList.map((item: any) => (
                  <div className="row border-top align-items-center">
                    <div className="col-12 col-sm-4 pt-2 pb-sm-2">
                      {item.firstName}&nbsp;{item.lastName}
                    </div>
                    <div className="col-5 col-sm-3  pt-sm-2 pb-2">
                      {item.relation}
                    </div>
                    <div className="col-3 col-sm-2 pt-sm-2 pb-2">
                      {item.age}
                    </div>
                    <div className="col-4 col-sm-3 pt-sm-2 pb-2">
                      <Link
                        to="#"
                        className="me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditShow(item);
                        }}
                      >
                        <i className="material-icons-outlined">edit</i>
                      </Link>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          confirmDelete(
                            item.familyMemberId,
                            item.firstName + " " + item.lastName
                          );
                        }}
                      >
                        <i className="material-icons-outlined">
                          delete_outline
                        </i>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            {!familyList && (
              <div className="mh-240p d-flex justify-content-center align-items-center">
                <div className="text-center text-999 p-4">
                  {" "}
                  <i className="material-icons-outlined fs-1"> info </i>
                  <p className="fs-6">No family members to display</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={showEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("editMember")}
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleEditClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <FamilyMembers
              mId={familyMemberId}
              mData={familyData}
              handleEditClose={handleEditClose}
              handlePopupClose={handlePopupClose}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showAddModal}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("addMember")}
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleAddClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <FamilyMembers mId={"0"} handlePopupClose={handlePopupClose} />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deleteConfirmation")} <b>{familyMemberName}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleDialogClose} className="btn btn-primary">
            {t("cancel")}
          </button>
          <button onClick={deleteMember} className="btn btn-primary">
            {t("yes")}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ManageFamily;
