import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";

import { IconButton, InputAdornment, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import cList from "../constants/countryList.json";
import { loginservice } from "../services/login.service";
import AuthContext from "../store/auth-context";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

function ForgetPass() {
  const { t } = useTranslation(["login", "forgetPass", "profile"]);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  // const [mobileCode, setMobileCode] = useState<any>("971");

  const [existingCallingCode, setExistingCallingCode] = useState<any>("971");

  const [pass, setPass] = useState<string>("");
  const [passError, setPassError] = useState<boolean>(false);
  const [PassErrorText, setPassErrorText] = useState<string>("");

  const [cPass, setCpass] = useState<string>("");
  const [cpassError, setCpassError] = useState<boolean>(false);
  const [cPassErrorText, setCpassErrorText] = useState<string>("");

  const [loginMobile, setLoginMobile] = useState<string>("");
  const [loginMobileError, setLoginMobileError] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(true);

  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpErrortext, setOtpErrorText] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(60);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showComparePassword, setShowComparePassword] = useState(false);
  const handleClickShowComparePassword = () =>
    setShowComparePassword(!showComparePassword);
  const handleMouseDownComparePassword = () =>
    setShowComparePassword(!showComparePassword);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("userMobile") ?? "{}");
    if (user.mobileNumber !== "" && user.mobileNumber !== undefined) {
      setLoginMobile(user.mobileNumber);
      setExistingCallingCode(user.callingCode);
    }
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          const secondCounter = counter % 60;
          const minuteCounter = Math.floor(counter / 60);
          const computedSecond =
            String(secondCounter).length === 1
              ? `0${secondCounter}`
              : secondCounter;
          const computedMinute =
            String(minuteCounter).length === 1
              ? `0${minuteCounter}`
              : minuteCounter;
          setSecond(computedSecond.toString());
          setMinute(computedMinute.toString());
          setCounter((counter) => counter - 1);
        } else {
          setIsActive(false);
          setCounter(0);
          setSecond("00");
          setMinute("00");
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.name === "pass" || e.target.name === "otp") {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
          if (e.target.name === "pass") {
            setPassErrorText(t("forgetPass:passError"));
          }
          if (e.target.name === "otp") {
            setOtpErrorText(t("login:otpError"));
          }
        } else {
          if (e.target.name === "otp") {
            const re = /^[0-9\b]+$/;
            if (!re.test(e.target.value)) {
              errorSetterName(true);
              setOtpErrorText(t("login:numberError"));
            }
          } else {
            errorSetterName(false);
            setPassErrorText("");
            setOtpErrorText("");
          }
        }
      }
    } else {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };

  const handleConfirmPasswordChange = (e: any) => {
    setCpass(e.target.value);
    let confirmPass = e.target.value;
    if (e.target.value === "" || e.target.value.trim() === "") {
      setCpassError(true);
      setCpassErrorText("");
    } else {
      if (pass !== confirmPass) {
        setCpassError(true);
        setCpassErrorText(t("forgetPass:passNotMatch"));
      } else {
        setCpassError(false);
        setCpassErrorText("");
      }
    }
  };

  function validateOtp() {
    let isValid: boolean = true;
    if (otp.trim() === "") {
      setOtpError(true);
      isValid = false;
    }
    return isValid;
  }

  let handleOtp = async () => {
    if (otp.length < 6) {
      setOtpError(true);
      setOtpErrorText(t("login:otpInvalidError"));
    } else {
      if (validateOtp()) {
        // let mbc;
        // if (mobileCode === "") {
        //   mbc = existingCallingCode;
        // } else {
        //   mbc = mobileCode;
        // }

        let res = await loginservice.forgotPassVerifyOtp(
          existingCallingCode,
          loginMobile,
          otp
        );

        if (res.validOtp && res.message === "Success") {
          setIsOtpVerified(false);
        } else {
          if (!res.validOtp) {
            handleShow();
            setModalType("error");
            setModalText(t("login:otpVerifyError"));
            setTimeout(() => {
              handleClose();
              setModalText("");
            }, 3000);
          }
        }
      }
    }
  };

  const handleDOBChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("profile:dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("profile:dobFormatError"));
    }
  };

  function validatePass() {
    let isValid: boolean = true;
    if (pass.trim() === "" || pass.length < 8) {
      setPassError(true);
      setPassErrorText("Password must be 8 character long");
      isValid = false;
    }
    if (cPass.trim() === "" || pass !== cPass) {
      setCpassError(true);
      setCpassErrorText("Password do not match");
      isValid = false;
    }

    if (selectedDOB === null || selectedDOBError) {
      setSelectedDOBError(true);
      setSelectedDOBErrorText("Please select date of birth");
      isValid = false;
    }
    return isValid;
  }

  const validateForgotPass = () => {
    let isValid: boolean = true;
    if (selectedDOB === null || selectedDOBError) {
      setSelectedDOBError(true);
      setSelectedDOBErrorText("Please select date of birth");
      isValid = false;
    }
    return isValid;
  };

  let handleSubmit = async () => {
    if (validatePass()) {
      // let mbc;
      // if (mobileCode === "") {
      //   mbc = existingCallingCode;
      // } else {
      //   mbc = mobileCode;
      // }

      let res = await loginservice.resetPass(
        existingCallingCode,
        loginMobile,
        otp,
        pass
      );
      if (res.message.status === "Success") {
        setModalText(t("forgetPass:resetPassSuccess"));
        handleShow();
        setModalType("success");
        setTimeout(() => {
          setModalText("");
          handleClose();
          history.push("/home/login");
        }, 3000);
      } else {
        setModalText(t("forgetPass:resetPassError"));
        handleShow();
        setModalType("error");
        setTimeout(() => {
          handleClose();
          setModalText("");
        }, 3000);
      }
    }
  };

  const back = () => {
    history.goBack();
  };

  let forgotPass = async () => {
    if (validateForgotPass()) {
      authCtx.toggleLoader(true);
      let res = await loginservice.forgotPassOtp(
        existingCallingCode,
        loginMobile,
        moment(selectedDOB).toISOString().slice(0, 10)
      );
      authCtx.toggleLoader(false);
      if (res && res.message && res.message.status === "Success") {
        setModalText(t("login:otpSendSuccess"));
        handleShow();
        setModalType("success");
        setIsActive(true);
        setTimeout(() => {
          setModalText("");
          handleClose();
          setIsOtpSent(true);
        }, 3000);
      } else if (res && res.message && res.message.status === "NotFound") {
        setModalText("User not found, please register");
        handleShow();
        setModalType("error");
        setTimeout(() => {
          setIsOtpSent(false);
          setModalText("");
          handleClose();
        }, 3000);
      } else if (res && res.message && res.message.status === "Invalid") {
        setModalText("Invalid DOB");
        handleShow();
        setModalType("error");
        setTimeout(() => {
          setIsOtpSent(false);
          setModalText("");
          handleClose();
        }, 3000);
      } else {
        setModalText(t("login:otpSendError"));
        handleShow();
        setModalType("error");
        setTimeout(() => {
          setIsOtpSent(false);
          setModalText("");
          handleClose();
        }, 3000);
      }
    }
  };

  return (
    <div>
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          back();
        }}
        className="mt-5 d-block text-decoration-none backLogin"
      >
        <i className="bi bi-arrow-left fs-3 me-2"></i>{" "}
        <span className="fs-5 position-relative t-n1">Back</span>
      </Link>
      <h3 className="mt-3 fw-500">{t("forgetPass:resetPass")}</h3>
      {!isOtpSent && (
        <div className="mb-3 mt-3">
          <label className="form-label">{t("forgetPass:resetTitle")}</label>
          <div className="input-group mt-2">
            {cList && existingCallingCode && (
              <Autocomplete
                id="country-select-demo"
                options={cList}
                disableClearable
                autoHighlight
                size="small"
                defaultValue={cList.find(
                  (x) => x.phoneCode === existingCallingCode
                )}
                onChange={(event, value) => {
                  setExistingCallingCode(value.phoneCode);
                }}
                filterOptions={createFilterOptions({
                  stringify: (option) => "+" + option.phoneCode + option.name,
                })}
                getOptionLabel={(option) => "+" + option.phoneCode}
                PaperComponent={({ children }) => (
                  <Paper style={{ minWidth: "327px" }}>{children}</Paper>
                )}
                style={{ minWidth: "100px" }}
                renderOption={(option) => (
                  <>
                    {option.name} (+{option.phoneCode})
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}
            <TextField
              autoComplete="off"
              id="loginMobile"
              label={t("login:mobile")}
              name="loginMobile"
              className="form-control"
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 10 }}
              value={loginMobile}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e: any) => {
                handleInputChange(e, setLoginMobile, setLoginMobileError);
              }}
            />
          </div>
          <div className="form-floating mt-3">
            <KeyboardDatePicker
              required
              autoOk={true}
              disableFuture
              size="medium"
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              id="dob"
              views={["year", "month", "date"]}
              label={t("profile:dob")}
              error={selectedDOBError}
              helperText={selectedDOBErrorText}
              value={selectedDOB}
              onChange={handleDOBChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="m-0 w-100"
            />
          </div>

          {loginMobileError && (
            <div className="invalid-feedback">Please enter valid number </div>
          )}

          <button
            type="submit"
            id="btnContinue"
            className="btn btn-primary w-100 mt-4 fs-18"
            disabled={!loginMobile}
            onClick={() => forgotPass()}
          >
            {" "}
            {t("login:genrateOTP")}
          </button>
        </div>
      )}

      {isOtpSent && (
        <div className="">
          {isOtpVerified && (
            <div className="">
              <div id="otp">
                <small className="text-muted mb-5">
                  {" "}
                  {t("otpTitle")}
                  <span className="fw-bolder">
                    {" "}
                    +{existingCallingCode}-
                    <span id="numberEntered">{loginMobile}</span>
                  </span>
                </small>
                <div className="mb-2 mt-4">
                  <TextField
                    autoComplete="off"
                    id="otp"
                    label={t("login:enterOTP")}
                    name="otp"
                    className="otp w-100 mb-3 letter-spacing-4"
                    variant="outlined"
                    size="medium"
                    inputProps={{ maxLength: 6 }}
                    error={otpError}
                    value={otp}
                    helperText={otpErrortext}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e: any) => {
                      handleInputChange(e, setOtp, setOtpError);
                    }}
                  />
                </div>
                <p className="text-muted">
                  {t("resendOtp")}{" "}
                  <span className="float-end">
                    <span className="minute">{minute}</span>
                    <span>:</span>
                    <span className="second">{second}</span>
                  </span>
                </p>

                <Link
                  to="#"
                  className={
                    isActive
                      ? " btn btn-link  p-0 disabled"
                      : " btn btn-link  p-0"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    forgotPass();
                  }}
                >
                  {" "}
                  {t("login:resendOtpLink")}
                </Link>

                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-4 fs-18"
                  disabled={!otp}
                  onClick={() => handleOtp()}
                >
                  {" "}
                  {t("login:continue")}
                </button>
              </div>
            </div>
          )}
          {!isOtpVerified && (
            <div>
              <div id="otp">
                <div className="mb-3 mt-4">
                  <div className="row">
                    <div className="form-group">
                      <TextField
                        id="pass"
                        name="pass"
                        label={t("forgetPass:newPass")}
                        className="w-100 mb-4"
                        variant="outlined"
                        size="small"
                        type={showNewPassword ? "text" : "password"}
                        error={passError}
                        helperText={PassErrorText}
                        value={pass}
                        onChange={(e: any) => {
                          handleInputChange(e, setPass, setPassError);
                        }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                onMouseDown={handleMouseDownNewPassword}
                              >
                                {showNewPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group">
                      <TextField
                        id="cPass"
                        name="cpass"
                        label={t("forgetPass:confirmPass")}
                        className="w-100 mb-4"
                        variant="outlined"
                        size="small"
                        type={showComparePassword ? "text" : "password"}
                        error={cpassError}
                        helperText={cPassErrorText}
                        value={cPass}
                        onChange={(e: any) => {
                          handleConfirmPasswordChange(e);
                        }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowComparePassword}
                                onMouseDown={handleMouseDownComparePassword}
                              >
                                {showComparePassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary w-100 mt-5"
                  disabled={!pass.length || !cPass.length || cpassError}
                  onClick={() => handleSubmit()}
                >
                  {" "}
                  {t("login:continue")}
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default ForgetPass;
