import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const timeSlotService = {
  getTimeSlot,
};

async function getTimeSlot(data: any) {
  const apiPath = API_ENDPOINTS.GET_TIMESLOT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      "facilityId": [data.facilityId],

      "department": [data.department],

      "physicianLicense": [data.physicianLicense],

      "physicianNo": [data.physicianNo],

      "providerLicence": "",

      "beginDate": data.beginDate,

      "endDate": data.endDate,
      
      "extensions": {
        "additionalProp1": "",
        "additionalProp2": "",
        "additionalProp3": "",
      }
      
    },
    customHeaders: {
      "Content-Type": "application/json",
    }
  };
  return await makeRequest(options);
}
