import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import { useState, useEffect } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import { appservice } from "../../services/app.service";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

function InternationalPatient() {
  const { t } = useTranslation(["internationalPatient"]);
  const [genderList, setGenderList] = useState<any>([]);

  const [fName, setfName] = useState<string>("");
  const [fNameError, setFnameError] = useState<boolean>(false);

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [mobile, setMobile] = useState<string>("");
  const [mobileError, setMobileError] = useState<boolean>(false);

  const [selectedTravelDate, setSelectedTravelDate] = useState<Date | null>(
    null
  );
  const [selectedTravelDateError, setSelectedTravelDateError] =
    useState<boolean>(false);
  const [selectedTravelDateErrorText, setSelectedTravelDateErrorText] =
    useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);

  const [doc, setDoc] = useState<string>("");
  const [docError, setDocError] = useState<boolean>(false);
  const [docErrorText, setDocErrorText] = useState<string>("");

  const [comment, setComment] = useState<string>("");
  const [commentError, setCommentError] = useState<boolean>(false);

  useEffect(() => {
    const getGenderList = async () => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
      setGender(listFromApi[0].id);
    };
    getGenderList();
    // eslint-disable-next-line
  }, []);
  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };
  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    errorSetterName(false);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };

  const handleTravelDateChange = (date: any | null) => {
    setSelectedTravelDate(date);
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date(), "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isBefore(today)) {
        setSelectedTravelDateError(true);
        setSelectedTravelDateErrorText(t("dobRangeError"));
      } else {
        setSelectedTravelDateError(false);
        setSelectedTravelDateErrorText("");
      }
    } else {
      setSelectedTravelDateError(true);
      setSelectedTravelDateErrorText(t("dobFormatError"));
    }
  };
  const handleFileChange = (e: any) => {
    const file = e.target.files[0]; // accesing file

    if (!file) return;
    setDocError(false);
    var ext = file.name.split(".").pop().toLowerCase();
    if (
      ext !== "pdf" &&
      ext !== "docx" &&
      ext !== "doc" &&
      ext !== "jpg" &&
      ext !== "jpeg" &&
      ext !== "png"
    ) {
      console.log("err");
      setDocErrorText(t("docFormatError"));
      setTimeout(() => {
        setDocErrorText("");
      }, 5000);
      return;
    }
    if (file.size > 2000 * 1024) {
      setDocErrorText(t("docSizeError"));
      setTimeout(() => {
        setDocErrorText("");
      }, 5000);
      return;
    }

    setDoc(file.name);
    let data = new FormData();
    data.append("document", file);
  };

  function validateForm() {
    let isValid: boolean = true;
    if (fName === "" || fNameError) {
      setFnameError(true);
      isValid = false;
    }
    if (gender === null || genderError) {
      setGenderError(true);
      isValid = false;
    }
    if (mobile === "" || mobileError) {
      setMobileError(true);
      isValid = false;
    }
    if (doc === "" || docError) {
      setDocError(true);
      isValid = false;
    }
    if (email === "" || emailError) {
      setEmailError(true);
      isValid = false;
    }
    if (comment === "" || commentError) {
      setCommentError(true);
      isValid = false;
    }

    if (selectedTravelDate === null || selectedTravelDateError) {
      setSelectedTravelDateError(true);
      isValid = false;
    }
    return isValid;
  }

  let handleSubmit = async () => {
    if (validateForm()) {
      console.log("valid");
    }
  };
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <div className="main-content animate py-4 px-3 px-sm-4">
      <div className="d-flex align-items-center mb-3">
        <a
          href="#"
          className="d-inline-block d-sm-none text-dark"
          onClick={(e) => openMobileMenu(e)}
        >
          <i className="material-icons-outlined me-2">menu</i>
        </a>
        <h3 className="fs-18-24">International Patient</h3>
        <div className="ms-auto">
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 ">
          <div className="card border-0 shadow-sm rounded-1rem overflow-hidden">
            {" "}
            <img
              src="/assets/images/int-banner.png"
              alt="banner"
              className="img-fluid "
            />
            <div className="card-body p-4 ln-24">
              <p>
                Our commitment to providing you with world-class health care
                remains steadfast, no matter where you're from or what language
                you speak.
              </p>
              <p>
                Your family and you will receive compassionate care and guidance
                from the committed professionals at Burjeel Medical City
                throughout your stay and treatment.We will make sure that your
                journey is customized every step of the way to meet you and your
                family's personal and cultural needs. Our teams are here to make
                your experience as comfortable as possible.
              </p>
              <p>
                Our International Patient Services team will help you and your
                family' with every aspect of your treatment at Burjeel Medical
                City, including scheduling appointments and procedures, helping
                you find a comfortable place to stay, managing insurance issues
                and more.
              </p>
              <h6 className="text-primary">Services include: </h6>
              <ul>
                <li>
                  Coordination of all aspects of your visit, with attention to
                  personal, cultural and travel-related needs
                </li>
                <li>Orientation for first-time patients and families.</li>
                <li>Assistance with patient registration/admission</li>
                <li>
                  Transportation arrangement (car rental, taxi and/or limo
                  services)
                </li>
                <li>Coordination with Lodging.</li>
                <li>Language Interpreters</li>
                <li>
                  Assistance with documentation (visa extensions, embassy
                  correspondence.Click here for information on the U.A.E Embassy
                  or Consulate closest to you.
                </li>
                <li>Other services</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-4 mt-md-0">
          <div className="card border-0 shadow-sm rounded-1rem">
            <div className="card-body p-4">
              <h6 className="fw-bold text-primary mb-3">Write Us</h6>
              <div className="form-floating mb-3">
                <TextField
                  required
                  id="fName"
                  label={t("fullName")}
                  className="w-100  "
                  variant="outlined"
                  size="small"
                  error={fNameError}
                  value={fName}
                  onChange={(e: any) => {
                    handleInputChange(e, setfName, setFnameError);
                  }}
                />
              </div>
              <div className="form-floating mb-3">
                <TextField
                  required
                  select
                  id="gender"
                  label={t("gender")}
                  className="w-100  "
                  variant="outlined"
                  size="small"
                  error={genderError}
                  value={gender}
                  onChange={(e: any) => {
                    setGender(e.target.value);
                    setGenderError(false);
                  }}
                >
                  {genderList &&
                    genderList.map((item: any) => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.text}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div className="form-floating mb-3">
                <TextField
                  required
                  id="mobile"
                  label={t("mobile")}
                  className="w-100 "
                  variant="outlined"
                  size="small"
                  type="tel"
                  inputProps={{ maxLength: 10 }}
                  error={mobileError}
                  value={mobile}
                  onChange={(e: any) => {
                    handleInputChange(e, setMobile, setMobileError);
                  }}
                />
              </div>
              <div className="form-floating mb-3">
                <TextField
                  required
                  id="email"
                  label={t("email")}
                  className="w-100  "
                  variant="outlined"
                  size="small"
                  error={emailError}
                  value={email}
                  onChange={(e: any) => {
                    handleInputChange(e, setEmail, setEmailError);
                  }}
                />
              </div>
              <div className="form-floating mb-3">
                {/* <label>Expected Travelling Date</label> */}
                <KeyboardDatePicker
                  required
                  autoOk={true}
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="dense"
                  id="TravelDate"
                  views={["year", "month", "date"]}
                  label={t("travelDate")}
                  error={selectedTravelDateError}
                  helperText={selectedTravelDateErrorText}
                  value={selectedTravelDate}
                  onChange={handleTravelDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className="m-0 w-100"
                />
              </div>

              <div className="form-floating mb-3">
                <TextField
                  required
                  id="comment"
                  label={t("comment")}
                  className="w-100  "
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                  rowsMax={4}
                  error={commentError}
                  value={comment}
                  onChange={(e: any) => {
                    handleInputChange(e, setComment, setCommentError);
                  }}
                />
                <small className="text-muted mt-1">Max 400 Letters</small>
              </div>
              <div className="form-floating mb-3">
                <button
                  className={
                    "btn btn-sm  fs-12 position-relative " +
                    (docError ? "btn-danger" : "btn-primary")
                  }
                >
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png,.doc,.docx,.pdf"
                    className="position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                    onChange={(e) => handleFileChange(e)}
                  />
                  {t("uploadMedicalReport")}
                </button>
                <div>{doc}</div>
                {docErrorText}
              </div>
              <button
                type="button"
                className="btn btn-primary float-end"
                onClick={() => handleSubmit()}
              >
                {t("submit")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InternationalPatient;
