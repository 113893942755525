import React from 'react'
import { Link, useLocation } from 'react-router-dom'



function ValetSideNav() {
const location = useLocation();
  return (
    <div className="left-menu position-fixed animate px-4 pt-4 pb-2 bg-white shadow vh-100 overflow-auto text-center">
         <Link to="#">
        <img
          src="/assets/images/burjeel.png"
          className="logo animate h-35p"
          alt="logo"
        />
      </Link>
         <ul className="menu p-0 mt-4">
        <li
          className={`d-block m-0 position-relative mb-3 ${
            location.pathname.toLowerCase().indexOf("valetauth/dashboard") > -1
              ? "active"
              : ""
          }`}
        >
          <Link
            to="/valetauth/dashboard"
            className="text-decoration-none d-block"
          >
             <i className="material-icons-outlined"> dashboard </i>
            <p className="m-0 fs-10">Dashboard</p>
          </Link>
        </li>
        </ul>
        <div className="bottom-copyright bottom-15">
        <ul className="menu p-0 m-0">
          <li className="d-block m-0 position-relative">
            {" "}
            <Link to="/valet/login" className="text-decoration-none d-block">
              <i className="material-icons-outlined"> power_settings_new </i>
              <p className="m-0 fs-10">Logout</p>
            </Link>{" "}
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ValetSideNav