import React, { useContext, useEffect, useState } from "react";
import { patientService } from "../../services/patient.service";
import AuthContext from "../../store/auth-context";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const UpcomingAppointmentsCard = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  //const [patientIdHISList, setPatientIdHISList] = useState<any>([]);
  //const [patientIdList, setPatientIdList] = useState<any>([]);

  const [upcomingAppt, setUpcomingAppts] = useState<any>([]);
  const [slideIndex, setSlideIndex] = useState<any>(0);
  const [slideTotalCount, setSlideTotalCount] = useState<any>(0);
  //const [familyMemberId, setFamilyMemberId] = useState<any>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  let upcomingAppointmentsArr: any = [];
  let consultedDoctorList: any = [];

  useEffect(() => {
    if (
      userData.isDOBAvailable &&
      userData.facilityId &&
      authCtx.locationGlobalList.length > 0
    )
      getPatientRec();

    return () => {
      setUpcomingAppts([]);
    };

    // eslint-disable-next-line
  }, [authCtx.isDobAvailable]);

  const handleModelClose = () => setShowModal(false);

  const getPatientRec = async () => {
    authCtx.toggleLoader(true);
    setUpcomingAppts([]);
    authCtx.setConsultedDoctorList([]);
    const currentHis: any = authCtx.locationGlobalList.find(
      (item: any) => item.locationId === userData.facilityId
    );
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const isPatientRecordCall = localStorage.getItem("isPatientRecordCall");
    if (
      Object.keys(patientRecord).length !== 0 &&
      isPatientRecordCall === "true"
    ) {
      getApptHistory(patientRecord);
    } else {
      let userData = JSON.parse(localStorage.getItem("user") || "{}");
      let res = await patientService.getPatientRec(
        userData.userId,
        0,
        userData.facilityId
      );
      let idList = res && res.data ? res.data : [];
      let patientIdHISList: any = [];
      if (res && res.data === undefined) {
        console.log("Somthing went wrong in response");
      } else if (res && res.status && res.status === 500) {
        console.log("Internal Server Error");
      } else {
        //setPatientIdList(idList);
        idList.forEach((item: any) => {
          if (
            item &&
            (item.patientId !== null || (item.mobileNo && item.mobileNo !== ""))
          ) {
            if (patientIdHISList.length > 0) {
              if (patientIdHISList.some((obj: any) => obj.his === item.his)) {
                patientIdHISList.forEach((item2: any) => {
                  if (
                    item2.his === item.his &&
                    item2.patientId !== item.patientId
                  ) {
                    item2.patientId = [...item2.patientId, item.patientId];
                  }
                });
              } else {
                let data = {
                  his: item.his,
                  patientId: [item.patientId],
                  mobileNo: item.mobileNo,
                };
                patientIdHISList.push(data);
              }
            } else {
              let data = {
                his: item.his,
                patientId: [item.patientId],
                mobileNo: item.mobileNo,
              };
              patientIdHISList.push(data);
            }
          }
        });
      }

      if (patientIdHISList.length > 0) {
        localStorage.setItem(
          "getPatientRecord",
          JSON.stringify(patientIdHISList)
        );
        localStorage.setItem("isPatientRecordCall", "true");
        getApptHistory(patientIdHISList);
      }
    }
    authCtx.toggleLoader(false);
  };

  const getApptHistory = async (idList: any) => {
    authCtx.toggleLoader(true);
    let tempArr = [...idList];
    const response = await patientService.apptHistory(idList[0]);
    upcomingAppointmentsArr = upcomingAppointmentsArr.concat(
      response.upcomingAppts
    );
    consultedDoctorList = consultedDoctorList.concat(response.consultedDoctors);
    tempArr.shift();

    if (tempArr.length > 0) {
      getApptHistory(tempArr);
    } else {
      if (upcomingAppointmentsArr && upcomingAppointmentsArr.length !== 0) {
        const filterdAppointment = upcomingAppointmentsArr.filter(
          (item: any) => {
            return item.facilityId === userData.facilityId;
          }
        );
        setUpcomingAppts(filterdAppointment);

        setSlideTotalCount(filterdAppointment.length);
      } else {
        setUpcomingAppts([]);
        authCtx.setConsultedDoctorList([]);
      }

      if (consultedDoctorList && consultedDoctorList.length > 0) {
        const filterdConsultedDoc = consultedDoctorList.filter((item: any) => {
          return item.locationId === userData.facilityId;
        });
        authCtx.setConsultedDoctorList(filterdConsultedDoc);
      } else {
        authCtx.setConsultedDoctorList([]);
      }
    }

    authCtx.toggleLoader(false);
  };

  const handleSlideIndex = (slideIndex: any) => {
    setSlideIndex(slideIndex + 1);
  };

  const handleTeleConsultation = async (item: any) => {
    authCtx.toggleLoader(true);
    const currentDate: any = new Date();
    const appointmentDate: any = new Date(item.apptDate + " " + item.apptTime);
    const timeDiff = diff_minutes(appointmentDate, currentDate);
    const threshold: any = process.env.REACT_APP_BEFORE_THRESHOLD_MINUTE;
    if (
      currentDate.toISOString().slice(0, 10) ===
      appointmentDate.toISOString().slice(0, 10)
    ) {
      if (timeDiff <= Number(threshold)) {
        const baseUrlForTele = process.env.REACT_APP_TELECONSULT_BASEURL;
        const loginURL: any = baseUrlForTele + "/web/api/default/sso-login";
        const APPID: any = process.env.REACT_APP_TELECONSULT_APPID_BURJEEL;
        let patientData: any = {};
        const user: any = authCtx.userProfileDetails;
        patientData = {
          name: user.firstName + user.lastName,
          gender: user.genderId === 1 ? "male" : "female",
          phone: user.mobile,
          emirates: user.documentId,
        };

        try {
          const res = await axios.post(loginURL, patientData, {
            headers: {
              "Content-Type": "application/json",
              APPID: APPID,
            },
          });
          if (res.status === 200 && res.data.success === true) {
            const authorization: any = res.data.data.access_token;
            const user_id = res.data.data.PatientProfile.user_id;
            const patient_profile_id = res.data.data.PatientProfile.id;
            const myGlobals = {
              baseURL: baseUrlForTele,
              APPID: APPID,
              Authorization: "Bearer" + " " + authorization,
              user_id: user_id,
              patient_profile_id: patient_profile_id,
              accessToken: authorization,
              apptDate: item.apptDate,
              apptTime: item.apptTime,
            };

            history.push({
              pathname: "/auth/Teleconsultation",
              state: myGlobals,
            });
          } else {
            setModalType("error");
            setModalText("Something went wrong");
            setShowModal(true);
          }
        } catch (error: any) {
          setModalType("error");
          setModalText("Something went wrong");
          setShowModal(true);
          authCtx.toggleLoader(false);
        }
      } else {
        setModalText(
          "You can start teleconsultation before 5 minute of your appointment time"
        );
        setModalType("error");
        setShowModal(true);
      }
    } else {
      setModalText(
        `Your teleconsultation appointment is scheduled on ${appointmentDate
          .toISOString()
          .slice(0, 10)}`
      );
      setModalType("error");
      setShowModal(true);
    }

    authCtx.toggleLoader(false);
  };

  // const handleTeleConsultation = async (item: any) => {
  //   authCtx.toggleLoader(true);
  //   const currentDate: any = new Date();
  //   const appointmentDate: any = new Date(item.apptDate + " " + item.apptTime);
  //   const timeDiff = diff_minutes(appointmentDate, currentDate);
  //   const threshold = 5;
  //   const baseUrlForTele = process.env.REACT_APP_TELECONSULT_BASEURL;
  //   const loginURL: any = baseUrlForTele + "/web/api/default/sso-login";
  //   const APPID: any = process.env.REACT_APP_TELECONSULT_APPID_BURJEEL;
  //   let patientData: any = {};
  //   const user: any = authCtx.userProfileDetails;
  //   patientData = {
  //     name: user.firstName + user.lastName,
  //     gender: user.genderId === 1 ? "male" : "female",
  //     phone: user.mobile,
  //     emirates: user.documentId,
  //   };

  //   try {
  //     const res = await axios.post(loginURL, patientData, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         APPID: APPID,
  //       },
  //     });
  //     if (res.status === 200 && res.data.success === true) {
  //       const authorization: any = res.data.data.access_token;
  //       const user_id = res.data.data.PatientProfile.user_id;
  //       const patient_profile_id = res.data.data.PatientProfile.id;
  //       const myGlobals = {
  //         baseURL: baseUrlForTele,
  //         APPID: APPID,
  //         Authorization: "Bearer" + " " + authorization,
  //         user_id: user_id,
  //         patient_profile_id: patient_profile_id,
  //         accessToken: authorization,
  //         apptDate: item.apptDate,
  //         apptTime: item.apptTime,
  //       };

  //       history.push({
  //         pathname: "/auth/Teleconsultation",
  //         state: myGlobals,
  //       });
  //     } else {
  //       setModalType("error");
  //       setModalText("Something went wrong");
  //       setShowModal(true);
  //     }
  //   } catch (error: any) {
  //     setModalType("error");
  //     setModalText("Something went wrong");
  //     setShowModal(true);
  //     authCtx.toggleLoader(false);
  //   }

  //   authCtx.toggleLoader(false);
  // };

  function diff_minutes(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  return (
    <>
      <div className="card border-0 shadow-sm rounded-1rem mb-4 position-relative">
        <div className="card-body p-4 ">
          <h6 className="fw-bold text-primary">Upcoming Appointments</h6>
          <Carousel
            controls={false}
            fade
            interval={3000}
            pause={"hover"}
            slide={true}
            variant={"dark"}
            indicators={false}
            nextLabel={"Next"}
            onSelect={(e) => handleSlideIndex(e)}
          >
            {upcomingAppt !== undefined &&
              upcomingAppt.length > 0 &&
              upcomingAppt.map((item: any, index: any) =>
                item ? (
                  <Carousel.Item key={index}>
                    <div className="d-flex mt-3 align-items-center">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            item && item.image
                              ? item.image
                              : "/assets/images/doc.png"
                          }
                          className="img-57 rounded-circle dr-photo"
                          alt=""
                        />
                      </div>

                      <div className="flex-grow-1 ms-3">
                        <label className="fw-bold">
                          {item && item.physicianName
                            ? item.physicianName
                            : null}
                        </label>
                        {item.scheduleType === "TELEMED" ? (
                          <div>
                            <button
                              className="btn btn-primary no-focus btn-sm mt-1"
                              onClick={(e) => handleTeleConsultation(item)}
                            >
                              Start Teleconsultation
                            </button>
                          </div>
                        ) : (
                          <>
                            <p className="text-muted fs-12 m-0">
                              {item && item.specialty ? item.specialty : null}
                            </p>
                            <p className="text-muted fs-12 m-0">
                              {item && item.location ? item.location : null}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="d-flex">
                      {item && item.apptTime && item.apptDate ? (
                        <p className="mt-3 mb-0">
                          {" "}
                          {new Date(
                            "1970-01-01T" + item.apptTime + "Z"
                          ).toLocaleTimeString("en-US", {
                            timeZone: "UTC",
                            hour12: true,
                            hour: "numeric",
                            minute: "numeric",
                          })}{" "}
                          |{" "}
                          {item && item.apptDate
                            ? new Date(item.apptDate).toDateString()
                            : null}
                        </p>
                      ) : null}
                      <p className="ms-auto mt-3 mb-0">
                        {" "}
                        {slideIndex}/{slideTotalCount}
                      </p>
                    </div>
                  </Carousel.Item>
                ) : (
                  <Carousel.Item key={index}>
                    <div className="mh-240p d-flex justify-content-center align-items-center">
                      <div className="text-center text-999 mt-4">
                        {" "}
                        <i className="material-icons-outlined fs-1"> info </i>
                        <p className="fs-6">No Upcoming Appointments</p>
                      </div>
                    </div>
                  </Carousel.Item>
                )
              )}
          </Carousel>
          {upcomingAppt && upcomingAppt.length === 0 && (
            <div className="mh-240p d-flex justify-content-center align-items-center">
              <div className="text-center text-999 mt-4">
                {" "}
                <i className="material-icons-outlined fs-1"> info </i>
                <p className="fs-6">No Upcoming Appointments</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModelClose}
        >
          <Alert onClose={handleModelClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default UpcomingAppointmentsCard;
