import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { appservice } from "../../services/app.service";
import { notificationService } from "../../services/notification.service";
import { profileService } from "../../services/profile.service";
import { dashboardservice } from "../../services/dashboard.service";
import AuthContext from "../../store/auth-context";
import TextField from "@material-ui/core/TextField";
import * as xlsx from "xlsx";
import * as fs from "file-saver";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { finddoctorservice } from "../../services/findDoctor.services";
import { Button } from "react-bootstrap";

const PublishNewBroadcast = (props: any) => {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["notification"]);

  const [notificationTypelist, setNotificationTypeList] = useState<any>([]);

  const [textAreaCount, setTextAreaCount] = useState<number>(0);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [NotificationTextMessage, setNotificationTextMessage] =
    useState<string>("");
  const [notificationTextError, setNotificationTextError] =
    useState<boolean>(false);

  const [Title, setNotificationTitle] = useState<string>("");
  const [TitleError, setTitleError] = useState<boolean>(false);
  const [TitleErrorText, setTitleErrorText] = useState<any>("");

  const [InURLPath, setInURLPath] = useState<string>("");
  const [InURLPathError, setInURLPathError] = useState<boolean>(false);
  const [InURLPathErrorText, setInURLPathErrorText] = useState<string>("");

  const brandName = localStorage.getItem("brandName");

  const [notificationType, setNotificationType] = useState<string>("2");

  const [userExcelData, setuserExcelData] = useState<string[]>();
  const [excelError, setExcelError] = useState<string>("");
  const [excelFile, setExcelFile] = useState<null>();
  const [excelValidated, setexcelValidated] = useState<boolean>(false);
  // const [excelMobileCount,SetExcelMobileCount] = useState<number>(0);
  const [sendTo, setSendTo] = useState<string>("toExcel");

  const [facilityId, setFacilityId] = useState<string>("");

  let userInfo = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    //Notification Type List
    if (props.rePublishItem) {
      setNotificationType(props.rePublishItem.notificationType);
      setInURLPath(props.rePublishItem.callbackURL);
      setNotificationTitle(props.rePublishItem.notificationTitle);
      setNotificationTextMessage(props.rePublishItem.message);
    }
    const getNotificationTypeList = async () => {
      const listFromApi = await fetchNotificationTypeList();
      if (listFromApi.length > 0) setNotificationTypeList(listFromApi);
      else setNotificationTypeList([]);
    };
    getNotificationTypeList();
  }, []);

  const fetchNotificationTypeList = async () => {
    let res = await appservice.SetNotificationType();
    return res;
  };

  const handleTextAreaChange = (e: any) => {
    setTextAreaCount(e.target.value.length);
    if (e.target.value.length > 0) {
      setNotificationTextMessage(e.target.value);
      setNotificationTextError(false);
    } else {
      setNotificationTextError(true);
      setNotificationTextMessage("");
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  function validateForm() {
    let isValid: boolean = true;

    if (Title === null || Title === "") {
      setTitleError(true);
      setTitleErrorText("Please enter notification title");
      isValid = false;
    }

    if (sendTo === "toExcel") {
      // if (InURLPath === "") {
      //   setInURLPathError(true);
      //   setInURLPathErrorText(t("urlError"));
      //   isValid = false;
      // }
      if (InURLPath !== "") {
        const pattern = /^(https?:\/\/)/;
        if (!InURLPath.match(pattern)) {
          setInURLPathError(true);
          setInURLPathErrorText(t("urlError"));
          isValid = false;
        }
      }

      if (NotificationTextMessage === null || NotificationTextMessage === "") {
        setNotificationTextError(true);
        isValid = false;
      }
      if (!excelValidated && facilityId === "") {
        setExcelError("Please select excel with valid data");
        isValid = false;
      }
    }

    return isValid;
  }

  let handleSubmit = async () => {
    let data;
    if (validateForm()) {
      authCtx.toggleLoader(true);
      data = {
        FacilityId: facilityId === "All" ? "" : facilityId,
        NotificationType: notificationType,
        NotificationTitle: Title,
        InURLPath: btoa(InURLPath),
        PhysicianNo: "",
        NotificationText: NotificationTextMessage,
        FromDate: "",
        ToDate: "",
        ImageURL: "",
        BrandName: brandName,
        DoctorName: "",
        Type: sendTo,
        Mobilelist: userExcelData?.toString(),
      };

      let res = await notificationService.BroadcastNotification(data);
      authCtx.toggleLoader(false);
      if (res.message.status === "Success") {
        setModalType("success");
        setShowModal(true);
        setModalText(res.message.message);
        authCtx.toggleLoader(false);

        setTimeout(() => {
          setModalText("");
          handleClose();
          props.handleAddClose();
          props.setRefreshPage(!props.refreshPage);
          //window.location.reload()
        }, 1000);
      } else {
        setNotificationTextError(res.message.message);
      }
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    const pattern = /^(https?:\/\/)/;

    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value !== "") {
        if (!e.target.value.match(pattern)) {
          errorSetterName(true);
          setInURLPathErrorText(t("urlError"));
        }
      } else {
        errorSetterName(false);
        setInURLPathErrorText("");
      }
    }
  };

  const xlsxchangeHandler = (e: any) => {
    setExcelError("");
    setuserExcelData([]);
    setExcelFile(null);
    var IsError = 0;

    const file = e.target.files[0]; // accesing file
    if (!file) return;
    var ext = file.name.split(".").pop().toLowerCase();
    if (ext !== "xlsx") {
      IsError = 1;
      setExcelError("Please select xlsx file.");
      return;
    }

    const reader = new FileReader();
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{2})$/;
    var re1 = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{3})$/;
    var re2 = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = xlsx.read(data, { type: "binary" });
      const SheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[SheetName];
      const jsonData = xlsx.utils.sheet_to_json(sheet);

      const mobileNumbers: string[] = [];

      const fmobileNumbers: string[] = [];

      if (jsonData.length === 0) {
        IsError = 1;
        setExcelError("Invalid/Empty file, please upload a correct file.");
        return;
      }

      jsonData.forEach((row: any) => {
        if (row["Mobile No"] === null || row["Country Code"] === null) {
          IsError = 1;
          setExcelError("Invalid/Empty file, please upload a correct file.");
          return;
        }

        if (
          re.test(row["Mobile No"]) ||
          re1.test(row["Mobile No"]) ||
          re2.test(row["Mobile No"])
        ) {
          const mobile = row["Country Code"] + "-" + row["Mobile No"];
          if (mobile.length >= 7 && mobile.length < 15) {
            mobileNumbers.push(mobile);
          } else {
            fmobileNumbers.push(row["Country Code"] + "-" + row["Mobile No"]);
          }
        } else {
          fmobileNumbers.push(row["Country Code"] + "-" + row["Mobile No"]);
        }
      });

      if (fmobileNumbers.length !== 0) {
        IsError = 1;
      }

      if (IsError === 0) {
        setExcelFile(file.name);
        setuserExcelData(mobileNumbers);
        setExcelError("");
        setexcelValidated(true);
      } else {
        setExcelFile(null);
        setexcelValidated(false);
        setuserExcelData(fmobileNumbers);
        setExcelError("Invalid/Empty file, please upload a correct file.");
      }

      // SetExcelMobileCount(mobileNumbers.length);
    };
  };
  const clearExcelSelection = () => {
    setuserExcelData([]);
    // SetExcelMobileCount(0);
  };
  const downloadFormat = () => {
    let headers: string[] = [];
    headers = ["Country Code", "Mobile No"];

    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();
    // Frozen worksheet
    const worksheet = workbook.addWorksheet("NotifyUser");
    //Adding Header Row
    let headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell: any, number: any) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4167B8" },
        bgColor: { argb: "" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "UploadMobileNo.xlsx");
    });
  };

  const downloadFakeMobile = () => {
    let headers: string[] = [];
    headers = ["Country Code", "Mobile No"];

    const ExcelJS = require("exceljs");
    const workbook = new ExcelJS.Workbook();

    const dataForExcel = [];
    // for (let i = 0; i < userExcelData.length; i++) {
    //   const item = userExcelData[i];
    //   const field = {
    //     appointmentRefNo: item.appointmentRefNo,
    //     Patient: item.patientName,

    //     }
    //   }

    // Frozen worksheet
    const worksheet = workbook.addWorksheet("NotifyUser");
    //Adding Header Row
    let headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell: any, number: any) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4167B8" },
        bgColor: { argb: "" },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    workbook.xlsx.writeBuffer().then((data: any) => {
      let blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, "WrongMobileNo.xlsx");
    });
  };

  const handleFacilityChange = (e: any) => {
    setFacilityId(e.target.value);
  };

  return (
    <>
      <div className="row p-1 mt-2">
        <div className="col-md-6 col-sm-12">
          <div className="form-floating mb-3">
            <TextField
              required
              select
              id="notificationType"
              label={t("Type Notification")}
              className="w-100  "
              variant="outlined"
              size="small"
              value={"2"}
            >
              <MenuItem value="2" key="2">
                In URL
              </MenuItem>
            </TextField>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="form-floating mb-3">
            <TextField
              id="InURLPath"
              label={t("In URL Path")}
              className="w-100  "
              variant="outlined"
              size="small"
              error={InURLPathError}
              helperText={InURLPathErrorText}
              value={InURLPath}
              onChange={(e: any) => {
                handleInputChange(e, setInURLPath, setInURLPathError);
              }}
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <TextField
            required
            id="Title"
            label={t("Title")}
            className="w-100  "
            variant="outlined"
            size="small"
            error={TitleError}
            helperText={TitleErrorText}
            value={Title}
            inputProps={{ maxLength: 30 }}
            onChange={(e: any) => {
              setNotificationTitle(e.target.value);
              setTitleError(false);
              setTitleErrorText("");
            }}
          ></TextField>
        </div>
        <div className="col-md-6 col-sm-12">
          <TextField
            select
            id="facility"
            label="Select Hospital"
            className="w-100  "
            variant="outlined"
            size="small"
            value={facilityId}
            onChange={(e) => handleFacilityChange(e)}
            disabled={props.rePublishItem !== ""}
          >
            <MenuItem value="" key="SelectFacility">
              Custom
            </MenuItem>
            <MenuItem value="All" key="Allfacility">
              All
            </MenuItem>

            {authCtx.locationGlobalList &&
              authCtx.locationGlobalList.map((item: any, index: any) => (
                <MenuItem value={item.locationId} key={item.locationId + index}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div className="col-md-6 col-sm-12 mt-2">
          <div className="">
            <Button
              className="btn  btn-sm btn-primary fs-12 position-relative"
              variant="outlined"
              disabled={facilityId !== ""}
            >
              <input
                type="file"
                accept=".xlsx"
                className="p-1 position-absolute w-100 h-100 opacity-0 left-0 top-0 cursor-pointer"
                onChange={(e) => xlsxchangeHandler(e)}
              />
              {t("Upload Excel")}
            </Button>
            <span className="px-2 fs-13">{excelFile} </span>
            <span className="font-f44336 fs-13">{excelError}</span>
            {/* {excelValidated===false &&(
                  <>
                  <span>Click to download</span>
                  </>
                  
                )} */}
          </div>
          <div className="rg ref fs-13" onClick={downloadFormat}>
            <Link to="#" className="d-flex text-decoration-none my-2">
              <span className="material-icons-outlined fs-16 me-1">
                file_download
              </span>
              <span className="">Download Sample Excel</span>
            </Link>
          </div>
        </div>
      </div>

      <div className="row-sm p-1 ">
        <div className="col mb-3">
          <TextField
            required
            id="outlined-multiline-static"
            label="Write Message"
            multiline
            rows={3}
            className="form-control  "
            variant="outlined"
            size="medium"
            error={notificationTextError}
            value={NotificationTextMessage}
            inputProps={{ maxLength: 120, minLength: 2 }}
            onChange={(e) => handleTextAreaChange(e)}
          />
          <div className="row">
            <div className="col fs-13 align-right">{textAreaCount}/120</div>
          </div>
        </div>
      </div>
      <div className="row g-sm-3 p-1">
        <div className=" text-end col-sm">
          <button
            className="btn btn-secondary mx-2"
            onClick={() => props.handleAddClose()}
          >
            {t("Close")}
          </button>

          <button className="btn btn-primary" onClick={() => handleSubmit()}>
            {t("Publish Now")}
          </button>
        </div>

        {modalType === "error" && (
          <Snackbar
            open={showModal}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {modalText}
            </Alert>
          </Snackbar>
        )}
        {modalType === "success" && (
          <Snackbar
            open={showModal}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="success">
              {modalText}
            </Alert>
          </Snackbar>
        )}
      </div>
    </>
  );
};

export default PublishNewBroadcast;
