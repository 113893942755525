import axios from "axios";

import queryString from "query-string";
import _, { forEach } from "lodash";
import { FETCH_TIMEOUT, baseUrl } from "../constants/apiConfig";

import moment from "moment";
import clearWebStorage from "../components/clearWebStorage";

const axiosInstance: any = axios.create();
let isRefreshTokenFetching: any = false;
let failedApis: any = [];

axiosInstance.interceptors.request.use(
  async (config: any) => {
    const token = await localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// const responseIntercepter = axiosInstance.interceptors.response.use(
//   (response: any) => {
//     return response;
//   },
//   async (error: any) => {
//     try {
//       const originalRequest = error?.config;

//       if (error.response?.status === 401 && !originalRequest?._retry) {
//         originalRequest._retry = true;
//         const newAccessToken = await handlerefreshToken();
//         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//         return axiosInstance(originalRequest);
//       }
//     } catch (err) {
//       clearWebStorage();
//       window.location.replace(window.location.origin + "/home/login");
//     }

//     return Promise.reject(error);
//   }
// );

const timeoutPromise = (timeout: any) =>
  new Promise((resolve, reject) =>
    setTimeout(() => reject(new Error("Network timeout")), timeout)
  );

const fetchData = async (url: string, options: any) => {
  try {
    const fetchResult = await Promise.race([
      axiosInstance.request(url, options),
      timeoutPromise(FETCH_TIMEOUT),
    ]);

    return handleResponse(fetchResult);
  } catch (error: any) {
    if (error && error.response && error.response.status === 401) {
      clearWebStorage();
      window.location.replace(window.location.origin + "/home/login");
    }

    let errorMessage =
      error && error.response && error.response.data
        ? error.response.data.error
          ? error.response.data.error.toString()
          : error.response.data.error
          ? error.response.data.error
          : ""
        : "";
    return {
      message: error.message,
      status:
        error && error.response && error.response.status
          ? error.response.status
          : error.response,
      statusText:
        error && error.response && error.response.statusText
          ? error.response.statusText
          : error.response,
      error: errorMessage,
      errorResponse: error.response,
    };
  }
};

function handleResponse(response: any) {
  const contentType = _.get(response, "headers.content-type", "");
  if (contentType && contentType.includes("application/json")) {
    return response ? response.data : [];
  }
  if (
    (contentType && contentType.includes("text/plain")) ||
    contentType.includes("text/html")
  ) {
    return response ? response.text : "";
  }
  if (contentType && contentType.includes("application/pdf")) {
    return response ? response.blob : "";
  }
  throw new Error(`Sorry, content-type ${contentType} not supported`);
}

// async function handlerefreshToken() {
//   let token = localStorage.getItem("token");
//   let refreshToken = localStorage.getItem("refreshToken");
//   if (token && refreshToken) {
//     try {
//       const res = await axios.post(baseUrl + "/auth/refreshtoken", {
//         token: token,
//         refreshToken: refreshToken,
//       });

//       if (res && res.data.message.status === "Success") {
//         localStorage.setItem("token", res.data.data.token);
//         localStorage.setItem("refreshToken", res.data.data.refreshToken);
//         localStorage.setItem("tokenExpiresAt", res.data.data.tokenExpiresAt);
//         localStorage.setItem(
//           "refreshExpiresAt",
//           res.data.data.refreshExpiresAt
//         );
//         return res.data.data.token;
//       } else {
//         clearWebStorage();
//         window.location.replace(window.location.origin + "/home/login");
//       }
//     } catch (error: any) {
//       clearWebStorage();
//       window.location.replace(window.location.origin + "/home/login");
//     }
//   } else {
//     clearWebStorage();
//     window.location.replace(window.location.origin + "/home/login");
//   }
// }

export const makeRequest = async ({
  cors_url,
  path,
  method,
  customHeaders,
  queryParams,
  bodyObj,
  formdata,
}: any) => {
  const apiHeaders: any = customHeaders;
  apiHeaders["Invoking_Channel"] = "PATIENTPORTA";
  const { url, query } = queryString.parseUrl(path);
  const newQueryString = queryString.stringify({ ...query, ...queryParams });
  let newURL = newQueryString ? `${url}?${newQueryString}` : url;
  const data = bodyObj ? JSON.stringify(bodyObj) : formdata ? formdata : null;
  if (!_.isEmpty(cors_url)) {
    newURL = cors_url + newURL;
  }
  return await fetchData(newURL, {
    method,
    headers: apiHeaders,
    data: data,
  });
};

export const makeRequestForTele = async ({
  cors_url,
  path,
  method,
  customHeaders,
  queryParams,
  bodyObj,
  formdata,
}: any) => {
  const apiHeaders: any = customHeaders;
  apiHeaders["Invoking_Channel"] = "TELECONSULT";
  const { url, query } = queryString.parseUrl(path);
  const newQueryString = queryString.stringify({ ...query, ...queryParams });
  let newURL = newQueryString ? `${url}?${newQueryString}` : url;
  const data = bodyObj ? JSON.stringify(bodyObj) : formdata ? formdata : null;
  if (!_.isEmpty(cors_url)) {
    newURL = cors_url + newURL;
  }
  return await fetchData(newURL, {
    method,
    headers: apiHeaders,
    data: data,
  });
};
