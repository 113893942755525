import React from "react";
import { Link } from "react-router-dom";

function AppointmentBooked(props: any) {
  return (
    <>
      <div className="modal-body px-4 pt-0 pb-1 text-center">
        <img
          src="/assets/images/check.png"
          className="my-4"
          style={{ height: 120 }}
          alt="check"
        />

        <h4 className="mb-2 mt-0">Appointment {props.appointmentText}</h4>
        <p className="text-dark mb-1 fw-500 mt-1">
          Appointment Ref. No.: {props.apptRefNumber}
        </p>
        {props.appointmentDoctor.map((item: any) => (
          <div className="mt-4">
            <img
              src={item.image}
              className="img-h-75 rounded-circle dr-photo"
              alt="dr-img"
            />
            <h6 className="mt-3 mb-1">
              {props.book === "Book" ? item.name : item.physicianName}
            </h6>
            <p className="text-muted mb-1">{item.specialty}</p>
            <p className="text-dark mb-1 fw-500 mt-3">
              {props.timeSlot}, {new Date(props.beginDate).toDateString()},{" "}
              {item.location}
            </p>
            <p className="text-dark mb-1 fw-500">
              Patient Name: <span className="fw-bold">{props.patientName}</span>
            </p>
          </div>
        ))}

        <div className="p-4">
          <Link to="/auth/appointments" className="btn btn-primary">
            Close
          </Link>
        </div>
      </div>
    </>
  );
}

export default AppointmentBooked;
