import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { dashboardservice } from "../../services/dashboard.service";
import { profileService } from "../../services/profile.service";
import clearWebStorage from "../clearWebStorage";

const UserMenu = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const brandName = localStorage.getItem("brandName");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  const [baseImage, setBase64Image] = useState<any>("");

  const [userInfo, setUserInfo] = useState<any>({
    firstName: " ",
    gender: " ",
    lastName: " ",
    profileImgUrl: " ",
  });

  const uaePassLogout: any = process.env.REACT_APP_UAEPASS_LOGOUT;

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user") ?? "");
    setUserInfo(user);
    const getProfileImage = async () => {
      if (user.profileImgUrl !== "") {
        getImageBase64(user.profileImgUrl, user.profileImgFileName);
      }
    };
    getProfileImage();
  }, []);

  let logout = async (e: any) => {
    e.preventDefault();
    authCtx.toggleLoader(true);
    await dashboardservice.logout();
    authCtx.toggleLoader(false);
    authCtx.setMobile("", "");
    authCtx.removeUserInfo();
    authCtx.removeApiToken();

    if (localStorage.getItem("uaeUser") === "1") {
      localStorage.setItem("uaeUser", "0");
      window.location.replace(uaePassLogout);
    } else {
      history.push("/home/login");
    }
    clearWebStorage();
    if (brandName === "burjeel" && userData.facilityId === "BM10") {
      let theme: any = document.getElementById("theme");
      theme.href = "/css/theme-burjeel.css";
      document.title = "Burjeel | Patient Portal";
    }
  };

  const getImageBase64 = async (imageUrl: any, fileName: any) => {
    // let res = await profileService.getProfileDocument(imageUrl, fileName);
    if (fileName) {
      let res = await profileService.getProfileDocument(null, fileName);
      setBase64Image(res);
    }
  };

  return (
    <div className="dropdown caret-none caret-sm-inline-block d-inline-block ms-sm-3 ms-2 t-n10">
      <div
        className="dropdown-toggle d-block text-dark text-decoration-none fw-bold cursor-pointer"
        data-bs-toggle="dropdown"
      >
        <img
          src={
            userInfo.profileImgUrl
              ? `data:image/jpeg;base64,${baseImage}`
              : "/assets/images/user.png"
          }
          className="img-36 rounded-circle me-sm-2"
          alt=""
        />
        <span className="d-none d-sm-inline-block">
          {" "}
          Hi {userInfo.firstName}
        </span>
      </div>
      <ul className="dropdown-menu shadow-sm border-0 mt-2 fs-12">
        <li>
          <Link
            to="/auth/profile"
            className="dropdown-item d-flex align-items-center py-2"
          >
            <i className="material-icons-outlined me-2"> person_outline </i>
            My Profile
          </Link>
        </li>
        <li>
          <Link
            to="/auth/needhelp"
            className="dropdown-item d-flex align-items-center py-2"
          >
            <i className="material-icons-outlined me-2"> support </i>
            Need Help ?
          </Link>
        </li>
        <li>
          <Link
            to="/auth/feedback"
            className="dropdown-item d-flex align-items-center py-2"
          >
            <i className="material-icons-outlined me-2"> rate_review </i>
            Feedback / Complaint
          </Link>
        </li>
        <li>
          <Link
            to="/"
            onClick={logout}
            className="dropdown-item d-flex align-items-center py-2"
          >
            <i className="material-icons-outlined me-2"> power_settings_new </i>
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
