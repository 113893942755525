import { useState, useEffect } from "react";
import {
  InputAdornment,
  MenuItem,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { appservice } from "../../services/app.service";

import { useTranslation } from "react-i18next";

function Bmi() {
  const { t } = useTranslation(["healthTool"]);

  const [genderList, setGenderList] = useState<any>([]);

  const [severity, setseverity] = useState<string>("normal");
  const [ageError, setAgeError] = useState<boolean>(false);

  const [height, setHeight] = useState<string>("");
  const [heightError, setHeightError] = useState<boolean>(false);
  const [heightErrorText, setHeightErrorText] = useState<string>("");

  const [weight, setWeight] = useState<string>("");
  const [weightError, setWeightError] = useState<boolean>(false);

  const [age, setAge] = useState<string>("");

  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);

  const [bmi, setBmi] = useState<string>("");
  const [bmiMsg, setMsg] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => {
    const getGenderList = async () => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
      setGender(listFromApi[0].id);
    };
    getGenderList();
    // eslint-disable-next-line
  }, []);
  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };

  const handleClose = () =>{
    setShowModal(false);
  }
  const handleShow = () => setShowModal(true);

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    errorSetterName(false);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
      if (e.target.name === "height") {
        if (e.target.value < 30 || e.target.value > 340) {
          errorSetterName(true);
          setHeightErrorText(t("heightRangeError"));
        } else {
          errorSetterName(false);
          setHeightErrorText("");
        }
      }
    }
  };

  function validateForm() {
    let isValid: boolean = true;
    if (age.trim() === "" || ageError) {
      setAgeError(true);
      isValid = false;
    }
    if (height.trim() === "" || heightError) {
      setHeightError(true);
      isValid = false;
    }
    if (gender === "" || genderError) {
      setGenderError(true);
      isValid = false;
    }
    if (weight.trim() === "" || weightError) {
      setWeightError(true);
      isValid = false;
    }
    return isValid;
  }

  let calculateBMI = () => {
    if (validateForm()) {
      let h = +height;
      let w = +weight;
      if (h !== 0 && w !== 0) {
        let heightInMeter = h / 100;
        var result = w / (heightInMeter * heightInMeter); //calculate
        let r = String((Math.round(result * 100) / 100).toFixed(2));
        setBmi(r);
        result = parseInt(result.toFixed(2)); //rounding off
        if (result < 16) {
          setseverity("high");
          setMsg(t("severeThinness"));
        } else if (result >= 16 && result < 17) {
          setMsg(t("moderateThinness"));
          setseverity("medium");
        } else if (result >= 17 && result < 18.5) {
          setseverity("medium");
          setMsg(t("mildThinness"));
        } else if (result >= 18.5 && result < 25) {
          setseverity("normal");
          setMsg(t("normal"));
        } else if (result >= 25 && result < 30) {
          setMsg(t("overweight"));
          setseverity("medium");
        } else if (result >= 30 && result < 35) {
          setMsg(t("obeseClassI"));
          setseverity("high");
        } else if (result >= 35 && result < 40) {
          setMsg(t("obeseClassII"));
          setseverity("high");
        } else if (result >= 40) {
          setseverity("high");
          setMsg(t("obeseClassIII"));
        } else {
          setMsg("");
        }
      } else {
        setMsg("");
      }
      handleShow();
    }
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <div className="row">
            <div className="col-sm mb-3">
              <TextField
                required
                id="age"
                label={t("age")}
                className="w-100  "
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 3 }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                error={ageError}
                value={age}
                onChange={(e: any) => {
                  handleInputChange(e, setAge, setAgeError);
                }}
              />
            </div>
            <div className="col-sm mb-3">
              <TextField
                required
                select
                id="gender"
                label={t("gender")}
                className="w-100  "
                variant="outlined"
                size="small"
                error={genderError}
                value={gender}
                onChange={(e: any) => {
                  setGender(e.target.value);
                  setGenderError(false);
                }}
              >
                { genderList && genderList.map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.text}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          <div className="row">
            <div className="col-sm mb-3">
              <div className="input-group">
                <TextField
                  label={t("height")}
                  className="w-100 "
                  id="height"
                  helperText={heightErrorText}
                  required
                  inputProps={{ maxLength: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">cm</InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  size="small"
                  error={heightError}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  name="height"
                  value={height}
                  onChange={(e: any) => {
                    handleInputChange(e, setHeight, setHeightError);
                  }}
                />
              </div>
              <small className="text-muted">
                ( {t("minHeight")} - {t("maxHeight")})
              </small>
            </div>
            <div className="col-sm mb-3">
              <div className="input-group">
                <TextField
                  label={t("weight")}
                  className="w-100 "
                  id="weight"
                  required
                  inputProps={{ maxLength: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">kg</InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  variant="outlined"
                  size="small"
                  error={weightError}
                  value={weight}
                  onChange={(e: any) => {
                    handleInputChange(e, setWeight, setWeightError);
                  }}
                />{" "}
              </div>
            </div>
          </div>

          <button
            className="btn btn-primary float-end"
            onClick={() => calculateBMI()}
          >
            {t("calculateBMI")}
          </button>
        </div>
        <div className="col-md-4"> </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("result")}
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {bmiMsg && (
            <div
              className={
                severity === "normal"
                  ? "alert alert-info"
                  : severity === "medium"
                  ? "alert alert-warning"
                  : "alert alert-danger"
              }
            >
              <p className="mb-1 fs-6">
                {" "}
                Your Health <span className="fw-bold">{bmi}</span> Kg/m2. (
                {bmiMsg})
              </p>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Bmi;
