import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const userService = {
  getUserList,
  addUser,
  resetPassword,
  getUserRoleList,
  saveUserDOB,
  saveUserFacilityId,
};

async function getUserList(skip: number, take: number, filterKey: string = "") {
  const apiPath =
    API_ENDPOINTS.GET_USER_LIST +
    "?skip=" +
    skip +
    "&take=" +
    take +
    "&filterKey=" +
    filterKey;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
// async function deleteAgent(agentId: string) {
//     const apiPath = API_ENDPOINTS.DELETE_AGENT + agentId;
//     const options = {
//       path: apiPath,
//       method: "DELETE",
//       bodyObj: {},
//       customHeaders: {
//         "Content-Type": "application/json",
//       },
//     };
//     return await makeRequest(options);
//   }

async function addUser(data: any) {
  const apiPath = API_ENDPOINTS.ADD_ADMIN;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      email: data.email,
      // "CallingCode": data.CallingCode,
      // "Mobile": data.mobile
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function resetPassword(userId: any) {
  const apiPath = API_ENDPOINTS.RESET_PASSWORD + "?userId=" + userId;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getUserRoleList() {
  const apiPath = API_ENDPOINTS.GET_USER_ROLE;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function saveUserDOB(dateOfBirth: any) {
  const apiPath = API_ENDPOINTS.SAVE_USER_DOB;
  const options = {
    path: apiPath,
    method: "PUT",
    bodyObj: {
      dateOfBirth: dateOfBirth,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function saveUserFacilityId(facilityId: any) {
  const apiPath = API_ENDPOINTS.SAVE_PREFERRED_FACILITY;
  const options = {
    path: apiPath,
    method: "PUT",
    bodyObj: {
      facilityId: facilityId,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
