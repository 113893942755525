import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, ...rest }: any) => {
    let token = localStorage.getItem("token");
    return (
        <Route {...rest} render={props => (
            token && token.length > 0 ?
                <Component {...props} />
                : <Redirect to="/home/login" />
        )} />
    )
}

export default ProtectedRoute
