import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import countryList from "../../constants/countryList.json";
import { feedbackService } from "../../services/feedback.service";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { dashboardservice } from "../../services/dashboard.service";
import AuthContext from "../../store/auth-context";
import { Alert } from "@material-ui/lab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FeedbackSubmission from "./FeedbackSubmission";
import FeedbackQuestionnaire from "./FeedbackQuestionnaire";
import { Link, useHistory, useLocation } from "react-router-dom";
import Complaints from "./Complaints";

function Feedback() {
  const authCtx = useContext(AuthContext);
  const [complaint, setComplaint] = useState<any>(false);
  const [facility, setFacility] = useState<any>(false);
  const [feedComp, setFeedComp] = useState<any>(false);

  const [selectionValue, setSelectionValue] = useState<any>({});
  const [facilityDropdownWide, setFacilityDropdownWide] =
    useState<boolean>(true);
  const [showGoButton, setShowGoButton] = useState<boolean>(true);
  const [showSelcectionDropdown, setShowSelectionDropdown] =
    useState<boolean>(true);
  const [renderFBScreen, setRenderFBScreen] = useState<Number>(0);
  const [fbSubmissionStatus, setFBSubmissionStatus] = useState<boolean>(false);
  const [feedbackLocations, setFeedbackLocations] = useState<any>([]);
  const [facilitySelected, setFacilitySelected] =
    useState<any>("Select Facility");
  const [facilityVblogValue, setFacilityVblogValue] = useState<any>(0);
  const [showApiErrorMsg, setShowApiErrorMsg] = useState<boolean>(false);
  const [apiErrorText, setApiErrorText] = useState<string>("");
  const [feedbackPatientTypeList, setFeedbackPatientTypeList] = useState<any>(
    []
  );
  const [feedbackPatientType, setFeedbackPatientType] = useState<string>("");
  const [noPatientTypesFound, setNoPatientTypesFound] =
    useState<boolean>(false);

  const brandName = localStorage.getItem("brandName");

  const dropdownvalues = ["Select", "Feedback", "Complaint"];
  const history = useHistory();
  const defaultFacility = "Select Facility";

  useEffect(() => {
    const getLocationsData = () => {
      getAllFeedbackLocations();
    };
    getLocationsData();
  }, []);

  const getAllFeedbackLocations = async () => {
    authCtx.toggleLoader(true);
    let res = await feedbackService.getFeedbackLocations(1);
    if (res.data && res.data.status === "success") {
      setFeedbackLocations(res.data.data);
    } else {
      setApiErrorText("Error in getting feedback locations");
      setShowApiErrorMsg(true);
    }
    authCtx.toggleLoader(false);
  };

  const getFeedbackPatientType = async (vblog: any) => {
    authCtx.toggleLoader(true);
    if (
      brandName === "medeor" ||
      brandName === "burjeel" ||
      brandName === "lifecare" ||
      brandName === "llh"
    ) {
      let res = await feedbackService.getFeedbackpatientType(vblog);
      if (res && res.data.status === "success") {
        setFeedbackPatientTypeList(res.data.data);
        setNoPatientTypesFound(false);
      } else {
        setFeedbackPatientTypeList([]);
        setNoPatientTypesFound(true);
        setApiErrorText("Error in getting patient types");
        setShowApiErrorMsg(true);
      }
    }
    authCtx.toggleLoader(false);
  };

  const handleSelectionChange = (e: any) => {
    setFeedComp(true);
    setSelectionValue(e.target.value);
    setFeedbackPatientType("");
    //setShowSelectionDropdown(false);
    if (
      e.target.value.toLowerCase() === "feedback" &&
      facilitySelected !== "Select Facility"
    ) {
      getFeedbackPatientType(facilityVblogValue);
      setRenderFBScreen(1);
      setShowSelectionDropdown(false);
      setFacilityDropdownWide(false);
    } else if (
      e.target.value.toLowerCase() === "complaint" &&
      facilitySelected !== "Select Facility"
    ) {
      setComplaint(true);
      setShowSelectionDropdown(false);
      setFacilityDropdownWide(false);
    }
  };

  const handleFSBackButtonClick = () => {
    setRenderFBScreen(0);
    setFacility(false);
    setComplaint(false);
    setShowSelectionDropdown(true);
    setFacilityDropdownWide(true);
    setFacilitySelected("Select Facility");
  };

  const getFeedbackSubmissionStatus = (value: any) => {
    setFBSubmissionStatus(value);
    if (value) {
      setRenderFBScreen(2);
    }
  };

  const getBackToFeedbackScreen = (isClick: any) => {
    if (isClick) {
      setRenderFBScreen(0);
      setFacilityDropdownWide(true);
      setShowSelectionDropdown(true);
      setFacility(false);
      setFeedComp(false);
      setComplaint(false);
    }
  };

  const handleFacilityChange = (e: any) => {
    e.stopPropagation();
    setFacility(true);
    if (e.target.value !== "Select Facility") {
      setFacilitySelected(JSON.parse(e.target.value));
      setFacilityVblogValue(JSON.parse(e.target.value).vblog);
      setFeedbackPatientTypeList("");
      setFeedbackPatientType("");
    } else {
      setFacility(false);
      setFacilityVblogValue(0);
    }
  };

  const handlePatientTypeChange = (e: any) => {
    setFeedbackPatientType(e.target.value);
  };

  const renderFeedbackScreens = (vblogValue: any) => {
    if (renderFBScreen === 1) {
      return (
        <FeedbackQuestionnaire
          facilityVblogValue={vblogValue}
          handleBackButton={handleFSBackButtonClick}
          fbSubmissionStatus={getFeedbackSubmissionStatus}
          feedbackPatientType={feedbackPatientType}
          feedbackPatientTypeList={feedbackPatientTypeList}
          noPatientTypesFound={noPatientTypesFound}
        />
      );
    } else if (renderFBScreen === 2) {
      return (
        <FeedbackSubmission
          facilityName={facilitySelected}
          getBackToFBHome={getBackToFeedbackScreen}
        />
      );
    }
  };

  const handleClose = () => {
    setShowApiErrorMsg(false);
  };

  const renderSubmissionScreen = () => {
    return (
      <div>
        <h6 className="col-md-7 col-xs-12 mx-auto text-center">
          We Would{" "}
          <FavoriteIcon
            sx={{
              color: "red",
            }}
          />{" "}
          Your Feedback
        </h6>
        <div className="col-md-7 mx-auto">
          <div
            className={
              "bg-white " +
              (!facilityDropdownWide
                ? "col-md-12 col-xs-12 mx-auto"
                : "col-md-7 col-xs-12 float-start mx-auto")
            }
          >
            {feedbackLocations && (
              <select
                className="ms-auto me-4 form-select fs-14 no-focus rounded-0.5rem"
                id="selectlocation"
                disabled={renderFBScreen !== 0 ? true : false}
                value={JSON.stringify(facilitySelected)}
                // value={facilitySelected.name}
                onChange={(e) => handleFacilityChange(e)}
              >
                <option value="Select Facility">Select Facility</option>
                {feedbackLocations &&
                  feedbackLocations.map((item: any, index: any) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          {renderFBScreen === 1 &&
            (brandName === "medeor" ||
              brandName === "burjeel" ||
              brandName === "lifecare" ||
              brandName === "llh") && (
              <div
                className={
                  "bg-white" +
                  (!facilityDropdownWide
                    ? "col-md-12 col-xs-12 mx-auto mt-2"
                    : "col-md-7 col-xs-12 float-start mx-auto mt-2")
                }
              >
                <select
                  className="ms-auto me-4 form-select fs-14 no-focus rounded-0.5rem"
                  id="selectPatient"
                  value={feedbackPatientType}
                  onChange={(e) => handlePatientTypeChange(e)}
                >
                  <option value="">Select Patient Type</option>
                  {feedbackPatientTypeList &&
                    feedbackPatientTypeList.map((item: any, index: any) => (
                      <option key={index} value={item.patient_type_value}>
                        {item.patient_type_label}
                      </option>
                    ))}
                </select>
              </div>
            )}
          <div className="col-md-3 col-xs-12 float-start ms-2">
            {showSelcectionDropdown && (
              <select
                className="form-select fs-14 no-focus"
                id="selectPatient"
                defaultValue="Select"
                disabled={facility ? false : true}
                onChange={(e) => handleSelectionChange(e)}
              >
                {dropdownvalues &&
                  dropdownvalues.map((ele: any, index: any) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
      </div>
    );
  };
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        {!complaint ? (
          <div>
            <div className="d-flex align-items-center mb-3">
              <a
                href="#"
                className="d-inline-block d-sm-none text-dark"
                onClick={(e) => openMobileMenu(e)}
              >
                <i className="material-icons-outlined me-2">menu</i>
              </a>
              <h3 className="fs-18-24">Feedback / Complaint</h3>
              <div className="ms-auto mw-90px">
                <Notification />
                <UserMenu />
              </div>
            </div>
            {renderFBScreen !== 2 ? renderSubmissionScreen() : null}

            <div className="col-md-7 mx-auto">
              {/* <div className="col-md-12 stroke-main-div card border-0 shadow-sm rounded-1rem mt-2">              */}
              {/* <div className="card border-0 shadow-sm rounded-1rem mt-2 bg-white">           */}
              {renderFBScreen !== 0
                ? renderFeedbackScreens({ facilityVblogValue })
                : null}

              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="col-md-1 mx-auto mt-5 pt-4 px-3">
              {/* {showGoButton && <button disabled={facility && feedComp ? false:true} className="btn btn-primary text-center" onClick={handleGoClick}>Go</button>} */}
            </div>

            {/* <FeedbackSubmission/>         */}
          </div>
        ) : (
          <Complaints
            facilityName={facilitySelected}
            getBackToFBHomeFromComp={getBackToFeedbackScreen}
            handleBackButton={handleFSBackButtonClick}
          />
        )}
      </div>

      <Snackbar
        open={showApiErrorMsg}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {apiErrorText}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Feedback;
