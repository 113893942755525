import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  HubConnectionBuilder,
  HubConnection,
  JsonHubProtocol,
} from "@microsoft/signalr";
import { notificationService } from "../../services/notification.service";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../constants/apiConfig";
import { Menu, MenuItem, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";

const Notification = () => {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [notificationCount, setNotificationCount] = useState<any>(0);
  const [notificationList, setNotificationList] = useState<any>([]);
  const [notificationReload, setNotificationReload] = useState<boolean>(false);
  // const [connection, setConnection] = useState<HubConnection | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    fetchNotification();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const newConnection = new HubConnectionBuilder()
  //     .withUrl(baseUrl + "/notificationhub", { withCredentials: false })
  //     .withAutomaticReconnect()
  //     .withHubProtocol(new JsonHubProtocol())
  //     .build();

  //   setConnection(newConnection);
  // }, []);

  // useEffect(() => {
  //   if (connection) {
  //     connection
  //       .start()
  //       .then((result) => {
  //         //console.log('Connected!');
  //         let userInfo = JSON.parse(localStorage.getItem("user") || "{}");
  //         if (userInfo.mobile) connection.invoke("addToGroup", userInfo.mobile);

  //         connection.on("notificationreceived", (message) => {
  //           fetchNotificationCount();
  //         });
  //       })
  //       .catch((e) => console.log("Connection failed: ", e));
  //   }
  // }, [connection]);

  useEffect(() => {
    const ac = new AbortController();
    const getNotification = async () => {
      await fetchNotificationCount();
    };
    getNotification();

    return () => {
      ac.abort();
    };
  }, [notificationReload]);

  // useEffect(() => {
  //   const getNotification = async () => {
  //     // await fetchNotification();
  //     await fetchNotificationCount();
  //   };
  //   getNotification();
  // }, [notificationReload]);

  const fetchNotification = async () => {
    authCtx.toggleLoader(true);
    const res = await notificationService.getPatientActiveNoti();
    if (
      res &&
      res.data &&
      res.message &&
      res.message.status &&
      res.message.status[0] === "Success"
    ) {
      setNotificationList(res.data);
    } else {
      setNotificationList([]);
      setNotificationCount(0);
    }
    authCtx.toggleLoader(false);
  };

  const fetchNotificationCount = async () => {
    const res = await notificationService.getActiveNotificationCount();
    if (res) {
      setNotificationCount(res);
    } else {
      setNotificationCount(0);
    }
  };

  const handleNotification = (item: any) => {
    authCtx.toggleLoader(true);
    if (item.type === "BROADCAST" && item.promotionType === "1") {
      markNotificationRead(item.notificationId);
      console.log("Doctor Promotions");
    } else if (
      item.type === "BROADCAST" &&
      item.promotionType === "URLPROMOTION"
    ) {
      if (item.link) {
        window.open(item.link);
      }
      if (!item.isRead) {
        markNotificationRead(item.notificationId);
      }
    } else if (
      item.type === "LABREPORT" &&
      item.promotionType === "LABREPORT"
    ) {
      if (!item.isRead) {
        markNotificationRead(item.notificationId);
      }
      if (item.isFamilyMember && item.familyMemberId !== 0) {
        history.push({
          pathname: "/auth/diagnosticReports",
          state: { from: "notification", familyMemberId: item.familyMemberId },
        });
      } else {
        history.push("/auth/diagnosticReports");
      }
    }
    handleClose();
    authCtx.toggleLoader(false);
  };

  const markNotificationRead = async (notificationId: any) => {
    await notificationService.markNotificationRead(notificationId);
    setNotificationReload(!notificationReload);
    authCtx.toggleLoader(false);
  };

  const hadleMarkAllNotification = async () => {
    await notificationService.markAllNotificationRead();
    setNotificationReload(!notificationReload);
  };

  return (
    <div className="dropdown no-caret d-inline-block notification">
      <Link
        to="#"
        className="dropdown-toggle"
        data-bs-toggle="dropdown"
        onClick={handleClick}
      >
        {notificationCount > 0 && (
          <span className="count position-absolute bg-danger text-white rounded-pill text-center fs-12">
            {notificationCount}
          </span>
        )}
        <i className="material-icons-outlined fs-2"> notifications </i>
      </Link>
      <Menu
        id="long-menu"
        className="p-0 notification-pannel"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 7,
            width: "50ch",
          },
        }}
      >
        {notificationList && notificationList.length > 0 ? (
          notificationList.map((item: any, index: any) => (
            <MenuItem
              className={`dropdown-item border-bottom p-3 d-block no-focus position-relative ${
                !item.isRead && "unread"
              }`}
              onClick={() => handleNotification(item)}
              key={"noti" + index}
            >
              <h6 className={item.isRead ? "text-muted m-0" : "m-0"}>
                {item.notificationTitle ? item.notificationTitle : ""}
              </h6>
              <Typography
                className={item.isRead ? "text-muted fs-13" : "fs-13"}
                variant="inherit"
                noWrap
              >
                {item.messageTextAr && item.messageTextAr.trim() !== ""
                  ? item.messageTextAr
                  : item.messageTextEn}
              </Typography>
              <p className="mb-0 mt-1 fs-13 text-muted">
                {new Date(item.createdOn).toLocaleString("en-uk", {
                  hour: "2-digit",
                  minute: "2-digit",
                  day: "2-digit",
                  year: "numeric",
                  month: "short",
                })}
              </p>
            </MenuItem>
          ))
        ) : (
          <MenuItem className="dropdown-item">No new notification</MenuItem>
        )}

        {notificationCount > 0 && (
          <div className="d-flex justify-content-center align-item-center py-2 notification-sticky border-top">
            {" "}
            <MenuItem
              className="text-primary"
              onClick={() => hadleMarkAllNotification()}
            >
              Mark all as read
            </MenuItem>
          </div>
        )}
      </Menu>
    </div>
  );
};

export default Notification;
