import React from "react";
import Notification from "./common/Notification";
import UserMenu from "./common/UserMenu";
import faqList from "../constants/faqList.json";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Parser from "html-react-parser";

function NeedHelp() {
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Need Help?</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-8 ">
            <div className="card border-0 shadow-sm rounded-1rem overflow-hidden">
              <div className="card-body p-4">
                <div className="mb-2 t-n1 position-relative">
                  <i className="material-icons-outlined t-8 position-relative">
                    call
                  </i>{" "}
                  <span className="ms-3">
                    24hrs Helpline Number <strong>800 55</strong>
                  </span>{" "}
                </div>
                <div className="t-n1 position-relative">
                  <i className="material-icons-outlined t-8 position-relative">
                    email
                  </i>{" "}
                  <span className="ms-3">
                    {localStorage.getItem("brandName") === "burjeel"
                      ? "info@burjeel.com"
                      : localStorage.getItem("brandName") === "medeor"
                      ? "info.auh@medeor.ae"
                      : "info@lifecarehospital.ae"}
                  </span>{" "}
                </div>
              </div>
              <div className="py-2 px-3 bg-dark bg-opacity-10 fs-12 fw-bold">
                Frequently Asked Question
              </div>
              {faqList &&
                faqList.map((item, index) => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="fw-600">{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {Parser(`<Typography>
                    ${item.description}
                  </Typography>`)}
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NeedHelp;
