import React, { useContext, useEffect, useState } from 'react'
import { finddoctorservice } from '../../services/findDoctor.services';
import Parser from "html-react-parser";
import AuthContext from '../../store/auth-context';

function DoctorProfile(props:any) {
const authCtx = useContext(AuthContext);
const [doctorProfileData,setDoctorprofileData] = useState<any>();

useEffect(()=>{
  fetcDoctorProfileDetails();
  // eslint-disable-next-line
},[]);

const fetcDoctorProfileDetails = async () => {
  authCtx.toggleLoader(true);
  const res = await finddoctorservice.getDoctorProfile(props.doctorProfileDetails);
  if(res && res.data && res.data.status === "success"){
    setDoctorprofileData(res.data.data[0]);
  }else{
    setDoctorprofileData([]);
    console.log(res.message);      
  }
 authCtx.toggleLoader(false);
}

  return (
    <>
    <div className="d-flex justify-content-start align-item-cenetr ">
      <div className="flex-shrink-0">            
        <img
              src={doctorProfileData && doctorProfileData.image}
              alt="news"
              className="img-fluid img-150 rounded dr-photo"
            />
          </div>
          <div className="flex-fill ms-3">
            <h6 className="fw-bold">{doctorProfileData && doctorProfileData.name}</h6>
            <p className='text-muted fs-12 m-0'>{doctorProfileData && doctorProfileData.specialization}</p>
            <hr/>
            <p className='fs-14'>Year of Experience : <b>{doctorProfileData && doctorProfileData.experience}</b></p>
            <p className='fs-14'>Nationality : <b>{ doctorProfileData && doctorProfileData.nationality}</b></p>
            <p className='fs-14'>Languages Known : <b>{doctorProfileData && doctorProfileData.know_langauges}</b></p>
          </div>
         </div>
          <div className="flex-fill">
          <h6 className="fw-500">Biography</h6>
          {doctorProfileData && 
          <div>
           {Parser(doctorProfileData.biography_web)}
          </div>}
          
        </div>
        </>
        

  )
}

export default DoctorProfile