import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ValetDashboard from "./ValetDashboard";
import ValetSideNav from "./ValetSideNav";

function ValetLayout() {
useEffect(() => {
    addValetCss();

},[])

const addValetCss = () => {
    let css : any = document.getElementById("custom-css");
    css.href = "/css/valet.dev.css";
    // let theme : any = document.getElementById("theme");
    // theme.href = "/css/valet-theme.css";
}


  return (
    <div className="d-flex">
      <ValetSideNav />
      <Switch>
        <Route path="/valetauth" exact>
          <Redirect to="/valetauth/dashboard" />
        </Route>
        <Route path="/valetauth/dashboard">
          <ValetDashboard />
        </Route>
      </Switch>
    </div>
  );
}

export default ValetLayout;
