import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../store/auth-context";
import {
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { userService } from "../../services/UserService";
import CloseIcon from "@material-ui/icons/Close";

function Location() {
  const authCtx = useContext(AuthContext);
  const brandName = localStorage.getItem("brandName");
  const [hospitalName, setHospitalName] = useState<string>("");

  const [chooseLocatinDialog, setChooseLocationDialog] =
    useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [facilityId, setFacilityId] = useState<string>("");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    if (userData.facilityId) {
      setFacilityId(userData.facilityId);
      const hospital: any = authCtx.locationGlobalList
        ? authCtx.locationGlobalList.filter((item: any) => {
            return item.locationId === userData.facilityId;
          })
        : "";
      const splitName = hospital.length > 0 ? hospital[0].name.split(",") : "";
      const hospitalName = splitName[splitName?.length - 1];
      setHospitalName(hospitalName);
    }
  }, [authCtx.locationGlobalList, authCtx.isDobAvailable]);

  const handleClick = () => {
    setChooseLocationDialog(true);
  };

  const handleClose = () => {
    setChooseLocationDialog(false);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleGlobalLocationChange = async () => {
    authCtx.toggleLoader(true);
    let res = await userService.saveUserFacilityId(facilityId);
    if (res.message.status === "Success") {
      const filterValue: any = authCtx.locationGlobalList.find(
        (item: any) => item.locationId === facilityId
      );
      const exsitingUserData = userData;
      exsitingUserData["facilityId"] = facilityId;
      exsitingUserData["vblog"] = filterValue.vblog;
      localStorage.setItem("user", JSON.stringify(exsitingUserData));
      handleClose();
      authCtx.setIsDobAvalable(!authCtx.isDobAvailable);
    } else {
      setModalType("error");
      setModalText("Error in update facility id");
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleModalClose();
      }, 3000);
    }
    authCtx.toggleLoader(false);
  };

  return (
    <>
      <div className="dropdown no-caret d-inline-block ms-sm-3 ms-2 ">
        <div
          className="dropdown-toggle"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          onClick={handleClick}
        >
          <div className="d-flex align-items-center justify-content-end text-primary cursor-pointer">
            {" "}
            <i className="material-icons-outlined fs-10"> location_on </i>
            <span className="fs-10 me-2">Select Hospital</span>
          </div>
          <div className="d-flex align-items-center fs-14 cursor-pointer">
            <span>{hospitalName}</span>
            <i className="material-icons-outlined "> expand_more </i>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={chooseLocatinDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary border-bottom">
          Choose Hospital
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-3 primary-scroll">
          <div className="">
            <FormControl className="w-100">
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={facilityId}
                onChange={(e) => setFacilityId(e.target.value)}
              >
                {authCtx.locationGlobalList &&
                  authCtx.locationGlobalList.map((item: any) => (
                    <FormControlLabel
                      value={item.locationId}
                      control={<Radio />}
                      label={item.name}
                      className="border-bottom"
                      key={"LOC-" + item.locationId}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="py-3 px-4 border-top">
          <button
            onClick={handleGlobalLocationChange}
            className="btn btn-primary"
          >
            Submit
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModalClose}
        >
          <Alert onClose={handleModalClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={showModal}
          autoHideDuration={3000}
          onClose={handleModalClose}
        >
          <Alert onClose={handleModalClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Location;
