import axios from "axios";

// {headers:{'Auth-Key': '6Q8R9SBUCVDXFYGZJ3K4M5P7Q'}}
const axiosInstance: any = axios.create();

export async function makeRequestForWP({ path }: any) {
   
    try {
        let wpBaseUrl = localStorage.getItem('wpBaseUrl');
        const response = await axiosInstance.get(wpBaseUrl+path);
        return response;
    } catch (error) {
        return error;
    }
}

export async function makeRequestForWPPost({ path }: any) {
   
    try {
        let wpBaseUrl = localStorage.getItem('wpBaseUrl');
        const response = await axiosInstance.post(wpBaseUrl+path);
        return response;
    } catch (error) {
        return error;
    }
}

