import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const patientService = {
  getPatientRec,
  apptHistory,
};

async function getPatientRec(
  patientId: any,
  familyMemberId: any,
  facilityId: any
) {
  const apiPath =
    API_ENDPOINTS.GET_PATIENT_REC +
    patientId +
    "&familyMemberId=" +
    familyMemberId +
    "&facilityId=" +
    facilityId;
  const options = {
    path: apiPath,
    method: "POST",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function apptHistory(data: any) {
  let brandName = localStorage.getItem("brandName");
  const apiPath = API_ENDPOINTS.APPT_HISTORY + "?brandName=" + brandName;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      HIS: data.his,
      patientId: data.patientId,
      mobileNo: data.mobileNo,
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
