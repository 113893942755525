import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { dashboardservice } from "../services/dashboard.service";
import UserMenu from "./common/UserMenu";
import Notification from "./common/Notification";
import AuthContext from "../store/auth-context";

function Insurance() {
  const [insurenceList, setInsurenceList] = useState<any>([]);
  const authCtx = useContext(AuthContext);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modelType, setModelType] = useState<any>("");
  const [modelText, setModelText] = useState<any>("");

  useEffect(() => {
    const getInsurence = () => {
      fetchAllInsurence();
    
    };

    getInsurence();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => setShowModel(false);

  const fetchAllInsurence = async () => {
    authCtx.toggleLoader(true);
    let res = await dashboardservice.getInsurence();
    if (res && res.data && res.data.status && res.data.status === "success") {
      const insurenceData = res.data.data;
      setInsurenceList(insurenceData);
      setShowModel(false);
      setModelText(res.data.message);
      authCtx.toggleLoader(false);
    } else {
      setModelType("error");
      setInsurenceList([]);
      setShowModel(true);
      setModelText(res.data.message);
      authCtx.toggleLoader(false);
    }
  };

 const openMobileMenu = (e: any) => {
   e.preventDefault();

   document.getElementById("leftMenu")?.classList.remove("d-none");
 };

 return (
   <>
     <div className="main-content animate py-4 px-3 px-sm-4">
       <div className="d-flex align-items-center mb-3">
         <a
           href="#"
           className="d-inline-block d-sm-none text-dark"
           onClick={(e) => openMobileMenu(e)}
         >
           <i className="material-icons-outlined me-2">menu</i>
         </a>
         <h3 className="fs-18-24">Insurance</h3>
         <div className="ms-auto mw-90px">
           <Notification />
           <UserMenu />
         </div>
       </div>
       <div className="row">
         {insurenceList &&
           insurenceList.map((item: any, index: any) => (
             <div className="col-sm-8 col-md-6 col-lg-3 zoom" key={index}>
               <div className="card border-0 shadow-sm rounded-1rem mt-4">
                 <div className="card-body p-4">
                   <div className="d-flex flex-row">
                     <div className="flex-shrink-0">
                       {" "}
                       <img
                         src={item.image}
                         alt="img"
                         style={{
                           height: 140,
                           width: 245,
                         }}
                       />{" "}
                     </div>
                   </div>
                   <div className="d-flex justify-content-center">
                     <label className="fw-bold">{item.name}</label>
                   </div>
                 </div>
               </div>
             </div>
           ))}
       </div>
     </div>

     {modelType === "error" && (
       <Snackbar open={showModel} autoHideDuration={3000} onClose={handleClose}>
         <Alert onClose={handleClose} severity="error">
           {modelText}
         </Alert>
       </Snackbar>
     )}
     {modelType === "success" && (
       <Snackbar open={showModel} autoHideDuration={3000} onClose={handleClose}>
         <Alert onClose={handleClose} severity="success">
           {modelText}
         </Alert>
       </Snackbar>
     )}
   </>
 );
}

export default Insurance;
