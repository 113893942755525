const clearWebStorage = () => {
  const removeItem: any = [
    "token",
    "refreshToken",
    "tokenExpiresAt",
    "refreshExpiresAt",
    "user",
    "userMobile",
    "getPatientRecord",
    "isPatientRecordCall",
    "uaeUser",
    "appConfiguration",
    "userMobile",
  ];

  for (const key of removeItem) {
    localStorage.removeItem(key);
  }
};

export default clearWebStorage;
