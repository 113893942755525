import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ParkingDetails from "./ParkingDetails";
import { Link } from "react-router-dom";

function ValetDashboard() {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleModalClose = async () => {
    setShowModal(false);
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4 min-vh-100">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Dashboard</h3>
          <button className="btn btn-link no-focus  text-decoration-none ms-auto fw-500">
            <i className="material-icons-outlined"> power_settings_new </i>
            <span className="position-relative t-n5 p-2">Logout</span>
          </button>{" "}
        </div>
        <div className="title">
          <p>Valet Parking Details</p>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card border-0 rounded-10 shadow-sm mb-4">
              <div className="card-body p-4">
                <div className="d-flex">
                  <h6 className="mt-1">Today's Valet Parking</h6>
                  <div className="d-sm-flex ms-auto">
                    <p className="me-2 mt-2">Total</p>
                    <h6 className="btn-primary rounded-10 p-2">38</h6>
                  </div>
                </div>
                <div
                  className="d-inline-flex align-items-center bg-primary rounded-10 overflow-hidden text-white cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <span className="px-3 py-1 bg-dark bg-opacity-25 fs-24">
                    07
                  </span>
                  <span className="px-4">08:09 AM</span>
                </div>
              </div>
            </div>
            <div className="card border-0 rounded-10 shadow-sm">
              <div className="card-body p-4">
                <div className="d-flex">
                  <h6 className="mt-1">Past Valet Parking</h6>
                  <div className="d-sm-flex ms-auto ">
                    <select className="form-select fs-14 rounded-10">
                      <option selected>current Month</option>
                    </select>
                  </div>
                </div>
                <div
                  className="d-inline-flex align-items-center bg-primary rounded-10 overflow-hidden text-white cursor-pointer"
                  onClick={() => setShowModal(true)}
                >
                  <span className="px-3 py-1 bg-dark bg-opacity-25 fs-24">
                    07
                  </span>
                  <span className="px-4">08:09 AM</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={showModal}
        fullWidth={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Parking List (8:00 - 9:00 AM)
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleModalClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <ParkingDetails />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ValetDashboard;
