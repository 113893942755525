import { MenuItem, Snackbar, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import countryList from "../../constants/countryList.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { dashboardservice } from "../../services/dashboard.service";
import { Alert } from "@material-ui/lab";
import AuthContext from "../../store/auth-context";

function AskDoctor(props:any) {
  const { t } = useTranslation(["familyMember", "profile", "login"]);
  const authCtx = useContext(AuthContext);

  const [fName, setfName] = useState<string>("");
  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [askText, setAskText] = useState<string>("");
  const [askTextError, setAskTextError] = useState<boolean>(false);

  const [callingCode, setCallingCode] = useState("");
  const [mobile, setLoginMobile] = useState<string>("");
  const [mobileError, setLoginMobileError] = useState<boolean>(false);
  const [mobileErrorText, setLoginMobileErrorText] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");

  const [modeOfCommunication, setmodeOfCommunication] = useState<any>("");
  const [modeOfCommError,setModeOfCommError] = useState<boolean>(false);
  const [isEmailCommunication, setIsEmailCommunication] =
    useState<boolean>(false);
  const [isMobileCommunication, setIsMobileCommunication] =
    useState<boolean>(false);

  const [askDocModal, setAskDocModal] = useState<boolean>(false);
  const [validationModal, setValidationModal] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<any>("");

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    setfName(user.firstName);
    setlName(user.lastName);
    setLoginMobile(user.mobile);
    setCallingCode(user.callingCode);
    setEmail(user.email);
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (e.target.value === "") {
      errorSetterName(true);
    } else {
      errorSetterName(false);
    }
  };

  const handleMobileChange = (e: any) => {
    setLoginMobile(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setLoginMobileError(true);
      setLoginMobileErrorText("");
    } else {
      if (e.target.value.length !== 10 && e.target.value.length < 8) {
        setLoginMobileError(true);
        setLoginMobileErrorText("Enter a valid mobile no.");
      } else {
        setLoginMobileError(false);
        setLoginMobileErrorText("");
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText("Please enter a valid email");
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleModeOfComm = (e: any) => {
    if (e.target.value === "mobile") {
      setmodeOfCommunication(e.target.value);
      setIsMobileCommunication(true);
      setIsEmailCommunication(false);
      setModeOfCommError(false);
    } else if (e.target.value === "email") {
      setmodeOfCommunication(e.target.value);
      setIsMobileCommunication(false);
      setIsEmailCommunication(true);
      setModeOfCommError(false);
    } else if(e.target.value === "both"){
      setmodeOfCommunication(e.target.value);
      setIsMobileCommunication(true);
      setIsEmailCommunication(true);
      setModeOfCommError(false);
    }
  };

  const validateForm = () => {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }

    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (mobile.trim() === "") {
      setLoginMobileError(true);
      isValid = false;
    }
    if (modeOfCommunication.trim() === ""){
      setModeOfCommError(true);
      isValid = false;
    }
    if (email.trim() === "") {
      setEmailError(true);
      isValid = false;
    }
    if (askText.trim() === "") {
      setAskTextError(true);
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async () => {
    authCtx.toggleLoader(true);
    if (validateForm()) {
      // props.close();
      let data = {
        firstName: fName,
        lastName: lName,
        callingCode: callingCode,
        mobile: mobile,
        email: email,
        isEmailCommunication: isEmailCommunication,
        isMobileCommunication:isMobileCommunication, 
        comment: askText,
      };

      let res = await dashboardservice.askDoctor(data);

      if (res === true) {
        setAskDocModal(true);
        setValidationModal(true);
        setValidationText("We have received your query. We will get back to you soon.");
        authCtx.toggleLoader(false);
        setTimeout(()=>{
          props.handleAskDoctorClose();
        }, 2000);
       } else {
        setValidationModal(true);
        setValidationText("Something went wrong");
        authCtx.toggleLoader(false);
        setTimeout(()=>{
          props.handleAskDoctorClose();
        }, 2000);
      }
    }else{
      setValidationModal(true);
      setValidationText("Please fill all data");
      authCtx.toggleLoader(false);
    }
  };

  const modeOfCommuList = [
    {
      text: "Mobile",
      value: "mobile",
    },
    {
      text: "Email",
      value: "email",
    },
    {
      text: "Both",
      value: "both",
    },
  ];

  return (
    <>
      <div className="row g-sm-3 ">
        {/* sign-up-form */}
        <div className="col-sm mb-3">
          <TextField
            required
            id="fName"
            label={t("profile:firstName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={fNameError}
            value={fName}
            onChange={(e: any) => {
              handleInputChange(e, setfName, setFnameError);
            }}
          />
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            id="lName"
            label={t("profile:lastName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={lNameError}
            value={lName}
            onChange={(e: any) => {
              handleInputChange(e, setlName, setLnameError);
            }}
          />
        </div>
      </div>
      <div className="mb-3 position-relative">
        <div className="input-group">
          {countryList && callingCode && (
            <Autocomplete
              id="country-select-demo"
              options={countryList}
              disableClearable
              autoHighlight
              size="small"
              defaultValue={
                countryList.find((x) => x.phoneCode === callingCode)
              }
              onChange={(event, value) => {
                setCallingCode(value.phoneCode);
              }}
              filterOptions={createFilterOptions({
                stringify: (option) => "+" + option.phoneCode + option.name,
              })}
              getOptionLabel={(option) => "+" + option.phoneCode}
              PaperComponent={({ children }) => (
                <Paper style={{ minWidth: "400px" }}>{children}</Paper>
              )}
              style={{ minWidth: "100px" }}
              renderOption={(option) => (
                <>
                  {option.name} (+{option.phoneCode})
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}

          <TextField
            required
            autoComplete="off"
            id="mobile"
            label="Mobile"
            name="mobile"
            className="form-control"
            variant="outlined"
            size="small"
            inputProps={{ maxLength: 10, minLength: 8 }}
            error={mobileError}
            helperText={mobileErrorText}
            value={mobile}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e: any) => {
              handleMobileChange(e);
            }}
          />
        </div>
      </div>
      <div className="row g-sm-3 ">
        {/* sign-up-form */}
        <div className="col-sm mb-3">
          <TextField
            required
            id="email"
            label="Email"
            className="w-100  "
            variant="outlined"
            size="small"
            error={emailError}
            helperText={emailErrorText}
            value={email}
            onChange={(e: any) => {
              handleEmailChange(e);
            }}
          />
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            select
            id="modeOfComm"
            label="Mode Of Communication"
            className="w-100  "
            variant="outlined"
            error={modeOfCommError}
            value={modeOfCommunication}
            size="small"
            onChange={(e: any) => {
              handleModeOfComm(e);
            }}
          >
            {modeOfCommuList && modeOfCommuList.map((item: any,index) => (
              <MenuItem key={index} value={item.value}>{item.text}</MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className="row g-sm-3 ">
        {/* sign-up-form */}
        <div className="col-sm mb-3">
          <TextField
            label="How can we help you?"
            multiline
            className="w-100"
            size="small"
            rows={4}
            variant="outlined"
            error={askTextError}
            value={askText}
            onChange={(e: any) => {
              handleInputChange(e, setAskText, setAskTextError);
            }}
          />
        </div>
      </div>
      <div className="text-end modal-footer border-top-0 pe-0">
        <button
          className="btn btn-primary"
          value=""
          //disabled={!isNumberVerified}
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>
      </div>

      {askDocModal && (
        <Snackbar
          open={validationModal}
          autoHideDuration={3000}
          onClose={() => setValidationModal(false)}
        >
          <Alert onClose={() => setValidationModal(false)} severity="success">
            {validationText}
          </Alert>
        </Snackbar>
      )}
      {!askDocModal && (
        <Snackbar
          open={validationModal}
          autoHideDuration={3000}
          onClose={() => setValidationModal(false)}
        >
          <Alert onClose={() => setValidationModal(false)} severity="error">
            {validationText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default AskDoctor;
