import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
} from "@material-ui/core";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import AuthContext from "../../store/auth-context";
import { Autocomplete } from "@mui/material";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Box from "@mui/material/Box";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import ComplaintSubmission from "./ComplaintSubmission";
import { feedbackService } from "../../services/feedback.service";

function Complaints(props: any) {
  const [selectedIncDate, setSelectedIncDate] = useState<Date | null>(null);
  const [selectedIncDateError, setSelectedIncDateError] =
    useState<boolean>(false);
  const [selectedIncDateErrorText, setSelectedIncDateErrorText] =
    useState<string>("");
  const [complaintText, setComplaintText] = useState<string>("");
  const [complaintDetails, setComplaintDetails] = useState<any>([]);
  const [complaintSubmissionStatus, setComplaintSubmissionStatus] =
    useState<boolean>(false);
  const [complaintNameError, setComplaintNameError] = useState<boolean>(false);
  const [complaintTextError, setComplaintTextError] = useState<boolean>(false);
  const [complaintNumber, setComplaintNumber] = useState<string>();
  const [showApiErrorMsg, setShowApiErrorMsg] = useState<boolean>(false);
  const [feedbackLocations, setFeedbackLocations] = useState<any>([]);
  const [facilitySelected, setFacilitySelected] =
    useState<any>("Select Facility");
  const [facilityVblogValue, setFacilityVblogValue] = useState<any>(0);

  const authCtx = useContext(AuthContext);
  const dropdownvalues = ["FirstQues", "SecondQues", "ThirdQues"];
  const brandName = localStorage.getItem("brandName");
  const defaultFacility = "Select Facility";

  useEffect(() => {
    const getLocationsData = () => {
      getAllFeedbackLocations();
    };
    getLocationsData();
  }, []);

  const getAllFeedbackLocations = async () => {
    let res = await feedbackService.getFeedbackLocations(1);

    if (res.data && res.data.status === "success") {
      setFeedbackLocations(res.data.data);
    } else {
      setShowApiErrorMsg(true);
    }
  };

  const handleFacilityChange = (e: any) => {
    if (e.target.value !== "Select Facility") {
      setFacilitySelected(JSON.parse(e.target.value));
      setFacilityVblogValue(JSON.parse(e.target.value).vblog);
    } else {
      setFacilityVblogValue(0);
      setFacilitySelected("Select Facility");
    }
  };

  const handleIncDateChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedIncDate(date);
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedIncDateError(true);
        setSelectedIncDateErrorText("Incident Date Range Error");
      } else {
        setSelectedIncDateError(false);
        setSelectedIncDateErrorText("");
      }
    } else {
      setSelectedIncDateError(true);
      setSelectedIncDateErrorText("Incident Date Format Error");
    }
  };

  const getBackToFBHomeFromComplaints = (isClick: any) => {
    props.getBackToFBHomeFromComp(isClick);
  };

  const handleTextboxChange = (e: any) => {
    setComplaintText(e.target.value);
    if (e.target.value.length > 5) {
      setComplaintTextError(false);
    } else {
      setComplaintTextError(true);
    }
  };

  const handleSubmit = async () => {
    authCtx.toggleLoader(true);

    const userInfo = JSON.parse(localStorage.getItem("user") || "{}");

    let incDate = null;
    if (selectedIncDate !== null) {
      incDate = `${selectedIncDate.getFullYear()}-${(
        selectedIncDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${selectedIncDate
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    }
    const complaintsData = {
      facilityCode:
        brandName === "burjeel" ||
        brandName === "medeor" ||
        brandName === "lifecare" ||
        brandName === "llh"
          ? props.facilityName.locationId
          : facilitySelected.locationId,
      complainantName: `${userInfo.firstName} ${userInfo.lastName}`,
      incidentDate: incDate,
      email: userInfo.email,
      phoneNumber: userInfo.mobile,
      source: "Portal",
      complaintText: complaintText,
    };

    const res = await feedbackService.postComplaints(complaintsData);
    if (res && res.complaintNumber) {
      setComplaintSubmissionStatus(true);
      setComplaintNumber(res.complaintNumber);
    } else {
      setShowApiErrorMsg(true);
    }
    authCtx.toggleLoader(false);
  };

  const handleBack = async () => {
    props.handleBackButton();
  };

  const handleClose = () => {
    setShowApiErrorMsg(false);
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };
  return (
    <>
      <div
        className={
          brandName === "burjeel" ||
          brandName === "medeor" ||
          brandName === "lifecare" ||
          brandName === "llh"
            ? "animate"
            : "main-content animate py-4 px-3 px-sm-4"
        }
      >
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Complaints</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div>
          {complaintSubmissionStatus === false ? (
            <div className="col-md-7 mx-auto card border-0 card-md-7 shadow-sm rounded-1rem mt-2 bg-white px-4">
              <div className="fs-5 fw-bold pt-4 px-4">
                {brandName === "burjeel" ||
                brandName === "medeor" ||
                brandName === "lifecare" ||
                brandName === "llh" ? (
                  <p>{props.facilityName.name}</p>
                ) : (
                  <select
                    className="me-4 pe-3 form-select fs-14 no-focus rounded-0.5rem w-100 col-sm"
                    id="selectPatient"
                    defaultValue={defaultFacility}
                    // value={facilitySelected}
                    onChange={(e) => handleFacilityChange(e)}
                  >
                    <option value={defaultFacility}>{defaultFacility}</option>
                    {feedbackLocations &&
                      feedbackLocations.map((item: any, index: any) => (
                        <option key={index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                )}
              </div>
              <div>
                <div className="col-sm px-4 pt-3">
                  <div className="form-floating">
                    <KeyboardDatePicker
                      required
                      autoOk={true}
                      size="small"
                      variant="inline"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      // margin="dense"
                      id="dob"
                      disableFuture={true}
                      views={["year", "month", "date"]}
                      label={"Incident Date"}
                      error={selectedIncDateError}
                      helperText={selectedIncDateErrorText}
                      value={selectedIncDate}
                      onChange={handleIncDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      className="m-0 w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="px-3 pt-2">
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Write your text here"
                    multiline
                    maxRows={8}
                    value={complaintText}
                    onChange={(e: any) => handleTextboxChange(e)}
                    //error={complaintTextError}
                    //helperText={"Please enter more than 5 characters"}
                    className="w-100 pe-3"
                    inputProps={{ maxLength: 500 }}
                  />
                </Box>
                {complaintTextError ? (
                  <p className="font-f44336 px-4 font-family-robo fs-12">
                    Please enter more than 5 characters
                  </p>
                ) : null}
              </div>
              <div className="text-right pb-4 px-4">
                <button
                  className="btn btn-primary mt-2 me-2"
                  value=""
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary mt-2"
                  value=""
                  disabled={
                    (
                      brandName === "burjeel" ||
                      brandName === "medeor" ||
                      brandName === "lifecare" ||
                      brandName === "llh"
                        ? selectedIncDate !== null &&
                          complaintText !== "" &&
                          !complaintNameError &&
                          !complaintTextError
                        : selectedIncDate !== null &&
                          complaintText !== "" &&
                          !complaintNameError &&
                          !complaintTextError &&
                          facilitySelected !== defaultFacility
                    )
                      ? false
                      : true
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <ComplaintSubmission
              complaintNum={complaintNumber}
              getBackToFBHomeFromCompSub={getBackToFBHomeFromComplaints}
            />
          )}
        </div>
      </div>

      <Snackbar
        open={showApiErrorMsg}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {"Something went wrong! Try again"}
        </Alert>
      </Snackbar>
    </>
  );
}
export default Complaints;
