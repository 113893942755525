import { useState } from "react";
import { FormControlLabel, IconButton, Radio, RadioGroup,  Dialog, DialogContent, DialogTitle,  } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
function StrokeRisk() {
    const { t } = useTranslation(["healthTool"]);


    const [severity, setSeverity] = useState<string>("normal");

    const [dataList, setDataList] = useState<any>([]);


    const [bloodPressure, setBloodPressure] = useState<string>("");
    const [bloodPressureError, setBloodPressureError] = useState<boolean>(false);


    const [cholesterol, setCholesterol] = useState<string>("");
    const [cholesterolError, setCholesterolError] = useState<boolean>(false);

    const [diabetic, setDiabetic] = useState<string>("");
    const [diabeticError, setDiabeticError] = useState<boolean>(false);

    const [smoker, setSmoker] = useState<string>("");
    const [smokerError, setSmokerError] = useState<boolean>(false);

    const [atrialFibrillation, setAtrialFibrillation] = useState<string>("");
    const [atrialFibrillationError, setAtrialFibrillationError] = useState<boolean>(false);

    const [weight, setWeight] = useState<string>("");
    const [weightError, setWeightError] = useState<boolean>(false);

    const [exercise, setExercise] = useState<string>("");
    const [exerciseError, setExerciseError] = useState<boolean>(false);

    const [strokeHistory, setStrokeHistory] = useState<string>("");
    const [strokeHistoryError, setStrokeHistoryError] = useState<boolean>(false);
    const [strokeMsg, setstrokeMsg] = useState<string>("");

    const [showModal, setShowModal] = useState<boolean>(false);

    const handleInputChange = (e: any) => {
        let key = e.target.name;
        let value = e.target.value
        let found = dataList.some((obj: any) => {
            if (Object.keys(obj)[0] === key) {
                obj[key] = value;
                return true;
            }
            return false;
        });
        if (!found) {
            setDataList([...dataList, { [key]: value }]);
        }

    }
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true)

    function validateForm() {
        let isValid: boolean = true;

        if (bloodPressure === "" || bloodPressureError) {

            setBloodPressureError(true);
            isValid = false;
        }
        if (cholesterol === "" || cholesterolError) {
            setCholesterolError(true);
            isValid = false;
        }
        if (diabetic === "" || diabeticError) {
            setDiabeticError(true);
            isValid = false;
        }
        if (smoker === "" || smokerError) {
            setSmokerError(true);
            isValid = false;
        }
        if (atrialFibrillation === "" || atrialFibrillationError) {
            setAtrialFibrillationError(true);
            isValid = false;
        }
        if (weight === "" || weightError) {
            setWeightError(true);
            isValid = false;
        }
        if (exercise === "" || exerciseError) {
            setExerciseError(true);
            isValid = false;
        }
        if (strokeHistory === "" || strokeHistoryError) {
            setStrokeHistoryError(true);
            isValid = false;
        }
        return isValid;
    }
    let calculateStroke = () => {
        let red = 0
        let yellow = 0
        if (validateForm()) {
            dataList.forEach((item: any) => {
                let val = Object.values(item)[0]
                if (val === "red") {
                    red++;
                }
                else if (val === "yellow") {
                    yellow++;
                }
            });

            if (red >= 3) {
                setSeverity("heigh")
                setstrokeMsg(t("strokeRiskResultHigh"))
            } else if ((red + yellow) >= 4) {
                setSeverity("moderate")
                setstrokeMsg(t("strokeRiskResultModerate"))
            } else {
                setSeverity("low")
                setstrokeMsg(t("strokeRiskResultLow"))
            }
            handleShow();
        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-md-12 stroke-main-div">

                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span  className={"strokeLable fs-14 pt-2 " + (bloodPressureError ? 'text-danger' : ' ')}>{t('bloodPressure')}</span>
                        </div>
                        <div className="col-sm-12 col-md-6  mb-3">
                            
                            <RadioGroup aria-label="bloodPressure" name="bloodPressure" value={bloodPressure} onChange={(e: any) => {
                                setBloodPressure(e.target.value);
                                setBloodPressureError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="140/90 or Higher" />
                                <FormControlLabel value="yellow" control={<Radio size="small" color="primary"/>} label="120/80 - 139/89" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="Less than 120/80" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (cholesterolError ? 'text-danger' : ' ')}>{t('cholesterol')}</span>
                        </div>
                        <div className="col-sm-12 col-md-6  mb-3">
                            <RadioGroup aria-label="cholesterol" name="cholesterol" value={cholesterol} onChange={(e: any) => {
                                setCholesterol(e.target.value);
                                setCholesterolError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary" />} label="More than 240" />
                                <FormControlLabel value="yellow" control={<Radio size="small" color="primary"/>} label="200 - 239" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="Less than 200" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (diabeticError ? 'text-danger' : ' ')}>{t('diabetic')}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <RadioGroup aria-label="diabetic" name="diabetic" value={diabetic} onChange={(e: any) => {
                                setDiabetic(e.target.value);
                                setDiabeticError(false);
                                handleInputChange(e)
                            }}>
                              <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="Yes" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="No" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (smokerError ? 'text-danger' : ' ')}>{t('smoker')}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <RadioGroup aria-label="smoker" name="smoker" value={smoker} onChange={(e: any) => {
                                setSmoker(e.target.value);
                                setSmokerError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="Yes" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="No" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (atrialFibrillationError ? 'text-danger' : ' ')}>{t('atrialFibrillation')}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <RadioGroup aria-label="atrialFibrillation" name="atrialFibrillation" value={atrialFibrillation} onChange={(e: any) => {
                                setAtrialFibrillation(e.target.value);
                                setAtrialFibrillationError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="Yes" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="No" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (weightError ? 'text-danger' : ' ')}>{t('strokeWeight')}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <RadioGroup aria-label="weight" name="weight" value={weight} onChange={(e: any) => {
                                setWeight(e.target.value);
                                setWeightError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="Overweight" />
                                <FormControlLabel value="yellow" control={<Radio size="small" color="primary"/>} label="Slightly Overweight" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="Normal" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (exerciseError ? 'text-danger' : ' ')}>{t('exercise')}</span>
                        </div>
                        <div className="col-md-6 mb-3">
                            <RadioGroup aria-label="exercise" name="exercise" value={exercise} onChange={(e: any) => {
                                setExercise(e.target.value);
                                setExerciseError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary"/>} label="Never" />
                                <FormControlLabel value="yellow" control={<Radio size="small" color="primary"/>} label="Sometimes" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="Regularly" />
                                 
                            </RadioGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <span className={"strokeLable fs-14 pt-2 " + (strokeHistoryError ? 'text-danger' : ' ')}>{t('strokeHistory')}</span>
                        </div>
                        <div className="col-md-6  mb-3">
                            <RadioGroup aria-label="strokeHistory" name="strokeHistory" value={strokeHistory} onChange={(e: any) => {
                                setStrokeHistory(e.target.value);
                                setStrokeHistoryError(false);
                                handleInputChange(e)
                            }}>
                                <FormControlLabel value="red" control={<Radio size="small" color="primary" />} label="Yes" />
                                <FormControlLabel value="yellow" control={<Radio size="small" color="primary"/>} label="Don't Know" />
                                <FormControlLabel value="normal"  control={<Radio size="small" color="primary"/>} label="No" />
                                 
                            </RadioGroup>
                        </div>

                    </div>
                    <button className="btn btn-primary float-end" onClick={() => calculateStroke()} >{t('calculateStroke')}</button>
                </div>
                <div className="col-md-4"> </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth="xs"
                open={showModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t("result")}
                    <IconButton className="float-end p-1" aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                {strokeMsg &&
                        <div className={severity === "low" ? "alert alert-info" : (severity === "moderate" ? "alert alert-warning" : "alert alert-danger")}>
                            <p className="mb-1 fs-6">  {t("strokeRiskResult")} <span className="fw-bold"> {strokeMsg}</span></p>
                        </div>
                    }
                </DialogContent>

            </Dialog>
        </div>
    )
}

export default StrokeRisk
