import { useState, useEffect, useContext } from "react";
import { dashboardservice } from "../../services/dashboard.service";
import AuthContext from "../../store/auth-context";

const LoginBanner = () => {
  const authCtx = useContext(AuthContext);
  const [bannerList, setBannerList] = useState<any>([])

  useEffect(() => {
    const getBanners = async () => {
      const listFromApi = await fetchBannerImges()
      if (listFromApi && listFromApi.data && listFromApi.data.data) {
        setBannerList(listFromApi.data.data)
      }else{
        setBannerList([]);
      }
    }
    if(authCtx.wpBaseUrl && authCtx.wpBaseUrl !== null && authCtx.wpBaseUrl !== ""){
    getBanners();
    }
  }, [authCtx.wpBaseUrl])

  const fetchBannerImges = async () => {
    let res = await dashboardservice.getBannerImg("Login");
    return res
  }
  return (
    <>
      <div className="login-banner text-white text-center p-0 d-none d-md-block vh-100">
        <div
          id="loginBanner"
          className="carousel slide h-100"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators w-100 left-0 m-0 p-3 gradient-bw">
            {bannerList &&
              bannerList.map((item: any, index: any) => (
                <button
                  type="button"
                  key={index}
                  data-bs-target="#loginBanner"
                  data-bs-slide-to={index}
                  className={index === 0 ? "active" : ""}
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
              ))}
          </div>
          <div className="carousel-inner h-100">
            {bannerList &&
              bannerList.map((item: any, index: any) => (
                <div
                  key={index}
                  className={
                    index === 0
                      ? "carousel-item active h-100"
                      : "carousel-item h-100"
                  }
                >
                  <img
                    src={item.imagepath}
                    className="d-block w-100 h-100 object-fit-cover"
                    alt=""
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginBanner
