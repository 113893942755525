import React, { useContext, useEffect, useState } from "react";
import PersonalDetails from "./PersonalDetails";
import UploadDocs from "./UploadDocs";
import ChangePassword from "./ChangePassword";
import Notification from "./common/Notification";
import UserMenu from "./common/UserMenu";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { dashboardservice } from "../services/dashboard.service";
import AuthContext from "../store/auth-context";
import { useHistory } from "react-router-dom";
import { appservice } from "../services/app.service";
import clearWebStorage from "../components/clearWebStorage";
function Profile() {
  const authCtx = useContext(AuthContext);
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [confirmationModel, setConfirmationModel] = useState<boolean>(false);
  const { t } = useTranslation(["profile"]);
  const brandName = localStorage.getItem("brandName");
  const userData = JSON.parse(localStorage.getItem("user") || "{}");

  const [refreshPage, setRefreshPage] = React.useState<number>(1);
  const uaePassLogout: any = process.env.REACT_APP_UAEPASS_LOGOUT;

  let uaeUserInfo = JSON.parse(
    localStorage.getItem("uaeUser") || "0"
  ).toString();

  const handleClose = () => {
    setShowModal(false);
  };

  const reloadPage = async () => {
    //to refresh child component
    let temp = refreshPage;
    setRefreshPage(temp + 1);
  };
  useEffect(() => {}, [refreshPage]);

  const handleComfirmationClose = () => setConfirmationModel(false);

  const handleDelteAccount = async () => {
    authCtx.toggleLoader(true);
    const res = await appservice.deleteAccount();
    if (res && res.message.status === "Success") {
      setConfirmationModel(false);
      setModalText(res.message.message);
      setModalType("success");
      setShowModal(true);
      setTimeout(() => {
        logout();
      }, 1000);
    } else {
      setModalText(res.message.message);
      setModalType("error");
      setShowModal(true);
    }

    authCtx.toggleLoader(false);
  };

  let logout = async () => {
    authCtx.toggleLoader(true);
    await dashboardservice.logout();
    authCtx.toggleLoader(false);
    authCtx.setMobile("", "");
    authCtx.removeUserInfo();
    authCtx.removeApiToken();
    if (localStorage.getItem("uaeUser") === "1") {
      localStorage.setItem("uaeUser", "0");
      window.location.replace(uaePassLogout);
    } else {
      history.push("/home/login");
    }
    clearWebStorage();
    if (brandName === "burjeel" && userData.facilityId === "BM10") {
      let theme: any = document.getElementById("theme");
      theme.href = "/css/theme-burjeel.css";
      document.title = "Burjeel | Patient Portal";
    }
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">My Profile</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu key={refreshPage} />
            {/* key is used to refresh component */}
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-sm-8 col-md-8">
            <div className="card border-0 shadow-sm mb-4">
              <div className="card-body p-4">
                <h6 className="mb-3 fw-bold text-primary">
                  {" "}
                  {t("personalDetails")}
                </h6>
                <PersonalDetails reloadPage={reloadPage} />
              </div>
            </div>
          </div>
          <div className="col-sm-8 col-md-8 col-xl-4">
            {uaeUserInfo !== "1" && (
              <div className="card border-0 shadow-sm mb-3">
                <div className="card-body p-4">
                  <h6 className="mb-3 fw-bold text-primary">
                    {" "}
                    {t("changePassword")}
                  </h6>
                  <ChangePassword />
                </div>
              </div>
            )}
            <div className="card border-0 shadow-sm mt-4">
              <div className="card-body p-4">
                <h6 className="mb-3 fw-bold text-primary">
                  {" "}
                  {t("DeleteAccount")}
                </h6>
                <p className="fs-14 fw-600">{t("DeleteAccountNote")}</p>
                <div className="text-end mt-4">
                  <button
                    className="btn btn-primary no-focus"
                    onClick={() => setConfirmationModel(true)}
                  >
                    {t("DeleteAccount")}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-8 col-md-8 ">
            {/* <div className="card border-0 shadow-sm mb-4">
              <div className="card-body p-4">
                <h6 className="mb-3 text-primary"> {t("DeleteAccount")}</h6>
                <p className="fs-14 fw-600">{t("DeleteAccountNote")}</p>
                <div className="text-end mt-4">
                  <button
                    className="btn btn-primary no-focus"
                    onClick={() => setConfirmationModel(true)}
                  >
                    {t("DeleteAccount")}
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="col-sm-8 col-md-6">
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                <h6 className="mb-3 text-primary"> {t("uploadDocuments")}</h6>
                <UploadDocs />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={confirmationModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary">Confirmation</DialogTitle>
        <DialogContent>
          {" "}
          <p className="fs-16 fw-600">
            Your profile & associated family members profile details will be
            deleted permanently. Are you sure you want to delete this account?
          </p>
        </DialogContent>
        <DialogActions>
          {" "}
          <button onClick={handleComfirmationClose} className="btn btn-primary">
            {t("no")}
          </button>
          <button onClick={handleDelteAccount} className="btn btn-primary">
            {t("yes")}
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Profile;
