import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AuthContext from "../../store/auth-context";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  IconButton,
  Snackbar,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, TabContext, TabList, TabPanel } from "@material-ui/lab";
import { familyMemberservice } from "../../services/familyMember.services";
import { LabReportsServices } from "../../services/labReports.service";
import { dashboardservice } from "../../services/dashboard.service";
import SingleLabReport from "./SingleLabReport";
import { loginservice } from "../../services/login.service";

import { patientService } from "../../services/patient.service";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { API_ENDPOINTS } from "../../constants/apiConfig";
import { finddoctorservice } from "../../services/findDoctor.services";

function LabReports() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["labReports", "login", "familyMember"]);
  const [labReportsCurrList, setLabReportsCurrList] = useState<any>([]);
  const [labReportsPastList, setLabReportsPastList] = useState<any>([]);
  const [labReportsCurrListBackup, setLabReportsCurrListBackup] = useState<any>(
    []
  );
  const [labReportsPastListBackup, setLabReportsPastListBackup] = useState<any>(
    []
  );
  const [labReportDialog, setLabReportDialog] = useState<boolean>(false);
  const [showNoReportsModal, setShowNoReportsModal] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [familyMemberMobile, setFamilyMemberMobile] = useState<any>("");
  const [familyMemberCallingCode, setFamilyMemberCallingCode] =
    useState<any>("");

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
  const [otpErrortext, setOtpErrorText] = useState<string>("");
  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);

  const [patientIdHISList, setPatientIdHISList] = useState<any>([]);
  const [familyMemberList, setFamilyMemberList] = useState<any>([]);
  const [familyMemberId, setFamilyMemberId] = useState<any>(0);
  const [userName, setUserName] = useState<any>("");
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [tabValue, setTabValue] = useState("1");
  const [tabListValue, setTabListValue] = useState("1");
  const [reportSearch, setReportSearch] = useState<string>();
  const [searchValue, setSearchValue] = useState<any>("");
  const [buttonImageToggle, setButtonImageToggle] = useState<boolean>(false);
  const [selectedPatient, setSelectedPatient] = useState<string>();
  const [clickedLabReport, setClickedLabReport] = useState<boolean>(false);
  const [pdfShow, setPdfShow] = useState<boolean>(false);
  const [selectedLabReportVal, setSelectedLabReportVal] = useState<any>();
  const [selectedclickedCard, setSelectedclickedCard] = useState<any>();
  const [selectedPatientName, setSelectedPatientName] = useState<string>();
  const [selectedPatientDOB, setSelectedPatientDOB] = useState<string>();
  const [showPasswordMsg, setShowPasswordMsg] = useState<boolean>(false);
  const [showPDFError, setShowPDFError] = useState<boolean>(false);
  const [fetchPDFError, setFetchPDFError] = useState<boolean>(true);
  const [isPastClicked, setIsPastClicked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [skipPasswordProtection, setSkipPasswordProtection] =
    useState<boolean>(false);
  const [labreportsType, setLabReportType] = useState<any>("All");
  const [expand, setExpanded] = useState<boolean>(false);
  const [labReportPDFDialog, setLabReportPDFDialog] = useState<boolean>(false);
  const [facilityId, setFacilityId] = useState<string>("");
  const [hospitallocationList, setHospitalLocationList] = useState<any>([]);
  const [showMoreBtnClick, setShowMoreBtnClick] = useState<boolean>(false);

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const location: any = useLocation();
  const brandNameVal = localStorage.getItem("brandName");

  const appConfiguration = JSON.parse(
    localStorage.getItem("appConfiguration") || "{}"
  );

  useEffect(() => {
    if (user.facilityId) {
      setFacilityId(user.facilityId);
    } else {
      setFacilityId("");
    }
    const getLocation = () => {
      getAllHospitalLocations();
    };
    getLocation();

    const getFamilyList = () => {
      fetchFamilyMember();
    };
    getFamilyList();
  }, []);

  useEffect(() => {
    if (location.state) {
      if (familyMemberList.length > 0) {
        getPatientRecord();
      }
    } else {
      if (hospitallocationList.length > 0) {
        getPatientRecord();
      }
    }

    // eslint-disable-next-line
  }, [familyMemberId, hospitallocationList, facilityId]);

  useEffect(() => {
    let intervalId: any;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          const secondCounter = counter % 60;
          const minuteCounter = Math.floor(counter / 60);
          const computedSecond =
            String(secondCounter).length === 1
              ? `0${secondCounter}`
              : secondCounter;
          const computedMinute =
            String(minuteCounter).length === 1
              ? `0${minuteCounter}`
              : minuteCounter;
          setSecond(computedSecond.toString());
          setMinute(computedMinute.toString());
          setCounter((counter) => counter - 1);
        } else {
          setIsActive(false);
          setCounter(60);
          setSecond("00");
          setMinute("00");
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  const fetchFamilyMember = async () => {
    const listFromApi = await familyMemberservice.getFamilyList();
    if (
      listFromApi &&
      listFromApi.message &&
      listFromApi.message.status === "Success"
    ) {
      setFamilyMemberList(listFromApi.data);
      if (location.state && location.state.from === "notification") {
        handleFamilyMemberNotification(
          listFromApi.data,
          location.state.familyMemberId
        );
      }
      //
    } else {
      setFamilyMemberList([]);
    }
  };

  const getAllHospitalLocations = async () => {
    authCtx.toggleLoader(true);
    let res = await finddoctorservice.getLocations();
    if (res.data && res.data.status === "success") {
      setHospitalLocationList(res.data.data);
    } else {
      setModalText("Error in getting locations");
      setModalType("error");
      setShowModal(true);
    }
    authCtx.toggleLoader(false);
  };

  const getPatientRecord = async () => {
    authCtx.toggleLoader(true);
    let idList;
    const emptyArray: any = [];
    setLabReportsCurrList([]);
    setLabReportsPastList([]);
    setLabReportsPastListBackup([]);
    setLabReportsCurrListBackup([]);
    patientIdHISList.splice(0, patientIdHISList.length);
    let patientDetailsList: any = [];

    const currentHis: any = hospitallocationList.find(
      (item: any) => item.locationId === facilityId
    );
    const patientRecord = JSON.parse(
      localStorage.getItem("getPatientRecord") || "{}"
    );
    const isPatientRecordCall = localStorage.getItem("isPatientRecordCall");

    if (familyMemberId === 0) {
      setUserName(user.firstName + " " + user.lastName);
      setSkipPasswordProtection(true);
      let profileInfo: any = authCtx.userProfileDetails;

      setSelectedPatientName(profileInfo.firstName + profileInfo.lastName);
      let dob = new Date(profileInfo.dateOfBirth);
      let formatedDob = `${dob.getFullYear()}-${(dob.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dob.getDate().toString().padStart(2, "0")}`;
      setSelectedPatientDOB(formatedDob);
      setFamilyMemberCallingCode(profileInfo.callingCode);
      setFamilyMemberMobile(profileInfo.mobile);
    } else {
      familyMemberList &&
        familyMemberList.forEach((item: any) => {
          if (item.familyMemberId === familyMemberId) {
            setUserName(item.firstName + " " + item.lastName);
          }
        });
    }
    if (
      Object.keys(patientRecord).length !== 0 &&
      isPatientRecordCall === "true" &&
      familyMemberId === 0 &&
      facilityId
    ) {
      fetchLatestLabReports(tabListValue, patientRecord);
      setPatientIdHISList(patientRecord);
    } else {
      let res = await patientService.getPatientRec(
        user.userId,
        familyMemberId,
        facilityId
      );
      if (res && res.data === undefined) {
        idList = [];
        console.log("Something went wrong in response");
      } else {
        idList = res.data;
      }

      idList &&
        idList.map((item: any) => {
          if (item && item.patientId !== null) {
            let data = {
              his: item.his,
              patientId: [item.patientId],
              mobileNo: item.mobileNo,
              dateOfBirth: selectedPatientDOB,
            };
            patientIdHISList.push(data);
          }
        });

      if (patientIdHISList.length > 0) {
        // if (familyMemberId === 0) {
        //   localStorage.setItem(
        //     "getPatientRecord",
        //     JSON.stringify(patientIdHISList)
        //   );
        //   localStorage.setItem("isPatientRecordCall", "true");
        // }

        fetchLatestLabReports(tabListValue, patientIdHISList);
        setPatientIdHISList(patientIdHISList);
        authCtx.toggleLoader(false);
      } else {
        authCtx.toggleLoader(false);
      }
    }

    setIsLoading(false);
    authCtx.toggleLoader(false);
  };

  const handleFamilyMemberNotification = async (
    memberList: any,
    fmemberId: any
  ) => {
    setIsLoading(true);
    setPdfShow(false);
    setShowPasswordMsg(false);
    setPatientIdHISList([]);
    setLabReportsCurrList([]);
    setLabReportsPastList([]);
    setIsPastClicked(false);
    let dob = null;
    let formatedDob = null;
    //getPatientRecord();
    let memberInfo = memberList.find(
      (member: any) => member.familyMemberId.toString() === fmemberId.toString()
    );
    setSelectedPatientName(memberInfo.firstName + memberInfo.lastName);
    if (user.mobile === memberInfo.mobile) {
      setSkipPasswordProtection(true);
    } else {
      setSkipPasswordProtection(false);
    }
    dob = new Date(memberInfo.dateOfBirth);
    formatedDob = `${dob.getFullYear()}-${(dob.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dob.getDate().toString().padStart(2, "0")}`;
    setSelectedPatientDOB(formatedDob);
    setFamilyMemberCallingCode(memberInfo.callingCode);
    setFamilyMemberMobile(memberInfo.mobile);
    setFamilyMemberId(fmemberId);
  };

  const handleFamilyChange = async (e: any) => {
    setIsLoading(true);
    setPdfShow(false);
    setShowPasswordMsg(false);
    setSelectedPatient(e.target.value);
    setPatientIdHISList([]);
    setLabReportsCurrList([]);
    setLabReportsPastList([]);
    setIsPastClicked(false);
    let dob = null;
    let formatedDob = null;
    if (e.target.value === user.firstName + " " + user.lastName) {
      setFamilyMemberId(0);
      setSkipPasswordProtection(true);
      //fetchLatestLabReports(true);
      let profileInfo: any = authCtx.userProfileDetails;

      setSelectedPatientName(profileInfo.firstName + profileInfo.lastName);
      dob = new Date(profileInfo.dateOfBirth);
      formatedDob = `${dob.getFullYear()}-${(dob.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dob.getDate().toString().padStart(2, "0")}`;
      setSelectedPatientDOB(formatedDob);
      setFamilyMemberCallingCode(profileInfo.callingCode);
      setFamilyMemberMobile(profileInfo.mobile);
    } else {
      setFamilyMemberId(Number(e.target.value));
      //getPatientRecord();
      let memberInfo = familyMemberList.find(
        (member: any) => member.familyMemberId.toString() === e.target.value
      );
      setSelectedPatientName(memberInfo.firstName + memberInfo.lastName);
      if (user.mobile === memberInfo.mobile) {
        setSkipPasswordProtection(true);
      } else {
        setSkipPasswordProtection(false);
      }
      dob = new Date(memberInfo.dateOfBirth);
      formatedDob = `${dob.getFullYear()}-${(dob.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${dob.getDate().toString().padStart(2, "0")}`;
      setSelectedPatientDOB(formatedDob);
      setFamilyMemberCallingCode(memberInfo.callingCode);
      setFamilyMemberMobile(memberInfo.mobile);
    }
  };

  const handleLapReportClose = () => {
    setLabReportDialog(false);
    setLabReportPDFDialog(false);
    setIsOtpSend(false);
    setIsActive(false);
    setCounter(60);
    setSecond("00");
    setMinute("00");
  };

  const fetchLatestLabReports = async (
    tabValue: any,
    patientIdHisList: any
  ) => {
    authCtx.toggleLoader(true);
    setPdfShow(false);
    setShowPasswordMsg(false);
    const current = new Date();
    let endDate: string = "";
    let startDate: string = "";
    let labReportsListVar: any = [];
    // let labReportsListBackupVar = [];

    if (tabValue === "1") {
      endDate = `${current.getFullYear()}-${(current.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${current.getDate().toString().padStart(2, "0")}`;
      const date15daysago = new Date(
        new Date().setDate(current.getDate() - 30)
      );
      startDate = `${date15daysago.getFullYear()}-${(
        date15daysago.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date15daysago
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    } else if (tabValue === "2") {
      const date16daysago = new Date(
        new Date().setDate(current.getDate() - 30)
      );
      endDate = `${date16daysago.getFullYear()}-${(date16daysago.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date16daysago
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      // endDate = `${current.getFullYear()}-${(current.getMonth()+1).toString().padStart(2,'0')}-${(current.getDate()).toString().padStart(2,'0')}`;
      const date6monthsago = new Date(
        new Date().setMonth(current.getMonth() - 6)
      );
      startDate = `${date6monthsago.getFullYear()}-${(
        date6monthsago.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${date6monthsago
        .getDate()
        .toString()
        .padStart(2, "0")}`;
    }
    if (patientIdHisList.length > 0) {
      let patientLabOrderDetailsList: any = [];
      let patientLabTestDetailsList: any = [];

      await Promise.all(
        patientIdHisList.map(async (item: any) => {
          const res = await LabReportsServices.getLabReports(
            item.patientId,
            item.his,
            startDate,
            endDate,
            item.mobileNo,
            item.dateOfBirth
          );
          let labReports = [];
          if (
            res &&
            res.message &&
            res.message.status &&
            res.message.status[0] === "S"
          ) {
            if (
              res.patientLabTestDetails.length !== 0 &&
              res.patientLabOrderDetails.length !== 0
            ) {
              patientLabOrderDetailsList = patientLabOrderDetailsList.concat(
                res.patientLabOrderDetails
              );
              patientLabTestDetailsList = patientLabTestDetailsList.concat(
                res.patientLabTestDetails
              );
            }
          }
        })
      );

      labReportsListVar = patientLabOrderDetailsList.map((order: any) => {
        let testName: any[] = [];
        const reportsName = patientLabTestDetailsList.filter((report: any) => {
          if (report.docNumber === order.docNumber) {
            testName.push(report.testName);
          }
        });
        let obj = order;
        Object.defineProperty(obj, "testNames", {
          value: testName.sort(),
        });
        return obj;
      });
    }

    var sortedDescLabReportsListVar = labReportsListVar.sort(
      (objA: any, objB: any) =>
        Number(new Date(objB.orderDate)) - Number(new Date(objA.orderDate))
    );
    let filterdReports: any;
    if (facilityId !== "" && appConfiguration.filterToBeApplied) {
      filterdReports = sortedDescLabReportsListVar.filter((item: any) => {
        return item.facilityId === facilityId;
      });
    } else {
      filterdReports = sortedDescLabReportsListVar;
    }

    if (tabValue === "1") {
      setLabReportsCurrList(filterdReports);
      setLabReportsCurrListBackup(filterdReports);
    } else if (tabValue === "2") {
      setLabReportsPastList(filterdReports);
      setLabReportsPastListBackup(filterdReports);
    }
    // setLabReportsListBackup(sortedDescLabReportsListVar);
    authCtx.toggleLoader(false);
  };

  const pdfAuthentication = async (val: any, clickedCard: any) => {
    if (skipPasswordProtection === true) {
      setSelectedLabReportVal(val);
      setSelectedclickedCard(clickedCard);
      showPdfReport(val);
    } else {
      setSelectedLabReportVal(val);
      setSelectedclickedCard(clickedCard);
      sendOtpToFamily();

      authCtx.toggleLoader(false);
    }
  };

  const showPdfReport = async (selectedLabReportValue: any) => {
    authCtx.toggleLoader(true);
    setFetchPDFError(true);
    //setShowPasswordMsg(false);
    if (selectedLabReportValue !== undefined) {
      setPdfShow(true);
      // setClickedCardKey(clickedCard);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const token = await localStorage.getItem("token");
      if (token) {
        myHeaders.append("Authorization", `Bearer ${token}`);
      }
      myHeaders.append("Invoking_Channel", "PATIENTPORTA");
      // myHeaders.append("Cookie", "ARRAffinity=dd329b08e1e37e0c4beb5b1915efd39b40586dea0c964bf832cc1ce3066bb3e2; ARRAffinitySameSite=dd329b08e1e37e0c4beb5b1915efd39b40586dea0c964bf832cc1ce3066bb3e2");

      var raw = JSON.stringify({
        documentNumber: selectedLabReportValue.reportDetails.docNumber,
        documentPart: selectedLabReportValue.reportDetails.docPart,
        documentType: selectedLabReportValue.reportDetails.docType,
        documentVersion: selectedLabReportValue.reportDetails.docVersion,
        facilityId: [selectedLabReportValue.reportDetails.facilityId],
        patientId: selectedLabReportValue.reportDetails.patientId,
        patientName: selectedPatientName,
        patientDOB: selectedPatientDOB,
        skipPasswordProtection: skipPasswordProtection,
      });

      var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(API_ENDPOINTS.GET_LAB_REPORT_PDF, requestOptions)
        .then((response) => response.blob())
        .then((blob) => {
          setPdfUrl(blob);
          if (blob.type !== "application/pdf") {
            setFetchPDFError(false);
          }
          const file = window.URL.createObjectURL(blob);
          const iframe = document.querySelector("iframe");
          if (iframe?.src) iframe.src = file;
          authCtx.toggleLoader(false);
        })
        .catch((err) => {
          // process error
          setShowPDFError(true);
          setFetchPDFError(false);
          setPdfShow(false);
          authCtx.toggleLoader(false);
        });
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.name === "loginMobile" || e.target.name === "otp") {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
          if (e.target.name === "otp") {
            setOtpErrorText(t("login:otpError"));
          }
        } else {
          const re = /^[0-9\b]+$/;
          if (!re.test(e.target.value)) {
            errorSetterName(true);
            if (e.target.name === "otp") {
              setOtpErrorText(t("login:numberError"));
            }
          } else {
            errorSetterName(false);
            setOtpErrorText("");
          }
        }
      } else {
        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
        } else {
          errorSetterName(false);
        }
      }
    }
  };

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const handleTabListChange = (event: any, newValue: any) => {
    setShowPasswordMsg(false);
    setTabListValue(newValue);
    setIsLoading(true);
    if (newValue === "1") {
      setLabReportsCurrList(labReportsCurrListBackup);
      setIsLoading(false);
    } else if (newValue === "2") {
      setIsPastClicked(true);
      setLabReportsPastList(labReportsPastListBackup);
      setIsLoading(false);
    }
    if (!isPastClicked) {
      fetchLatestLabReports(newValue, patientIdHISList);
    }
  };

  function validateOtp() {
    let isValid: boolean = true;
    if (otp.trim() === "" || otpError) {
      setOtpError(true);
      isValid = false;
    }
    return isValid;
  }

  let sendOtpToFamily = async () => {
    setOtp("");
    authCtx.toggleLoader(true);
    let res = await familyMemberservice.sendOtpToFamily(
      familyMemberCallingCode,
      familyMemberMobile
    );
    authCtx.toggleLoader(false);
    if (res.message.status === "Success") {
      setCounter(60);
      setIsOtpSend(true);
      setIsActive(true);
      setLabReportDialog(true);
      setModalText(t("login:otpSendSuccess"));
      setModalType("success");
      handleShow();
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    } else {
      handleShow();
      setModalText(t("login:otpSendError"));
      setModalType("error");
      setTimeout(() => {
        setModalText("");
        handleClose();
      }, 3000);
    }
  };

  let handleOtp = async () => {
    authCtx.toggleLoader(true);
    if (validateOtp()) {
      let res = await loginservice.verifyUser(
        familyMemberCallingCode,
        familyMemberMobile,
        otp
      );
      authCtx.toggleLoader(false);
      if (res.validOtp && res.message === "Success") {
        setLabReportDialog(false);
        setShowPasswordMsg(true);
        setClickedLabReport(true);
        setShowPDFError(false);
        setPdfShow(false);
        setLabReportDialog(false);
        setShowPasswordMsg(true);
      } else {
        if (!res.validOtp) {
          handleShow();
          setModalText(t("login:otpVerifyError"));
          setModalType("error");
          setTimeout(() => {
            setModalText("");
            handleClose();
          }, 3000);
        }
      }
      setOtp("");
    }
  };

  const closePdf = () => {
    setPdfShow(false);
    setShowPasswordMsg(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setShowNoReportsModal(false);
  };
  const handleShow = () => setShowModal(true);
  const [value, setValue] = useState("1");

  const handleChange = (event: any, newValue: React.SetStateAction<string>) => {
    setValue(newValue);
  };

  const labReportSearch = (e: any) => {
    e.preventDefault();
    if (e.target.value === "") {
      if (tabListValue === "1") {
        setLabReportsCurrList(labReportsCurrListBackup);
      } else if (tabListValue === "2") {
        setLabReportsPastList(labReportsPastListBackup);
      }
    } else {
      setReportSearch(e.target.value);
      if (tabListValue === "1") {
        handleLabReportSearch(e.target.value, labReportsCurrListBackup);
      } else if (tabListValue === "2") {
        handleLabReportSearch(e.target.value, labReportsPastListBackup);
      }
    }
  };

  const handleLabReportSearch = async (
    reportSearch: any,
    labReportsListBackup: any
  ) => {
    setSearchValue(reportSearch);

    if (reportSearch.length > 2) {
      const filteredItems: any = labReportsListBackup.filter((item: any) => {
        return item.docNumber
          .toLowerCase()
          .includes(reportSearch.toLowerCase());
      });
      if (tabListValue === "1") {
        setLabReportsCurrList(filteredItems);
      } else if (tabListValue === "2") {
        setLabReportsPastList(filteredItems);
      }
    }
  };

  const changeToHoverImage = () => {
    setButtonImageToggle(true);
  };

  const changeToLeaveImage = () => {
    setButtonImageToggle(false);
  };

  const getButtonImageIcon = () => {
    switch (brandNameVal) {
      case "medeor":
        return "/assets/images/dbicon-medeor.png";
        break;
      case "burjeel":
        return "/assets/images/dbicon-burjeel.png";
        break;
      default:
        return "/assets/images/dbicon-burjeel.png"; //dbicon.png
    }
  };

  const handleTypeChange = (e: any) => {
    setLabReportType(e.target.value);
    if (e.target.value === "All") {
      if (tabListValue === "1") {
        setLabReportsCurrList(labReportsCurrListBackup);
      } else if (tabListValue === "2") {
        setLabReportsPastList(labReportsPastListBackup);
      }
    } else if (e.target.value === "LAB") {
      if (tabListValue === "1") {
        const filteredItem = labReportsCurrListBackup.filter(
          (item: any) => item.docType === "LAB"
        );
        setLabReportsCurrList(filteredItem);
      } else if (tabListValue === "2") {
        const filteredItem = labReportsPastListBackup.filter(
          (item: any) => item.docType === "LAB"
        );
        setLabReportsPastList(filteredItem);
      }
    } else if (e.target.value === "RBF") {
      if (tabListValue === "1") {
        const filteredItem = labReportsCurrListBackup.filter(
          (item: any) => item.docType === "RBF"
        );
        setLabReportsCurrList(filteredItem);
      } else if (tabListValue === "2") {
        const filteredItem = labReportsPastListBackup.filter(
          (item: any) => item.docType === "RBF"
        );
        setLabReportsPastList(filteredItem);
      }
    }
  };

  const handleExpandChange = (panle: any) => (event: any, isExpanded: any) => {
    event.stopPropagation();
    setExpanded(isExpanded ? panle : false);
  };

  const handlePDFOpen = (e: any, report: any) => {
    e.stopPropagation();
    const val: any = {
      reportDetails: report,
      showPdfReport: pdfAuthentication,
      selectedPatient: userName,
    };
    pdfAuthentication(val, report);
    setLabReportPDFDialog(true);
  };

  const handleLocationChange = (e: any) => {
    setFacilityId(e.target.value);
  };

  const handleShowMoreClick = () => {
    setShowMoreBtnClick(true);
    setFacilityId("");
  };

  const labreportTypeList = [
    {
      value: "RBF",
    },
    {
      value: "LAB",
    },
  ];

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">{t("labReportTitle")}</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="card-body p-1 mt-1">
          <div className="card border-0 shadow-sm rounded-1rem">
            <div className="card-body py-2 px-0 position-relative">
              <TabContext value={tabListValue}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={tabListValue}
                    onChange={handleTabListChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab value="1" label="Current" />
                    <Tab value="2" label="Past" />
                  </Tabs>
                </Box>
                <div className="gx-3 light-bg py-3 row m-0 px-2">
                  <div className="col-xxl-4">
                    {tabListValue === "1" ? (
                      <p className="mt-xxl-3 mb-2">
                        Latest 1 month reports for selected patient
                      </p>
                    ) : (
                      <p className="mt-xxl-3 mb-2">
                        Latest 6 months reports for selected patient
                      </p>
                    )}
                  </div>
                  {appConfiguration.filterToBeApplied && (
                    <div className="col-xxl-2 col-xl-3 col-md-6 ms-auto">
                      <div className="form-floating">
                        <select
                          className="form-select fs-14 no-focus w-100"
                          id="selectHospital"
                          onChange={(e) => handleLocationChange(e)}
                          value={facilityId}
                        >
                          {hospitallocationList &&
                            hospitallocationList.map(
                              (item: any, index: any) => (
                                <option key={index} value={item.locationId}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                        <label htmlFor="selectHospital">Select Hospital</label>
                      </div>
                    </div>
                  )}

                  <div
                    className={`col-xxl-2 col-xl-3 col-md-6  mt-md-0 ${
                      !appConfiguration.filterToBeApplied ? "ms-auto" : "mt-3"
                    }`}
                  >
                    <div className="form-floating">
                      <select
                        className="form-select fs-14 no-focus w-100"
                        id="selectPatient"
                        onChange={(e) => handleFamilyChange(e)}
                      >
                        <option value={user.firstName + " " + user.lastName}>
                          {user.firstName} {user.lastName}
                        </option>
                        {familyMemberList &&
                          familyMemberList.map((item: any, index: any) => (
                            <option key={index} value={item.familyMemberId}>
                              {item.firstName} {item.lastName} ({item.relation})
                            </option>
                          ))}
                      </select>
                      <label htmlFor="selectPatient">Select Patient</label>
                    </div>
                  </div>
                  <div
                    className={`col-xxl-2 col-xl-3 col-md-6 mt-3 mt-xl-0 ${
                      !appConfiguration.filterToBeApplied
                        ? "mt-md-0"
                        : "mt-xl-0"
                    }`}
                  >
                    <div className="form-floating">
                      <select
                        className="form-select fs-14 no-focus w-100"
                        id="selectType"
                        onChange={(e) => handleTypeChange(e)}
                        value={labreportsType}
                      >
                        <option value="All">All</option>
                        {labreportTypeList &&
                          labreportTypeList.map((item: any, index: any) => (
                            <option key={index} value={item.value}>
                              {item.value === "LAB"
                                ? "Lab Report"
                                : "Radiology Report"}
                            </option>
                          ))}
                      </select>
                      <label htmlFor="selectType">Select Type</label>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-md-6 mt-3 mt-xl-0">
                    <div className="form-floating">
                      <input
                        onChange={(e) => labReportSearch(e)}
                        type="search"
                        id="searchReport"
                        className="form-control fs-14 no-focus w-100"
                        placeholder="Search"
                        aria-label="Search"
                      />
                      <label htmlFor="searchReport">Search By Report No.</label>
                    </div>
                  </div>
                </div>
                <TabPanel value="1">
                  {labReportsCurrList && labReportsCurrList.length > 0 ? (
                    <>
                      <div className="container-fluid">
                        <div className="row align-items-center d-none d-md-flex">
                          <div className="col-lg-4 p-3 fw-600">Report No. </div>
                          <div className="col-lg-3 p-3 fw-600">Date</div>
                          <div className="col-lg-2 p-3 fw-600">Type</div>
                          <div className="col-lg-3 p-3 fw-600">Action</div>
                        </div>

                        {labReportsCurrList &&
                          labReportsCurrList.map((item: any, index: any) => (
                            <Accordion
                              expanded={expand === index}
                              onChange={handleExpandChange(index)}
                              className="lab-accordian"
                              key={"currentLab-" + index}
                            >
                              <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <div className="row border-top align-items-center">
                                  <div className="col-md-4 col-7 px-3 pt-3 pb-md-3">
                                    {expand === index ? (
                                      <ExpandMoreIcon className="" />
                                    ) : (
                                      <KeyboardArrowRightIcon className="" />
                                    )}
                                    <span className=" ms-0">
                                      {item.docNumber}
                                    </span>
                                  </div>
                                  <div className="col-md-3 col-5 px-3 pt-3 pb-md-3">
                                    {item.orderDate}
                                  </div>
                                  <div className="col-md-3 col-7 px-3 py-2 py-md-3">
                                    {item.docType === "LAB"
                                      ? "Lab Report"
                                      : "Radiology Report"}
                                  </div>
                                  <div className="col-md-2 col-5 px-3 py-2 py-md-3">
                                    <Link
                                      to="#"
                                      className="btn-link no-focus"
                                      onClick={(e) => handlePDFOpen(e, item)}
                                    >
                                      View Report
                                    </Link>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails className="fs-p60rem">
                                <div className="d-flex flex-wrap">
                                  {item.testNames &&
                                    item.testNames.length > 0 &&
                                    item.testNames.map(
                                      (test: any, index: any) => (
                                        <p
                                          className="bg-ef1 p-1 rounded-3 me-2"
                                          key={test + index}
                                        >
                                          {test}
                                        </p>
                                      )
                                    )}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        {/* {!showMoreBtnClick &&
                          !appConfiguration.filterToBeApplied && (
                            <div className="w-100 d-flex justify-content-center">
                              <button
                                onClick={() => handleShowMoreClick()}
                                className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                              >
                                <i className="material-icons-outlined me-2">
                                  autorenew
                                </i>
                                Load More
                              </button>
                            </div>
                          )} */}
                      </div>
                    </>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Reports
                    </p>
                  ) : (
                    <>
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        {"No Reports to display"}
                      </p>
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </>
                  )}
                </TabPanel>
                <TabPanel value="2">
                  {labReportsPastList && labReportsPastList.length > 0 ? (
                    <div className="container-fluid">
                      <div className="row align-items-center d-none d-md-flex">
                        <div className="col-md-4 p-3 fw-600">Report No.</div>
                        <div className="col-md-3 p-3 fw-600">Date</div>
                        <div className="col-md-3 p-3 fw-600">Type</div>
                        <div className="col-md-2 p-3 fw-600">Action</div>
                      </div>
                      {labReportsPastList &&
                        labReportsPastList.map((item: any, index: any) => (
                          <Accordion
                            expanded={expand === index}
                            onChange={handleExpandChange(index)}
                            className="lab-accordian"
                            key={"PastLab-" + index}
                            TransitionProps={{
                              timeout: { appear: 1, enter: 1, exit: 4 },
                            }}
                            square
                            defaultExpanded
                          >
                            <AccordionSummary
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              className="p-0"
                            >
                              <div className="row border-top align-items-center">
                                <div className="col-md-4 col-7 px-3 pt-3 pb-md-3">
                                  {expand === index ? (
                                    <ExpandMoreIcon className="ms-n10" />
                                  ) : (
                                    <KeyboardArrowRightIcon className="ms-n10 ms-md-0" />
                                  )}
                                  <span className=" ms-0">
                                    {item.docNumber}
                                  </span>
                                </div>
                                <div className="col-md-3 col-5 px-3 pt-3 pb-md-3">
                                  {item.orderDate}
                                </div>
                                <div className="col-md-3 col-7 px-3 py-2 py-md-3">
                                  {item.docType === "LAB"
                                    ? "Lab Report"
                                    : "Radiology Report"}
                                </div>
                                <div className="col-md-2 col-5 px-3 py-2 py-md-3">
                                  <Link
                                    to="#"
                                    className="btn-link no-focus"
                                    onClick={(e) => handlePDFOpen(e, item)}
                                  >
                                    View Report
                                  </Link>
                                </div>
                              </div>
                            </AccordionSummary>
                            <AccordionDetails className="fs-p60rem">
                              <div className="d-flex flex-wrap">
                                {item.testNames &&
                                  item.testNames.length > 0 &&
                                  item.testNames.map(
                                    (test: any, index: any) => (
                                      <span
                                        className="bg-ef1 p-1 rounded-3 me-2 mt-2"
                                        key={test + index}
                                      >
                                        {test}
                                      </span>
                                    )
                                  )}
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </div>
                  ) : isLoading ? (
                    <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                      Please Wait! Loading Reports
                    </p>
                  ) : (
                    <>
                      <p className="fw-11 text-center text-secondary position-relative top-50 p-5">
                        {"No Reports to display"}
                      </p>
                      {/* {!showMoreBtnClick &&
                        !appConfiguration.filterToBeApplied && (
                          <div className="w-100 d-flex justify-content-center">
                            <button
                              onClick={() => handleShowMoreClick()}
                              className="btn btn-link  no-focus text-decoration-none d-flex align-items-center"
                            >
                              <i className="material-icons-outlined me-2">
                                autorenew
                              </i>
                              Load More
                            </button>
                          </div>
                        )} */}
                    </>
                  )}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={labReportDialog}
        //open= {true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {!isOtpSend ? "Verify OTP" : "Verify OTP"}
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => handleLapReportClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <>
          <DialogContent>
            <small className="text-muted mb-5">
              {t("familyMember:sentOtpTitle")}
              <span className="fw-bolder">
                {" "}
                +{familyMemberCallingCode}-
                <span id="numberEntered">{familyMemberMobile}</span>
              </span>
            </small>
            <div className="mb-3 mt-4">
              <TextField
                autoComplete="off"
                id="otp"
                label={t("login:enterOTP")}
                name="otp"
                className="otp w-100 mb-4 letter-spacing-4"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 6 }}
                error={otpError}
                value={otp}
                helperText={otpErrortext}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e: any) => {
                  handleInputChange(e, setOtp, setOtpError);
                }}
              />
            </div>
            <p className="text-muted">
              {t("login:resendOtp")}{" "}
              <span className="float-end">
                <span className="minute">{minute}</span>
                <span>:</span>
                <span className="second">{second}</span>
              </span>
            </p>

            <Link
              to="#"
              className={
                isActive ? " btn btn-link  p-0 disabled" : " btn btn-link  p-0"
              }
              onClick={(e) => {
                e.preventDefault();
                sendOtpToFamily();
              }}
            >
              {" "}
              {t("login:resendOtpLink")}
            </Link>
          </DialogContent>
          <DialogActions>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => handleLapReportClose()}
              // {() => setIsOtpSend(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary "
              disabled={!otp}
              onClick={() => handleOtp()}
            >
              {" "}
              Verify
            </button>
          </DialogActions>
        </>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={labReportPDFDialog}
        //open= {true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          View Report
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={() => setLabReportPDFDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <>
          <DialogContent
            className={`bg-ef1 iframe-body position-relative ${
              fetchPDFError ? "overflow-hidden" : ""
            }`}
          >
            <div>
              {showPDFError ? (
                <p className="text-danger fw-10 p-2">
                  Something went wrong. Try again
                </p>
              ) : null}
              {pdfShow ? (
                <div>
                  {/* <button
                    type="button"
                    className="btn-primary float-end"
                    onClick={closePdf}
                  >
                    close
                  </button> */}
                  <div className="iframeWidth">
                    {fetchPDFError ? (
                      <iframe
                        id="pdfID"
                        src=""
                        width="100%"
                        height="100%"
                      ></iframe>
                    ) : (
                      <p className="text-danger text-center">
                        Something went wrong! Try again
                      </p>
                    )}
                  </div>
                </div>
              ) : showPasswordMsg ? (
                <div className="text-center left-25 top-35 rounded-1rem body-bg mx-auto w-50 p-3 position-absolute">
                  <p>
                    This Link is Confidential and should not be shared. Please
                    enter the first 4 letters of your name in CAPITAL and the
                    date of birth (DDMMYYYY) as password.
                  </p>
                  {/* <button
                    className="btn btn-primary me-2"
                    onClick={() => setShowPasswordMsg(false)}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="btn btn-primary"
                    onClick={() => showPdfReport(selectedLabReportVal)}
                  >
                    Ok, Continue
                  </button>
                </div>
              ) : (
                <div className="text-center pos-abs left-50 top-50">
                  <h1 className="text-secondary">PDF</h1>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => setLabReportPDFDialog(false)}
            >
              Close
            </button>
          </DialogActions>
        </>
      </Dialog>

      <Snackbar
        open={showNoReportsModal}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {"No data found"}
        </Alert>
      </Snackbar>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default LabReports;
