import { API_ENDPOINTS, wpAPI_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";
import { makeRequestForWP } from "../middleware/axiosForWordpress";

export const dashboardservice = {
  logout,
  getProfile,
  saveProfile,
  saveProfileImg,
  removeProfileImg,
  getConditions,
  getBannerImg,
  getCounsltedDoc,
  askDoctor,
  feedback,
  contactUs,
  getInsurence,
  getAllNews,
  getSpecificNews,
  getAllMedia,
  getSpecificMedia,
  getAllHealthPackages,
  getSpecificHealthPackage,
  bookHealthPackage,
  getTermsAndCondition,
  getPrivacyPolicy,
  getRestrictedInsurance,
  getPatientDetails,
};

async function logout() {
  const apiPath = API_ENDPOINTS.LOGOUT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function getProfile() {
  const apiPath = API_ENDPOINTS.GET_PROFILE;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function saveProfile(data: any) {
  const apiPath = API_ENDPOINTS.SAVE_PROFILE;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      dateOfBirth: data.dateOfBirth,
      genderId: data.genderId,
      countryId: data.countryId,
      documentTypeId: data.documentTypeId,
      documentId: data.documentId,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function saveProfileImg(data: any) {
  const apiPath = API_ENDPOINTS.SAVE_PROFILE_IMG;
  const options = {
    path: apiPath,
    method: "POST",
    formdata: data,
    customHeaders: {
      "Content-Type": "multipart/form-data",
    },
  };
  return await makeRequest(options);
}
async function removeProfileImg() {
  const apiPath = API_ENDPOINTS.DELETE_PROFILE_IMG;
  const options = {
    path: apiPath,
    method: "DELETE",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getConditions() {
  const apiPath = wpAPI_ENDPOINTS.GET_ALL_SPECIALITIES;
  const options = {
    path: apiPath,
  };
  return await makeRequestForWP(options);
}

async function getBannerImg(category: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_WEB_BANNER_IMG + category + "&lang=en";
  const options = {
    path: apiPath,
  };
  return await makeRequestForWP(options);
}

async function getCounsltedDoc(data: any) {
  let brandName = localStorage.getItem("brandName");
  const apiPath =
    API_ENDPOINTS.GET_CONSULTED_DOC +
    "patientId=" +
    data.patientId +
    "&familyMemberId=" +
    data.familyMemberId +
    "&brandName=" +
    brandName;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function askDoctor(data: any) {
  const apiPath = API_ENDPOINTS.ASK_DOCTOR;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      isEmailCommunication: data.isEmailCommunication,
      isMobileCommunication: data.isMobileCommunication,
      comment: data.comment,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function feedback(data: any) {
  const apiPath = API_ENDPOINTS.FEEDBACK;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      inPatient: data.inPatient,
      expierience: data.experience,
      name: data.name,
      email: data.email,
      callingCode: data.callingCode,
      mobile: data.mobile,
      comment: data.comment,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function contactUs(data: any) {
  const apiPath = API_ENDPOINTS.CONTACT_US;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      message: data.message,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function bookHealthPackage(data: any) {
  const apiPath = API_ENDPOINTS.BOOK_HEALTH_PACKAGE;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      facilityId: [data.facilityId],
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      callingCode: data.callingCode,
      packageName: data.packageName,
      subPackageName: data.subPackageName,
      hospitalLocation: data.hospitalLocation,
      apptDate: data.apptDate,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getInsurence() {
  const apiPath = wpAPI_ENDPOINTS.GET_ALL_INSURENCE;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getAllNews() {
  const apiPath = wpAPI_ENDPOINTS.GET_ALL_NEWS;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getSpecificNews(data: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_SPECIFIC_NEWS + data.newsId;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getAllMedia() {
  const apiPath = wpAPI_ENDPOINTS.GET_ALL_MEDIA;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getSpecificMedia(data: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_SPECIFIC_MEDIA + data.mediaId;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getAllHealthPackages() {
  const apiPath = wpAPI_ENDPOINTS.GET_ALL_HEALTHPACKAGES;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getSpecificHealthPackage(packageName: any) {
  const apiPath = wpAPI_ENDPOINTS.GET_SPECIFIC_HEALTHPACKAGE + packageName;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getTermsAndCondition() {
  const apiPath = wpAPI_ENDPOINTS.GET_TERMSNCONDITION;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getPrivacyPolicy() {
  const apiPath = wpAPI_ENDPOINTS.GET_PRIVACYPOLICY;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getRestrictedInsurance(id: any) {
  const apiPath =
    wpAPI_ENDPOINTS.GET_ALL_RESTRICTED_INSURANCE + "?location_id=" + id;
  const options = {
    path: apiPath,
    method: "GET",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequestForWP(options);
}

async function getPatientDetails(data: any) {
  const apiPath = API_ENDPOINTS.GET_PATIENT_DETAILS;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      patientDetails: {
        his: data.his,
        patientId: data.patientId,
        institution: data.institution,
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
