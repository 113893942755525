import { useState, useEffect, useContext } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { loginservice } from "../../services/login.service";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function OtpForLogin() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["login"]);
  const history = useHistory();

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpErrortext, setOtpErrorText] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(60);

  const [callingCode, setCallingCode] = useState<string>("");
  const [loginMobile, setLoginMobile] = useState<string>("");

  const [loginPass, setLoginPass] = useState<string>("");

  const [channel, setChannel] = useState<boolean>(false);
  const [redirectUriValue, setRedirectUriValue] = useState<any>();
  const brandName = localStorage.getItem("brandName");

  const [showPassword, setShowPassword] = useState(false);

  const location: any = useLocation();

  useEffect(() => {
    setCallingCode(location.state.callingCode);
    setLoginMobile(location.state.loginMobile);
    setLoginPass(location.state.password);
    if (brandName === "colab") {
      setChannel(Boolean(localStorage.getItem("channel")));
      setRedirectUriValue(localStorage.getItem("colabRedirectUrl"));
    }
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          const secondCounter = counter % 60;
          const minuteCounter = Math.floor(counter / 60);
          const computedSecond =
            String(secondCounter).length === 1
              ? `0${secondCounter}`
              : secondCounter;
          const computedMinute =
            String(minuteCounter).length === 1
              ? `0${minuteCounter}`
              : minuteCounter;
          setSecond(computedSecond.toString());
          setMinute(computedMinute.toString());
          setCounter((counter) => counter - 1);
        } else {
          setIsActive(false);
          setCounter(60);
          setSecond("00");
          setMinute("00");
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  let checkUserForLogin = async () => {
    authCtx.toggleLoader(true);
    let res = await loginservice.checkUserForLogin(
      callingCode,
      loginMobile,
      true,
      loginPass
    );
    authCtx.toggleLoader(false);
    if (res.otpSent) {
      setIsActive(true);
      setModalText("OTP is sent to your mobile number");
      setModalType("success");
      setShowModal(true);
    } else {
      setModalText("Error in sending otp");
      setModalType("error");
      setShowModal(true);
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
        if (e.target.name === "otp") {
          setOtpErrorText(t("otpError"));
        }
      } else {
        const re = /^[0-9\b]+$/;
        if (!re.test(e.target.value)) {
          errorSetterName(true);
          if (e.target.name === "otp") {
            setOtpErrorText(t("numberError"));
          }
        } else {
          errorSetterName(false);
          setOtpErrorText("");
        }
      }
    }
  };

  const handleClose = () => setShowModal(false);

  function validateLogin() {
    let isValid: boolean = true;

    if (otp.trim() === "" || otpError) {
      setOtpError(true);
      isValid = false;
    } else if (otp.length < 6) {
      setOtpError(true);
      setOtpErrorText(t("otpInvalidError"));
      isValid = false;
    }

    return isValid;
  }

  let handleSubmit = async () => {
    authCtx.toggleLoader(true);
    const referenceIdForLinking = "";
    if (validateLogin()) {
      let res = await loginservice.loginUser(
        callingCode,
        loginMobile,
        loginPass,
        otp,
        referenceIdForLinking
      );
      authCtx.toggleLoader(false);
      if (
        res &&
        res.data &&
        res.message.status &&
        res.message.status === "Success"
      ) {
        if (brandName === "colab") {
          const redirectColabUrl = process.env.REACT_APP_COLAB_REDIRECT_URL;
          if (redirectColabUrl) {
            authCtx.toggleLoader(true);
            localStorage.setItem("channel", "");
            localStorage.setItem("colabRedirectUrl", "");
            window.location.href = `${redirectColabUrl}?token=${res.data.token}&refreshToken=${res.data.refreshToken}`;
            authCtx.toggleLoader(false);
          } else {
            setModalText("Something went wrong, please try again!");
            setModalType("error");
            setShowModal(true);
          }
        } else {
          authCtx.setToken(res.data.token);
          let temp = {
            userId: res.data.userId,
            firstName: res.data.firstName,
            lastName: res.data.lastName,
            gender: res.data.gender,
            email: res.data.email,
            mobile: loginMobile,
            callingCode: callingCode,
            profileImgUrl: res.data.profileImgUrl,
            profileImgFileName: res.data.profileImgFileName,
            refreshToken: res.data.refreshToken,
            roleId: res.data.roleId,
            roleName: res.data.roleName,
            refreshExpiresAt: res.data.refreshExpiresAt,
            tokenExpiresAt: res.data.tokenExpiresAt,
            isDOBAvailable: res.data.isDOBAvailable,
            facilityId: res.data.facilityId,
          };
          // localStorage.setItem("refreshToken", res.data.refreshToken);
          // localStorage.setItem("tokenExpiresAt", res.data.tokenExpiresAt);
          // localStorage.setItem("refreshExpiresAt", res.data.refreshExpiresAt);
          authCtx.setUserInfo(temp);
          history.push("/auth/dashboard");
        }
      }
      if (res.message.status === "Invalid") {
        setModalText("OTP is incorrect, please enter valid OTP");
        setModalType("error");
        setShowModal(true);
      }
    }
    authCtx.toggleLoader(false);
  };

  const back = (e: any) => {
    e.preventDefault();
    history.push("/home/login");
  };

  return (
    <>
      <div id="login">
        <>
          <Link
            to="#"
            onClick={(e) => back(e)}
            className="mt-5 d-block text-decoration-none backLogin"
          >
            <i className="bi bi-arrow-left fs-3 me-2"></i>{" "}
            <span className="fs-5 position-relative t-n1">Back</span>
          </Link>
          <h3 className="mt-3 fw-500">{t("loginWithOTP")}</h3>
          <small className="text-muted mb-5">
            {" "}
            {t("otpTitle")}
            <span className="fw-bolder">
              {" "}
              +{callingCode}-<span id="numberEntered">{loginMobile}</span>
            </span>
          </small>
          <div className="mb-2 mt-4">
            {/* <label className="form-label">{t("enterOTP")}</label> */}
            <TextField
              autoComplete="off"
              id="otp"
              label={t("enterOTP")}
              name="otp"
              className="otp w-100 mb-4 letter-spacing-4"
              variant="outlined"
              size="medium"
              inputProps={{ maxLength: 6 }}
              error={otpError}
              value={otp}
              helperText={otpErrortext}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }
              }}
              onChange={(e: any) => {
                handleInputChange(e, setOtp, setOtpError);
              }}
            />
          </div>
          <p className="text-muted">
            {t("resendOtp")}{" "}
            <span className="float-end">
              <span className="minute">{minute}</span>
              <span>:</span>
              <span className="second">{second}</span>
            </span>
          </p>
          <div className="d-flex ">
            <Link
              to="#"
              className={
                isActive ? " btn btn-link  p-0 disabled" : " btn btn-link  p-0"
              }
              onClick={(e) => {
                e.preventDefault();
                checkUserForLogin();
              }}
            >
              {" "}
              {t("resendOtpLink")}
            </Link>
          </div>
        </>

        <button
          type="submit"
          className="btn btn-primary w-100 mt-4 fs-18"
          disabled={!otp}
          onClick={() => handleSubmit()}
        >
          {" "}
          {t("continue")}
        </button>
      </div>

      {modalType === "error" ? (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default OtpForLogin;
