import React, { useState, useEffect } from "react";
import PublishNewBroadcast from "../notification/PublishNewBroadcast";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import { useTranslation } from "react-i18next";
import BroadcastHistory from "./BroadcastHistory";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function BroadcastNotification() {
  const { t } = useTranslation(["notification"]);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [refreshPage, setRefreshPage] = useState<boolean>(false);
  const [rePublishItem, setRepublishItem] = useState<any>("");
  let uaeUserInfo = JSON.parse(
    localStorage.getItem("uaeUser") || "0"
  ).toString();

  const handleAddClose = async () => {
    setRepublishItem("");
    setShowAddModal(false);
  };
  const handleAddShow = () => {
    setShowAddModal(true);
  };
  // useEffect(() => {}, [refreshPage]);

  const handleRePublishNotification = (item: any) => {
    setRepublishItem(item);
    handleAddShow();
  };
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Notification</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
            {/* key is used to refresh component */}
          </div>
        </div>

        <div className="card border-0 shadow-sm rounded-1rem">
          <div className="card-body py-2 px-0 position-relative">
            <div className="row p-4">
              <div className="col">
                <h6 className="mb-3 text-primary"> {t("History")}</h6>
              </div>
              <div className="col">
                {" "}
                <div className=" text-end col-sm">
                  <button className="btn btn-primary" onClick={handleAddShow}>
                    Create Broadcast
                  </button>
                </div>
              </div>
            </div>
            <BroadcastHistory
              refreshPage={refreshPage}
              setRefreshPage={setRefreshPage}
              handleRePublishNotification={handleRePublishNotification}
            />
          </div>
        </div>
      </div>

      <Dialog
        open={showAddModal}
        fullWidth={true}
        maxWidth="lg"
        onClose={handleAddClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Publish New Broadcast
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleAddClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <PublishNewBroadcast
              id={"0"}
              handleAddClose={handleAddClose}
              setRefreshPage={setRefreshPage}
              refreshPage={refreshPage}
              rePublishItem={rePublishItem}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BroadcastNotification;
