import React, { useContext, useEffect, useState } from "react";
import { loginservice } from "../../services/login.service";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import cList from "../../constants/countryList.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { appservice } from "../../services/app.service";
import NumberFormat from "react-number-format";
import CloseIcon from "@material-ui/icons/Close";
import PrivacyPolicy from "./PrivacyPolicy";
import TermAndCon from "./TermAndCon";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { set } from "lodash";

function RegisterUAEPass() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["login", "profile"]);
  const history = useHistory();
  const location = useLocation();

  const [docId, setDocId] = useState<string>("");
  const [docIdError, setDocIdError] = useState<boolean>(false);
  const [docIdErrorText, setDocIdErrorText] = useState<string>("");
  const [docTypeList, setDocTypeList] = useState<any>([]);

  const [tnc, setTnc] = useState<boolean>(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState<boolean>(true);
  const [disableInput, setDisableInput] = useState<boolean>(false);

  const [callingCode, setCallingCode] = useState("");
  const [loginMobile, setLoginMobile] = useState<string>("");
  const [uaePassMobile, setUAEPassMobile] = useState<string>("");

  const [userRegisterModel, setRegisterUserModal] = useState<boolean>(false);
  const [userRegisterModelMessage, setRegisterUserMessage] = useState<any>("");
  const [tncModel, setTncModel] = useState<boolean>(false);
  const [privacyModel, setPrivacyModel] = useState<boolean>(false);
  const [uaePassAccessToken, setusePassAccessToken] = useState<string>("");

  const [fNameError, setFnameError] = useState<boolean>(false);
  const [fName, setfName] = useState<string>("");
  const [lName, setlName] = useState<string>("");
  const [lNameError, setLnameError] = useState<boolean>(false);
  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [selectedDOBError, setSelectedDOBError] = useState<boolean>(false);
  const [selectedDOBErrorText, setSelectedDOBErrorText] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [genderError, setGenderError] = useState<boolean>(false);
  const [genderList, setGenderList] = useState<any>([]);
  const [country, setCountry] = useState<string>("2");
  const [countryError, setCountryError] = useState<boolean>(false);
  const [docType, setDocType] = useState(0);
  const [docTypeError, setDocTypeError] = useState<boolean>(false);
  const [countryList, setcountryList] = useState<any>([]);
  const [uuid, setUuid] = useState<string>("");
  const [referenceId, setRefrenceId] = useState<string>("");

  const handleClose = () => setRegisterUserModal(false);
  //const handleShow = () => setRegisterUserModal(true);

  const toggleTnC = () => setTnc((value) => !value);

  const togglePassworBlock = () => {
    setCallingCode(authCtx.cCode);
    setLoginMobile(authCtx.userMobile);
    setIsRegisteredUser(true);

    toggleTnC();
  };

  useEffect(() => {
    const getGenderList = async (statevalue: any) => {
      const listFromApi = await fetchGenderList();
      setGenderList(listFromApi);
      // const gendervalue = listFromApi.filter(
      //   (x: { text: any }) => x.text == statevalue.detail.data.gender
      // );
      // setGender(gendervalue[0].id);
    };

    getGenderList(location.state);

    fetchDocTypeList();

    const getCountryCode = async () => {
      const listFromApi = await fetchCountryCode();
      setcountryList(listFromApi);
    };
    getCountryCode();

    prePopulateInitValue(location.state);

    // eslint-disable-next-line
  }, []);

  const prePopulateInitValue = (initUserInfo: any) => {
    setfName(initUserInfo.firstName);
    setlName(initUserInfo.lastName);
    setEmail(initUserInfo.email);
    setDocId(initUserInfo.documentId);
    setDocType(initUserInfo.documentTypeId);
    setUuid(initUserInfo.uuid);
    setGender(initUserInfo.genderId);
    setUAEPassMobile("+" + initUserInfo.callingCode + initUserInfo.mobile);
    setRefrenceId(initUserInfo.referenceId);
  };

  let handleUAEValidNonUser = async (nonExistingUserInfo: any) => {
    authCtx.setToken(nonExistingUserInfo.data.token);
    let temp = {
      userId: nonExistingUserInfo.data.userId,
      firstName: nonExistingUserInfo.data.firstName,
      lastName: nonExistingUserInfo.data.lastName,
      gender: nonExistingUserInfo.data.gender,
      email: nonExistingUserInfo.data.email,
      mobile: nonExistingUserInfo.data.mobile,
      callingCode: nonExistingUserInfo.data.callingCode,
      profileImgUrl: nonExistingUserInfo.data.profileImgUrl,
      profileImgFileName: nonExistingUserInfo.data.profileImgFileName,
      refreshToken: nonExistingUserInfo.data.refreshToken,
      isDOBAvailable: nonExistingUserInfo.data.isDOBAvailable,
      roleId: nonExistingUserInfo.data.roleId,
      roleName: nonExistingUserInfo.data.roleName,
      refreshExpiresAt: nonExistingUserInfo.data.refreshExpiresAt,
      tokenExpiresAt: nonExistingUserInfo.data.tokenExpiresAt,
      facilityId: nonExistingUserInfo.data.facilityId,
    };
    authCtx.setMobile(temp.callingCode, temp.mobile);
    // localStorage.setItem("refreshToken", nonExistingUserInfo.data.refreshToken);
    // localStorage.setItem(
    //   "tokenExpiresAt",
    //   nonExistingUserInfo.data.tokenExpiresAt
    // );
    // localStorage.setItem(
    //   "refreshExpiresAt",
    //   nonExistingUserInfo.data.refreshExpiresAt
    // );
    authCtx.setUserInfo(temp);
    history.push("/auth/dashboard");
  };

  const fetchDocTypeList = async () => {
    let res = await appservice.getDocumentType();
    setDocTypeList(res);
  };

  const fetchCountryCode = async () => {
    let res = await appservice.getCountryList();
    return res;
  };

  const fetchGenderList = async () => {
    let res = await appservice.getGender();
    return res;
  };

  const handleDocIdChange = (e: any) => {
    let pattern = /^784[0-9]{12}$/;
    if (docType === 1) {
      let text = e.target.value;
      let emirateId = text.replace(/-/g, "").trim();
      setDocId(emirateId);
      if (pattern.test(emirateId)) {
        if (emirateId.length < 8) {
          setDocIdError(true);
          setDocIdErrorText("Please enter a valid Emirates ID");
        } else {
          setDocIdError(false);
          setDocIdErrorText("");
        }
      } else {
        setDocIdError(true);
        setDocIdErrorText("Please enter a valid Emirates ID");
      }
    } else {
      setDocId(e.target.value);
      if (e.target.value === "" || e.target.value.trim() === "") {
        setDocIdError(true);
        setDocIdErrorText("");
      } else if (e.target.value.length < 5) {
        setDocIdError(true);
        setDocIdErrorText(t("profile:docIdInvalidError"));
      } else {
        setDocIdError(false);
        setDocIdErrorText("");
      }
    }
  };

  const validationForm = () => {
    let isValid = true;
    if (selectedDOB === null) {
      setSelectedDOBError(true);
      setSelectedDOBErrorText("Please select date of birth");
      isValid = false;
    }
    return isValid;
  };

  let registerUAEUser = async () => {
    if (validationForm()) {
      const completeUserData = {
        referenceId: referenceId,
        dateOfBirth: selectedDOB,
      };
      let completeUserResponse = await loginservice.registerUaePassUser(
        completeUserData
      );
      console.log(completeUserResponse);

      if (
        completeUserResponse &&
        completeUserResponse.message.status &&
        completeUserResponse.message.status === "Success"
      ) {
        localStorage.setItem("uaeUser", "1");
        handleUAEValidNonUser(completeUserResponse);
      } else {
        setRegisterUserModal(true);
        setRegisterUserMessage(completeUserResponse.message.message);
      }
    }
  };

  const handleTncClose = () => setTncModel(false);
  const handlePrivacyClose = () => setPrivacyModel(false);

  const handleDOBChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedDOB(date);
    }
    let isValid = moment(date, "DD/MM/YYYY", true).isValid();
    if (isValid) {
      let today = moment(new Date().getDate() + 1, "DD/MM/YYYY");
      let selectedDate = moment(date, "DD/MM/YYYY");
      if (moment(selectedDate).isAfter(today)) {
        setSelectedDOBError(true);
        setSelectedDOBErrorText(t("dobRangeError"));
      } else {
        setSelectedDOBError(false);
        setSelectedDOBErrorText("");
      }
    } else {
      setSelectedDOBError(true);
      setSelectedDOBErrorText(t("dobFormatError"));
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText(t("emailValidError"));
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };

  return (
    <div id="registerUAEUser">
      {isRegisteredUser && (
        <div className="">
          <h4 className="mt-3 fw-500 mb-0">Register on Burjeel Hospitals</h4>
          <div className="mb-3">
            <h6 className="mt-3 fs-12 fw-500 text-muted">
              Please provide your basic details
            </h6>

            <div className="row g-3">
              <div className="col-sm">
                <TextField
                  required
                  id="fName"
                  label={"First Name"}
                  className="w-100  "
                  variant="outlined"
                  size="medium"
                  disabled
                  error={fNameError}
                  value={fName}
                  onChange={(e: any) => {
                    handleInputChange(e, setfName, setFnameError);
                  }}
                />
              </div>
              <div className="col-sm">
                <TextField
                  required
                  id="lName"
                  label={"Last Name"}
                  className="w-100  "
                  variant="outlined"
                  size="medium"
                  disabled
                  error={lNameError}
                  value={lName}
                  onChange={(e: any) => {
                    handleInputChange(e, setlName, setLnameError);
                  }}
                />
              </div>
            </div>
            <div className="mb-3 mt-3">
              <KeyboardDatePicker
                required
                autoOk={true}
                disableFuture
                size="medium"
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                id="dob"
                views={["year", "month", "date"]}
                label={"DOB"}
                error={selectedDOBError}
                helperText={selectedDOBErrorText}
                value={selectedDOB}
                onChange={handleDOBChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                className="m-0  w-100"
              />
            </div>

            <div className="mb-3 mt-3">
              <TextField
                autoComplete="off"
                id="loginMobile"
                label={t("mobile")}
                name="loginMobile"
                className="form-control"
                variant="outlined"
                size="medium"
                disabled
                inputProps={{ maxLength: 10 }}
                value={uaePassMobile}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>

            <div className="mb-3 mt-3">
              <TextField
                required
                id="email"
                label={"Email"}
                className="w-100  "
                variant="outlined"
                size="medium"
                disabled
                error={emailError}
                helperText={emailErrorText}
                value={email}
                onChange={(e: any) => {
                  handleEmailChange(e);
                }}
              />
            </div>

            <div className="mb-3 mt-3">
              <TextField
                required
                select
                id="gender"
                label={"Gender"}
                className="w-100  "
                variant="outlined"
                size="medium"
                disabled
                error={genderError}
                value={gender}
                onChange={(e: any) => {
                  setGender(e.target.value);
                  setGenderError(false);
                }}
              >
                {genderList &&
                  genderList.map((item: any) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.text}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="mb-3 mt-3">
              <TextField
                required
                select
                id="country"
                label={"Nationality"}
                className="w-100  "
                variant="outlined"
                size="medium"
                disabled
                error={countryError}
                value={country}
                onChange={(e: any) => {
                  setCountry(e.target.value);
                  setCountryError(false);
                }}
              >
                {countryList &&
                  countryList.map((country: any) => (
                    <MenuItem value={country.id} key={country.id}>
                      {country.text}
                    </MenuItem>
                  ))}
              </TextField>
            </div>
            <div className="mb-3 mt-3">
              <div className="row g-3">
                <div className="col-sm">
                  <TextField
                    required
                    select
                    id="docType"
                    label={t("profile:docType")}
                    className="w-100  "
                    variant="outlined"
                    size="medium"
                    error={docTypeError}
                    value={docType === 0 ? "" : docType}
                    onChange={(e: any) => {
                      setDocType(e.target.value);
                      setDocId("");
                    }}
                    disabled
                  >
                    {docTypeList &&
                      docTypeList.map((item: any) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.text}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div className="col-sm">
                  {docType === 1 ? (
                    <NumberFormat
                      required
                      customInput={TextField}
                      id="docId1"
                      className="w-100"
                      size="medium"
                      variant="outlined"
                      format="###-####-#######-#"
                      label={t("profile:docId")}
                      error={docIdError}
                      helperText={docIdErrorText}
                      value={docId}
                      onChange={(e: any) => {
                        handleDocIdChange(e);
                      }}
                      disabled
                    />
                  ) : (
                    <TextField
                      required
                      id="docId"
                      label={t("profile:docId")}
                      className="w-100"
                      variant="outlined"
                      size="medium"
                      type="tel"
                      inputProps={{ maxLength: 15 }}
                      error={docIdError}
                      helperText={docIdErrorText}
                      value={docId}
                      onChange={(e: any) => {
                        handleDocIdChange(e);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            <button
              type="submit"
              id="btnContinue"
              className="btn btn-primary w-100 mt-2 fs-18"
              // disabled={!loginMobile || !tnc || docIdError || !docType}
              onClick={() => registerUAEUser()}
            >
              Register
            </button>
          </div>
        </div>
      )}

      <Dialog
        open={tncModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handleTncClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Terms And Condition
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handleTncClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <TermAndCon />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={privacyModel}
        fullWidth={true}
        maxWidth="sm"
        onClose={handlePrivacyClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Privacy Policy
          <IconButton
            className="float-end p-1"
            aria-label="close"
            onClick={handlePrivacyClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pt-0">
          <DialogContentText id="alert-dialog-description">
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={userRegisterModel}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {userRegisterModelMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RegisterUAEPass;
