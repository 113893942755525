import React, { useContext, useEffect, useState } from "react";
import { dashboardservice } from "../../services/dashboard.service";
import Parser from "html-react-parser";
import AuthContext from "../../store/auth-context";

function PrivacyPolicy() {
  const authCtx = useContext(AuthContext);
  const [privacyPolicyDetails,setprivacyPolicyDetails] = useState<any>("");
  useEffect(()=> {
    fetchPrivacyPolicy();
    // eslint-disable-next-line
  },[]);

  const fetchPrivacyPolicy = async () =>{ 
   authCtx.toggleLoader(true);
    const res = await dashboardservice.getPrivacyPolicy();
    if(res && res.data && res.data.status === "success"){
      setprivacyPolicyDetails(res.data.data);
    }
    authCtx.toggleLoader(false);
  }

  return (
    <div className="container background-white">
      <div className="row">
        <div className="col-12">
          {privacyPolicyDetails && Parser(privacyPolicyDetails.page_text)}
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
