import { Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { finddoctorservice } from "../../services/findDoctor.services";
import AuthContext from "../../store/auth-context";

function SearchResults() {
  const authCtx = useContext(AuthContext);
  const [doctorSearch, setDoctorSearch] = useState<string>("");
  const [doctorSearchList, setDoctorSearchList] = useState<any>([]);
  const [docSearchError, setDocSerachError] = useState<any>(false);
  const [docSerachMessage, setDocSearchErroeMessage] = useState<any>("");
  const [searchValue, setSearchValue] = useState<any>("");

  const history = useHistory();
  const loc: any = useLocation();
  const value: any = loc.state.doctorSearchString;

  useEffect(() => {
    const handleDocSearch = () => {
      handleDoctorSearch(value);
    };

    handleDocSearch();
    // eslint-disable-next-line
  }, [loc]);

  const handleSearch = (e: any) => {
    e.preventDefault();
    setDoctorSearch(e.target.value);
    handleDoctorSearch(e.target.value);
  };

  const handleDoctorSearch = async (docSearch: any) => {
    setSearchValue(docSearch);

    if (docSearch.length > 2) {
      let res = await finddoctorservice.searchDoctor(docSearch);

      if (res && res.data && res.data.status && res.data.status === "success") {
        setDoctorSearchList(res.data.data);
        authCtx.toggleLoader(false);
      } else {
        setDoctorSearchList(res.data.data);
        authCtx.toggleLoader(false);
      }
    }
  };

  const search = () => {
    if (doctorSearch === "") {
      setDocSerachError(true);
      setDocSearchErroeMessage("Search first");
    } else {
      handleDoctorSearch(doctorSearch);
      setDoctorSearch("");
    }
  };

  const handleClick = (item: any) => {
    history.push({
      pathname: "/auth/findDoctor/",
      state: { item: item, search: "doc" },
    });
  };

 const openMobileMenu = (e: any) => {
   e.preventDefault();

   document.getElementById("leftMenu")?.classList.remove("d-none");
 };

 return (
   <>
     <div className="main-content animate py-4 px-3 px-sm-4">
       <div className="d-flex align-items-center mb-3">
         <a
           href="#"
           className="d-inline-block d-sm-none text-dark"
           onClick={(e) => openMobileMenu(e)}
         >
           <i className="material-icons-outlined me-2">menu</i>
         </a>
         <div className="title">
           <h2>Search</h2>
         </div>
       </div>
       <div className="row">
         <div className="col-lg-12 position-relative">
           <p className="mb-2">Search Doctor By Name, Specialty, Hospital</p>
           <div className="card  border-0 shadow-sm rounded-10">
             <div className="d-sm-flex">
               <div className="p-2 flex-fill">
                 <div className="input-group ">
                   <span className="input-group-text bg-transparent border-0 p-2">
                     <i className="material-icons-outlined text-999">search</i>
                   </span>
                   <input
                     type="search"
                     className="form-control border-0 fs-14 no-focus"
                     value={doctorSearch}
                     placeholder="Search doctor by name, specialty, hospital"
                     onChange={(e) => handleSearch(e)}
                   />
                 </div>
               </div>
               <div className="p-2">
                 <button
                   className="btn btn-link text-white btn-primary d-block d-sm-flex"
                   onClick={() => search()}
                 >
                   Search
                 </button>
               </div>
             </div>
           </div>
           <ul
             className={`card border-0 shadow-sm rounded-10 text-start p-0 m-0 auto-complete position-absolute w-100 overflow-auto mxh-160 zindex ${
               doctorSearch.length < 2 ? "d-none" : " "
             }`}
           >
             {doctorSearchList &&
               doctorSearchList.map((item: any, index: any) => (
                 <li className="d-block" key={index}>
                   <Link
                     className="p-3 d-block text-dark text-decoration-none"
                     onClick={() => handleClick(item)}
                   >
                     {item.name}
                     <small className="float-end text-999">
                       {item.cat_type}
                     </small>{" "}
                     <small className="text-999">{item.location}</small>
                   </Link>
                 </li>
               ))}
           </ul>
         </div>
       </div>
       <div className="row mt-4 mb-6rem">
         <div className="col-12">
           <p className="mb-2 float-start">
             Search Result for <span className="fw-bold">{searchValue}</span>
           </p>

           <div className="col-lg-12 d-flex mt-5 position-relative">
             <ul
               className={
                 "card flex-fill border-0 shadow-sm rounded-10 text-start p-0 m-0 auto-complete position-absolute w-100 overflow-auto mxh-16"
               }
             >
               {doctorSearchList &&
                 doctorSearchList.length > 0 &&
                 doctorSearchList.map((item: any, index: any) => (
                   <li className="d-block h-50" key={index}>
                     <Link
                       className="p-1 m-3 mb-0 d-block text-dark text-decoration-none"
                       onClick={() => handleClick(item)}
                     >
                       <h5>{item.name}</h5>
                       <small className="float-end text-999">
                         {item.cat_type}
                       </small>{" "}
                       <p className="text-999">{item.location}</p>
                       <hr className="w-100 my-4 bg-secondary bg-opacity-50" />
                     </Link>
                   </li>
                 ))}
             </ul>
             {doctorSearchList.length === 0 && (
               <div className="text-999">
                 <h3 className="position-relative ">
                   <i className="material-icons-outlined fs-3 me-2">info</i>
                   No Data Available
                 </h3>
               </div>
             )}
           </div>
         </div>
       </div>
     </div>
     <Snackbar
       open={docSearchError}
       autoHideDuration={3000}
       onClose={() => setDocSerachError(false)}
     >
       <Alert onClose={() => setDocSerachError(false)} severity="error">
         {docSerachMessage}
       </Alert>
     </Snackbar>
   </>
 );
}

export default SearchResults;
