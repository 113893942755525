import { Link } from "react-router-dom";
const HealthToolsCard = () => {
  return (
    <div className="card scale-hover animate border-0 shadow-sm rounded-1rem overflow-hidden cursor-pointer">
      <Link to="/auth/healthtools" className="text-decoration-none">
        <div className="d-flex justify-content-center align-item-center btn-primary py-3">
          <i className="fa-solid fa-heart-pulse fs-95 p-2"></i>
        </div>
        <div className="card-body p-2 py-3 position-relative">
          <div className="position-absolute left-0 w-100 gradient-bw h-64p t-n64"></div>
          <div className="card-hover position-absolute w-100 left-0 top-0 bg-primary animate"></div>
          <h6 className="fw-bold mb-1 text-truncate">Health Tap</h6>
          <p className="m-0 text-muted fs-12 text-truncate">
            Keep a tap on your health
          </p>
        </div>
      </Link>
    </div>
  );
};

export default HealthToolsCard;
