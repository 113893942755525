import { useState, useContext } from "react";
import AuthContext from "../store/auth-context";
import { loginservice } from "../services/login.service";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

function ChangePassword() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["changePassword"]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");

  const [oldPass, setOldPass] = useState<string>("");
  const [oldPassError, setOldPassError] = useState<boolean>(false);
  const [oldPassErrorText, setOldPassErrorText] = useState<string>("");

  const [pass, setPass] = useState<string>("");
  const [passError, setPassError] = useState<boolean>(false);
  const [passErrorText, setPassErrorText] = useState<string>("");

  const [cPass, setCpass] = useState<string>("");
  const [cpassError, setCpassError] = useState<boolean>(false);
  const [cPassErrorText, setCpassErrorText] = useState<string>("");

  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseDownNewPassword = () => setShowNewPassword(!showNewPassword);

  const [showComparePassword, setShowComparePassword] = useState(false);
  const handleClickShowComparePassword = () =>
    setShowComparePassword(!showComparePassword);
  const handleMouseDownComparePassword = () =>
    setShowComparePassword(!showComparePassword);

  let uaeUserInfo = JSON.parse(localStorage.getItem("uaeUser") || "0").toString();

  const handleClose = () => {
    setShowModal(false);
  };
  const handleShow = () => {
    setShowModal(true);
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any,
    errorSetterText?: any
  ) => {
    setterName(e.target.value);

    if (e.target.value === "" || e.target.value.trim() === "") {
      errorSetterName(true);
      errorSetterText(t(""));
    } else {
      if (e.target.value.length > 50 || e.target.value.length < 8) {
        errorSetterName(true);
        errorSetterText(t("invalidPassword"));
      } else {
        errorSetterName(false);
        errorSetterText(t(""));
      }
    }
  };

  const handleConfirmPasswordChange = (e: any) => {
    setCpass(e.target.value);
    let confirmPass = e.target.value;
    if (e.target.value === "" || e.target.value.trim() === "") {
      setCpassError(true);
      setCpassErrorText("");
    } else {
      if (e.target.value.length > 50 || e.target.value.length < 8) {
        setCpassError(true);
        setCpassErrorText(t("invalidPassword"));
      } else {
        if (pass !== confirmPass) {
          setCpassError(true);
          setCpassErrorText(t("passNotMatch"));
        } else {
          setCpassError(false);
          setCpassErrorText("");
        }
      }
    }
  };

  function validateForm() {
    let isValid: boolean = true;

    if (pass.trim() === "" || passError) {
      setPassError(true);
      isValid = false;
    }
    if (cPass.trim() === "" || cpassError) {
      setCpassError(true);
      isValid = false;
    }
    if (oldPass.trim() === "" || oldPassError) {
      setOldPassError(true);
      isValid = false;
    }
    return isValid;
  }
  let handleSubmit = async () => {
    if (validateForm()) {
      if (oldPass === pass) {
        handleShow();
        setModalText(t("oldNewPassSame"));
        setModalType("error");
        setTimeout(() => {
          setPass("");
          setCpass("");
          handleClose();
          setModalText("");
        }, 3000);
      } else {
        authCtx.toggleLoader(true);

        let res = await loginservice.changePass(oldPass, pass);
        if (res.message.status === "Success") {
          authCtx.toggleLoader(false);
          handleShow();
          setModalText(t("passChangeSuccess"));
          setModalType("success");
          setTimeout(() => {
            setOldPass("");
            setPass("");
            setCpass("");
            handleClose();
          }, 3000);
        }
        if (res.message.status === "Invalid") {
          if (res.message.message === "Incorrect old password") {
            setModalText(t("oldPassError"));
          } else {
            setModalText(t("passChangeError"));
          }
          authCtx.toggleLoader(false);
          handleShow();
          setModalType("error");

          setTimeout(() => {
            setOldPass("");
            setPass("");
            setCpass("");
            handleClose();
            setModalText("");
          }, 3000);
        }
      }
    }
  };

  return (
    <>
      <div className="form-floating mb-3">
        <TextField
          id="oldPass"
          label={t("oldPass")}
          className="w-100  "
          variant="outlined"
          size="small"
          disabled = {uaeUserInfo === "1"? true : false}
          type={showOldPassword ? "text" : "password"}
          error={oldPassError}
          helperText={oldPassErrorText}
          value={oldPass}
          onChange={(e: any) => {
            handleInputChange(
              e,
              setOldPass,
              setOldPassError,
              setOldPassErrorText
            );
          }}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowOldPassword}
                  onMouseDown={handleMouseDownOldPassword}
                >
                  {showOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="form-floating mb-3">
        <TextField
          id="pass"
          label={t("newPass")}
          className="w-100  "
          variant="outlined"
          size="small"
          disabled = {uaeUserInfo === "1"? true : false}
          type={showNewPassword ? "text" : "password"}
          error={passError}
          helperText={passErrorText}
          value={pass}
          onChange={(e: any) => {
            handleInputChange(e, setPass, setPassError, setPassErrorText);
          }}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                >
                  {showNewPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="form-floating mb-3">
        <TextField
          id="cPass"
          label={t("confirmPass")}
          className="w-100  "
          variant="outlined"
          size="small"
          disabled = {uaeUserInfo === "1"? true : false}
          type={showComparePassword ? "text" : "password"}
          error={cpassError}
          helperText={cPassErrorText}
          value={cPass}
          onChange={(e: any) => {
            handleConfirmPasswordChange(e);
          }}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowComparePassword}
                  onMouseDown={handleMouseDownComparePassword}
                >
                  {showComparePassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="text-end">
        <button className="btn btn-primary" onClick={() => handleSubmit()}  disabled = {uaeUserInfo === "1"? true : false}>
          {t("changePass")}
        </button>
      </div>
      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default ChangePassword;
