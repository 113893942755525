import React from 'react'

const BrandsCard = () => {
    return (
        <div className="card border-0 shadow-sm rounded-1rem mb-4">
            <div className="card-body p-4">
                <h6 className="fw-bold text-primary">Brands</h6>
                <div className="row">
                    <div className="col-6 col-sm-3 col-md-6 p-2">
                        <img src={"/assets/images/burjeel.png"} className="img-fluid" alt="logo" />
                    </div>
                    <div className="col-6 col-sm-3 col-md-6 p-2">
                        <img src={"/assets/images/medeor.png"} className="img-fluid" alt="logo" />
                    </div>
                    <div className="col-6 col-sm-3 col-md-6 p-2">
                        <img src={"/assets/images/lifecare.png"} className="img-fluid" alt="logo" />
                    </div>
                    {/* <div className="col-6 col-sm-3 col-md-6 p-2">
                        <img src={"/assets/images/tajmeel.png"} className="img-fluid" alt="logo" />
                    </div> */}
                    <div className="col-6 col-sm-3 col-md-6 p-2">
                        <img src={"/assets/images/lifeline.png"} className="img-fluid" alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BrandsCard
