import React from 'react'
import { Link } from 'react-router-dom'

function LocationCard() {
  return (
    <div className="card scale-hover animate border-0 shadow-sm rounded-1rem overflow-hidden cursor-pointer">
    <Link
      to="/auth/locations"
      className="text-decoration-none"
    >
      <div className="card-hover position-absolute w-100 h-100 text-center text-white d-flex justify-content-center align-items-center animate">
    
      </div>
      <div className="d-flex justify-content-center align-item-center btn-primary py-3"><i className="fa-solid fa-location-dot fs-95 p-2"></i></div>
      <div className="card-body p-3 position-relative">
      <div className="position-absolute left-0 w-100 gradient-bw h-64p t-n64"></div>
      <div className="card-hover position-absolute w-100 left-0 top-0 bg-primary animate"></div>
        <h6 className="fw-bold mb-1 text-truncate">Location</h6>
        <p className="m-0 text-muted fs-12 text-truncate">View our locations</p>
      </div>
    </Link>
  </div>
  )
}

export default LocationCard