import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const bookAppointmentService = {
  bookAppointment,
  deleteAppointment,
  updateAppointment,
  getDepartmentCodesList,
};

async function bookAppointment(data: any) {
  const apiPath = API_ENDPOINTS.BOOK_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      facilityId: [data.facilityId],
      appointmentdetails: {
        apptDate: data.apptDate,
        apptStartTime: data.apptStartTime,
        apptEndTime: data.apptEndTime,
        patientId: data.patientId,
        familyMemberId: data.familyMemberId,
        callpoint: data.callpoint,
        department: data.department,
        physicianNo: data.physicianNo,
        physicianLicense: data.physicianLicense,
        scheduleType: data.scheduleType,
      },
      patientdetails: {
        comment: data.comment,
        title: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dateOfBirth: data.dateOfBirth,
        identityNumber: data.identityNumber,
        mobileNo: data.mobileNo,
        genderId: data.genderId,
        nationality: data.nationality,
        countryId: data.countryId,
        documentTypeId: data.documentTypeId,
        identityDocExpiryDate: data.identityDocExpiryDate,
        patientLang: data.patientLang,
        occupation: data.occupation,
        bloodGroup: data.bloodGroup,
        externalPatientId: data.externalPatientId,
        address: {
          country: data.country,
          city: data.city,
          region: data.region,
          streetNumber: data.streetNumber,
          postOfficeBox: data.postOfficeBox,
        },
        kinFirstName: data.kinFirstName,
        kinLastName: data.kinLastName,
        kinCountry: data.kinCountry,
        kinTelephone: data.kinTelephone,
        kinRelationship: data.kinRelationship,
      },
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function deleteAppointment(data: any) {
  const apiPath = API_ENDPOINTS.DELETE_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "DELETE",
    bodyObj: {
      facilityId: [data.facilityId],
      appointmentId: data.appointmentId,
      reason: data.reason,
      changeReason: "",
      extensions: {
        additionalProp1: "",
        additionalProp2: "",
        additionalProp3: "",
      },
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function updateAppointment(data: any) {
  const apiPath = API_ENDPOINTS.UPDATE_APPOINTMENT;
  const options = {
    path: apiPath,
    method: "PUT",
    bodyObj: {
      facilityId: [data.facilityId],

      appointmentId: data.appointmentId,

      apptDate: data.apptDate,

      apptTime: data.apptTime,

      duration: "",

      department: "",

      patientNo: data.patientNo,

      physicianNo: data.physicianNo,

      physicianLicense: data.physicianLicense,

      changeReason: "",

      mobileNo: data.mobileNo,

      extensions: {
        additionalProp1: "",

        additionalProp2: "",

        additionalProp3: "",
      },
    },

    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function getDepartmentCodesList() {
  const apiPath = API_ENDPOINTS.GET_DEPARTMENT_CODES;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
