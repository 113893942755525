import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Notification from "../common/Notification";
import UserMenu from "../common/UserMenu";
import AuthContext from "../../store/auth-context";
import { bookAppointmentService } from "../../services/bookAppointment.service";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { familyMemberservice } from "../../services/familyMember.services";
import TeleAppointmentBooked from "./TeleAppointmentBooked";
import { teleconsulationService } from "../../services/teleconsult.service";

function BookTeleAppointments() {
  const [beginDate, setBeginDate] = useState<any>(
    new Date().toISOString().slice(0, 10)
  );
  const [morningSlots, setMorningSlot] = useState<any>([]);
  const [afternoonSlots, setAfternoonSlot] = useState<any>([]);
  const [eveningSlots, setEveningSlot] = useState<any>([]);
  const [futureDates, setFutureDates] = useState<any>([]);
  const [timeSlot, setTime] = useState<any>("");
  const [patientName, setPatientName] = useState<any>("");
  const [timeItem, setTimeItem] = useState<any>("");
  const authCtx = useContext(AuthContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("");
  const [appointmentBookedModal, setAppointBookedModal] =
    useState<boolean>(false);
  const [familyMemberList, setFamilyMemberList] = useState<any>([]);
  const [familyMemberId, setFamilyMemberId] = useState<any>(null);
  const [isValidUser, setIsValidUser] = useState<boolean>(true);
  const [bookingConfirmation, setBookingConfirmation] =
    useState<boolean>(false);
  const [deptList, setDeptList] = useState<any>([]);
  const [apptRefNumber, setAppointmentRefNo] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [CommentError, setCommenterror] = useState<boolean>(false);
  const [commentErrorText, setCommentErrorText] = useState<string>("");

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  let location = useLocation();
  let doctorValue: any = location.state;

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    const fetchDepartments = () => {
      fetchDepartmentCodes();
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    setPatientName(user.firstName + " " + user.lastName);
    const fetchTime = () => {
      fetchTimeSlot();
    };
    fetchTime();

    const getFamilyList = async () => {
      fetchFamilyList();
    };
    getFamilyList();
    // eslint-disable-next-line
  }, [beginDate]);

  const fetchDepartmentCodes = async () => {
    const deptList = await bookAppointmentService.getDepartmentCodesList();
    if (deptList.length > 0) {
      setDeptList(deptList);
    }
  };

  const fetchFamilyList = async () => {
    const listFromApi = await familyMemberservice.getFamilyList();
    if (
      listFromApi &&
      listFromApi.message &&
      listFromApi.message.status === "Success"
    ) {
      setFamilyMemberList(listFromApi.data);
    } else {
      setFamilyMemberList([]);
    }
  };

  const handleDateChange = (e: any) => {
    setBeginDate(e.target.value);
    setTime("");
  };

  const fetchTimeSlot = async () => {
    authCtx.toggleLoader(true);
    let timeData: any = {};
    timeData = {
      facilityId: doctorValue.locationId,

      department: doctorValue.department_code,

      physicianLicense: doctorValue.physician_code,

      physicianNo: doctorValue.physician_number,

      providerLicence: "",

      beginDate: beginDate,

      endDate: "",
    };
    // authCtx.toggleLoader(false);
    if (deptList.includes(timeData.department)) {
    } else {
      let result = await teleconsulationService.getTimeSlot(timeData);
      if (result && result.status === 500) {
        console.log(result.message);
      } else {
        if (result) {
          setMorningSlot(result.morningSlots);
          setAfternoonSlot(result.afternoonSlots);
          setEveningSlot(result.eveningSlots);
          setFutureDates(result.futureDates);
        }
      }
    }
    authCtx.toggleLoader(false);
  };

  const hanldeTimeChange = (e: any, timeItem: any) => {
    setTime(e.target.value);
    setTimeItem(timeItem);
  };

  const handleFamilyChange = (e: any) => {
    if (e.target.value === user.firstName + " " + user.lastName) {
      setPatientName(user.firstName + " " + user.lastName);
      setFamilyMemberId(null);
    } else {
      setFamilyMemberId(Number(e.target.value));

      let familMember = familyMemberList.find(
        (item: any) => item.familyMemberId === Number(e.target.value)
      );
      let patientName = familMember.firstName + " " + familMember.lastName;
      setPatientName(patientName);
    }
  };

  function validationForm() {
    let isValid = true;
    // if (comment === "") {
    //   setCommenterror(true);
    //   setCommentErrorText("Please enter a reason for teleconsultation");
    //   isValid = false;
    // }
    return isValid;
  }

  const confirmBooking = async () => {
    let data: any;
    if (familyMemberId !== 0 && familyMemberId !== null) {
      let familMember = familyMemberList.find(
        (item: any) => item.familyMemberId === familyMemberId
      );
      if (familMember.age > 18) {
        if (
          familMember.mobile === user.mobile &&
          familMember.callingCode === user.callingCode
        ) {
          setShowModal(true);
          setModalType("error");
          setModalText(
            "family member mobile number is same as login user mobile number"
          );
          setIsValidUser(false);
        }
      }
    }

    if (validationForm()) {
      if (isValidUser) {
        authCtx.toggleLoader(true);
        data = {
          // facilityId: doctorValue.locationId,
          facilityId: "BU10",

          apptDate: timeItem.date,
          apptStartTime: timeItem.startTime,
          apptEndTime: timeItem.endTime,
          patientId: user.userId,
          familyMemberId: familyMemberId,
          callpoint: "",
          department: timeItem.department,
          physicianNo: timeItem.physicianNo,
          physicianLicense: timeItem.physicianLicense,
          scheduleType: "TELEMED",
          comment: comment,
          title: "Mr",
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          dateOfBirth: "",
          genderId: 0,
          identityNumber: "",
          mobileNo: user.mobile,
          nationality: "",
          countryId: user.countryCode,
          documentTypeId: 0,
          identityDocExpiryDate: "",
          patientLang: "",
          occupation: "",
          bloodGroup: "",
          externalPatientId: "",
          country: "",
          city: "",
          region: "",
          streetNumber: "",
          postOfficeBox: "",
          kinFirstName: "",
          kinLastName: "",
          kinCountry: "",
          kinTelephone: "",
          kinRelationship: "",
        };
        const response = await teleconsulationService.bookAppointmentForTele(
          data
        );
        authCtx.toggleLoader(false);
        if (response && response.data && response.data.appointmentRefNo) {
          setAppointmentRefNo(response.data.appointmentRefNo);
          setAppointBookedModal(true);
          if (familyMemberId === 0) {
            localStorage.setItem("isPatientRecordCall", "false");
          }
        } else {
          if (
            response &&
            response.message &&
            response.message.message &&
            response.message.status[0] === "E"
          ) {
            handleShow();
            setModalText(response.message.message[0]);
            setModalType("error");
            setTimeout(() => {
              setModalText("");
              handleClose();
            }, 5000);
          } else if (
            response.errorResponse &&
            response.errorResponse.data.error &&
            response.errorResponse.data.error.detail
          ) {
            handleShow();
            setModalText(
              response.errorResponse && response.errorResponse.data.error.detail
            );
            setModalType("error");
            setTimeout(() => {
              setModalText("");
              handleClose();
            }, 5000);
          } else {
            handleShow();
            setModalText("Something went wrong");
            setModalType("error");
            setTimeout(() => {
              setModalText("");
              handleClose();
            }, 5000);
          }
        }
      }
    }
  };

  const handleComments = (e: any) => {
    setComment(e.target.value);
    setCommentErrorText("");
    setCommenterror(false);
  };
  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <>
      <div className="main-content animate py-4 px-3 px-sm-4">
        <div className="d-flex align-items-center mb-3">
          <a
            href="#"
            className="d-inline-block d-sm-none text-dark"
            onClick={(e) => openMobileMenu(e)}
          >
            <i className="material-icons-outlined me-2">menu</i>
          </a>
          <h3 className="fs-18-24">Request Teleconsultation</h3>
          <div className="ms-auto mw-90px">
            <Notification />
            <UserMenu />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="card border-0 shadow-sm rounded-1rem">
              <div className="card-body p-4">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    {" "}
                    <img
                      src={doctorValue && doctorValue.image}
                      className="img-h-75  rounded-circle dr-photo"
                      alt="dr-img"
                    />{" "}
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fw-bold mt-0 mb-1">
                      {doctorValue && doctorValue.name}
                    </h6>
                    <p className="fw-bold text-muted mb-1">
                      General Practitioner
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    style={{ backgroundColor: "white" }}
                    className=" ms-auto text-dark border-0 pt-1 float-end"
                    onClick={() => {
                      let date = new Date(beginDate);
                      date.setDate(date.getDate() + 1);
                      let newDate = date.toISOString().slice(0, 10);
                      setBeginDate(newDate);
                      setTime("");
                    }}
                  >
                    <i className="material-icons-outlined"> chevron_right </i>
                  </button>
                  <button
                    style={{
                      pointerEvents:
                        beginDate === new Date().toISOString().slice(0, 10)
                          ? "none"
                          : "auto",
                      backgroundColor: "white",
                    }}
                    className="text-dark border-0 pt-1 float-end"
                    onClick={() => {
                      let date = new Date(beginDate);
                      date.setDate(date.getDate() - 1);
                      let newDate = date.toISOString().slice(0, 10);
                      setBeginDate(newDate);
                      setTime("");
                    }}
                  >
                    <i className="material-icons-outlined"> chevron_left </i>
                  </button>
                  <label className="fw-bold mb-1 mt-1">Choose Date</label>

                  <input
                    type="date"
                    className="form-control fs-14 no-focus"
                    id="slotDate"
                    value={beginDate}
                    onChange={(e) => handleDateChange(e)}
                    min={new Date().toISOString().slice(0, 10)}
                  />
                </div>
                <div className="fs-12 mt-4 border-top pt-4">
                  <h6 className="mb-4 fs-14">
                    Showing slots for{" "}
                    <span className="fw-bold">
                      {" "}
                      {new Date(beginDate).toLocaleString("en-uk", {
                        weekday: "long",
                        day: "2-digit",
                        year: "numeric",
                        month: "short",
                      })}
                    </span>
                  </h6>
                  <div className="mb-3">
                    <h6 className="fs-12">Morning</h6>
                    {morningSlots && morningSlots.length > 0 ? (
                      morningSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Morning"
                            id={"morning" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"morning" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"morning" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Afternoon</h6>
                    {afternoonSlots && afternoonSlots.length > 0 ? (
                      afternoonSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Afternoon"
                            id={"afternoon" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"afternoon" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"afternoon" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Evening</h6>
                    {eveningSlots && eveningSlots.length > 0 ? (
                      eveningSlots.map((timeItem: any, index: any) => (
                        <>
                          <input
                            key={index}
                            type="radio"
                            className="btn-check no-focus"
                            name="Evening"
                            id={"evening" + index}
                            value={timeItem.text}
                            checked={timeSlot === timeItem.text}
                            onChange={(e) => hanldeTimeChange(e, timeItem)}
                          />

                          <label
                            key={"evening" + index}
                            className="btn btn-outline-primary btn-xs mb-1 me-1 fs-12 w-150 no-focus"
                            htmlFor={"evening" + index}
                          >
                            {timeItem.text}
                          </label>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slot available
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <h6 className="fs-12">Next Available Slots</h6>
                    {futureDates && futureDates.length > 0 ? (
                      futureDates.map((futureItem: any, index: any) => (
                        <button
                          key={index}
                          className="btn btn-outline-success btn-xs mb-1 me-1 fs-12k w-185 no-focus"
                          onClick={() => {
                            setBeginDate(futureItem);
                            setTime("");
                          }}
                        >
                          {new Date(futureItem).toLocaleString("en-uk", {
                            weekday: "short",
                            day: "2-digit",
                            year: "numeric",
                            month: "short",
                          })}
                        </button>
                      ))
                    ) : (
                      <>
                        <div className="text-999">
                          <i className="material-icons-outlined fs-3 me-2">
                            info
                          </i>
                          <span className="position-relative t-n10">
                            No slots available for next 30 days
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <div className="card border-0 shadow-sm rounded-1rem position-sticky top-0">
              <div className="card-body">
                <div className="mt-2">
                  <select
                    className="form-select fs-14 no-focus"
                    id="selectPatient"
                    onChange={(e) => handleFamilyChange(e)}
                    value={familyMemberId}
                  >
                    <option value={user.firstName + " " + user.lastName}>
                      {user.firstName} {user.lastName}
                    </option>
                    {familyMemberList &&
                      familyMemberList.map((item: any, index: any) => (
                        <option key={index} value={item.familyMemberId}>
                          {item.firstName} {item.lastName} ({item.relation})
                        </option>
                      ))}
                  </select>
                </div>
                <div className="mt-4">
                  <label className="fw-bold mb-2">Timing</label>
                  {timeSlot ? (
                    <p className="fs-14 m-0">
                      {timeSlot}, {new Date(beginDate).toDateString()}
                    </p>
                  ) : (
                    <p className="fs-14 m-0">--:--</p>
                  )}
                </div>
                {/* <div className="mt-2">
                  <p className="fw-bold">Reason For Teleconsultation</p>
                  <TextField
                    id="outlined-multiline-static"
                    variant="outlined"
                    label="Description"
                    multiline
                    rows={4}
                    value={comment}
                    onChange={(e: any) => handleComments(e)}
                    error={CommentError}
                    className="form-control"
                    inputProps={{ maxLength: 250 }}
                  />
                </div> */}
                <button
                  className="btn btn-primary mt-3 w-100"
                  onClick={() => setBookingConfirmation(true)}
                  disabled={timeSlot === ""}
                >
                  Request Teleconsultation
                </button>{" "}
                <p className="text-muted mt-4">
                  - Burjeel team will connect you for further details &
                  Consultation fees.
                  <br /> - Consultation fees will be subjected to your insurance
                  provider.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={appointmentBookedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="bg-ef1">
          <TeleAppointmentBooked
            appointmentDoctor={[doctorValue]}
            timeSlot={timeSlot}
            timeItem={timeItem}
            beginDate={beginDate}
            patientName={patientName}
            apptRefNumber={apptRefNumber}
            book={"Book"}
          />
        </DialogContent>
      </Dialog>

      <Dialog
        open={bookingConfirmation}
        onClose={() => setBookingConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="text-primary">Confirmation</DialogTitle>
        <DialogContent>
          <h6 className="pe-4 font-weight-bold">
            Are you sure, you want to requsest this Teleconsultation?
          </h6>
        </DialogContent>
        <DialogActions className="my-4 pe-4">
          <button
            className="btn btn-secondary"
            onClick={() => setBookingConfirmation(false)}
          >
            No, Close
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              confirmBooking();
              setBookingConfirmation(false);
            }}
          >
            Yes, Continue
          </button>
        </DialogActions>
      </Dialog>

      {modalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      )}
      {modalType === "success" && (
        <Snackbar
          open={showModal}
          onClose={() => {
            handleClose();
            setModalText("");
          }}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default BookTeleAppointments;
