import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Snackbar, TextField } from "@material-ui/core";
import { finddoctorservice } from "../../services/findDoctor.services";
import cList from "../../constants/countryList.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AuthContext from "../../store/auth-context";
import { Alert } from "@mui/material";
import { dashboardservice } from "../../services/dashboard.service";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Parser from "html-react-parser";

function BookHealthPackages(props: any) {
  const { t } = useTranslation(["familyMember", "profile", "login"]);
  const authCtx = useContext(AuthContext);

  const [fName, setFname] = useState<any>("");
  const [fNameError, setFnameError] = useState<boolean>(false);

  const [lName, setLname] = useState<any>("");
  const [lNameError, setLnameError] = useState<boolean>(false);

  const [callingCode, setCallingCode] = useState<any>("");
  const [mobile, setMobile] = useState<any>("");
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [mobileErrorText, setMobileErrorText] = useState<any>("");

  const [email, setEmail] = useState<any>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<any>("");

  const [hospitalId, setHospitalId] = useState<any>("");
  const [hospitalList, setHospitalList] = useState<any>([]);
  const [hospitalError, setHospitalError] = useState<boolean>(false);

  const [packages, setPackage] = useState<any>("");
  const [packageError, setPackageError] = useState<boolean>(false);

  const [subPackage, setSubPackage] = useState<any>("");
  const [subPackagesList, setSubPackagesList] = useState<any>([]);
  const [subPackageError, setSubPackageError] = useState<boolean>(false);

  const [selectedApptDate, setSelectedApptDate] = useState<Date | null>(null);
  const [selectedApptDateError, setSelectedApptDateError] =
    useState<boolean>(false);
  const [selectedApptDateErrorText, setSelectedApptDateErrorText] =
    useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalText, setShowModalText] = useState<any>("");
  const [showModalType, setShowModalType] = useState<any>("");

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  useEffect(() => {
    setPackage(props.packageName);
    setSubPackage(props.subPackageName);
    setSubPackagesList(props.healthPackagesList);
    setFname(user.firstName);
    setLname(user.lastName);
    setEmail(user.email);
    setMobile(user.mobile);
    setCallingCode(user.callingCode);

    const getHospitalList = () => {
      fetchHospitalList();
    };

    getHospitalList();
    // eslint-disable-next-line
  }, []);

  const fetchHospitalList = async () => {
    let res = await finddoctorservice.getLocations();

    if (res && res.data && res.data.status && res.data.status === "success") {
      setHospitalList(res.data.data);
      setHospitalError(false);
    } else {
      setHospitalList(res.data.data);
      setHospitalError(true);
    }
    return res;
  };

  function validateForm() {
    let isValid: boolean = true;
    if (fName.trim() === "") {
      setFnameError(true);
      isValid = false;
    }
    if (lName.trim() === "") {
      setLnameError(true);
      isValid = false;
    }
    if (email.trim() === "" || emailError) {
      setEmailError(true);
      isValid = false;
    }
    if (mobile.trim() === "" || mobileError) {
      setMobileError(true);
      isValid = false;
    }
    if (selectedApptDate === null || selectedApptDateError) {
      setSelectedApptDateError(true);
      isValid = false;
    }
    if (hospitalId.trim() === "" || hospitalError) {
      setHospitalError(true);
      isValid = false;
    }
    if (packages.trim() === "" || packageError) {
      setPackageError(true);
      isValid = false;
    }
    if (subPackage.trim() === "" || subPackageError) {
      setSubPackageError(true);
      isValid = false;
    }
    return isValid;
  }

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (e.target.value === "") {
      errorSetterName(true);
    } else {
      errorSetterName(false);
    }
  };

  const handleMobileChange = (e: any) => {
    setMobile(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setMobileError(true);
      setMobileErrorText("");
    } else {
      if (e.target.value.length !== 10 && e.target.value.length < 8) {
        setMobileError(true);
        setMobileErrorText("Enter a valid mobile no.");
      } else {
        setMobileError(false);
        setMobileErrorText("");
      }
    }
  };

  const handleApptDateChange = (date: any | null) => {
    if (date !== null && date.toString() !== "Invalid Date") {
      const offset = date.getTimezoneOffset();
      date = new Date(date.getTime() - offset * 60 * 1000);
      setSelectedApptDate(date);
      setSelectedApptDateError(false);
      setSelectedApptDateErrorText("");
    } else {
      setSelectedApptDateError(true);
      setSelectedApptDateErrorText(t("profile:dobFormatError"));
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setEmailError(true);
      setEmailErrorText("");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      setEmailError(true);
      setEmailErrorText("Please a enter valid email");
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
  };

  const handleSubmit = async () => {
    let hospital = hospitalList.find(
      (item: any) => item.locationId === hospitalId
    );
    let hospitalName = hospital ? hospital.name : "";

    if (validateForm()) {
      authCtx.toggleLoader(true);
      let data = {
        facilityId: hospitalId,
        firstName: fName,
        lastName: lName,
        callingCode: callingCode,
        mobile: mobile,
        email: email,
        hospitalLocation: hospitalName,
        packageName: packages,
        subPackageName: subPackage,
        apptDate: moment(selectedApptDate).toISOString().slice(0, 10),
      };
      let res = await dashboardservice.bookHealthPackage(data);

      if (res === true) {
        setShowModal(true);
        setShowModalType("success");
        setShowModalText("Health package booked successfully");
        setTimeout(() => {
          props.close();
        }, 3000);
      } else {
        setShowModal(true);
        setShowModalType("error");
        setShowModalText("Health package was not booked");
        setTimeout(() => {
          props.close();
        }, 3000);
      }
      authCtx.toggleLoader(false);
    } else {
      setShowModal(true);
      setShowModalType("error");
      setShowModalText("All fields are required");
    }
  };

  return (
    <>
      <div className="row g-sm-3 ">
        {/* sign-up-form */}
        <div className="col-sm mb-3">
          <TextField
            required
            id="fName"
            label={t("profile:firstName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={fNameError}
            value={fName}
            onChange={(e: any) => {
              handleInputChange(e, setFname, setFnameError);
            }}
          />
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            id="lName"
            label={t("profile:lastName")}
            className="w-100   "
            variant="outlined"
            size="small"
            error={lNameError}
            value={lName}
            onChange={(e: any) => {
              handleInputChange(e, setLname, setLnameError);
            }}
          />
        </div>
      </div>
      <div className="row g-sm-3 ">
        <div className="col-sm mb-3">
          <div className="input-group">
            {cList && callingCode && (
              <Autocomplete
                id="country-select-demo"
                options={cList}
                disableClearable
                autoHighlight
                size="small"
                defaultValue={cList.find(
                  (x: any) => x.phoneCode === callingCode
                )}
                onChange={(event, value) => {
                  setCallingCode(value.phoneCode);
                }}
                filterOptions={createFilterOptions({
                  stringify: (option) => "+" + option.phoneCode + option.name,
                })}
                getOptionLabel={(option) => "+" + option.phoneCode}
                PaperComponent={({ children }) => (
                  <Paper style={{ minWidth: "400px" }}>{children}</Paper>
                )}
                style={{ minWidth: "100px" }}
                renderOption={(option) => (
                  <>
                    {option.name} (+{option.phoneCode})
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            )}

            <TextField
              required
              autoComplete="off"
              id="mobile"
              label="Mobile"
              name="mobile"
              className="form-control"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 10, minLength: 8 }}
              error={mobileError}
              helperText={mobileErrorText}
              value={mobile}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e: any) => {
                handleMobileChange(e);
              }}
            />
          </div>
        </div>
        <div className="col-sm mb-3">
          <TextField
            required
            id="email"
            label="Email"
            className="w-100"
            variant="outlined"
            size="small"
            error={emailError}
            helperText={emailErrorText}
            value={email}
            onChange={(e: any) => {
              handleEmailChange(e);
            }}
          />
        </div>
      </div>
      <div className="row g-sm-3 ">
        <div className="col-sm mb-3">
          <KeyboardDatePicker
            autoOk={true}
            required
            size="small"
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="dense"
            id="dob"
            views={["year", "month", "date"]}
            label={t("profile:apptDate")}
            error={selectedApptDateError}
            helperText={selectedApptDateErrorText}
            value={selectedApptDate}
            onChange={handleApptDateChange}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            className="m-0 w-100"
            minDate={new Date()}
          />
        </div>
        <div className="col-sm mb-3 w-50">
          <TextField
            required
            select
            id="hospital"
            label="Select Hospital"
            className="w-100"
            variant="outlined"
            error={hospitalError}
            value={hospitalId}
            size="small"
            onChange={(e: any) => {
              handleInputChange(e, setHospitalId, setHospitalError);
            }}
          >
            {hospitalList &&
              hospitalList.map((item: any, index: any) => (
                <MenuItem key={index} value={item.locationId}>
                  {item.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className="row g-sm-3 ">
        <div className="col-sm mb-3">
          <TextField
            required
            id="packages"
            label="Package"
            className="w-100  "
            variant="outlined"
            error={packageError}
            value={packages}
            size="small"
          />
        </div>
        <div className="col-sm mb-3 w-50">
          <TextField
            required
            select
            id="subPackages"
            label="Select sub Package"
            className="w-100  "
            variant="outlined"
            error={subPackageError}
            value={subPackage}
            size="small"
            onChange={(e: any) => setSubPackage(e.target.value)}
          >
            {subPackagesList &&
              subPackagesList.map((item: any, index: any) => (
                <MenuItem key={index} value={item.name}>
                  {Parser(item.name)}
                </MenuItem>
              ))}
          </TextField>
        </div>
      </div>
      <div className="text-end modal-footer border-top-0 pe-0">
        <button
          className="btn btn-primary"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>
      </div>

      {showModalType === "success" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={() => setShowModal(false)}
        >
          <Alert onClose={() => setShowModal(false)} severity="success">
            {showModalText}
          </Alert>
        </Snackbar>
      )}
      {showModalType === "error" && (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={() => setShowModal(false)}
        >
          <Alert onClose={() => setShowModal(false)} severity="error">
            {showModalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default BookHealthPackages;
