import React from 'react'
import { Link } from 'react-router-dom'

function HealthPackagesCard() {
  return (
    <div className="card scale-hover animate border-0 shadow-sm rounded-1rem overflow-hidden cursor-pointer">
        <Link
          to="/auth/healthPackages"
          className="text-decoration-none"
        >
        
          <div className="d-flex justify-content-center align-item-center btn-primary py-3"><i className="fa-solid fa-heart-circle-plus fs-95 p-2"></i></div>
          <div className="card-body p-3 position-relative">
          <div className="position-absolute left-0 w-100 gradient-bw h-64p t-n64"></div>
      <div className="card-hover position-absolute w-100 left-0 top-0 bg-primary animate"></div>
            <h6 className="fw-bold mb-1 text-truncate">Health Package</h6>
            <p className="m-0 text-muted fs-12 text-truncate">Book a health check-up</p>
          </div>
        </Link>
      </div>
  )
}

export default HealthPackagesCard