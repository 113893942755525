// let wpBaseUrl = "https://vpswebdev.vpshealth.com/wp-json/mobileapi";

export const FETCH_TIMEOUT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? 300000
    : process.env.REACT_APP_FETCHTIMEOUT;

/* Internet facing public URL DEV */
export let baseUrl = process.env.REACT_APP_BASEURL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  baseUrl = "https://vpsportalnixdev-ppapi.azurewebsites.net/api";
}

export var API_ENDPOINTS = {
  LOGIN: baseUrl + "/auth/login",
  LOGOUT: baseUrl + "/auth/logout",
  CHECK_USER_EXIST: baseUrl + "/auth/identify",
  VERIFY_USER: baseUrl + "/auth/verifymobile",
  CHANGE_PASS: baseUrl + "/auth/changepassword",
  GET_COUNTRY: baseUrl + "/masterdata/country",
  SIGNUP: baseUrl + "/user/register",
  GET_GENDER: baseUrl + "/masterdata/gender",
  GET_DOCUMENT_TYPE: baseUrl + "/masterdata/documenttype",
  FORGOT_PASS_OTP: baseUrl + "/user/sendotpforpassword",
  FORGOT_PASS_VERIFY_OTP: baseUrl + "/user/verifyotpforpassword",
  RESET_PASS: baseUrl + "/user/resetpassword",
  GET_WP_BASE: baseUrl + "/masterdata/fetchwpbase",
  GET_REFRESH_TOKEN: baseUrl + "/auth/refreshtoken",

  //profile
  GET_PROFILE: baseUrl + "/user/profile",
  SAVE_PROFILE: baseUrl + "/user/saveprofile",
  SAVE_PROFILE_IMG: baseUrl + "/user/uploadprofileimage",
  DELETE_PROFILE_IMG: baseUrl + "/user/removeprofileimage",
  //family members
  GET_FAMILY_MEMBERS: baseUrl + "/user/familymembers",
  SAVE_FAMILY_MEMBER: baseUrl + "/user/savefamilymemberdetails",
  DELETE_FAMILY_MEMBER: baseUrl + "/user/deletefamilymember?familyMemberId=",
  GET_RELATION: baseUrl + "/masterdata/relations",

  //Doc upload
  UPLOAD_DOCUMENT: baseUrl + "/user/uploaddocument",
  GET_DOCUMENTS: baseUrl + "/user/documents",
  REMOVE_DOCUMENT: baseUrl + "/user/removedocument",
  SEND_OTP_TO_FAMILY: baseUrl + "/user/sendotptofamily",
  GET_PATIENT_HIS_INFO: baseUrl + "/user/getpatienthisinfo",
  GET_PATIENT_SERACH: baseUrl + "/user/getpatientsearch",

  //book appointment
  GET_TIMESLOT: baseUrl + "/appointment/timeslots",

  BOOK_APPOINTMENT: baseUrl + "/appointment/bookappt",

  DELETE_APPOINTMENT: baseUrl + "/appointment/cancelappt",

  UPDATE_APPOINTMENT: baseUrl + "/appointment/updateappt",

  GET_PATIENT_REC: baseUrl + "/user/getpatientrec?patientId=",

  APPT_HISTORY: baseUrl + "/appointment/appointments",

  GET_CONSULTED_DOC: baseUrl + "/user/recentconsultations?",

  ASK_DOCTOR: baseUrl + "/user/saveaskadoc",

  FEEDBACK: baseUrl + "/user/savefeedback",

  CONTACT_US: baseUrl + "/user/savecontactus",

  GET_PROFILE_DOCUMENT: baseUrl + "/user/getdocument?url=",

  BOOK_HEALTH_PACKAGE: baseUrl + "/user/requesthealthpkg",
  SAVE_USER_DOB: baseUrl + "/user/savedob",

  GET_DEPARTMENT_CODES: baseUrl + "/masterdata/getdepartmentcodes",
  GET_APP_CONFIGURARION: baseUrl + "/masterdata/getappconfigurations",

  //Notifucatios

  GET_PATIENT_ACTIVE_NOTI: baseUrl + "/user/patientactivenotifications",
  MARK_NORIFICATION_READ:
    baseUrl + "/user/marknotificationread?patientNotificationId=",
  GET_ACTIVE_LABREPORTS: baseUrl + "/user/activelabreports",
  MARK_ALL_NOTIFICATION_READ: baseUrl + "/user/markallnotificationread",
  GET_NOTIFICATION_COUNT:
    baseUrl + "/notification/GetAllActiveNotificationsCount",

  //UAEPASS Api
  GET_UAE_PASS_RESPONSE: baseUrl + "/auth/authorizeuaepassuser",
  REGISTER_UAEPASS_USER: baseUrl + "/auth/registeruaepassuser",

  //Complete User Profile If UAE pass User doesn't have profile in Burjeel
  COMPLETE_USER_PROFILE: baseUrl + "/User/completeprofile",

  //Lab reports Apis
  //fetching all the lab reports from past 6 months
  GET_LAB_REPORTS: baseUrl + "/appointment/labreports",
  GET_LAB_REPORT_PDF: baseUrl + "/appointment/labreportspdf",

  //fething PDF format of the selected lab report
  GET_SELECTED_PDF_REPORT: baseUrl + "/appointment/labreportspdf",

  //MyMedication Api
  GET_MEDICATION_DETAILS: baseUrl + "/appointment/medications?brandName=",

  //Feedback Complaints Submission Api
  POST_COMPLAINTS_SUBMISSION: baseUrl + "/user/complaints",
  GET_PARSE_MOBILE_NO: baseUrl + "/user/parsemobileno",
  //newNotification Changes
  GET_USER_LIST: baseUrl + "/user/getuserlist",

  SET_NOTIFICATION_BY_GENDER: baseUrl + "/notification/bygenderlist",

  SET_NOTIFICATION_TYPE: baseUrl + "/notification/notifytype",
  SET_NOTIFICATION_BY_AGE: baseUrl + "/notification/ByAgeList",
  SET_NOTIFICATION_INAPPPATH: baseUrl + "/notification/InAppPath",

  SAVE_PROFILEBROADCASTNOTIFICATION: baseUrl + "/notification/broadcast",
  SAVE_NOTIFICATION_IMG: baseUrl + "/notification/uploadnotifyimage",
  GET_BROADCASTHISTORY: baseUrl + "/notification/broadcasthistory",
  GET_ADMINLIST: baseUrl + "/user/getadminlist",
  UPLOAD_NOTIFY_USER_EXCEL: baseUrl + "/notification/uploadnotifyuser",
  //User Role
  RESET_PASSWORD: baseUrl + "/user/complaints",
  GET_USER_ROLE: baseUrl + "GET_USER_ROLE",
  ADD_USER: baseUrl + "ADD_USER",
  ADD_ADMIN: baseUrl + "/user/addadmin",
  SAVE_PREFERRED_FACILITY: baseUrl + "/user/savepreferredfacility",
  DELETE_ACCOUNT: baseUrl + "/auth/deleteaccount",
  GET_PATIENT_DETAILS: baseUrl + "/user/getpatientdetails",
};

export var wpAPI_ENDPOINTS = {
  //wordpress-api
  //Removed wpBaseUrl intentionally as the call should be made only once wpBaseUrl is set in local storage
  GET_WEB_BANNER_IMG: "/getwebbanner?banner_for=",
  //  GET_WEB_BANNER_IMG: wpBaseUrl + "/getalltipsauth",
  FIND_DOC: "/autocompletesearch?lang=eng&searchkey=",
  GET_ALL_DOCTOR: "/getAllDocters?lang=eng&searchkey=&id=",
  GET_LOCATIONS: "/getallhospitallocation?position=1",
  GET_DOC_BY_CONDITION: "/getAllDoctersByLocation?vblog=",
  GET_DOC_BY_LOCATION: "/getAllDoctersByLocation?vblog=",
  GET_ALL_SPECIALITIES: "/getallspecialties",
  GET_ALL_INSURENCE: "/getallinsurance",
  GET_ALL_NEWS: "/getallmedianews?lang=eng",
  GET_SPECIFIC_NEWS: "/getallmedianews?lang=eng&newsid=",
  GET_ALL_MEDIA: "/getallmedia?lang=eng",
  GET_SPECIFIC_MEDIA: "/getallmedia?lang=eng&mediaid=",
  GET_ALL_HEALTHPACKAGES: "/getallpackages?packagename=",
  GET_SPECIFIC_HEALTHPACKAGE: "/getallpackages?packagename=",
  GET_ALL_SPECIALITIES_BY_LOCATION: "/getallspecialties?vblog=",
  GET_DOCTOR_PROFILE: "/getdoctorprofile?lang=eng&vblog=",
  GET_TERMSNCONDITION: "/gettermsconditions",
  GET_PRIVACYPOLICY: "/getprivacypolicy",

  GET_FEEDBACK_LOCATIONS: "/getallhospitallocation?position=",
  GET_FEEDBACK_QUESTIONNAIRE: "/getallfeedback?vblog=",
  POST_FEEDBACK_RESPONSE: "/getfeedbackresponse",
  GET_FEEDBACK_PATIENT_TYPE: "/getfeedbackpatienttype?vblog=",
  GET_TELECONSULT_DOCTOR: "/getallteleconsultationdocters?vblog=2",
  GET_ALL_RESTRICTED_INSURANCE: "/getrestrictedinsurance",
};

export const burjeelAndroidUrl =
  "https://play.google.com/store/apps/details?id=com.burjeelPatientPortalNative";
export const burjeelIosUrl =
  "https://apps.apple.com/in/app/burjeel-hospitals/id1603973269";
