import { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { loginservice } from "../../services/login.service";
import AuthContext from "../../store/auth-context";
import { useTranslation } from "react-i18next";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function Otp(props: any) {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation(["login"]);
  const history = useHistory();

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<boolean>(false);
  const [otpErrortext, setOtpErrorText] = useState<string>("");

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>("");
  const [modalType, setModalType] = useState<string>("error");
  const [second, setSecond] = useState<string>("00");
  const [minute, setMinute] = useState<string>("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    let intervalId: any;
    if (isActive) {
      intervalId = setInterval(() => {
        if (counter > 0) {
          const secondCounter = counter % 60;
          const minuteCounter = Math.floor(counter / 60);
          const computedSecond =
            String(secondCounter).length === 1
              ? `0${secondCounter}`
              : secondCounter;
          const computedMinute =
            String(minuteCounter).length === 1
              ? `0${minuteCounter}`
              : minuteCounter;
          setSecond(computedSecond.toString());
          setMinute(computedMinute.toString());
          setCounter((counter) => counter - 1);
        } else {
          setIsActive(false);
          setCounter(60);
          setSecond("00");
          setMinute("00");
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  let checkUser = async () => {
    authCtx.toggleLoader(true);
    let res = await loginservice.checkUser(
      props.ccode,
      props.loginMobile,
      props.isInvokedForLogin
    );
    authCtx.toggleLoader(false);
    if (res.otpSent) {
      setIsActive(true);
      setModalText("OTP is sent to your mobile number");
      setModalType("success");
      setShowModal(true);
    } else {
      setModalText("Error in sending otp");
      setModalType("error");
      setShowModal(true);
    }
  };

  const handleInputChange = (
    e: any,
    setterName: any,
    errorSetterName?: any
  ) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
        if (e.target.name === "otp") {
          setOtpErrorText(t("otpError"));
        }
      } else {
        const re = /^[0-9\b]+$/;
        if (!re.test(e.target.value)) {
          errorSetterName(true);
          if (e.target.name === "otp") {
            setOtpErrorText(t("numberError"));
          }
        } else {
          errorSetterName(false);
          setOtpErrorText("");
        }
      }
    }
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  function validateOtp() {
    let isValid: boolean = true;
    if (otp.trim() === "" || otpError) {
      setOtpError(true);
      isValid = false;
    }
    return isValid;
  }

  let handleOtp = async () => {
    authCtx.toggleLoader(true);
    if (otp.length < 6) {
      setOtpError(true);
      setOtpErrorText(t("otpInvalidError"));
      authCtx.toggleLoader(false);
    } else {
      if (validateOtp()) {
        let res = await loginservice.verifyUser(
          props.ccode,
          props.loginMobile,
          otp
        );
        authCtx.toggleLoader(false);
        if (res.validOtp && res.message === "Success") {
          if (props.userData !== null) {
            authCtx.setUserInfo(props.userData);
          } else {
            authCtx.setUserInfo(null);
          }

          history.push({ pathname: "/home/sign-up", state: otp });
        } else {
          if (!res.validOtp) {
            setModalText(t("otpVerifyError"));
            handleShow();
          }
        }
      }
    }
  };

  const back = (e: any) => {
    e.preventDefault();
    props.onClick();
  };

  return (
    <>
      <div id="otp">
        <Link
          to="#"
          onClick={(e) => back(e)}
          className="mt-5 d-block text-decoration-none backLogin"
        >
          <i className="bi bi-arrow-left fs-3 me-2"></i>{" "}
          <span className="fs-5 position-relative t-n1">Back</span>
        </Link>
        <h3 className="mt-3 fw-500">{t("OTP")}</h3>
        <small className="text-muted mb-5">
          {" "}
          {t("otpTitle")}
          <span className="fw-bolder">
            {" "}
            +{props.ccode}-<span id="numberEntered">{props.loginMobile}</span>
          </span>
        </small>
        <div className="mb-2 mt-4">
          {/* <label className="form-label">{t("enterOTP")}</label> */}
          <TextField
            autoComplete="off"
            id="otp"
            label={t("enterOTP")}
            name="otp"
            className="otp w-100 mb-4 letter-spacing-4"
            variant="outlined"
            size="medium"
            inputProps={{ maxLength: 6 }}
            error={otpError}
            value={otp}
            helperText={otpErrortext}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onChange={(e: any) => {
              handleInputChange(e, setOtp, setOtpError);
            }}
          />
        </div>
        <p className="text-muted">
          {t("resendOtp")}{" "}
          <span className="float-end">
            <span className="minute">{minute}</span>
            <span>:</span>
            <span className="second">{second}</span>
          </span>
        </p>

        <Link
          to="#"
          className={
            isActive ? " btn btn-link  p-0 disabled" : " btn btn-link  p-0"
          }
          onClick={(e) => {
            e.preventDefault();
            checkUser();
          }}
        >
          {" "}
          {t("resendOtpLink")}
        </Link>

        <button
          type="submit"
          className="btn btn-primary w-100 mt-4 fs-18"
          disabled={!otp}
          onClick={() => handleOtp()}
        >
          {" "}
          {t("continue")}
        </button>
      </div>
      {modalType === "error" ? (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modalText}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={showModal}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modalText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default Otp;
