import { Link, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import { dashboardservice } from "../../services/dashboard.service";
import Parser from "html-react-parser";

function NewsAndMediaCard(props: any) {
  const [newsList, setNewsList] = useState<any>([]);
  const [customNewsList, setCustomNewsList] = useState<any>([]);
  const [modelType, setModelType] = useState<any>("");
  const [showModel, setShowModel] = useState<boolean>(false);
  const [modelText, setModelText] = useState<any>("");

  const history = useHistory();
  useEffect(() => {
    const ac = new AbortController();
    const getAllNews = () => {
      fetchAllNewsList();
    };
    getAllNews();

    return () => ac.abort();
  }, []);

  const handleClose = () => setShowModel(false);

  const fetchAllNewsList = async () => {
    let res = await dashboardservice.getAllNews();
    if (res && res.data && res.data.status === "success") {
      setNewsList(res.data.data);
      res.data.data && res.data.data.length > 5
        ? setCustomNewsList(res.data.data.slice(0, 5))
        : setCustomNewsList(res.data.data);
      setModelType("success");
      setShowModel(false);
      setModelText(res.data.message);
    } else {
      if (res && res.data) {
        setModelType("error");
        setShowModel(true);
        setModelText(res.data.message);
      }
    }
  };

  const handleNewsChnage = (index: any) => {
    history.push({
      pathname: "/auth/news",
      state: { index: index, newslist: newsList },
    });
  };

  return (
    <>
      <div className="card border-0 shadow-sm rounded-1rem">
        <div className="card-body p-4">
          <h6 className="fw-bold text-primary">News</h6>

          {customNewsList &&
            customNewsList.map((item: any, index: any) => (
              <Link
                onClick={() => handleNewsChnage(index)}
                className="text-black cursor-pointer"
                key={index}
              >
                <div className="card border-0 rounded-1rem p-2">
                  <div className="card-body px-0">
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          src={item.imagepath}
                          alt="img"
                          className="img-95 rounded"
                        />
                      </div>
                      <div className="flex-grow ms-3">
                        <label className="fw-600 curser-pointer text-truncate-two-line">
                          {Parser(item.title)}
                        </label>
                        <p className="text-muted fs-12 m-0">
                          {item.publishDate}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          {customNewsList &&
            newsList &&
            newsList.length > customNewsList.length && (
              <button
                className="btn btn-link no-focus float-end"
                onClick={() => history.push({ pathname: "/auth/allNews" })}
              >
                View more
              </button>
            )}
        </div>
      </div>

      {modelType === "error" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {modelText}
          </Alert>
        </Snackbar>
      )}
      {modelType === "success" && (
        <Snackbar
          open={showModel}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            {modelText}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default NewsAndMediaCard;
