import React, { useEffect, useState } from "react";
import Parser from "html-react-parser";
import { useLocation } from "react-router-dom";

function News() {
  const [newsList, setnewsList] = useState<any>([]);
  const [newsIndex, setNewsIndex] = useState<any>();
  const [newsData, setNewsData] = useState<any>({});
  const location: any = useLocation();

  useEffect(() => {
    setnewsList(location.state.newslist);
    setNewsIndex(location.state.index);
    setAllNewsData(location.state.newslist, location.state.index);
    scrollTop();
    // eslint-disable-next-line
  }, []);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const setAllNewsData = (list: any, index: any) => {
    let data = list[index];
    setNewsData(data);
  };

  const previous = () => {
    let ind = newsIndex - 1;
    setNewsIndex(ind);
    let data = newsList[ind];
    setNewsData(data);
  };

  const next = () => {
    let ind = newsIndex + 1;
    setNewsIndex(ind);
    let data = newsList[ind];
    setNewsData(data);
  };

  const openMobileMenu = (e: any) => {
    e.preventDefault();

    document.getElementById("leftMenu")?.classList.remove("d-none");
  };

  return (
    <div className="main-content animate py-4 px-3 px-sm-4">
      <div className="d-flex align-items-center mb-3">
        <a
          href="#"
          className="d-inline-block d-sm-none text-dark"
          onClick={(e) => openMobileMenu(e)}
        >
          <i className="material-icons-outlined me-2">menu</i>
        </a>
        <h3 className="fs-18-24">News</h3>
      </div>
      <div className="row">
        <div className="col-md-12">
          {Parser(`<h4 className="mt-2">${newsData.title}</h4>`)}
          <hr />
          <div className="d-flex justify-content-center align-item-cenetr ">
            <img src={newsData.imagepath} alt="news" className="img-fluid" />
          </div>
          <div className="mt-5" style={{ whiteSpace: "pre-line" }}>
            {Parser(`<p className="fs-14">${newsData.description}</p>`)}
          </div>
          <div className="d-flex justify-content-between mt-4">
            <button
              className="btn btn-primary"
              onClick={() => previous()}
              disabled={newsIndex === 0}
            >
              Previous
            </button>
            <button
              className="btn btn-primary"
              onClick={() => next()}
              disabled={newsIndex === newsList.length - 1}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
