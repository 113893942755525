import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const familyMemberservice = {
  getFamilyList,
  saveFamilyMember,
  deleteFamilyMember,
  sendOtpToFamily,
};

async function getFamilyList() {
  const apiPath = API_ENDPOINTS.GET_FAMILY_MEMBERS;
  const options = {
    path: apiPath,
    method: "GET",
    bodyObj: {},
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}

async function saveFamilyMember(data: any) {
  const apiPath = API_ENDPOINTS.SAVE_FAMILY_MEMBER;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      FamilyMemberId: data.familyMemberId,
      FirstName: data.firstName,
      LastName: data.lastName,
      Email: data.email,
      Mobile: data.mobile,
      CallingCode: data.callingCode,
      DateOfBirth: data.dateOfBirth,
      GenderId: data.genderId,
      CountryId: data.countryId,
      DocumentTypeId: data.documentTypeId,
      DocumentId: data.documentId,
      RelationId: data.relationId,
      otp: data.otp,
      // "DigitalConsentFileUrl":data.digitalConsentFileUrl,
      // "DigitalConsentFileName":data.digitalConsentFileName
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function deleteFamilyMember(id: any) {
  const apiPath = API_ENDPOINTS.DELETE_FAMILY_MEMBER + id;
  const options = {
    path: apiPath,
    method: "Delete",
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
async function sendOtpToFamily(callingCode: string, mobile: string) {
  const apiPath = API_ENDPOINTS.SEND_OTP_TO_FAMILY;
  const options = {
    path: apiPath,
    method: "POST",
    bodyObj: {
      callingCode: callingCode,
      mobile: mobile,
    },
    customHeaders: {
      "Content-Type": "application/json",
    },
  };
  return await makeRequest(options);
}
